import { z } from "zod";
import { zFutureDateString, zRequiredString } from "../rules/string";

export const createPTAInvoiceProductSchema = z.object({
  name: zRequiredString,
  dueDate: zFutureDateString,
});
export type CreatePTAInvoiceProductType = z.infer<
  typeof createPTAInvoiceProductSchema
>;

export const updatePTAInvoiceProductSchema = z.object({
  id: zRequiredString,
  name: zRequiredString,
  dueDate: zFutureDateString,
});
export type UpdatePTAInvoiceProductType = z.infer<
  typeof updatePTAInvoiceProductSchema
>;

export const getPTAInvoiceProductSchema = z.object({
  id: zRequiredString,
});
export type GetPTAInvoiceProductType = z.infer<
  typeof getPTAInvoiceProductSchema
>;
