import {
  createPartSchema,
  CreatePartSchemaType,
  deletePartSchema,
  DeletePartSchemaType,
  updatePartSchema,
  UpdatePartSchemaType,
} from "@shared/validator/features/part.schema";
import { PartSchemaType as Part } from "@shared/validator/models/part.schema";
import * as client from "./client";

export async function createPart(args: CreatePartSchemaType) {
  createPartSchema.parse(args);
  const res = await client.post<CreatePartSchemaType, Part>(
    "/part/create",
    args
  );
  return res;
}

export async function updatePart(args: UpdatePartSchemaType) {
  updatePartSchema.parse(args);
  const res = await client.post<UpdatePartSchemaType, Part>(
    "/part/update",
    args
  );
  return res;
}

export async function deletePart(args: DeletePartSchemaType) {
  deletePartSchema.parse(args);
  const res = await client.post<DeletePartSchemaType, Part>(
    "/part/delete",
    args
  );
  return res;
}
