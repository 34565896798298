// FIXME: Long lines (>1000 lines) CODE SMELLS
import React, { useState } from "react";
import { useCurrentUser } from "../../../hooks/recoil/user";
import * as usersApi from "../../../apiClients/users";
import { CurrentUser } from "../../../apiClients/auth";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useModal } from "../../../components/Modal";
import { PageHeader } from "../../../components/Page";
import {
  useCurrentCommunityId,
  usePolyfitLocationQuery,
  usePolyfitUrl,
} from "../../../hooks/router";
import { useGetChildren } from "../../../hooks/api/child";
import { BreadCrumb } from "../../../components/Common/BreadCrumb";
import CareerInfoContent from "../../../features/Profile/Career/CareerInfoContent";
import AppliedRecruitmentContent from "../../../features/Recruitment/application/AppliedRecruitmentContent";
import DeniedContent from "../../../features/Recruitment/application/DeniedContent";
import { zIndexes } from "../../../zIndex";
import { Title } from "../../../components/Title";
import { EditRoleModal } from "../../../features/Member/EditRoleModal";
import { EditBaseInfoModal } from "../../../features/Member/EditBaseInfo";
import { LeaveModal } from "../../../features/Member/LeaveModal";
import { useRecruitmentApplicantListByUserId } from "../../../hooks/api/recruitmentApplication";
import {
  isCsAdminRole,
  isPtaAdminRole,
  isAdminRole,
  isPtaMemberRole,
} from "../../../utils/types/role";
import { useOrganization } from "../../../hooks/recoil/organization";
import { Spinner } from "../../../components/icons/Spinner";
import { useMemberUserById } from "src/hooks/query/users/useMemberUserById";
import ChildInfoContent from "src/features/Profile/ChildInfo/ChildInfoContent";
import { MemberInfoContent } from "src/features/Member/MemberInfoContent";
import { CsChildInfoContent } from "src/features/Profile/ChildInfo/CsChildInfoContent";
import { useAdminUsersByOrganizationId } from "src/hooks/api/users";
// publicディレクト配下の絶対パスを指定
export const SettingButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: #e1e2e5;
  color: #343741;
  border-radius: 10px;
  border: none;
  margin-left: auto;
  padding-bottom: 10px;
  cursor: pointer;
`;

const MenuBackGround = styled.div`
  z-index: ${zIndexes.notModalMenuBackground};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

/**
 * PTA名簿のメンバー詳細ページ
 */
export default function PTAMemberPage() {
  const cu: CurrentUser = useCurrentUser();
  const { userId } = usePolyfitLocationQuery("PTA_LIST_MEMBER", {
    userId: "",
  });
  const { communityId } = useCurrentCommunityId();
  const {
    memberUser,
    getUser,
    isLoading: isMemberLoading,
    isError,
    error,
  } = useMemberUserById(userId);
  const { organization } = useOrganization({});
  const { children } = useGetChildren(
    userId,
    !memberUser || isMemberLoading || memberUser.type !== "PARENT"
  );
  const { recruitmentList, isLoading, refetch } =
    useRecruitmentApplicantListByUserId(userId, cu);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditInfoOpen, setIsEditInfoOpen] = useState(false);
  const [isEditRoleOpen, setIsEditRoleOpen] = useState(false);
  const [isLeaveOpen, setIsLeaveOpen] = useState(false);
  const isAdmin = isAdminRole(cu.role);
  const isPtaAdmin = isPtaAdminRole(cu.role);
  const isPtaMember = isPtaMemberRole(cu.role);
  const isCSAdmin = isCsAdminRole(cu.role);
  const isCurrentUser = cu.id === memberUser?.id;
  const isAdminOrCurrentUser = isAdmin || isCurrentUser;
  const { adminUsers } = useAdminUsersByOrganizationId({
    organizationId: organization?.id ?? "",
  });
  const isLastAdminUser = (): boolean => {
    if (!memberUser) {
      return false;
    }
    if (!isPtaAdminRole(memberUser.role)) {
      return false;
    }
    return adminUsers.length <= 1;
  };

  const isLastCSAdminUser = (): boolean => {
    if (memberUser == undefined) {
      // usersを読み込むまでfalse
      return false;
    }
    if (!isCsAdminRole(memberUser.role)) {
      return false;
    }
    return adminUsers.length <= 1;
  };

  const [isShown, { show, close }] = useModal();
  const [isEditInfoModal, setIsEditInfoModal] = useState(false);
  const [isEditRoleModal, setIsEditRoleModal] = useState(false);
  const [isLeaveModal, setIsLeaveModal] = useState(false);
  const backUrl = usePolyfitUrl({ to: "PTA_LIST" });

  if (isPtaMember) {
    return <>この画面の閲覧権限がありません</>;
  }

  if (isError) {
    //エラー処理
    if ((isCSAdmin || isPtaAdmin) && (!memberUser || memberUser.id == "")) {
      return <>指定されたユーザーは存在しません</>;
    } else if (
      error?.message == "権限がありません" ||
      error?.message == "このメンバー情報の閲覧権限がありません"
    ) {
      return <>この画面の閲覧権限がありません</>;
    } else {
      console.error(error);
      return <>エラーが発生しました</>;
    }
  } else {
    // Loading
    if (isMemberLoading || memberUser == undefined) {
      return (
        <NameListPage>
          <BreadCrumb>
            <Link
              to={{
                pathname: "/pta/list",
                search: `communityId=${communityId}`,
              }}
            >
              名簿
            </Link>
            <LinkWrap>メンバー</LinkWrap>
          </BreadCrumb>
          <NameListHeadingRow>
            <NameListHeading>
              {/* モバイル時のみ戻るボタン表示 */}
              <MobileMemberDiv>
                <PageHeader backTo={backUrl}>
                  <Title>メンバー</Title>
                </PageHeader>
              </MobileMemberDiv>
              <PCMemberDiv>
                <Title>メンバー</Title>
              </PCMemberDiv>
            </NameListHeading>
          </NameListHeadingRow>
          <MemberWrap>
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          </MemberWrap>
        </NameListPage>
      );
    } //エラーがない場合の画面
    return (
      <NameListPage>
        {isEditInfoOpen && <EditInfoModal>情報を編集</EditInfoModal>}
        {isEditRoleOpen && <EditInfoModal>権限を変更</EditInfoModal>}
        {isLeaveOpen && <EditInfoModal>退会</EditInfoModal>}
        <BreadCrumb>
          <Link
            to={{
              pathname: "/pta/list",
              search: `communityId=${communityId}`,
            }}
          >
            名簿
          </Link>
          <LinkWrap>メンバー</LinkWrap>
        </BreadCrumb>
        <NameListHeadingRow>
          <NameListHeading>
            {/* モバイル時のみ戻るボタン表示 */}
            <MobileMemberDiv>
              <PageHeader backTo={backUrl}>
                <Title>メンバー</Title>
              </PageHeader>
            </MobileMemberDiv>
            <PCMemberDiv>
              <Title>メンバー</Title>
            </PCMemberDiv>
          </NameListHeading>

          {isAdmin && (
            <SettingButtonDiv>
              <div>
                <SettingButton
                  onClick={() => {
                    if (isOpen) {
                      setIsEditInfoOpen(false);
                      setIsEditRoleOpen(false);
                      setIsLeaveOpen(false);
                    }
                    setIsOpen(!isOpen);
                  }}
                >
                  …
                </SettingButton>
              </div>
              {isOpen && organization != null && (
                <>
                  <EditMenuTab>
                    {(isPtaAdmin || isCSAdmin) && (
                      <SettingEditInfo
                        onClick={() => {
                          setIsEditRoleModal(!isEditRoleModal);
                          show();
                        }}
                      >
                        権限を変更
                        {isShown && isEditRoleModal && memberUser != null && (
                          <EditRoleModal
                            user={memberUser}
                            onClose={() => {
                              setIsEditRoleModal(!isEditRoleModal);
                              close();
                            }}
                            getUser={async () => {
                              await getUser();
                            }}
                            isLastAdminUser={isLastAdminUser()}
                            isLastCSAdminUser={isLastCSAdminUser()}
                          />
                        )}
                      </SettingEditInfo>
                    )}
                    {(isPtaAdmin || isCSAdmin) && memberUser.account && (
                      <SettingEditInfo
                        onClick={() => {
                          setIsLeaveModal(!isLeaveModal);
                          show();
                        }}
                      >
                        退会
                        {isShown && isLeaveModal && (
                          <LeaveModal
                            user={memberUser}
                            account={memberUser.account}
                            onClose={() => {
                              setIsLeaveModal(!isLeaveModal);
                              close();
                            }}
                            isLastAdminUser={isLastAdminUser()}
                          />
                        )}
                      </SettingEditInfo>
                    )}
                  </EditMenuTab>
                  <MenuBackGround onClick={() => setIsOpen(false)} />
                </>
              )}
            </SettingButtonDiv>
          )}
        </NameListHeadingRow>
        {/* メンバーの基本情報 */}
        <MemberInfoContent
          memberUser={memberUser}
          userId={userId}
          isPtaMember={isPtaMember}
          isPtaAdmin={isPtaAdmin}
          isAdmin={isAdmin}
          isCurrentUser={isCurrentUser}
          recruitmentList={recruitmentList}
          isCSAdmin={isCSAdmin}
          inPTA={usersApi.inPTA(cu)}
          organization={organization}
          openEditBaseInfoModal={() => setIsEditInfoModal(true)}
        />
        {/* PTA: 子ども情報 */}
        {usersApi.inPTA(cu) &&
          usersApi.inPTA(memberUser) &&
          memberUser.type === "PARENT" && (
            <ChildInfoContentWrapper>
              <ChildInfoContent
                user={memberUser}
                isAdminOrCurrentUser={isAdminOrCurrentUser}
              />
            </ChildInfoContentWrapper>
          )}

        {/* CS: 子ども情報 */}
        {isCSAdmin && memberUser.account && (
          <ChildInfoContentWrapper>
            <CsChildInfoContent user={memberUser} />
          </ChildInfoContentWrapper>
        )}

        {/* 過去の経歴 */}
        {usersApi.inPTA(cu) && usersApi.inPTA(memberUser) && (
          <>
            <Spacer />
            <CareerInfoContent userChildren={children} userId={memberUser.id} />
          </>
        )}
        {/* 地域住民の場合は過去の応募状態を表示 */}
        {usersApi.inCommunitySchool(cu) &&
          usersApi.inCommunitySchool(memberUser) && (
            <>
              <Spacer />
              {memberUser.id !== cu.id ? (
                <AppliedRecruitmentContent
                  isLoading={isLoading}
                  recruitmentList={recruitmentList}
                  refetch={refetch}
                  currentCommunityId={communityId}
                />
              ) : (
                <>
                  {/* CS管理者かつ自身の名簿のときはコメントのアクセスを禁止 */}
                  <DeniedContent title="自分へのコメントは閲覧できません" />
                </>
              )}
            </>
          )}
        <Spacer />
        {isEditInfoModal && memberUser != null && (
          <EditBaseInfoModal
            user={memberUser}
            organization={organization}
            onClose={() => {
              setIsEditInfoModal(!isEditInfoModal);
              close();
            }}
            getUser={async () => {
              await getUser();
            }}
            userId={userId}
            cu={cu}
          />
        )}
      </NameListPage>
    );
  }
}
const EditInfoModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 50px;
  border: 1px solid;
  border-radius: 5px;
`;

const MobileMemberDiv = styled.div`
  @media (min-width: 1279px) {
    display: none;
  }
`;

const PCMemberDiv = styled.div`
  padding-bottom: 18px;
  padding-top: 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const Spacer = styled.div`
  height: 20px;
`;

// FIXME: do not use "export const" with styled-components
export const SettingEditInfo = styled.div`
  font-size: 14px;
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
`;

const SettingEditTab = styled.div`
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
`;

const EditMenuTab = styled(SettingEditTab)`
  z-index: ${zIndexes.notModalMenu};
`;

const NameListPage = styled.div`
  position: relative;
`;

// FIXME: do not use "export const" with styled-components
export const NameListHeadingRow = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const NameListHeading = styled.div`
  /* font-weight: bold;
    font-size: 22px;
    color: #1A1C21; */
`;

const LinkWrap = styled.span`
  font-weight: bold;
`;

const MemberWrap = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e4e6f3;
`;

export const PictureWrap = styled.div`
  padding: 20px;
  display: flex;
`;

const SettingButtonDiv = styled.div`
  margin-left: auto;
  text-align: right;
  position: relative;
  width: 152px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
`;

const ChildInfoContentWrapper = styled.div`
  margin-top: 20px;
`;
