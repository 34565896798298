import * as client from "./client";
import {
  getStorageEntitySchema,
  GetStorageEntitySchemaType,
  getStorageDownloadSchema,
  GetStorageDownloadSchemaType,
  deleteStorageImageSchema,
  DeleteStorageImageSchemaType,
} from "../@shared/validator/features/storage.schema";

export async function entity(filePath: string) {
  const args = getStorageEntitySchema.parse({ filePath });
  const res = await client.getBlob<GetStorageEntitySchemaType>(
    "/storage/entity",
    args
  );
  return res;
}

export async function getDownloadUrl(filePath: string, fileName: string) {
  const args = getStorageDownloadSchema.parse({ filePath, fileName });
  const res = await client.get<GetStorageDownloadSchemaType, { url: string }>(
    "/storage/download",
    args
  );
  return res.url;
}

export async function upload({ file }: { file: File }): Promise<string> {
  const res = await client.postWithFormData<
    { file: File },
    { filePath: string }
  >("/storage/upload", { file });
  return res.filePath;
}

// uploadとの差分
// - uploadはuploadしたファイルの相対パスが返ってくる
// ex: /storage/2021/01/01/xxxxx.pdf
// - uploadImageはuploadしたファイルのURLがフルパスで返ってくる
// ex: https://xxxxx.cloudfront.net/storage/2021/01/01/xxxxx.pdf
export async function uploadImage({
  file,
  imageUploadDirectory,
}: {
  file: File;
  imageUploadDirectory?: string;
}): Promise<string> {
  const res = await client.postWithFormData<
    { file: File; imageUploadDirectory?: string },
    { filePath: string }
  >("/storage/uploadImage", { file, imageUploadDirectory });
  return res.filePath;
}

export async function deleteImage(
  args: DeleteStorageImageSchemaType
): Promise<string> {
  deleteStorageImageSchema.parse(args);
  const res = await client.post<DeleteStorageImageSchemaType>(
    "/storage/deleteImage",
    args
  );
  return res.result;
}

export function entityUrl(filePath: string) {
  return `${client.PATH_PREFIX}/storage/entity?filePath=${encodeURIComponent(
    filePath
  )}`;
}
