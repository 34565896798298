import React from "react";
import styled from "styled-components";
import { ApprovalStatus } from "@shared/types/chatRoom";
import type {
  ChatRoom,
  GroupChatWithOrganization,
} from "@shared/types/chatRoom";
import { Button } from "../../../../components/Button";
import { RejectChatRoomModal } from "src/features/common/ChatMessage/components/ChatRoomGroup/RejectChatRoomModal";
import { useModal } from "../../../../components/Modal";
import { usePolyfitHistory } from "../../../../hooks/router";
import { ChatInputForm } from "src/components/form/ChatInputForm";
import {
  approveParticipantChatRoom,
  rejectParticipantChatRoom,
} from "src/apiClients/participantChatRoom";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { useInvalidateGetChatRoom } from "src/hooks/query/chatRoom";
import { updateAdminOnly } from "src/apiClients/chatRoom";

type GroupContentChatMessageInputAreaProps = {
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  canSend: boolean;
  onSend: () => void;
  onApprove: () => void;
  chatRoom: ChatRoom;
  chatRoomParticipantId: string;
  groupChatWithOrganization: GroupChatWithOrganization;
  isAdmin: boolean;
};

export const GroupContentChatMessageInputArea = (
  props: GroupContentChatMessageInputAreaProps
) => {
  const [isOpen, { show: showRejctModal, close }] = useModal();
  const history = usePolyfitHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const toast = useToast();
  const { invalidateGetChatRoom } = useInvalidateGetChatRoom();

  const handleApprove = async () => {
    try {
      setIsSubmitting(true);
      await approveParticipantChatRoom({ chatRoomId: props.chatRoom.id });
      invalidateGetChatRoom();
      setIsSubmitting(false);
      props.onApprove();
    } catch (error) {
      setIsSubmitting(false);
      if (error instanceof APIError) {
        toast.error(error.message);
        return;
      }
      toast.error("グループの参加に失敗しました");
    }
  };

  const handleReject = async () => {
    try {
      setIsSubmitting(true);
      await rejectParticipantChatRoom({ chatRoomId: props.chatRoom.id });
      invalidateGetChatRoom();
      setIsSubmitting(false);
      close();
      history.push({ to: "RESIDENT_CHAT", query: {} });
    } catch (error) {
      setIsSubmitting(false);
      if (error instanceof APIError) {
        toast.error(error.message);
        return;
      }
      toast.error("グループの参加拒否に失敗しました");
    }
  };

  const onCheckedAdminOnly = async (checked: boolean) => {
    await updateAdminOnly({
      chatRoomId: props.chatRoom.id,
      adminOnly: checked,
    });
  };

  return (
    <>
      {props.chatRoom.ParticipantChatRooms.find(
        (participantChatRoom) =>
          participantChatRoom.participantId === props.chatRoomParticipantId
      )?.approvalStatus === ApprovalStatus.PENDING ? (
        <RequestActionContainer>
          <ActionButtonContainer>
            <ActionDeleteButton
              empty={true}
              height="40px"
              onClick={showRejctModal}
              disabled={isSubmitting}
            >
              拒否
            </ActionDeleteButton>
            <Button
              fill={true}
              height="40px"
              width="112px"
              onClick={handleApprove}
              disabled={isSubmitting}
            >
              参加
            </Button>
          </ActionButtonContainer>
        </RequestActionContainer>
      ) : (
        <ChatMessageInputContainer>
          <ChatMessageInputArea>
            <ChatInputForm
              isAdmin={props.isAdmin}
              adminOnly={props.chatRoom.adminOnly}
              onCheckedAdminOnly={onCheckedAdminOnly}
              placeholder="メッセージを入力"
              setValue={props.setInput}
              setFiles={props.setFiles}
              canSend={props.canSend}
              onSend={props.onSend}
            />
          </ChatMessageInputArea>
        </ChatMessageInputContainer>
      )}
      <RejectChatRoomModal
        isOpen={isOpen}
        onClose={close}
        onSubmit={handleReject}
        chatRoomName={props.chatRoom.name}
      />
    </>
  );
};

const ChatMessageInputContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChatMessageInputArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const RequestActionContainer = styled(ChatMessageInputContainer)`
  flex-direction: column;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 24px;
`;

const ActionDeleteButton = styled(Button)`
  margin-right: 16px;
  color: #b4251d;
`;
