import { z } from "zod";
import { zRequiredString, zHiraganaString } from "../rules/string";

export const partnerSchema = z.object({
  id: zRequiredString,
  email: zRequiredString,
  lastName: zRequiredString,
  firstName: zRequiredString,
  lastNameKana: zHiraganaString,
  firstNameKana: zHiraganaString,
  isShare: z.boolean(),
});

export type Partner = z.infer<typeof partnerSchema>;
