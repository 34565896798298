import { UnverifiedUserStatus } from "@shared/types/unverified";
import * as client from "./client";
import {
  deleteUnverifiedUserSchema,
  DeleteUnverifiedUserSchemaType,
  deleteUserFromBounceListSchema,
  DeleteUserFromBounceListSchemaType,
  getUnverifiedUserByEmailSchema,
  GetUnverifiedUserByEmailSchemaType,
} from "@shared/validator/features/unverified.schema";

export async function getUnverifiedUserByEmail(
  args: GetUnverifiedUserByEmailSchemaType
) {
  getUnverifiedUserByEmailSchema.parse(args);
  const res = await client.get<
    GetUnverifiedUserByEmailSchemaType,
    UnverifiedUserStatus
  >("/unverified/getUnverifiedUserByEmail", args);
  return res;
}

export async function deleteUserFromBounceList(
  args: DeleteUserFromBounceListSchemaType
) {
  deleteUserFromBounceListSchema.parse(args);
  const res = await client.post<DeleteUserFromBounceListSchemaType>(
    "/unverified/deleteUserFromBounceList",
    args
  );
  return res;
}

export async function deleteUnverifiedUser(
  args: DeleteUnverifiedUserSchemaType
) {
  deleteUnverifiedUserSchema.parse(args);
  const res = await client.post<DeleteUnverifiedUserSchemaType>(
    "/unverified/deleteUnverifiedUser",
    args
  );
  return res;
}
