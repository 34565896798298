import React, {
  useLayoutEffect,
  useCallback,
  useState,
  useEffect,
} from "react";
import { usePolyfitLocationQuery } from "../hooks/router";
import { useInvitation } from "../hooks/recoil/invitation";
import { useSignupWithInvitationToken } from "../hooks/recoil/user";
import { useRecoilState } from "recoil";
import { formEmailPasswordState } from "../hooks/formEmailPassword";
import { Panel } from "../components/Panel";
import styled from "styled-components";
import * as theme from "../theme";
import { Heading1, Heading2 } from "../components/Typo";
import { Button } from "../components/Button";
import { Google } from "../components/icons/SvgIcons";
import { FlexLayout } from "../components/Container";
import { Link } from "react-router-dom";
import { PasswordInput, EmailInput } from "../components/Form";
import { isExistsOrganizationUser } from "../apiClients/auth";
import { errorCodeToMessage } from "../utils/errorCodeToMessage";
import { ApproveCheckbox as CustomCheckBox } from "./pta/list/DisabledMembersPage";
import { LeadUsingChromeText } from "../components/LeadUsingChromeText";
import { SideLineHeaderText } from "../components/SideLineHeaderText";
import { APIError } from "../utils/types/ApiError";
import { logout } from "../utils/auth";
import { logger } from "src/utils/logger";
import { Banner } from "src/components/Banner";
import { BREAKPOINTS } from "src/components/Responsive";

const Container = styled.div`
  background: ${theme.states.pageBackgroundColor};
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  gap: 460px;
  position: absolute;
  height: 48px;
  left: 0px;
  right: 0px;
  top: 0px;
  background: #2277cc;
  box-shadow: 0px 0.7px 1.4px rgba(0, 0, 0, 0.07),
    0px 1.9px 4px rgba(0, 0, 0, 0.05), 0px 4.5px 10px rgba(0, 0, 0, 0.05);
`;

const Header = styled.header`
  width: 45px;
  height: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const FormDescription = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${theme.colorTokens.text};
  margin-bottom: 20px;
`;

const Spacer = styled.div`
  height: 20px;
`;

const RegisterButton = styled.button`
  height: 36px;
  display: block;
  width: 100%;
  align-items: center;
  color: #ffffff;
  background-color: ${theme.colorsPallet.primary};
  border: 1px solid rgba(105, 112, 125, 0.2);
  border-radius: 6px;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.7;
  }
`;

const ErrorMessage = styled.div`
  margin-top: 12px;
  background-color: #fff0ef;
  color: #bd271e;
  font-size: 12px;
  text-align: start;
  padding: 6px 10px;
`;

const ContainerBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  left: 0;
  right: 0;
  top: calc(48px + 80px);
  width: 100%;
  /* height: 100vh; */

  @media (max-width: ${BREAKPOINTS.TABLET}) {
    top: calc(48px + 62px);
  }

  ${Panel} {
    padding: 24px;
    text-align: center;
    width: 340px;
    min-height: 200px;
  }

  ${Button} {
    height: 36px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: ${theme.colorTokens.text};
    border: 1px solid rgba(105, 112, 125, 0.2);
    border-radius: 6px;
  }

  ${Button} svg {
    margin-right: 8px;
  }

  ${Heading2} {
    margin-bottom: 42px;
  }

  ${Heading1} {
    text-align: center;
    font-weight: 700;
    color: #1a1c21;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: "Inter";
  }
`;

const ConsentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;

const ConsentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`;

const ConsentText = styled.p`
  margin: 0;
  padding: 0;
  margin-top: -5px;
  margin-left: 8px;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #343741;

  a {
    color: #0077cc;
  }
`;

const ConsentErrorMessageWrapper = styled.div`
  background: #fff0ef;
  color: #bd271e;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  padding: 6px 10px;
  margin-top: 10px;
`;

const GoogleConsentMessage = styled(ConsentText)`
  font-size: 12px;
  line-height: 18px;
`;

export default function SignupPage() {
  const [error, setError] = useState("");
  const [isConsent, setIsConsent] = useState(false);
  const [isConsentError, setIsConsentError] = useState(false);
  const [disabledRegisterEmail, setDisabledRegisterEmail] = useState(false);
  const { token } = usePolyfitLocationQuery("SIGNUP", { token: "" });
  const { isValid, isLoading, type } = useInvitation(token);
  const { signupEmailPassword, signupProvider } = useSignupWithInvitationToken({
    token,
  });
  const [isFirstUser, setIsFirstUser] = useState(false);
  const [emailValidation, setEmailValidation] = useState("");
  const { 0: formEmailPassword } = useRecoilState(formEmailPasswordState);

  const isGuest =
    !isFirstUser &&
    type === "RESIDENT" &&
    Boolean(new URL(window.location.href).searchParams.get("guest"));

  const fetchIsExistsOrganizationUser = useCallback(async () => {
    await isExistsOrganizationUser(token)
      .then((isExists) => {
        setIsFirstUser(!isExists);
      })
      .catch(() => {
        setIsFirstUser(false);
      });
  }, []);

  useLayoutEffect(() => {
    fetchIsExistsOrganizationUser();
  }, []);

  const registerEmailAndPassword = async () => {
    setDisabledRegisterEmail(true);
    if (!isConsent) {
      setDisabledRegisterEmail(false);
      return setIsConsentError(true);
    }

    if (!formEmailPassword.email || !formEmailPassword.password) {
      setDisabledRegisterEmail(false);
      const errorMessage = "メールアドレスまたはパスワードが不正です";
      logger.error(new Error(errorMessage), {
        email: formEmailPassword.email,
        errorMessage,
      });
      return setError(errorMessage);
    }

    const pattern =
      /^[a-zA-Z0-9]+[a-zA-Z0-9+._-]*@[a-zA-Z0-9_-]+[a-zA-Z0-9._-]+$/;

    if (!formEmailPassword.email.match(pattern)) {
      setDisabledRegisterEmail(false);
      return setEmailValidation(
        "メールアドレスは半角英数字で正しく入力してください"
      );
    }

    try {
      await signupEmailPassword(
        formEmailPassword.email,
        formEmailPassword.password,
        isGuest
      );
      setDisabledRegisterEmail(false);
    } catch (e) {
      setDisabledRegisterEmail(false);
      // FirebaseAuthenticationのエラーハンドリング
      if (e instanceof Object && "code" in e && typeof e.code === "string") {
        const errorMessage = errorCodeToMessage(e.code);
        setError(errorMessage);
        logger.error(e, {
          email: formEmailPassword.email,
          errorMessage,
          errorCode: e.code,
        });
        return;
      }
      // APIのエラーハンドリング
      if (e instanceof APIError) {
        setError(e.message);
        logger.error(e, {
          email: formEmailPassword.email,
          errorMessage: e.message,
        });
        return;
      }
      const errorMessage = "不明なエラーが発生しました。";
      setError(errorMessage);
      logger.error(e, { email: formEmailPassword.email, errorMessage });
    }
  };

  useEffect(() => {
    logout();
  }, []);

  const formDescription: string = (() => {
    if (isFirstUser) {
      return "新規開設";
    } else {
      if (type === "PTA") {
        return "PTA入会登録";
      } else {
        if (isGuest) {
          return "ゲスト登録";
        } else {
          return "地域住民登録";
        }
      }
    }
  })();

  return (
    <Container>
      <HeaderContainer>
        <Header>polyfit</Header>
      </HeaderContainer>
      <Banner
        url="https://www.polyfit.jp/support/pta/question"
        title="登録でお困りの場合はこちら"
      />
      <ContainerBox>
        <FlexLayout direction="column">
          <Heading1>{isFirstUser ? "新規開設" : "新規登録"}</Heading1>
          <Panel>
            <FormDescription>
              こちらから
              {formDescription}
              が行えます
            </FormDescription>
            {!token ? (
              <div>無効なリンク</div>
            ) : isLoading ? (
              <div>...</div>
            ) : !isValid ? (
              <div>無効なリンク</div>
            ) : (
              <>
                <Button
                  onClick={() => signupProvider(isGuest)}
                  fill={true}
                  style={{ width: "100%" }}
                >
                  <Google />
                  Googleで新規登録
                </Button>
                <Spacer />
                <GoogleConsentMessage>
                  「Googleで新規登録」のクリックで、
                  <a
                    href="https://polyfit.notion.site/f3109c5b855b446683d23899ac95535a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    利用規約
                  </a>
                  と
                  <a
                    href="https://polyfit.notion.site/712228abd98b4b2fb45cf4d81333e5ad"
                    target="_blank"
                    rel="noreferrer"
                  >
                    個人情報保護方針
                  </a>
                  に同意したことになります。
                </GoogleConsentMessage>
                <SideLineHeaderText text="Eメールで新規登録" />
                <div>
                  <EmailInput />
                  {emailValidation.length > 0 && (
                    <ErrorMessage>{emailValidation}</ErrorMessage>
                  )}
                  <Spacer />
                  <PasswordInput isLogin={false} />

                  {!!error && (
                    <ErrorMessage>
                      {error}
                      <br />
                      {error === "メールアドレスは既に登録されています" ? (
                        <>
                          代わりに<a href="/login">ログイン</a>をお試し下さい。
                        </>
                      ) : (
                        <>管理者までお問合せ下さい。</>
                      )}
                    </ErrorMessage>
                  )}
                  <Spacer />

                  <ConsentContainer>
                    <ConsentWrapper>
                      <CustomCheckBox
                        checked={isConsent}
                        onChange={() => {
                          setIsConsent(!isConsent);
                          if (isConsentError) setIsConsentError(false);
                        }}
                      />
                      <ConsentText>
                        <a href="https://www.notion.so/f3109c5b855b446683d23899ac95535a">
                          利用規約
                        </a>
                        と
                        <a href="https://www.notion.so/712228abd98b4b2fb45cf4d81333e5ad">
                          プライバシーポリシー
                        </a>
                        に同意する
                      </ConsentText>
                    </ConsentWrapper>

                    {isConsentError && (
                      <ConsentErrorMessageWrapper>
                        利用規約とプライバシーポリシーに同意できない場合はこのサービスを利用できません
                      </ConsentErrorMessageWrapper>
                    )}
                  </ConsentContainer>

                  <RegisterButton
                    type="button"
                    disabled={disabledRegisterEmail}
                    onClick={registerEmailAndPassword}
                  >
                    メールアドレスで登録
                  </RegisterButton>
                </div>
              </>
            )}
            <LeadUsingChromeText />
          </Panel>

          {!isFirstUser && (
            <Link
              to={{
                pathname: "/login",
                search: `token=${token}`,
              }}
              style={{
                color: theme.colorsPallet.darkShade,
                borderBottom: `1px solid ${theme.colorsPallet.darkShade}`,
                fontSize: "14px",
                margin: "16px auto 0 auto",
              }}
            >
              ログインはこちら
            </Link>
          )}
        </FlexLayout>
      </ContainerBox>
    </Container>
  );
}
