import React, { useMemo } from "react";
import { toDateFormat } from "../../utils/time";
import { PostType } from "../../hooks/api/post";
import { getStaticImageUrl } from "../../utils/getStaticImageUrl";
import { Avatar } from "../../components/Common/Avatar";
import styled, { css } from "styled-components";
import { useGetApprovers } from "src/hooks/query/approvers";
import { Approver } from "@shared/types/user";

export const PostHistoryContent = ({ post }: { post: PostType }) => {
  const { approvers, isLoading } = useGetApprovers({});
  if (isLoading) {
    return <></>;
  }

  if (post.status === "DRAFT") {
    return (
      <Flex
        style={{
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <DraftComment post={post} />
      </Flex>
    );
  }

  return (
    <Flex
      style={{
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <CreatedComment post={post} />
      <ApprovingComment post={post} approvers={approvers} />
      <ApproveHistoryComments post={post} />
      <RemainingApproversComment post={post} approvers={approvers} />
    </Flex>
  );
};

const DraftComment = ({ post }: { post: PostType }) => {
  return (
    <Comment
      iconUrl={post.user.picture}
      title={
        post.user.baseInfo?.lastName +
        " " +
        post.user.baseInfo?.firstName +
        "さん が下書きを保存しました。"
      }
      date={post.updatedAt}
      isLastItem
    />
  );
};

const CreatedComment = ({ post }: { post: PostType }) => {
  return (
    <Comment
      iconUrl={post.user.picture}
      title={
        post.user.baseInfo?.lastName +
        " " +
        post.user.baseInfo?.firstName +
        "さん が投稿を申請しました。"
      }
      date={post.createdAt}
    />
  );
};

const ApprovingComment = ({
  post,
  approvers,
}: {
  post: PostType;
  approvers: Approver[];
}) => {
  const approverNames = approvers.map((user) => user.name + "さん");
  return (
    <Comment
      title={approverNames.join(" ") + " の承認を待っています。"}
      date={post.updatedAt}
    />
  );
};

const ApproveHistoryComments = ({ post }: { post: PostType }) => {
  const comments = post.postApprovals.map((approval) => {
    const name = `${approval.user.baseInfo?.lastName} ${approval.user.baseInfo?.firstName}`;
    const title = `${name}さんが${
      approval.approve ? "承認しました。" : "差し戻しました。"
    }`;
    return (
      <Comment
        key={approval.id}
        iconUrl={approval.user.picture}
        title={title}
        date={approval.updatedAt}
        message={approval.comment}
      />
    );
  });
  return <>{comments}</>;
};

const RemainingApproversComment = ({
  post,
  approvers,
}: {
  post: PostType;
  approvers: Approver[];
}) => {
  // 作成日降順に並び替えたpostApprovals
  const approvals = Array.from(post.postApprovals).sort((a, b) =>
    a.createdAt < b.createdAt ? 1 : -1
  );

  // 最終差し戻し
  const lastRemandApproval = approvals.find((approval) => !approval.approve);
  // 最終差し戻し時間
  const lastRemandedAt = lastRemandApproval?.createdAt;

  // 最終差し戻し以降の承認
  const approvalsAfterLastRemand = approvals.map((approval) => {
    // 差し戻しがない場合は全てのapprovalを返す
    if (!lastRemandedAt) {
      return approval;
    }
    if (approval.createdAt > lastRemandedAt) {
      return approval;
    }
  });

  // 未承認ユーザーの氏名リスト
  const unApprovedUserNames = approvers
    .map((approver) => {
      // ユーザーが最終差し戻し以降に承認しているか
      const approval = approvalsAfterLastRemand.find(
        (approval) => approval?.userId == approver.id
      );
      if (!approval) {
        return approver.name + "さん";
      }
    })
    .filter((v) => v);

  // 配信済みの場合は最終承認者を取得
  const lastApproval = useMemo(() => {
    if (!post.postApprovals) return undefined;
    return post.postApprovals.length > 2
      ? post.postApprovals.reduce((prev, curr) => {
          return prev.createdAt > curr.createdAt ? prev : curr;
        })
      : post.postApprovals[0];
  }, [post]);

  if (
    (post.status === "APPROVED" && !lastApproval) ||
    (post.status !== "APPROVED" && unApprovedUserNames.length === 0)
  ) {
    return <Comment title={"読み込み中です..."} date={new Date()} isLastItem />;
  }

  return (
    <>
      {post.status !== "APPROVED" ? (
        <Comment
          title={unApprovedUserNames.join(" ") + "は未承認です。"}
          date={new Date()}
          isLastItem
        />
      ) : !post.scheduledSendAt ? (
        <Comment
          title={
            lastApproval?.user.baseInfo?.lastName +
            " " +
            lastApproval?.user.baseInfo?.firstName +
            "さん が投稿しました。"
          }
          date={lastApproval?.createdAt ?? new Date()}
          isLastItem
        />
      ) : new Date(post.scheduledSendAt) > new Date() ? (
        <Comment
          title={
            lastApproval?.user.baseInfo?.lastName +
            " " +
            lastApproval?.user.baseInfo?.firstName +
            "さん が投稿予約しました。"
          }
          date={post.scheduledSendAt}
          isLastItem
          isScheduled
        />
      ) : (
        <>
          <Comment
            title={
              lastApproval?.user.baseInfo?.lastName +
              " " +
              lastApproval?.user.baseInfo?.firstName +
              "さん が投稿予約しました。"
            }
            date={post.scheduledSendAt}
            isScheduled
          />
          <Comment
            title={
              lastApproval?.user.baseInfo?.lastName +
              " " +
              lastApproval?.user.baseInfo?.firstName +
              "さん が投稿しました。"
            }
            date={post.scheduledSendAt}
            isLastItem
          />
        </>
      )}
    </>
  );
};

type CommentProps = {
  iconUrl?: string;
  title: string;
  message?: string;
  date: string | Date;
  isLastItem?: boolean;
  isScheduled?: boolean;
};

const Comment = ({
  iconUrl,
  title,
  message,
  date,
  isLastItem,
  isScheduled,
}: CommentProps) => {
  const defaultIconUrl = getStaticImageUrl("/logo.png");
  const dateString = toDateFormat(new Date(date));
  return (
    <StyledTimelineWrapper isLastItem={isLastItem}>
      <Avatar src={iconUrl || defaultIconUrl} alt="" size={40} />
      <Flex
        style={{
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Title>{title}</Title>
        {message && <Message>{message}</Message>}
        <Timestamp>
          {dateString}
          {isScheduled && "に投稿されます"}
        </Timestamp>
      </Flex>
    </StyledTimelineWrapper>
  );
};

const Flex = styled.div`
  display: flex;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #1a1c21;
`;

const Message = styled.div`
  background-color: white;
  font-size: 16px;
  color: #1a1c21;
  padding: 8px 15px;
`;

const Timestamp = styled.p`
  font-size: 14px;
  color: #69707d;
`;

const StyledTimelineWrapper = styled.div<{
  isLastItem?: boolean;
}>`
  display: flex;
  position: relative;
  gap: 20px;

  img {
    position: relative;
    z-index: 1;
  }

  ${({ isLastItem }) =>
    !isLastItem &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 20px;
        height: calc(100% + 40px);
        width: 2px;
        background-color: rgb(211, 218, 230);
        z-index: 0;
      }
    `}
`;
