import * as client from "./client";
import { PTAParentFamily } from "@shared/types/ptaParentFamily";
import { UpdatePTAParentFamilyRequestSchemaType } from "@shared/validator/features/ptaParentFamily.schema";

export const getPTAParentFamily = async (): Promise<PTAParentFamily | null> => {
  return await client.get("/ptaParentFamily/getByCurrentAccount", {});
};

export const udpatePTAParentFamily = async (
  data: UpdatePTAParentFamilyRequestSchemaType
): Promise<void> => {
  await client.post("/ptaParentFamily/updateById", { data });
};
