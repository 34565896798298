import { useCallback, useState } from "react";
import { BaseInfo } from "@shared/validator/models/baseInfo.schema";
import * as authApi from "src/apiClients/boardEducation/auth";
import * as storageApi from "src/apiClients/storage";
import { resizeImage } from "src/hooks/api/baseInfo";

export function useRegisterInfo() {
  const [isSaving, setIsSaving] = useState(false);

  const registerInfo = useCallback(async (data: BaseInfo) => {
    setIsSaving(true);
    await authApi.registerInfo({
      ...data,
    });
    setIsSaving(false);
  }, []);

  const registerFaceImgPath = useCallback(
    async (faceImg: File | undefined, accountId: string) => {
      let imgPath = "";
      if (faceImg) {
        imgPath = await storageApi.upload({
          file: await resizeImage(faceImg),
        });
      }
      await authApi.registerFaceImgPath({
        accountId: accountId,
        faceImgPath: imgPath,
      });
    },
    []
  );

  return {
    registerInfo,
    registerFaceImgPath,
    isSaving,
  };
}
