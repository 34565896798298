import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { PencilIcon } from "../../../components/icons/PencilIcon";
import {
  ChildFormContainer,
  ChildFormLabel,
  EditBaseInfoIcon,
  EditLinkContainer,
  PCBaseInfoWrapper,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledTr,
} from "../../../pages/pta/admin/ProfilePage";
import { Heading2 } from "../../../components/Typo";
import { usePTAParentFamily } from "src/hooks/query/ptaParentFamily";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { getClassName } from "src/utils/getChildClass";
import { PTAParentFamilyInfoEditModal } from "./PTAParentFamilyInfoEditModal";
import { Button } from "src/components/Button";
import { PTAParentFamilyInvitationModal } from "./PTAParentFamilyInvitationModal";
import { CurrentUser } from "src/apiClients/auth";

export const PTAParentFamilyInfoContent = ({
  inPTA,
  user,
}: {
  inPTA: boolean;
  user: CurrentUser;
}) => {
  const { ptaParentFamily, isLoading, refetch } = usePTAParentFamily();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenInvitationModal, setIsOpenInvitationModal] = useState(false);

  const onCloseEditModal = useCallback(() => {
    setIsOpenEditModal(false);
  }, [setIsOpenEditModal]);

  const onOpenEditModal = useCallback(() => {
    setIsOpenEditModal(true);
  }, [setIsOpenEditModal]);

  const onCloseInvitationModal = useCallback(() => {
    setIsOpenInvitationModal(false);
  }, [setIsOpenInvitationModal]);

  const onOpenInvitationModal = useCallback(() => {
    setIsOpenInvitationModal(true);
  }, [setIsOpenInvitationModal]);

  const children = ptaParentFamily?.children;
  const isMainAccount = ptaParentFamily?.mainAccount.id === user.account?.id;

  return (
    <>
      <PCBaseInfoWrapper>
        <StyledFlexContainer>
          {isLoading ? (
            <>読み込み中…</>
          ) : (
            <div>
              <StyledContainerPc>
                <Title>世帯情報</Title>

                <StyledTable>
                  <StyledTr>
                    <StyledTh>世帯名</StyledTh>
                    <StyledTd>{ptaParentFamily?.name}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    {/* 一旦 ID を表示するで仮置き、InternalAdmin を外す前には表示する項目を精査 */}
                    <StyledTh>メインアカウントID</StyledTh>
                    <StyledTd>{ptaParentFamily?.mainAccount?.id}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    {/* 一旦 ID を表示するで仮置き、InternalAdmin を外す前には表示する項目を精査 */}
                    <StyledTh>サブアカウントID</StyledTh>
                    <StyledTd>{ptaParentFamily?.subAccount?.id}</StyledTd>
                  </StyledTr>
                </StyledTable>
              </StyledContainerPc>

              <StyledContainerSp>
                <StyledTable>
                  <StyledTr>
                    <StyledTh>世帯名</StyledTh>
                    <StyledTd>{ptaParentFamily?.name}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    {/* 一旦 ID を表示するで仮置き、InternalAdmin を外す前には表示する項目を精査 */}
                    <StyledTh>パートナーID</StyledTh>
                    <StyledTd>{ptaParentFamily?.subAccount?.id}</StyledTd>
                  </StyledTr>
                </StyledTable>
              </StyledContainerSp>
              <ChildFormContainer>
                <StyledContainerPc>
                  {children?.map((child, index) => (
                    <ChildrenWrapper key={index} $isFirstChild={index === 0}>
                      <ChildFormLabel>
                        お子さま ({index + 1}人目)
                      </ChildFormLabel>

                      <ChildInfoTable>
                        <thead>
                          <StyledTr>
                            <StyledTh>お名前</StyledTh>
                            <StyledTd>
                              {child?.childLastName} {child?.childFirstName}
                            </StyledTd>
                          </StyledTr>
                          <StyledTr>
                            <StyledTh>学年</StyledTh>
                            <StyledTd>
                              {getGradeLabel(
                                child?.grade,
                                // organization?.schoolDisplayType,
                                undefined,
                                true,
                                false,
                                "short"
                              )}
                            </StyledTd>
                          </StyledTr>
                          <StyledTr>
                            <StyledTh>クラス</StyledTh>
                            <StyledTd>{getClassName(child.class)}</StyledTd>
                          </StyledTr>
                        </thead>
                      </ChildInfoTable>
                    </ChildrenWrapper>
                  ))}
                </StyledContainerPc>
              </ChildFormContainer>

              <ChildFormContainer>
                {children?.map((child, index) => (
                  <StyledContainerSp key={index}>
                    <ChildFormLabel>お子さま ({index + 1}人目)</ChildFormLabel>

                    <StyledTableSp>
                      <div>
                        <StyledTHSp>お名前</StyledTHSp>
                        <StyledTdSp>
                          {child?.childLastName} {child?.childFirstName}
                        </StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>学年</StyledTHSp>
                        <StyledTdSp>
                          {getGradeLabel(
                            child?.grade,
                            // organization?.schoolDisplayType,
                            undefined,
                            true,
                            false,
                            "short"
                          )}
                        </StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>クラス</StyledTHSp>
                        <StyledTdSp>{getClassName(child.class)}</StyledTdSp>
                      </div>
                    </StyledTableSp>
                  </StyledContainerSp>
                ))}
              </ChildFormContainer>
            </div>
          )}

          <Flex style={{ justifyContent: "flex-end" }}>
            {!isLoading && isMainAccount && (
              <InvitationButton
                fill
                color="text"
                disabled={Boolean(ptaParentFamily?.subAccount)}
                onClick={onOpenInvitationModal}
              >
                家族を招待
              </InvitationButton>
            )}
            {!isLoading && (
              <EditLinkContainer onClick={onOpenEditModal}>
                <PencilIcon />
                <EditBaseInfoIcon>編集</EditBaseInfoIcon>
              </EditLinkContainer>
            )}
          </Flex>

          {isOpenEditModal && (
            <PTAParentFamilyInfoEditModal
              onClose={onCloseEditModal}
              ptaParentFamily={ptaParentFamily}
              fetch={refetch}
            />
          )}
          {isOpenInvitationModal && (
            <PTAParentFamilyInvitationModal onClose={onCloseInvitationModal} />
          )}
        </StyledFlexContainer>
      </PCBaseInfoWrapper>
    </>
  );
};

const StyledContainerPc = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;
const StyledContainerSp = styled.div`
  @media (min-width: 500px) {
    display: none;
  }
`;

const StyledTableSp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

const InvitationButton = styled(Button)`
  margin-right: 10px;
`;

export const PartnerInfoContainer = styled.div`
  width: 100%;

  @media (max-width: 1025px) {
    width: 100%;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 480px) {
    display: block;
  }
`;

const StyledTHSp = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const StyledTdSp = styled.p`
  font-size: 12px;
  word-break: break-all;
`;

const ChildrenWrapper = styled.div<{ $isFirstChild: boolean }>`
  margin-top: ${(props) => (props.$isFirstChild ? 0 : "10px")};
`;

const ChildInfoTable = styled.table`
  margin-top: 0;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #343741;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 14px;
  color: #0077cc;
  padding: 5px;
`;
