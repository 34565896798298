// post上に持つアンケート回答に関わる情報
import { SurveyAnswers } from "@shared/types/surveyAnswers";
import { get, post } from "./client";
import { isMultiChoiceQuestion, isOpenEndedQuestion } from "./survey";
import {
  AnswerSchemaType,
  fetchSurveyAnswersSchema,
  FetchSurveyAnswersSchemaType,
  MultiChoiceAnswerSchemaType,
  OpenEndedAnswerSchemaType,
  sendSurveyAnswersSchema,
  SendSurveyAnswersSchemaType,
} from "@shared/validator/features/surveyAnswers.schema";

// 各回答を表すデータ型
export type Answer = AnswerSchemaType;

// 種別判定用の関数、回答用
// 質問用の判定関数を呼ぶだけ
export function isMultiChoiceAnswer(
  answer: Answer
): answer is MultiChoiceAnswerSchemaType {
  return isMultiChoiceQuestion(answer.question);
}
export function isOpenEndedAnswer(
  answer: Answer
): answer is OpenEndedAnswerSchemaType {
  return isOpenEndedQuestion(answer.question);
}

// アンケート回答をサーバーから受信する関数
// ユーザーと質問から該当する回答を探す
export async function fetchSurveyAnswers(
  surveyQuestionsId: string
): Promise<SurveyAnswers> {
  const args = fetchSurveyAnswersSchema.parse({
    surveyQuestionsId: surveyQuestionsId,
  });
  return await get<FetchSurveyAnswersSchemaType, SurveyAnswers>(
    "/surveyAnswers/fetch",
    args
  );
}

// アンケート回答をサーバーに送信する関数
// TODO: 質問も一緒に送信しているが無駄かも
export async function sendSurveyAnswers(
  surveyAnswers: SurveyAnswers
): Promise<void> {
  const args = sendSurveyAnswersSchema.parse({
    surveyAnswers: surveyAnswers,
  });
  return await post<SendSurveyAnswersSchemaType, void>(
    "/surveyAnswers/upsert",
    args
  );
}
