import { DownloadSchema } from "@shared/validator/features/download.schema";
import * as client from "../client";

export async function getDownloadUrl(args: DownloadSchema) {
  const res = await client.get<DownloadSchema, { url: string }>(
    "/boardEducation/storage/download",
    args
  );
  return res.url;
}
