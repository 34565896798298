import { useQuery } from "@tanstack/react-query";
import { getPTAParentFamilyInvoiceList } from "src/apiClients/ptaParentFamilyInvoice";

export function useGetPTAParentFamilyInvoiceList(ptaInvoiceProductId?: string) {
  const query = useQuery({
    queryKey: ["api", "ptaParentFamilyInvoice", "list", ptaInvoiceProductId],
    queryFn: async () => {
      if (!ptaInvoiceProductId) return [];
      return await getPTAParentFamilyInvoiceList({
        ptaInvoiceProductId,
      });
    },

    enabled: !!ptaInvoiceProductId,
  });

  return {
    ptaParentFamilyInvoices: query.data ?? [],
    ...query,
  };
}
