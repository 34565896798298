import { useCallback, useState } from "react";
import * as careerApi from "../../apiClients/career";
import { logger } from "src/utils/logger";
import { APIError } from "src/utils/types/ApiError";

export function useCareer(userId: string) {
  const [career, setCareer] = useState<careerApi.Career>();
  const [careerList, setCareerList] = useState<careerApi.Career[]>([]);
  const [isLoadingCareer, setIsLoadingCareer] = useState(false);

  const fetchCareerList = async () => {
    setIsLoadingCareer(true);
    const tmpCareerList = await careerApi.getCareerListByUser({ userId });
    setCareerList(tmpCareerList);
    setIsLoadingCareer(false);
    return tmpCareerList;
  };

  const updateCareer = useCallback(
    async (
      careerId: string | null,
      userId: string | null,
      childId: string | null,
      isGraduatedChild: boolean,
      isHighestPart: boolean,
      organizationId: string | null,
      belongId: string | null,
      partId: string | null,
      startDate: string,
      endDate: string | null
    ) => {
      try {
        const career = await careerApi.updateCareer({
          id: careerId ?? "",
          userId: userId ?? "",
          childId: childId ?? "",
          isGradutatedChild: isGraduatedChild ?? !childId,
          isHighestPart: isHighestPart ?? false,
          organizationId: organizationId ?? "",
          belongId: belongId ?? "",
          partId: partId ?? "",
          startDate: startDate ?? "",
          endDate: endDate ?? "",
        });
        setCareer(career);
      } catch (err) {
        if (err instanceof APIError) {
          throw err;
        } else {
          logger.error(err, {
            careerId: careerId,
            userId: userId,
            organizationId: organizationId,
          });
          const error = err as Error;
          throw new Error(
            error.message +
              ` (careerId: ${careerId}, userId: ${userId}, organizationId: ${organizationId})`
          );
        }
      }
    },
    []
  );

  const fetchCareer = useCallback(async (careerId: string) => {
    setIsLoadingCareer(true);
    const tmpCareer = await careerApi.getCareer({ careerId });
    setCareer(tmpCareer);
    setIsLoadingCareer(false);
    return tmpCareer;
  }, []);

  const createCareer = useCallback(
    async (
      userId: string,
      childId: string | null,
      isGraduatedChild: boolean,
      isHighestPart: boolean,
      organizationId: string,
      belongId: string,
      partId: string,
      startDate: string,
      endDate: string
    ) => {
      try {
        const career = await careerApi.createCareer({
          userId: userId,
          organizationId: organizationId,
          isGradutatedChild: isGraduatedChild,
          isHighestPart: isHighestPart,
          childId: childId,
          belongId: belongId,
          partId: partId,
          startDate: startDate,
          endDate: endDate,
        });
        return career;
      } catch (err) {
        logger.error(err);
        if (err instanceof APIError) {
          throw err;
        } else {
          logger.error(err, {
            userId: userId,
            organizationId: organizationId,
          });
          const error = err as Error;
          throw new Error(error.message);
        }
      }
    },
    []
  );

  const deleteCareer = useCallback(async (careerId: string) => {
    try {
      const career = await careerApi.deleteCareer(careerId);
      return career;
    } catch (err) {
      logger.error(err);
    }
  }, []);

  return [
    {
      career,
      careerList,
      isLoadingCareer,
    },
    {
      updateCareer,
      fetchCareer,
      fetchCareerList,
      createCareer,
      deleteCareer,
    },
  ] as const;
}
