import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getPTAInvoiceProductList,
  getPTAInvoiceProduct,
  createPTAInvoiceProduct,
  updatePTAInvoiceProduct,
} from "src/apiClients/ptaInvoiceProduct";
import {
  CreatePTAInvoiceProductType,
  UpdatePTAInvoiceProductType,
} from "@shared/validator/features/ptaInvoiceProduct.schema";

export function useGetPTAInvoiceProductList() {
  const query = useQuery({
    queryKey: ["api", "ptaInvoiceProduct", "list"],
    queryFn: async () => {
      return await getPTAInvoiceProductList();
    },
  });

  return {
    ptaInvoiceProducts: query.data ?? [],
    ...query,
  };
}

export function useGetPTAInvoiceProduct(id?: string) {
  const query = useQuery({
    queryKey: ["api", "ptaInvoiceProduct", id],
    queryFn: async () => {
      if (!id) return null;
      return await getPTAInvoiceProduct({ id });
    },
    enabled: !!id,
  });

  return {
    ptaInvoiceProduct: query.data,
    ...query,
  };
}

export function useCreatePTAInvoiceProduct() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreatePTAInvoiceProductType) =>
      createPTAInvoiceProduct(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["api", "ptaInvoiceProduct"] });
    },
  });
}

export function useUpdatePTAInvoiceProduct() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdatePTAInvoiceProductType) =>
      updatePTAInvoiceProduct(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["api", "ptaInvoiceProduct"] });
    },
  });
}
