import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import {
  useForm,
  UseFormSetValue,
  UseFormGetValues,
  UseFormWatch,
} from "react-hook-form";
import { PageBody, PageContainer } from "../../../components/Page";
import {
  useModal,
  ModalPortal,
  ModalHeader,
  ModalBody,
  ModalRemoveButtons,
} from "../../../components/Modal";
import * as Responsive from "../../../components/Responsive";
import styled from "styled-components";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
} from "../../../hooks/router";
import { TeacherUser, User } from "../../../apiClients/users";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { useGetDisabledUsersChildrenByOrganizationId } from "../../../hooks/api/child";
import { Child } from "@shared/types/child";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableItem,
  TableTh,
} from "../../../components/Table";
import { Link } from "react-router-dom";
import { Title } from "../../../components/Title";
import {
  approveUsers,
  deleteUnApprovedAccount,
} from "../../../apiClients/auth";
import { useToast } from "../../../components/Toast";
import { Icon } from "../../../components/form/CheckBox";
import { Avatar } from "../../../components/Common/Avatar";
import { BreadCrumb } from "../../../components/Common/BreadCrumb";
import { TabNavigation } from "../../../components/TabNavigation";
import { useDisabledTeacherUser } from "../../../hooks/api/users";
import { unreachable } from "../../../utils/unreachable";
import { getClassName } from "../../../utils/getChildClass";
import { isPtaAdminRole } from "../../../utils/types/role";
import { Spinner } from "../../../components/icons/Spinner";
import { EmptyResultCard } from "../../../components/Common/NoResult";
import { logger } from "src/utils/logger";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";

const BREAKPOINTS = {
  SP: "480px",
  TABLET: "768px",
  PC: "1024px",
};

type DisabledMembersFormType = {
  children: Child[];
  teachers: TeacherUser[];
  searchText: string;
};

type RemoveUserModalPropsType = {
  user: User;
  accountId: string;
  onClose: () => void;
  setValue: UseFormSetValue<DisabledMembersFormType>;
  refetch: () => void;
};

const DisabledMemberCheckbox = styled.div<{ $isChecked: boolean }>`
  height: 16px;
  width: 16px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  svg {
    display: ${(props) => !props.$isChecked && "none"};
    position: absolute;
    top: 0;
    left: 0;
  }

  ${(props) =>
    props.$isChecked ? `background: #0077CC;` : `border: 1px solid #C9CBCD;`}
`;

type ApproveCheckboxPropsType = {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
};

export const ApproveCheckbox = ({
  checked,
  onChange,
}: ApproveCheckboxPropsType) => {
  return (
    <DisabledMemberCheckbox $isChecked={checked} onClick={onChange}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </DisabledMemberCheckbox>
  );
};

const RemoveUserModal = ({
  user,
  accountId,
  onClose,
  setValue,
  refetch,
}: RemoveUserModalPropsType) => {
  const toast = useToast();

  const onSubmit = useCallback(async () => {
    try {
      await deleteUnApprovedAccount(accountId);
      toast.success("削除しました");
      setValue("children", []);
      onClose();
      refetch();
    } catch (err) {
      logger.error(err);
    }
  }, []);

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>削除</ModalHeader>
      <ModalBody>
        {user.name}さんを削除します。
        <br />
        削除した後、再度承認する場合は新規の再登録をする必要があります。よろしいですか？
        <ModalRemoveButtons
          submitColor="danger"
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </ModalBody>
    </ModalPortal>
  );
};

type UserTableRowPropsType = {
  child: Child;
  accountId: string | undefined;
  getValues: UseFormGetValues<DisabledMembersFormType>;
  setValue: UseFormSetValue<DisabledMembersFormType>;
  watch: UseFormWatch<DisabledMembersFormType>;
  refetch: () => void;
};
const UsersTableRow = ({
  child,
  accountId,
  getValues,
  setValue,
  watch,
  refetch,
}: UserTableRowPropsType) => {
  const [isShown, { show, close }] = useModal();
  const user = useCurrentUser();
  const { organization } = useGetCurrentOrganization();

  const onChangeSelectMember = () => {
    if (
      getValues("children")
        .map((formChild) => formChild.id)
        .includes(child.id)
    ) {
      const filteredChildren = getValues("children").filter(
        (unapprovedChild) => unapprovedChild.id !== child.id
      );
      setValue("children", filteredChildren);
    } else {
      return setValue("children", [...getValues("children"), child]);
    }
  };

  return (
    <TableRow>
      <TableItem width="5px">
        <ApproveCheckbox
          checked={watch("children")
            .map((child) => child.id)
            .includes(child.id)}
          onChange={onChangeSelectMember}
          disabled={!isPtaAdminRole(user.role)}
        />
      </TableItem>
      <TableItem>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={child.user?.picture!}
            size={40}
            alt={child.user?.name!}
          />
          <span style={{ marginLeft: "5px" }}>{child?.user?.name}</span>
        </div>
      </TableItem>
      <TableItem>
        {child?.childLastName} {child?.childFirstName}
      </TableItem>
      <TableItem>
        {getGradeLabel(
          child?.grade,
          organization?.schoolDisplayType,
          true,
          false,
          "short"
        )}
      </TableItem>
      <TableItem>{getClassName(child?.class)}</TableItem>
      <TableItem>{child?.user?.email}</TableItem>
      <TableItem>{child?.user?.baseInfo?.phoneNumber}</TableItem>
      <TableItem>
        {isPtaAdminRole(user.role) && (
          <DeleteLink onClick={() => show()}>削除</DeleteLink>
        )}
      </TableItem>
      {isShown && accountId && (
        <RemoveUserModal
          user={child?.user!}
          accountId={accountId}
          onClose={close}
          setValue={setValue}
          refetch={refetch}
        />
      )}
    </TableRow>
  );
};

type UsersTableProps = {
  userChildren: Child[];
  getValues: UseFormGetValues<DisabledMembersFormType>;
  setValue: UseFormSetValue<DisabledMembersFormType>;
  watch: UseFormWatch<DisabledMembersFormType>;
  refetch: () => void;
};
const UsersTable = ({
  userChildren,
  getValues,
  setValue,
  watch,
  refetch,
}: UsersTableProps) => {
  const user = useCurrentUser();
  const [isAllChecked, setIsAllChecked] = useState(
    watch("children").length === userChildren.length
  );

  const onCheckAllMembers = () => {
    if (isAllChecked) {
      setValue("children", []);
    } else {
      setValue("children", userChildren);
    }
    setIsAllChecked(!isAllChecked);
  };

  if (userChildren.length === 0) {
    return <EmptyResultCard title="承認待ちメンバーはいません" />;
  }

  return (
    <UnapprovedUsersContainer>
      <TableHeader>
        <TableTh>
          <ApproveCheckbox
            checked={
              !!watch("children").length &&
              watch("children").length === userChildren.length
            }
            onChange={onCheckAllMembers}
            disabled={!isPtaAdminRole(user.role)}
          />
        </TableTh>
        <TableTh>氏名</TableTh>
        <TableTh>子の氏名</TableTh>
        <TableTh>学年</TableTh>
        <TableTh>クラス</TableTh>
        <TableTh>メールアドレス</TableTh>
        <TableTh>電話番号</TableTh>
        <TableTh></TableTh>
      </TableHeader>
      <TableBody>
        {userChildren.map((child) => {
          return (
            <UsersTableRow
              watch={watch}
              child={child}
              accountId={child.accountId}
              key={child.user?.id}
              setValue={setValue}
              getValues={getValues}
              refetch={refetch}
            />
          );
        })}
      </TableBody>
    </UnapprovedUsersContainer>
  );
};

type TeacherUsersTableProps = {
  teachers: TeacherUser[];
  getValues: UseFormGetValues<DisabledMembersFormType>;
  setValue: UseFormSetValue<DisabledMembersFormType>;
  watch: UseFormWatch<DisabledMembersFormType>;
  refetch: () => void;
};
const TeacherUsersTable = ({
  teachers,
  getValues,
  setValue,
  watch,
  refetch,
}: TeacherUsersTableProps) => {
  const user = useCurrentUser();
  const [isAllChecked, setIsAllChecked] = useState(
    watch("teachers").length === teachers.length
  );

  const onCheckAllMembers = () => {
    if (isAllChecked) {
      setValue("teachers", []);
    } else {
      setValue("teachers", teachers);
    }
    setIsAllChecked(!isAllChecked);
  };

  if (teachers.length === 0) {
    return <EmptyResultCard title="承認待ちメンバーはいません" />;
  }

  return (
    <UnapprovedUsersContainer>
      <TableHeader>
        <TableTh>
          <ApproveCheckbox
            checked={
              !!watch("children").length &&
              watch("children").length === teachers.length
            }
            onChange={onCheckAllMembers}
            disabled={!isPtaAdminRole(user.role)}
          />
        </TableTh>
        <TableTh>氏名</TableTh>
        <TableTh>担任</TableTh>
        <TableTh>学年</TableTh>
        <TableTh>クラス</TableTh>
        <TableTh>メールアドレス</TableTh>
        <TableTh>電話番号</TableTh>
        <TableTh></TableTh>
      </TableHeader>
      <TableBody>
        {teachers.map((teacher) => {
          return (
            <TeacherUsersTableRow
              watch={watch}
              teacher={teacher}
              key={teacher.id}
              setValue={setValue}
              getValues={getValues}
              refetch={refetch}
            />
          );
        })}
      </TableBody>
    </UnapprovedUsersContainer>
  );
};

type TeacherUserTableRowPropsType = {
  teacher: TeacherUser;
  getValues: UseFormGetValues<DisabledMembersFormType>;
  setValue: UseFormSetValue<DisabledMembersFormType>;
  watch: UseFormWatch<DisabledMembersFormType>;
  refetch: () => void;
};
const TeacherUsersTableRow = ({
  teacher,
  getValues,
  setValue,
  watch,
  refetch,
}: TeacherUserTableRowPropsType) => {
  const [isShown, { show, close }] = useModal();
  const user = useCurrentUser();
  const { organization } = useGetCurrentOrganization();

  const onChangeSelectMember = () => {
    if (
      getValues("teachers")
        .map((teacher) => teacher.id)
        .includes(teacher.id)
    ) {
      const filteredTeachers = getValues("teachers").filter(
        (unapprovedChild) => unapprovedChild.id !== teacher.id
      );
      setValue("teachers", filteredTeachers);
    } else {
      return setValue("teachers", [...getValues("teachers"), teacher]);
    }
  };

  return (
    <TableRow>
      <TableItem width="5px">
        <ApproveCheckbox
          checked={watch("teachers")
            .map((user) => user.id)
            .includes(teacher.id)}
          onChange={onChangeSelectMember}
          disabled={!isPtaAdminRole(user.role)}
        />
      </TableItem>
      <TableItem>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={teacher.picture} size={40} alt={teacher.name} />
          <span style={{ marginLeft: "5px" }}>{teacher.name}</span>
        </div>
      </TableItem>
      <TableItem>
        {teacher.teacherInfo?.hasHomeroom ? "あり" : "なし"}
      </TableItem>
      <TableItem>
        {getGradeLabel(
          teacher.teacherInfo?.grade,
          organization?.schoolDisplayType,
          true,
          false,
          "short"
        )}
      </TableItem>
      <TableItem>{getClassName(teacher.teacherInfo?.class)}</TableItem>
      <TableItem>{teacher.email}</TableItem>
      <TableItem>{teacher.baseInfo?.phoneNumber}</TableItem>
      <TableItem>
        {isPtaAdminRole(user.role) && (
          <DeleteLink onClick={() => show()}>削除</DeleteLink>
        )}
      </TableItem>
      {isShown && teacher.teacherInfo?.accountId && (
        <RemoveUserModal
          user={teacher}
          accountId={teacher.teacherInfo?.accountId}
          onClose={close}
          setValue={setValue}
          refetch={refetch}
        />
      )}
    </TableRow>
  );
};

export default function DisabledMembersPage() {
  const [isSending, setIsSending] = useState(false);
  const [tab, setTab] = useState<"parent" | "teacher">("parent");

  const user = useCurrentUser();
  const toast = useToast();
  const history = usePolyfitHistory();
  const { communityId } = useCurrentCommunityId();

  const {
    children,
    getDisabledUsersChildrenByOrganizationId,
    isLoading: isChildrenLoading,
  } = useGetDisabledUsersChildrenByOrganizationId();

  const { handleSubmit, register, getValues, setValue, watch, reset } =
    useForm<DisabledMembersFormType>({
      defaultValues: {
        children: [],
        teachers: [],
        searchText: "",
      },
    });

  const {
    isLoading: isTeacherLoading,
    teachers,
    getDisabledTeacherUsers,
  } = useDisabledTeacherUser();

  const isLoading = isChildrenLoading || isTeacherLoading;

  const onSubmitApproveMember = async () => {
    setIsSending(true);

    switch (tab) {
      case "parent": {
        const children = watch("children");

        const userIds = children.map((child) => {
          return { userId: child?.user?.id!, accountId: child?.accountId! };
        });
        await approveUsers(userIds);

        reset();
        await getDisabledUsersChildrenByOrganizationId();

        break;
      }
      case "teacher": {
        const teachers = watch("teachers");

        const userIds = teachers.map((user) => {
          return {
            userId: user?.id!,
            accountId: user?.teacherInfo?.accountId!,
          };
        });
        await approveUsers(userIds);

        reset();
        await getDisabledTeacherUsers();

        break;
      }
      default:
        unreachable(tab);
    }

    toast.success("承認しました");
    setIsSending(false);
  };

  useLayoutEffect(() => {
    if (!isPtaAdminRole(user.role)) return history.push({ to: "PTA_INDEX" });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    watch((value, { name, type }) => {
      if (name === "searchText" && tab === "parent")
        return getDisabledUsersChildrenByOrganizationId(value.searchText);
      if (name === "searchText" && tab === "teacher")
        return getDisabledTeacherUsers(value.searchText);
    });
  }, [tab, watch]);

  const isShowApproveButton = useCallback(() => {
    switch (tab) {
      case "parent":
        return !!watch("children").length && !isSending;
      case "teacher":
        return !!watch("teachers").length && !isTeacherLoading && !isSending;
      default:
        unreachable(tab);
    }
  }, [tab, watch, isSending, isTeacherLoading]);

  return (
    <PageContainer>
      <form onSubmit={handleSubmit(onSubmitApproveMember)}>
        <BreadCrumb>
          <Link to={{ pathname: "/pta", search: `communityId=${communityId}` }}>
            ホーム
          </Link>
          <span>承認待ちメンバー</span>
        </BreadCrumb>
        <FlexContainer>
          <Title>承認待ちメンバー</Title>
        </FlexContainer>

        <TabNavigation
          elements={[
            {
              isActive: tab === "parent",
              text: "保護者",
              onclick: () => {
                setTab("parent");
                getDisabledUsersChildrenByOrganizationId();
                reset();
              },
            },
            {
              isActive: tab === "teacher",
              text: "教員",
              onclick: () => {
                setTab("teacher");
                getDisabledTeacherUsers();
                reset();
              },
            },
          ]}
        />

        <FlexContainer>
          <TextFieldLabel htmlFor="search-text-field">
            <TextField
              id="search-text-field"
              type="text"
              placeholder="氏名で検索"
              {...register("searchText")}
            />
          </TextFieldLabel>

          <ApproveButton
            type="submit"
            disabled={!isShowApproveButton()}
            $isActive={isShowApproveButton()}
          >
            承認
          </ApproveButton>
        </FlexContainer>

        <PageBody>
          <Responsive.Row>
            <Responsive.Col>
              <TableWrapper>
                {isLoading && (
                  <Card>
                    <Spinner />
                  </Card>
                )}
                {!isLoading && tab === "parent" && (
                  <UsersTable
                    userChildren={children || ([] as Child[])}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    refetch={getDisabledUsersChildrenByOrganizationId}
                  />
                )}
                {!isLoading && tab === "teacher" && (
                  <TeacherUsersTable
                    teachers={teachers || []}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    refetch={getDisabledTeacherUsers}
                  />
                )}
              </TableWrapper>
            </Responsive.Col>
          </Responsive.Row>
        </PageBody>
      </form>
    </PageContainer>
  );
}

const ApproveButton = styled.button<{ $isActive: boolean }>`
  border-radius: 6px;
  width: 112px;
  height: 40px;
  text-align: center;
  border: none;
  color: #fff;
  padding: 10px 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: ${(props) => (props.$isActive ? "pointer" : "non-allowed")};
  background-color: ${(props) =>
    props.$isActive ? "#0071C2" : "rgba(105, 112, 125, 0.2)"};
`;

const DeleteLink = styled.a`
  display: block;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0077cc;
  width: 32px;
  height: 24px;
  cursor: pointer;
`;

const TextFieldLabel = styled.label`
  position: relative;
  width: 90%;

  @media (max-width: 1200px) {
    width: 85%;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}) {
    width: 75%;
  }

  @media (max-width: ${BREAKPOINTS.SP}) {
    width: 90%;
  }

  &:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%23343741' d='m11.271 11.978 3.872 3.873a.502.502 0 0 0 .708 0 .502.502 0 0 0 0-.708l-3.565-3.564c2.38-2.747 2.267-6.923-.342-9.532-2.73-2.73-7.17-2.73-9.898 0-2.728 2.729-2.728 7.17 0 9.9a6.955 6.955 0 0 0 4.949 2.05.5.5 0 0 0 0-1 5.96 5.96 0 0 1-4.242-1.757 6.01 6.01 0 0 1 0-8.486 6.004 6.004 0 0 1 8.484 0 6.01 6.01 0 0 1 0 8.486.5.5 0 0 0 .034.738Z'/%3e%3c/svg%3e")
      center / contain no-repeat;
  }
`;

const TextField = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  padding-left: 40px;

  ::placeholder {
    color: #aab4c4;
  }

  @media (max-width: ${BREAKPOINTS.SP}) {
    width: 95%;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 10px;
`;

const UnapprovedUsersContainer = styled(Table)`
  background-color: #fff;
`;

const TableWrapper = styled.div`
  overflow-x: scroll;
`;

const Card = styled.div`
  border-radius: 6px;
  border: 1px solid #e4e6f3;
  background: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
