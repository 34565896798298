import React, { useMemo, useState } from "react";
import { Button } from "src/components/Button";
import { RegisterStepper } from "src/components/RegisterStepper";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import styled from "styled-components";
import * as Typo from "src/components/Typo";
import { Margin } from "src/components/Margin";
import { DownloadIcon } from "src/components/icons/Lucide/DownloadIcon";
import { colorsPallet } from "src/theme";
import { CustomInfoModal } from "src/features/Invoice/CustomInfoModal";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { usePostUserGroupList } from "src/hooks/recoil/postUserGroup";
import { useToast } from "src/components/Toast";
import { useFileDownload } from "src/hooks/useFileDownload";
import { toInputDateFormatYearMonth } from "src/utils/time";
import { exportCsvByPTAInvoiceProcess } from "src/apiClients/csv";
import { InvoiceCSVCustomInfoSchemaType } from "@shared/validator/features/invoiceCSVCustomInfo";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { useCurrentUser } from "src/hooks/recoil/user";
import { comma } from "src/utils/comma";

const CURERNT_STEP = 1;

/**
 * デフォルトのCSVカスタム
 * - 配列が空の場合は全てのデータを表示
 */
const defaultCsvCustom: InvoiceCSVCustomInfoSchemaType = {
  assignFee: "",
  grades: [],
  belongIds: [],
  userGroupIds: [],
};

export const PTAInvoiceProcessDownloadPage = () => {
  const currentUser = useCurrentUser();
  const { id: invoiceProductId } = usePolyfitLocationQuery(
    "PTA_INVOICE_PROCESS",
    {
      id: "",
    }
  );
  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );

  const history = usePolyfitHistory();
  const [csvCustom, setCsvCustom] =
    useState<InvoiceCSVCustomInfoSchemaType>(defaultCsvCustom);
  const [isCustomInfoModalOpen, setIsCustomInfoModalOpen] = useState(false);
  const { organization } = useGetCurrentOrganization();
  const { postGroups } = usePostUserGroupList();

  // for CSV download
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const { download } = useFileDownload();
  const toast = useToast();

  const belongOptions = useMemo(() => {
    return (
      organization?.belongs?.map((belong) => {
        return {
          id: belong.id,
          name: belong.name,
          order: belong.order,
        };
      }) ?? []
    );
  }, [organization]);

  const userGroupOptions = useMemo(() => {
    return (
      postGroups?.map((postGroup) => {
        return {
          id: postGroup.id,
          name: postGroup.name,
        };
      }) ?? []
    );
  }, [postGroups]);

  if (!ptaOrganizationInvoiceAccount?.enabled) {
    // 閲覧できないための画面/ロジック
    return <div>閲覧できません</div>;
  }

  const prevStep = () => {
    history.push({
      to: "PTA_INVOICE_LIST",
    });
  };

  const nextStep = () => {
    history.push({
      to: "PTA_INVOICE_PROCESS",
      query: { id: invoiceProductId, step: `${CURERNT_STEP + 1}` },
    });
  };

  const onSubmitCustomInfo = (csvCustom: InvoiceCSVCustomInfoSchemaType) => {
    setCsvCustom(csvCustom);
    setIsCustomInfoModalOpen(false);
  };

  const handleClickDownload = async () => {
    if (isCSVLoading) return;
    setIsCSVLoading(true);
    try {
      const blob = await exportCsvByPTAInvoiceProcess(csvCustom);
      const date = toInputDateFormatYearMonth(new Date());
      const fileName = `請求用PTA名簿_${date}.csv`;
      download(blob, fileName);
    } catch (e) {
      toast.error("CSVのダウンロードに失敗しました");
    } finally {
      setIsCSVLoading(false);
    }
  };

  return (
    <Container>
      <RegisterStepper count={2} current={1} title="" />
      <Margin marginBottom={16} />
      <PaddingWrapper>
        <SubTitleWrapper>
          <Typo.Heading3>請求データのダウンロード</Typo.Heading3>
        </SubTitleWrapper>
        <Margin marginBottom={16} />
        <div>
          <p>以下の情報を含む名簿データをダウンロードします。</p>
          <Ul>
            <Li>世帯名</Li>
            <Li>保護者名</Li>
            <Li>子供名</Li>
            <Li>学年</Li>
            {csvCustom.grades.length > 0 && (
              <Section>
                <span>対象(年生):</span>
                {csvCustom.grades.map((grade, index) => (
                  <span key={grade}>
                    {grade}
                    {index < csvCustom.grades.length - 1 ? "," : ""}
                  </span>
                ))}
              </Section>
            )}
            <Li>クラス</Li>
            <Li>割り当て料金: {comma(Number(csvCustom.assignFee)) ?? 0}円</Li>
            {csvCustom.belongIds.length > 0 && (
              <>
                <Li>所属</Li>
                <Section>
                  <span>対象:</span>
                  {csvCustom.belongIds.map((belongId, index) => (
                    <span key={belongId}>
                      {belongOptions.find((belong) => belong.id === belongId)
                        ?.name ?? belongId}
                      {index < csvCustom.belongIds.length - 1 ? "," : ""}
                    </span>
                  ))}
                </Section>
              </>
            )}
            {csvCustom.userGroupIds.length > 0 && (
              <>
                <Li>連絡グループ</Li>
                <Section>
                  <span>対象:</span>
                  {csvCustom.userGroupIds.map((userGroupId, index) => (
                    <span key={userGroupId}>
                      {userGroupOptions.find(
                        (userGroup) => userGroup.id === userGroupId
                      )?.name ?? userGroupId}
                      {index < csvCustom.userGroupIds.length - 1 ? "," : ""}
                    </span>
                  ))}
                </Section>
              </>
            )}
          </Ul>
          <Margin marginTop={16} />
          <TextButton onClick={() => setIsCustomInfoModalOpen(true)}>
            <p>ダウンロード情報の変更はこちら</p>
          </TextButton>

          <Margin marginTop={16} />
          <FlexButton
            color="primary"
            fill
            onClick={handleClickDownload}
            disabled={isCSVLoading}
          >
            <DownloadIcon size={16} color="white" />
            <p>CSVダウンロード</p>
          </FlexButton>
        </div>
        <ButtonWrapper>
          <PrevButton color="text" fill onClick={prevStep}>
            請求一覧へ
          </PrevButton>
          <NextButton color="primary" fill onClick={nextStep}>
            次へ
          </NextButton>
        </ButtonWrapper>
        {isCustomInfoModalOpen && (
          <CustomInfoModal
            onClose={() => setIsCustomInfoModalOpen(false)}
            onSubmit={onSubmitCustomInfo}
            csvCustom={csvCustom}
          />
        )}
      </PaddingWrapper>
    </Container>
  );
};

const Container = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const PaddingWrapper = styled.div`
  padding: 0 16px;
`;

const TextButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${colorsPallet.primary};
  font-weight: bold;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 32px;
`;

const PrevButton = styled(Button)`
  width: 30%;

  padding: 9.5px 0;
`;

const NextButton = styled(Button)`
  width: 30%;

  padding: 9.5px 0;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const FlexButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9.5px 16px;
`;

const Ul = styled.ul`
  margin-top: 8px;
  padding-left: 16px;
`;

const Li = styled.li`
  margin-top: 8px;
`;

const Section = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
