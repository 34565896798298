import React from "react";
import styled from "styled-components";

type Props = {
  url: string;
  title: string;
};

export const Banner = ({ url, title }: Props) => {
  return (
    <BannerContainer href={url} target="_blank" rel="noreferrer">
      <BannerText>{title}</BannerText>
    </BannerContainer>
  );
};

const BannerContainer = styled.a`
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  height: 48px;
  background: #dbe6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
  text-decoration: none;
  cursor: pointer;

  &:hover span {
    text-decoration: underline;
  }
`;

const BannerText = styled.span`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
`;
