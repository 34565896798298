import * as client from "./client";
import {
  Community,
  CommunityMemberRoleCountsResponse,
  GetCommunityListQuery,
} from "@shared/types/community";
import {
  getCommunitySchema,
  getCommunityListSchema,
  updateCommunitySchema,
  UpdateCommunitySchemaType,
  GetCommunitySchemaType,
} from "../@shared/validator/features/community.schema";

export async function getCommunityById(communityId: string) {
  const args = getCommunitySchema.parse({ communityId });
  const res = await client.get<GetCommunitySchemaType, Community>(
    "/community/getCommunity",
    args
  );
  return res;
}

export async function getCommunityMemberRoleCounts() {
  const res = await client.get<{}, CommunityMemberRoleCountsResponse>(
    "/community/getCommunityMemberRoleCounts",
    {}
  );
  return res;
}

export async function updateCommunity(
  args: UpdateCommunitySchemaType
): Promise<Community> {
  updateCommunitySchema.parse(args);
  const res = await client.post<UpdateCommunitySchemaType, Community>(
    "/community/updateCommunity",
    args
  );
  return res;
}

export async function getCommunities(
  searchText: string,
  take?: number
): Promise<Community[]> {
  const args = getCommunityListSchema.parse({
    searchText,
    take: take?.toString(),
  });
  const res = await client.get<GetCommunityListQuery, Community[]>(
    "/community/list",
    args
  );
  return res;
}
