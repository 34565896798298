import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createRecruitment } from "../../../apiClients/recruitment";
import { deleteRecruitmentTemplateById } from "../../../apiClients/recruitmentTemplate";
import { entity, upload } from "../../../apiClients/storage";
import ResidentRecruitmentForm from "../../../features/Recruitment/recruitment/RecruitmentForm";
import RecruitmentPreview from "../../../features/Recruitment/recruitment/RecruitmentFormPreview";
import {
  convertRecruitmentCommunityUserTypes,
  RecruitmentFormValue,
  useRecruitmentForm,
} from "../../../features/Recruitment/recruitment/useRecruitmentForm";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import {
  toRecruitmentDateFormat,
  toRecruitmentTimeFormat,
} from "../../../utils/time";
import styled from "styled-components";
import {
  useRecuseRecruitmentTemplate,
  useInvalidateRecruitmentTemplate,
} from "src/hooks/query/recruitmentTemplate";
import { useToast } from "../../../components/Toast";
import { useGeoCording } from "../../../hooks/useGeocoding";
import DeleteRecruitmentTemplateModal from "../../../features/Recruitment/recruitment/DeleteRecruitmentTemplateModal";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { isCsAdminRole, isCsOperatorRole } from "../../../utils/types/role";
import { Header } from "../../../components/Header";
import { Spinner } from "../../../components/icons/Spinner";
import { transformIsAutoApprovalToEntryMethod } from "src/validator/form/recruitment/recruitmentSchema";
import { RecruitmentCommunityUserTypes } from "@shared/validator/features/recruitment.schema";
import { formatSchedule } from "src/features/Recruitment/recruitment/utils/formatSchedule";
export default function ResidentRecruitmentCreateFromTemplatePage() {
  const { communityId } = useCurrentCommunityId();
  const { templateId: recruitmentTemplateId } = usePolyfitLocationQuery(
    "RESIDENT_RECRUITMENT_CREATE_FROM_TEMPLATE",
    {
      templateId: "",
    }
  );
  const user = useCurrentUser();

  const { recruitmentTemplate, isLoading, isError, error } =
    useRecuseRecruitmentTemplate(recruitmentTemplateId);
  const { invalidateRecruitmentTemplate } = useInvalidateRecruitmentTemplate();

  const [isPreview, setIsPreview] = useState(false);
  const history = usePolyfitHistory();

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [prvPicture, setPrvPicture] = useState<File | null>(null);

  const canDeleteTemplate = useMemo(() => {
    return (
      !isPreview &&
      (isCsAdminRole(user.role) ||
        // CSOperatorかつ自分で作成したひな形の場合は削除可能
        (isCsOperatorRole(user.role) &&
          user.account?.id === recruitmentTemplate?.createdBy))
    );
  }, [isPreview, user, recruitmentTemplate]);

  const { getLocationAddressFromPostcode } = useGeoCording();

  const {
    control,
    register,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    errors,
    isSubmitted,
    watch,
    setError,
    clearErrors,
  } = useRecruitmentForm({});

  useEffect(() => {
    if (recruitmentTemplate) {
      setValue("title", recruitmentTemplate.title);
      setValue("isPaidVolunteer", recruitmentTemplate.isPaidVolunteer);
      setValue("volunteerType", recruitmentTemplate.volunteerType);
      setValue("volunteerDetailType", recruitmentTemplate.volunteerDetailType);
      setValue("description", recruitmentTemplate.description);
      setValue("postalCode", recruitmentTemplate.postalCode ?? "");
      setValue("prefecture", recruitmentTemplate.prefecture ?? "");
      setValue("city", recruitmentTemplate.city ?? "");
      setValue("address1", recruitmentTemplate.address1 ?? "");
      setValue("address2", recruitmentTemplate.address2);
      setValue("latitude", recruitmentTemplate.latitude);
      setValue("longitude", recruitmentTemplate.longitude);
      setValue("treatment", recruitmentTemplate.treatment);
      setValue("teacherLicenses", recruitmentTemplate.teacherLicenses);
      setValue("medicalLicenses", recruitmentTemplate.medicalLicenses);
      setValue("skills", recruitmentTemplate.skills);
      setValue("wageType", recruitmentTemplate.wageType);
      setValue("wageAmount", recruitmentTemplate.wageAmount);
      setValue(
        "isAutoApproval",
        recruitmentTemplate.entryMethod === "AUTO_APPROVAL"
      );
      setValue(
        "alreadyUploadedPdfFiles",
        recruitmentTemplate.recruitmentTemplatePDFs ?? []
      );
      setValue("pdfFiles", []);
      if (recruitmentTemplate.volunteerCount === -1) {
        setValue("isUnlimitedVolunteerCount", true);
        setValue("volunteerCount", undefined);
      } else {
        setValue("isUnlimitedVolunteerCount", false);
        setValue("volunteerCount", recruitmentTemplate.volunteerCount);
      }

      if (recruitmentTemplate.targetCommunities.length === 0) {
        setValue("recruitmentCommunityUserTypes", {});
      } else {
        recruitmentTemplate.targetCommunities.forEach(
          (targetCommunity, index) => {
            const isSchoolSelected = RecruitmentCommunityUserTypes.every(
              (type) => targetCommunity.communityUserTypes.includes(type)
            );
            setValue(
              `recruitmentCommunityUserTypes.${targetCommunity.communityId}`,
              {
                parent:
                  isSchoolSelected ||
                  targetCommunity.communityUserTypes.includes("PARENT"),
                teacher:
                  isSchoolSelected ||
                  targetCommunity.communityUserTypes.includes("TEACHER"),
                insideResident:
                  isSchoolSelected ||
                  targetCommunity.communityUserTypes.includes(
                    "INSIDE_RESIDENT"
                  ),
                outsideResident:
                  isSchoolSelected ||
                  targetCommunity.communityUserTypes.includes(
                    "OUTSIDE_RESIDENT"
                  ),
              }
            );
          }
        );
      }

      if (
        recruitmentTemplate.picture &&
        recruitmentTemplate.pictureUploadPath
      ) {
        setValue("uploadImage", recruitmentTemplate.picture);
        entity(recruitmentTemplate.pictureUploadPath).then((res) => {
          const file = new File([res], "templateImage");
          setPrvPicture(file);
        });
      }
      recruitmentTemplate.schedule.forEach((s, i) => {
        if (s.date !== null) {
          setValue(
            `schedule.${i}.date`,
            toRecruitmentDateFormat(new Date(s.date))
          );
        }
        if (s.start !== null) {
          setValue(
            `schedule.${i}.startTime`,
            toRecruitmentTimeFormat(new Date(s.start))
          );
        }
        if (s.end !== null) {
          setValue(
            `schedule.${i}.endTime`,
            toRecruitmentTimeFormat(new Date(s.end))
          );
        }
      });
    }
  }, [recruitmentTemplate]);

  const getAddress = useCallback(async (value: string) => {
    try {
      const res = await getLocationAddressFromPostcode(value);
      setValue("prefecture", res.prefecture);
      setValue("city", res.city);
      setValue("address1", res.address1);
      setValue("address2", "");
      setValue("latitude", res.latitude);
      setValue("longitude", res.longitude);
      clearErrors("postalCode");
    } catch (err) {
      setError("postalCode", {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      });
    }
  }, []);

  const watchAllValue = watch();
  const [isPostLoading, setIsPostLoading] = useState(false);

  const onPreviewClick = useCallback(() => {
    setIsPreview(true);
  }, []);

  const onDraftClick = useCallback(async () => {
    if (!communityId) return;
    const formValues = getValues();
    const files = formValues.pdfFiles ?? [];

    const pictureUploadPath =
      typeof formValues.uploadImage === "string" && prvPicture
        ? await upload({ file: prvPicture })
        : formValues?.uploadImage?.length === 1 &&
          formValues.uploadImage[0] instanceof File
        ? await upload({ file: formValues.uploadImage[0] })
        : undefined;

    const schedule = formatSchedule({
      schedule: formValues.schedule,
    });

    await createRecruitment(
      {
        communityId: communityId,
        status: "DRAFT",
        title: formValues.title,
        isPaidVolunteer: formValues.isPaidVolunteer ?? null,
        volunteerType: formValues.volunteerType,
        schedule,
        description: formValues.description,
        postalCode: formValues.postalCode,
        prefecture: formValues.prefecture,
        city: formValues.city,
        address1: formValues.address1,
        address2: formValues.address2,
        latitude: formValues.latitude,
        longitude: formValues.longitude,
        treatment: formValues.treatment,
        wageType: formValues.wageType,
        wageAmount: formValues.wageAmount,
        teacherLicenses: formValues.teacherLicenses,
        medicalLicenses: formValues.medicalLicenses,
        skills: formValues.skills,
        targetCommunities: convertRecruitmentCommunityUserTypes(
          formValues.recruitmentCommunityUserTypes
        ),
        alreadyUploadedPdfFiles: formValues.alreadyUploadedPdfFiles,
        pictureUploadPath: pictureUploadPath ?? null,
        saveTemplate: formValues.saveTemplate,
        volunteerDetailType: formValues.volunteerDetailType,
        volunteerCount: formValues.isUnlimitedVolunteerCount
          ? -1
          : isNaN(formValues.volunteerCount ?? 0)
          ? null
          : formValues.volunteerCount ?? 0,
        entryMethod: transformIsAutoApprovalToEntryMethod(
          formValues.isAutoApproval
        ),
      },
      files
    );
    invalidateRecruitmentTemplate(recruitmentTemplateId);

    history.push({ to: "RESIDENT_RECRUITMENT_DRAFT_LIST" });
  }, [prvPicture, communityId]);

  const onPreviewSubmitClick = useCallback(
    async (formValues: RecruitmentFormValue) => {
      if (!communityId) return;
      setIsPostLoading(true);
      const files = formValues.pdfFiles ?? [];
      const pictureUploadPath =
        typeof formValues.uploadImage === "string" && prvPicture
          ? await upload({ file: prvPicture })
          : formValues?.uploadImage?.length === 1 &&
            formValues.uploadImage[0] instanceof File
          ? await upload({ file: formValues.uploadImage[0] })
          : undefined;

      const schedule = formatSchedule({
        schedule: formValues.schedule,
      });

      await createRecruitment(
        {
          communityId: communityId,
          status: "OPENED",
          title: formValues.title,
          isPaidVolunteer: formValues.isPaidVolunteer,
          volunteerType: formValues.volunteerType,
          schedule,
          description: formValues.description,
          postalCode: formValues.postalCode,
          prefecture: formValues.prefecture,
          city: formValues.city,
          address1: formValues.address1,
          address2: formValues.address2,
          latitude: formValues.latitude,
          longitude: formValues.longitude,
          treatment: formValues.treatment,
          wageType: formValues.wageType,
          wageAmount: formValues.wageAmount,
          teacherLicenses: formValues.teacherLicenses,
          medicalLicenses: formValues.medicalLicenses,
          skills: formValues.skills,
          alreadyUploadedPdfFiles: formValues.alreadyUploadedPdfFiles,
          targetCommunities: convertRecruitmentCommunityUserTypes(
            formValues.recruitmentCommunityUserTypes
          ),
          pictureUploadPath: pictureUploadPath ?? null,
          saveTemplate: formValues.saveTemplate,
          volunteerDetailType: formValues.volunteerDetailType,
          volunteerCount: formValues.isUnlimitedVolunteerCount
            ? -1
            : formValues.volunteerCount ?? 0,
          entryMethod: transformIsAutoApprovalToEntryMethod(
            formValues.isAutoApproval
          ),
        },
        files
      );
      invalidateRecruitmentTemplate(recruitmentTemplateId);

      setIsPostLoading(false);
      history.push({ to: "RESIDENT_RECRUITMENT_LIST" });
    },
    [prvPicture]
  );

  const onPreviewCancelClick = useCallback(() => {
    setIsPreview(false);
  }, []);

  const toast = useToast();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmitDeleteModal = useCallback(async () => {
    setDoubleClickBlocked(true);
    try {
      await deleteRecruitmentTemplateById(recruitmentTemplateId);
      toast.success("募集を削除しました");
      history.push({ to: "RESIDENT_RECRUITMENT_TEMPLATE_LIST" });
    } catch (e) {
      toast.error("募集の削除に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
      setIsOpenDeleteModal(false);
    }
  }, [recruitmentTemplateId]);

  if (isLoading) {
    return (
      <>
        <Header title={isPreview ? "プレビュー" : "ひな形から新規作成"} />
        {canDeleteTemplate && (
          <ButtonWrapper>
            <DeleteButton onClick={() => null} disabled={true}>
              ひな形を削除
            </DeleteButton>
          </ButtonWrapper>
        )}
        <SpinnerCard>
          <Spinner />
        </SpinnerCard>
      </>
    );
  }

  if (isError || !recruitmentTemplate) {
    return <span>{error?.message || "ひな型募集情報が見つかりません"}</span>;
  }

  return (
    <>
      <Header title={isPreview ? "プレビュー" : "ひな形から新規作成"} />
      {canDeleteTemplate && (
        <ButtonWrapper>
          <DeleteButton onClick={() => setIsOpenDeleteModal(true)}>
            ひな形を削除
          </DeleteButton>
        </ButtonWrapper>
      )}
      {!isPreview && (
        <ResidentRecruitmentForm
          control={control}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
          setValue={setValue}
          trigger={trigger}
          isSubmitted={isSubmitted}
          watchAllValue={watchAllValue}
          onPreviewClick={handleSubmit(onPreviewClick)}
          onDraftSubmit={onDraftClick}
          getAddress={getAddress}
          defaultPdfFiles={getValues().pdfFiles}
          defaultAlreadyUploadedPdfFiles={getValues().alreadyUploadedPdfFiles}
        />
      )}
      {isPreview && (
        <RecruitmentPreview
          watchAllValue={watchAllValue}
          onSubmit={handleSubmit(onPreviewSubmitClick)}
          onCancel={onPreviewCancelClick}
          isLoading={isPostLoading}
          recruitmentPreview={recruitmentTemplate}
          defaultPdfFiles={getValues().pdfFiles}
          defaultAlreadyUploadedPdfFiles={getValues().alreadyUploadedPdfFiles}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteRecruitmentTemplateModal
          title={recruitmentTemplate?.title ?? ""}
          onSubmit={onSubmitDeleteModal}
          onClose={() => setIsOpenDeleteModal(false)}
          disabled={doubleClickBlocked}
        />
      )}
    </>
  );
}

const DeleteButton = styled.button`
  color: #bd271e;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
  background-color: transparent;
  border: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`;

const SpinnerCard = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-bottom: 40px;
  padding-top: 40px;
`;
