import React, { useEffect } from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { useGetChildren } from "../../../hooks/api/child";
import { Child } from "@shared/types/child";
import styled from "styled-components";
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { StepAdmin } from "../../../components/icons/StepAdmin";
import {
  MAX_STEP_NUMBER,
  StepMember,
} from "../../../components/icons/StepMember";
import { Margin } from "../../../components/Margin";
import { RegistrationFooter } from "../../../components/RegistrationFooter";
import { useFieldArray, useForm } from "react-hook-form";
import { upsertAndDeleteChildren } from "../../../apiClients/child";
import { unreachable } from "../../../utils/unreachable";
import { classOption } from "../../../utils/getChildClass";
import { useBaseInfo } from "../../../hooks/api/baseInfo";
import { Role, isPtaAdminRole } from "../../../utils/types/role";
import { useGradeOptions } from "src/hooks/useGradeOptions";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";

export default function RegisterChildInfoPage() {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const { children, isLoading } = useGetChildren(user.id);
  const { currentBaseInfo } = useBaseInfo();
  const { organization } = useGetCurrentOrganization();
  const gradeOptions = useGradeOptions(organization?.schoolType);

  const onSubmit = async (data: FormValues) => {
    const children: Child[] = [];
    data.itemRows.map((value) => {
      children.push({
        userId: user.id,
        childLastName: value.childLastName,
        childFirstName: value.childFirstName,
        childLastNameKana: value.childLastNameKana,
        childFirstNameKana: value.childFirstNameKana,
        grade: value.grade,
        class: value.class,
        id: value.id,
      });
    });

    try {
      await upsertAndDeleteChildren(user.id, children);
      switch (user.role) {
        case Role.PTAAdmin:
          history.push({
            to: "COMMUNITY_SETTING",
          });
          break;
        case Role.PTAMember:
          history.push({
            to: "POSITION_SETTING",
          });
          break;
        case Role.CSAdmin:
        case Role.CSMember:
        case Role.CSGuest:
        case Role.CSOperator:
        case Role.BEAdmin:
          break;
        default:
          unreachable(user.role);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // FormValues の型
  type FormValues = {
    itemRows: Child[];
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    mode: "onBlur",
    shouldUnregister: false,
  });

  const { fields, append, remove, replace } = useFieldArray({
    name: "itemRows",
    control,
  });

  // itemRowsの入力状態を監視させる
  const watchFieldArray = watch("itemRows");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    // setValue関数は一度しか実行できないため、取得完了後に一度だけ実行する
    if (isLoading) {
      return;
    }
    if (children.length) {
      setValue("itemRows", children);
    } else {
      replace([
        {
          childLastName: currentBaseInfo.lastName,
          childFirstName: "",
          childLastNameKana: currentBaseInfo.lastNameKana,
          childFirstNameKana: "",
          grade: undefined,
          class: undefined,
        },
      ]);
    }
  }, [children, isLoading, currentBaseInfo]);

  return (
    <div>
      <Margin marginBottom={24} />
      {isPtaAdminRole(user.role) ? (
        <StepAdmin stepNum={2} />
      ) : (
        <StepMember stepNum={2} maxNum={MAX_STEP_NUMBER.default} />
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <StyledContainer>
          <Margin marginBottom={16} />
          <Typo.Heading3>お子さま情報の入力</Typo.Heading3>
          <Margin marginBottom={8} />
          <Responsive.Row>
            {controlledFields.map((field, index) => {
              return (
                <Responsive.Col key={index}>
                  <Typo.Heading4> {index + 1}人目</Typo.Heading4>
                  <Margin marginBottom={10} />
                  <Typo.Heading5>お名前</Typo.Heading5>
                  <Margin marginBottom={8} />
                  <HorizontalInputWrapper>
                    <SelectWrapper>
                      <TextChildField
                        placeholder="例) 山田"
                        {...register(
                          `itemRows.${index}.childLastName` as const,
                          {
                            required: "入力必須項目です",
                          }
                        )}
                      />
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.childLastName != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.childLastName?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                    <SelectWrapper>
                      <TextChildField
                        placeholder="例）太朗"
                        {...register(
                          `itemRows.${index}.childFirstName` as const,
                          {
                            required: "入力必須項目です",
                          }
                        )}
                      />
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.childFirstName != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.childFirstName?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                  </HorizontalInputWrapper>
                  <Typo.Heading5>ふりがな</Typo.Heading5>
                  <Margin marginBottom={8} />
                  <HorizontalInputWrapper>
                    <SelectWrapper>
                      <TextChildField
                        {...register(
                          `itemRows.${index}.childLastNameKana` as const,
                          {
                            required: "入力必須項目です",
                            pattern: {
                              value: /^[ぁ-んーー]+$/u,
                              message: "ひらがなのみで入力してください",
                            },
                          }
                        )}
                        placeholder="例）やまだ"
                      />
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.childLastNameKana != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.childLastNameKana?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                    <SelectWrapper>
                      <TextChildField
                        {...register(
                          `itemRows.${index}.childFirstNameKana` as const,
                          {
                            required: "入力必須項目です",
                            pattern: {
                              value: /^[ぁ-んーー]+$/u,
                              message: "ひらがなのみで入力してください",
                            },
                          }
                        )}
                        placeholder="例）たろう"
                      />
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.childFirstNameKana != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.childFirstNameKana?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                  </HorizontalInputWrapper>
                  <HorizontalInputWrapper>
                    <SelectWrapper>
                      <Typo.Heading5>学年</Typo.Heading5>
                      <Margin marginBottom={8} />
                      <SelectChildInfo
                        {...register(`itemRows.${index}.grade` as const, {
                          required: "入力必須項目です",
                          setValueAs: (v) =>
                            v === "" ? undefined : parseInt(v, 10),
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          選択してください
                        </option>
                        {gradeOptions.map((opt) => {
                          return (
                            <option key={opt.id} value={opt.id}>
                              {getGradeLabel(
                                opt.id,
                                organization?.schoolDisplayType,
                                true,
                                false,
                                "short"
                              )}
                            </option>
                          );
                        })}
                      </SelectChildInfo>
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.grade != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.grade?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                    <SelectWrapper>
                      <Typo.Heading5>クラス</Typo.Heading5>
                      <Margin marginBottom={8} />
                      <SelectChildInfo
                        {...register(`itemRows.${index}.class` as const, {
                          required: "入力必須項目です",
                          setValueAs: (v) =>
                            v === "" ? undefined : parseInt(v, 10),
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          選択してください
                        </option>
                        {classOption.map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </SelectChildInfo>
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.class != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.class?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                  </HorizontalInputWrapper>
                  {controlledFields.length > 1 && (
                    <TrashButton onClick={() => remove(index)} type="button">
                      <TrashIcon />
                    </TrashButton>
                  )}
                </Responsive.Col>
              );
            })}
            <AddChildForm
              type="button"
              onClick={() => {
                append({
                  childLastName: currentBaseInfo.lastName,
                  childFirstName: "",
                  childLastNameKana: currentBaseInfo.lastNameKana,
                  childFirstNameKana: "",
                  grade: undefined,
                  class: undefined,
                });
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "5px" }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 7H11.5C11.7761 7 12 7.22386 12 7.5C12 7.77614 11.7761 8 11.5 8H8V11.5C8 11.7761 7.77614 12 7.5 12C7.22386 12 7 11.7761 7 11.5V8H3.5C3.22386 8 3 7.77614 3 7.5C3 7.22386 3.22386 7 3.5 7H7V3.5C7 3.22386 7.22386 3 7.5 3C7.77614 3 8 3.22386 8 3.5V7ZM7.5 0C11.6356 0 15 3.36441 15 7.5C15 11.6356 11.6356 15 7.5 15C3.36441 15 0 11.6356 0 7.5C0 3.36441 3.36441 0 7.5 0ZM7.5 0.882353C3.84529 0.882353 0.882353 3.84529 0.882353 7.5C0.882353 11.1547 3.84529 14.1176 7.5 14.1176C11.1547 14.1176 14.1176 11.1547 14.1176 7.5C14.1176 3.84529 11.1547 0.882353 7.5 0.882353Z"
                  fill="#5A606B"
                />
              </svg>
              お子さまを追加
            </AddChildForm>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter />
      </form>
    </div>
  );
}

export const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding-bottom: 88px;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

export const HorizontalInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 16px;
`;

export const SelectWrapper = styled.div`
  min-width: 240px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;

export const TrashButton = styled.button`
  border: none;
  display: block;
  margin: 0 0 0 auto;
  cursor: pointer;
  background: none;
`;

export const AddChildForm = styled.button`
  border: none;
  display: block;
  margin: 0 0 0 auto;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  width: 138px;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: #5a606b;
`;

export const TextChildField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

export const SelectChildInfo = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
`;

export const TrashIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 3H16V4H0V3H5V1C5 0.448 5.448 0 6 0H10C10.552 0 11 0.448 11 1V3ZM3.944 11H7V12H4.1L4.492 14.519C4.534 14.788 4.746 14.977 4.985 14.977H11.015C11.254 14.977 11.466 14.788 11.508 14.519L13.006 4.943H14L12.496 14.673C12.38 15.42 11.756 15.977 11.015 15.977H4.985C4.244 15.977 3.62 15.42 3.504 14.673L1.993 4.943H9V5.95H3.157L3.476 8H8V9H3.632L3.944 11ZM6 3H10V1H6V3Z"
        fill="#5A606B"
      />
    </svg>
  );
};
