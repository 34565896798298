import * as client from "./client";
import {
  Recruitment,
  RecruitmentListResponse,
} from "@shared/types/recruitment";
import {
  RecruitmentApplyBodyType,
  RecruitmentListQueryType,
  recruitmentIdSchema,
  RecruitmentIdSchemaType,
  getRecruitmentByIdQuerySchema,
  GetRecruitmentByIdQuerySchemaType,
  recruitmentListQuerySchema,
  recruitmentApplyListQuerySchema,
  RecruitmentApplyListQuerySchema,
  recruitmentApplyBodySchema,
  recruitmentCreateSchema,
  recruitmentUpdateSchema,
  RecruitmentCreateSchemaType,
  RecruitmentUpdateSchemaType,
} from "@shared/validator/features/recruitment.schema";

/**
 * 募集系APIクライアント
 */

/**
 * 募集の状態を返す
 * @param {Recruitment} recruitment
 * @returns {boolean} 募集中かどうか
 */
export const recruitmentIsOpened = (
  recruitment: Pick<Recruitment, "schedule" | "status">
): boolean => {
  const { schedule, status } = recruitment;
  if (status === "CLOSED") {
    return false;
  }
  // 募集時間(複数)の中で一番遅い終了時刻
  const end = schedule
    .map((s) => s.end)
    .sort()
    .reverse()[0];
  return new Date() < new Date(end);
};

export async function getDraftRecruitmentList(): Promise<Recruitment[]> {
  return await client.get<{}, Recruitment[]>("/recruitment/draftList", {});
}

export async function getRecruitmentList(
  filter: RecruitmentListQueryType
): Promise<RecruitmentListResponse> {
  const args = recruitmentListQuerySchema.parse(filter);
  return await client.get<RecruitmentListQueryType, RecruitmentListResponse>(
    "/recruitment/list",
    args
  );
}

export async function getApplyRecruitmentList(
  status?: string
): Promise<Recruitment[]> {
  const args = recruitmentApplyListQuerySchema.parse({
    status,
  });
  return await client.get<RecruitmentApplyListQuerySchema, Recruitment[]>(
    "/recruitment/applyList",
    args
  );
}

export async function getRecruitmentById(
  recruitmentId: string
): Promise<Recruitment> {
  const args = getRecruitmentByIdQuerySchema.parse({ recruitmentId });
  return await client.get<GetRecruitmentByIdQuerySchemaType, Recruitment>(
    "/recruitment/getByRecruitmentId",
    args
  );
}

export async function createRecruitment(
  arg: RecruitmentCreateSchemaType,
  files: File[]
): Promise<void> {
  const args = recruitmentCreateSchema.parse(arg);
  const recruitmentCreateArg = JSON.stringify(args);
  await client.postWithFormData<
    { recruitmentCreateArg: string; files: File[] },
    { result: string }
  >("/recruitment/create", { recruitmentCreateArg, files });
}

export async function updateRecruitment(
  arg: RecruitmentUpdateSchemaType,
  files: File[]
) {
  const args = recruitmentUpdateSchema.parse(arg);
  const recruitmentUpdateArg = JSON.stringify(args);
  await client.postWithFormData<
    { recruitmentUpdateArg: string; files: File[] },
    { result: string }
  >("/recruitment/update", { recruitmentUpdateArg, files });
}

export async function closeRecruitment(recruitmentId: string): Promise<void> {
  const args = recruitmentIdSchema.parse({ recruitmentId });
  await client.post<RecruitmentIdSchemaType, { result: string }>(
    "/recruitment/close",
    args
  );
}

export async function resendRecruitment(recruitmentId: string): Promise<void> {
  const args = recruitmentIdSchema.parse({ recruitmentId });
  await client.post<RecruitmentIdSchemaType, { result: string }>(
    "/recruitment/resend",
    args
  );
}

export async function deleteRecruitment(recruitmentId: string): Promise<void> {
  const args = recruitmentIdSchema.parse({ recruitmentId });
  await client.post<RecruitmentIdSchemaType, { result: string }>(
    "/recruitment/delete",
    args
  );
}

// 公開済みの募集は論理削除する
export async function deleteOpenRecruitment(
  recruitmentId: string
): Promise<void> {
  const args = recruitmentIdSchema.parse({ recruitmentId });
  await client.post<RecruitmentIdSchemaType, { result: string }>(
    "/recruitment/deleteOpen",
    args
  );
}

export async function applyRecruitment(
  body: RecruitmentApplyBodyType
): Promise<void> {
  const args = recruitmentApplyBodySchema.parse(body);
  await client.post<RecruitmentApplyBodyType, { result: string }>(
    "/recruitment/apply",
    args
  );
}
