import React from "react";
import styled from "styled-components";
import { Margin } from "../../components/Margin";
import { getClassName } from "../../utils/getChildClass";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import {
  GradeNumberSchemaType,
  ClassNumberSchemaType,
} from "@shared/validator/rules/child";

interface Props {
  currentTeacherInfo:
    | { grade: GradeNumberSchemaType; class: ClassNumberSchemaType }
    | null
    | undefined;
}

const SignUpPreviewTeacherInfo = ({ currentTeacherInfo }: Props) => {
  const { organization } = useGetCurrentOrganization();

  return (
    <>
      <ProfileLabel>担当情報の入力</ProfileLabel>
      <ProfileContent>
        {currentTeacherInfo?.grade && currentTeacherInfo?.class ? (
          <>
            {`${getGradeLabel(
              currentTeacherInfo.grade,
              organization?.schoolDisplayType,
              true,
              true,
              "full"
            )} ${getClassName(currentTeacherInfo.class, true)}`}
          </>
        ) : (
          "担任なし（校長、副校長、その他）"
        )}
      </ProfileContent>
      <Margin marginBottom={32} />
    </>
  );
};

const ProfileLabel = styled.p`
  color: #343741;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: bold;
`;

const ProfileContent = styled.p`
  color: #343741;
  font-size: 18px;
`;

export default SignUpPreviewTeacherInfo;
