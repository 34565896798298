import { z } from "zod";
import { zRequiredString } from "../rules";
import { ServiceType } from "../../types/organization";

// Create Organization Schema
export const createOrganizationSchema = z.object({
  communityIds: z.array(zRequiredString),
  name: z.string(),
  serviceType: z.nativeEnum(ServiceType).default("PTA"),
});

export type CreateOrganizationSchemaType = z.infer<
  typeof createOrganizationSchema
>;

// Create BE Organization Schema
export const createBEOrganizationSchema = z.object({
  name: zRequiredString,
  csOrganizationId: zRequiredString,
});

export type CreateBEOrganizationSchemaType = z.infer<
  typeof createBEOrganizationSchema
>;

//packages/server/src/@shared/types/organization.ts と内容が被るが、typesの定義を用いると表現できないため、ここでも定義する
const schoolType = z.enum(["B", "C", "D", "BC", "CD", "BCD"]);
const schoolDisplayType = z.enum(["CONTINUOUS", "SEGMENTED"]);

// Update Organization Schema
export const updateOrganizationSchema = z.object({
  id: zRequiredString,
  name: z.string().optional(),
  communityIds: z.array(z.string()).optional(),
  nameListPublished: z.boolean().optional(),
  requireAdminToUpdateCareer: z.boolean().optional(),
  postSchoolYearsFilter: z.array(z.string()).optional(),
  lineOption: z.boolean().optional(),
  schoolType: schoolType.optional(),
  schoolDisplayType: schoolDisplayType.optional(),
  showAllPostToTeacher: z.boolean().optional(),
});

export type UpdateOrganizationSchemaType = z.infer<
  typeof updateOrganizationSchema
>;

// Get Organization Schema
export const getOrganizationSchema = z.object({
  organizationId: zRequiredString,
});

export type GetOrganizationSchemaType = z.infer<typeof getOrganizationSchema>;

// List By Community ID Schema
export const listByCommunityIdSchema = z.object({
  communityId: zRequiredString,
});

export type ListByCommunityIdSchemaType = z.infer<
  typeof listByCommunityIdSchema
>;
