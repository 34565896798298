import { z } from "zod";
import { zRequiredString } from "../rules";
import { baseRequestSchema } from "./common.schema";
import { childSchema } from "../models/child.schema";

export const getChildrenByConditionSchema = baseRequestSchema.extend({
  q: z.string().optional(),
  filter: z.string().optional(), // JSON文字列化されたフィルター
});

export type GetChildrenByConditionSchemaType = z.infer<
  typeof getChildrenByConditionSchema
>;

export const getChildrenSchema = baseRequestSchema.extend({
  userId: zRequiredString,
});

export type GetChildrenSchemaType = z.infer<typeof getChildrenSchema>;

export const getDisabledUsersChildrenByConditionSchema =
  baseRequestSchema.extend({
    q: z.string().optional(),
    filter: z.string().optional(), // JSON文字列化されたフィルター
  });

export type GetDisabledUsersChildrenByConditionSchemaType = z.infer<
  typeof getDisabledUsersChildrenByConditionSchema
>;

export const upsertAndDeleteChildrenSchema = z.object({
  userId: zRequiredString,
  data: z.array(childSchema),
});

export type UpsertAndDeleteChildrenSchemaType = z.infer<
  typeof upsertAndDeleteChildrenSchema
>;
