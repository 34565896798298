import { z } from "zod";
import { zRequiredString } from "../rules";

export const createBelongSchema = z.object({
  organizationId: zRequiredString,
  name: zRequiredString,
  order: z.number().nullable(),
});

export type CreateBelongSchemaType = z.infer<typeof createBelongSchema>;

export const updateBelongSchema = z.object({
  belongId: zRequiredString,
  name: zRequiredString,
  order: z.number().nullable(),
});

export type UpdateBelongSchemaType = z.infer<typeof updateBelongSchema>;

export const deleteBelongSchema = z.object({
  belongId: zRequiredString,
});

export type DeleteBelongSchemaType = z.infer<typeof deleteBelongSchema>;
