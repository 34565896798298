import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { getDownloadUrl } from "src/apiClients/boardEducation/storage";
import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "../../QueryOptions";
import { DownloadSchema } from "@shared/validator/features/download.schema";

export function useSignatureTemplatePdf(
  args: DownloadSchema,
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: [
      "api",
      "boardEducation",
      "signatureTemplate",
      "pdf",
      apiContext,
      args,
    ],
    queryFn: async () => {
      return await getDownloadUrl(args);
    },
    ...queryOptions,
  });

  return {
    pdfUrl: useMemo(() => query.data || undefined, [query.data]),
    ...query,
  };
}
