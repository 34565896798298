import { z } from "zod";
import { zRequiredString } from "../rules/string";
import { Role } from "../../types/role";
import { InternalUserRole } from "../../types/internalUserRole";
import { UserType } from "../../types/userType";
import { baseInfoSchema } from "./baseInfo.schema";
import { accountSchema } from "./account.schema";
import { partnerSchema } from "./partner.schema";

export const userSchema = z.object({
  id: zRequiredString,
  email: zRequiredString,
  name: zRequiredString,
  role: z.nativeEnum(Role),
  isApprover: z.boolean(),
  able: z.boolean(),
  signUpState: z.boolean(),
  internalRole: z.nativeEnum(InternalUserRole),
  picture: z.string(),
  type: z.nativeEnum(UserType),
  firebaseUid: z.string(),
  walkMinute: z.number().optional(),
  baseInfo: baseInfoSchema,
  account: accountSchema.optional(),
  partner: partnerSchema.optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  deletedAt: z.string().optional(),
});

export type User = z.infer<typeof userSchema>;
