import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { NonPostElement } from "../PTAPersonalPage";
import {
  ModalBody,
  ModalPortal,
  ModalPortalProps,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { PostHeader } from "../../../features/Post/PostHeader";

import { PostType, useUnApprovedPostList } from "../../../hooks/api/post";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { Posts } from "./PostPage";
import { PostNavigation } from "../../../features/Post/Post";
import { Margin } from "../../../components/Margin";
import { Heading2 } from "../../../components/Typo";
import { PostCard } from "../../../features/Post/PostCard";
import { remandPost } from "../../../apiClients/post";
import { useToast } from "../../../components/Toast";
import { Spinner } from "../../../components/icons/Spinner";
import { useOrganization } from "../../../hooks/recoil/organization";
import { usePostUserGroupList } from "../../../hooks/recoil/postUserGroup";
import { logger } from "src/utils/logger";
import { TargetGradesType } from "@shared/types/post/api";

/**
 * 連絡ページ(未承認)
 *
 * TODO: 要検討 コンポーネント設計
 * 全て、自分、未承認は共通の方がメンテしやすそうなので共通化する
 * getAllPosts, getCurrentUserPosts, getUnApprovedPosts(これはAdminのみ)
 */
export default function PostUnApprovedPage() {
  const currentUser = useCurrentUser();
  const { organization, writeOrganization } = useOrganization({});
  const { getPostUserGroupLabel } = usePostUserGroupList();
  const { posts, getPosts, isLoading } = useUnApprovedPostList(currentUser);
  const [openPostId, setOpenPostId] = useState("");

  // 承認する
  const handleApprovePost = async () => {
    getPosts();
  };

  const getPostBelongLabel = useCallback(
    (target: TargetGradesType | string) => {
      return (
        organization?.belongs?.find((belong) => belong.id === target)?.name ??
        "削除済み"
      );
    },
    [organization]
  );

  return (
    <>
      {currentUser.isApprover ? (
        <>
          <PostHeader
            organization={organization}
            updateOrganization={writeOrganization}
            refetch={getPosts}
          />
          <PostNavigation />
          <Posts>
            {posts.length === 0 && isLoading && (
              <Card>
                <Spinner />
              </Card>
            )}
            {posts.length !== 0
              ? posts.map((post, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        if (openPostId !== post.id) {
                          setOpenPostId(post.id);
                        } else {
                          setOpenPostId("");
                        }
                      }}
                    >
                      <PostCard
                        post={post}
                        isOpen={openPostId == post.id}
                        type="unapproved"
                        onPostApproved={handleApprovePost}
                        getPostBelongLabel={getPostBelongLabel}
                        getPostUserGroupLabel={getPostUserGroupLabel}
                        refetch={getPosts}
                        borderBottom={i !== posts.length - 1}
                      />
                    </div>
                  );
                })
              : !isLoading && (
                  <NonPostElement
                    title="連絡がありません"
                    message="新規作成から作成ができます"
                  />
                )}
          </Posts>
        </>
      ) : (
        <>閲覧権限がありません</>
      )}
    </>
  );
}

/**
 * 投稿の差し戻しモーダル
 * 差し戻しの仕様↓
 * Post.isRemanded == true かつ RemandUserId is not null
 */
export const RemandPostModal = ({
  post,
  onClose,
  refetch,
}: {
  post: PostType;
  onClose: () => void;
  refetch: () => void;
} & ModalPortalProps) => {
  const [text, setText] = useState<string>("");
  const currentUser = useCurrentUser();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { posts, getPosts } = useUnApprovedPostList(currentUser);
  const [errorRemandText, setErrorRemandText] = useState(false);
  const toast = useToast();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async () => {
    if (errorRemandText) {
      return;
    }
    setDoubleClickBlocked(true);
    try {
      // 差し戻しAPI
      await remandPost({ postId: post.id, comment: text });
      await getPosts();
      onClose();
      await refetch();
    } catch (error) {
      logger.error(error);
      toast.error("差し戻しに失敗しました");
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  const maxLength = 3000;

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length <= maxLength) {
        setText(e.target.value);
        setErrorRemandText(false);
      } else {
        setErrorRemandText(true);
      }
    },
    []
  );

  return (
    <ModalPortal onClose={onClose} modalBoxIsHeightFull={true}>
      <ModalRemandTop>
        <ModalHeader>投稿の差し戻し</ModalHeader>
        <ModalBody>
          <div>投稿を差し戻しますか？</div>
          <div>コメントをつけて差し戻しができます。</div>
          <div>コメントは投稿者が確認できます。</div>
          <Margin marginTop={15} />
          <ModalLabel>コメント</ModalLabel>
          <Margin marginTop={5} />
          <RemandTextArea
            name=""
            id=""
            cols={30}
            rows={10}
            value={text}
            onChange={onChangeText}
            placeholder="入力してください（3000文字以内）"
          />
          {errorRemandText && <>3000文字以内で入力してください</>}
          <ModalSubmitButtons
            disabled={errorRemandText || doubleClickBlocked}
            submitText="差し戻す"
            onSubmit={onSubmit}
            onCancel={onClose}
          />
        </ModalBody>
      </ModalRemandTop>
    </ModalPortal>
  );
};

const ModalRemandTop = styled.div`
  height: 424px;
`;

const ModalHeader = styled(Heading2)`
  padding-bottom: 24px;
  display: flex;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Close = styled.div`
  margin-left: auto;
  cursor: pointer;
  width: 10px;
  height: 10px;
`;

const RemandTextArea = styled.textarea`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px;
`;

const ModalLabel = styled.p`
  font-size: 12px;
  font-weight: bold;
`;

const Card = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
