import { useState, useCallback, useEffect } from "react";
import * as authApi from "../../apiClients/auth";
import {
  GradeNumberSchemaType,
  ClassNumberSchemaType,
} from "@shared/validator/rules/child";

export const useTeacherInfo = () => {
  const [currentTeacherInfo, setCurrentTeacherInfo] = useState<
    | { grade: GradeNumberSchemaType; class: ClassNumberSchemaType }
    | null
    | undefined
  >(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const getTeacherInfo = useCallback(async () => {
    setIsLoading(true);
    const info = (await authApi.getTeacherInfo()).data;
    setCurrentTeacherInfo(info);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getTeacherInfo();
  }, []);

  const updateTeacherInfo = useCallback(
    async (data: {
      grade: GradeNumberSchemaType;
      class: ClassNumberSchemaType;
    }) => {
      await authApi.updateTeacherInfo(data);
    },
    []
  );

  return {
    isLoading,
    currentTeacherInfo,
    updateTeacherInfo,
    refetchTeacherInfo: getTeacherInfo,
  };
};
