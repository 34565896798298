import { z } from "zod";
import { zRequiredString } from "../rules";

export const getInvitationBEUsersSchema = z.object({
  organizationId: zRequiredString,
});

export type GetInvitationBEUsersSchemaType = z.infer<
  typeof getInvitationBEUsersSchema
>;

export const deleteInvitationBEUserSchema = z.object({
  id: zRequiredString,
  organizationId: zRequiredString,
});

export type DeleteInvitationBEUserSchemaType = z.infer<
  typeof deleteInvitationBEUserSchema
>;
