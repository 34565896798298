import { UnverifiedUserStatus } from "@shared/types/unverified";
import React from "react";
import styled from "styled-components";
import { Button } from "src/components/Button";
import { colorsPallet } from "src/theme";

function UnverifiedUserCard({
  unverifiedUserStatus,
  setIsBounceDeleteModalOpen,
  setIsUserDeleteModalOpen,
}: {
  unverifiedUserStatus: UnverifiedUserStatus;
  setIsBounceDeleteModalOpen: (open: boolean) => void;
  setIsUserDeleteModalOpen: (open: boolean) => void;
}) {
  if (!unverifiedUserStatus) return null;
  return (
    <UserStatusCard>
      <InfoItem>
        <Label>メールアドレス:</Label>
        <Value>{unverifiedUserStatus?.user?.email}</Value>
      </InfoItem>
      <InfoItem>
        <Label>組織名:</Label>
        <Value>{unverifiedUserStatus?.organization?.name}</Value>
      </InfoItem>
      <StatusBox isBounce={unverifiedUserStatus.bounce.length > 0}>
        <StatusBoxLeft>
          <StatusText isBounce={unverifiedUserStatus.bounce.length > 0}>
            {unverifiedUserStatus.bounce.length > 0
              ? "メール配信停止中"
              : "メール配信可能"}
          </StatusText>
          {unverifiedUserStatus.bounce.length > 0 && (
            <ReasonText>
              <Label>停止理由:</Label>
              <Value>{unverifiedUserStatus.bounce[0].reason}</Value>
            </ReasonText>
          )}
        </StatusBoxLeft>
        {unverifiedUserStatus.bounce.length > 0 && (
          <ActionButton>
            <Button
              color="primary"
              fill
              onClick={() => setIsBounceDeleteModalOpen(true)}
              size="large"
            >
              配信停止を解除する
            </Button>
          </ActionButton>
        )}
      </StatusBox>
      <UserDeleteButton>
        <Button
          color="danger"
          fill
          size="large"
          onClick={() => setIsUserDeleteModalOpen(true)}
        >
          ユーザーを削除する
        </Button>
      </UserDeleteButton>
    </UserStatusCard>
  );
}

export default UnverifiedUserCard;

const UserStatusCard = styled.div`
  background: white;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${colorsPallet.lightShade};
  margin-top: 16px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const Label = styled.span`
  width: 128px;
  font-size: 14px;
  color: ${colorsPallet.darkShade};
  margin-left: 8px;
`;

const Value = styled.span`
  color: ${colorsPallet.darkShade};
  font-weight: 500;
`;

const StatusBox = styled.div<{ isBounce: boolean }>`
  margin-top: 24px;
  padding: 8px;
  background-color: ${(props) => (props.isBounce ? "#fff2f2" : "#f0f9ff")};
  border: 1px solid ${(props) => (props.isBounce ? "#ffd1d1" : "#bae6fd")};
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const StatusBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StatusText = styled.div<{ isBounce: boolean }>`
  font-weight: 500;
  color: ${(props) => (props.isBounce ? "#dc2626" : "#0ea5e9")};
`;

const ReasonText = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
`;

const ActionButton = styled.div``;

const UserDeleteButton = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`;
