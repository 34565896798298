import React, { useState } from "react";
import styled from "styled-components";
import * as theme from "../theme";
import { Panel } from "../components/Panel";
import { Heading1, Heading2 } from "../components/Typo";
import { Button } from "../components/Button";
import { Google } from "../components/icons/SvgIcons";
import { FlexLayout } from "../components/Container";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { formEmailPasswordState } from "../hooks/formEmailPassword";
import { PasswordInput, EmailInput } from "../components/Form";
import { errorCodeToMessage } from "../utils/errorCodeToMessage";
import { LeadUsingChromeText } from "../components/LeadUsingChromeText";
import { SideLineHeaderText } from "../components/SideLineHeaderText";
import { usePolyfitHistory, usePolyfitLocationQuery } from "../hooks/router";
import { signInOrSignUpWithPopup } from "../utils/auth";
import { signInWithEmailAndPassword } from "../utils/firebase";
import { APIError } from "../utils/types/ApiError";
import { logger } from "src/utils/logger";
import { Banner } from "src/components/Banner";
import { BREAKPOINTS } from "src/components/Responsive";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  gap: 460px;
  position: fixed;
  height: 48px;
  left: 0px;
  right: 0px;
  top: 0px;
  background: #2277cc;
  box-shadow: 0px 0.7px 1.4px rgba(0, 0, 0, 0.07),
    0px 1.9px 4px rgba(0, 0, 0, 0.05), 0px 4.5px 10px rgba(0, 0, 0, 0.05);
`;

const Header = styled.header`
  width: 45px;
  height: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const Container = styled.div`
  background: ${theme.states.pageBackgroundColor};
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
`;

const Spacer = styled.div`
  height: 20px;
`;

// FIXME: do not use "export const" with styled-components
export const ResetPasswordLinkWrapper = styled.div`
  text-align: right;

  a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #69707d;
  }
`;

const RegisterButton = styled.button`
  height: 36px;
  display: block;
  width: 100%;
  align-items: center;
  color: #ffffff;
  background-color: ${theme.colorsPallet.primary};
  border: 1px solid rgba(105, 112, 125, 0.2);
  border-radius: 6px;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.7;
  }
`;

const ContainerBox = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  left: 0;
  right: 0;
  top: calc(48px + 80px);
  width: 100%;
  /* height: 100%; */

  @media (max-width: ${BREAKPOINTS.TABLET}) {
    top: calc(48px + 62px);
  }

  ${Panel} {
    padding: 24px;
    text-align: center;
    width: 340px;
    min-height: 200px;
  }

  ${Button} {
    height: 36px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: ${theme.colorTokens.text};
    border: 1px solid rgba(105, 112, 125, 0.2);
    border-radius: 6px;
  }

  ${Button} svg {
    margin-right: 8px;
  }

  ${Heading2} {
    margin-bottom: 42px;
  }

  ${Heading1} {
    text-align: center;
    font-weight: 700;
    color: #1a1c21;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: "Inter";
  }
`;

const ErrorMessage = styled.div`
  margin-top: 12px;
  background-color: #fff0ef;
  color: #bd271e;
  font-size: 12px;
  text-align: start;
  padding: 6px 10px;
`;

export default function LoginPage() {
  const [error, setError] = useState("");
  const history = usePolyfitHistory();
  const { token } = usePolyfitLocationQuery("LOGIN", {
    token: undefined,
  });

  const signInOrFail = async () => {
    try {
      await signInOrSignUpWithPopup();
      history.push({
        to: "LOADING",
        query: { ...(token && { token }) },
      });
    } catch (e) {
      if (e instanceof Object && "code" in e && typeof e.code === "string") {
        const errorMessage = errorCodeToMessage(e.code);
        setError(errorMessage);
        logger.error(e, {
          errorMessage,
          errorCode: e.code,
        });
        return;
      }
      // TODO: APIErrorでハンドリングすると登録前にログインを試行した際に「セッションが切れました」と出てしまう
      // 一旦、全てのエラーで登録されていませんと表示しているが、APIErrorでハンドリングするように修正したい
      const errorMessage = "アカウントが登録されていません。";
      setError(errorMessage);
      logger.error(e, { errorMessage });
    }
  };

  const { 0: formEmailPassword } = useRecoilState(formEmailPasswordState);

  const loginByEmailAndPassword = async () => {
    if (!formEmailPassword.email || !formEmailPassword.password) {
      const errorMessage = "メールアドレスまたはパスワードが不正です";
      logger.error(new Error(errorMessage), {
        email: formEmailPassword.email,
        errorMessage,
      });
      return setError(errorMessage);
    }

    try {
      await signInWithEmailAndPassword(
        formEmailPassword.email,
        formEmailPassword.password
      );
      history.push({
        to: "LOADING",
        query: { ...(token && { token }) },
      });
    } catch (e) {
      if (e instanceof Object && "code" in e && typeof e.code === "string") {
        const errorMessage = errorCodeToMessage(e.code);
        setError(errorMessage);
        logger.error(e, {
          email: formEmailPassword.email,
          errorMessage,
          errorCode: e.code,
        });
        return;
      }
      // APIのエラーハンドリング
      if (e instanceof APIError) {
        setError(e.message);
        logger.error(e, {
          email: formEmailPassword.email,
          errorMessage: e.message,
        });
        return;
      }
      const errorMessage = "不明なエラーが発生しました。";
      setError(errorMessage);
      logger.error(e, { email: formEmailPassword.email, errorMessage });
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <Header>polyfit</Header>
      </HeaderContainer>
      <Banner
        url="https://www.polyfit.jp/support/pta/question"
        title="ログインでお困りの場合はこちら"
      />
      <ContainerBox>
        <FlexLayout direction="column">
          <Heading1>ログイン</Heading1>
          <Panel>
            <Button
              onClick={signInOrFail}
              color={"primary"}
              fill={true}
              style={{ width: "100%" }}
            >
              <Google />
              Googleでログイン
            </Button>
            <SideLineHeaderText text="Eメールでログイン" />
            <div>
              <EmailInput />
              <Spacer />
              <PasswordInput isLogin={true} />

              <ResetPasswordLinkWrapper>
                <Link
                  to={"/input_email"}
                  style={{
                    color: theme.colorsPallet.darkShade,
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  パスワードを忘れた方
                </Link>
              </ResetPasswordLinkWrapper>

              {error && (
                <ErrorMessage role="alert">
                  {error === "パスワードが正しくありません" ? (
                    <>
                      {error}
                      <br />
                      パスワードをお忘れの場合は、{" "}
                      <Link
                        to="/input_email"
                        style={{
                          color: theme.colorsPallet.primary,
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        こちら
                      </Link>
                      からリセットしてください。
                    </>
                  ) : error === "メールアドレスが正しくありません" ||
                    error === "メールアドレスまたはパスワードが不正です" ? (
                    <>{error}</>
                  ) : (
                    <>
                      {error}
                      <br />
                      管理者までお問合せ下さい。
                    </>
                  )}
                </ErrorMessage>
              )}

              <Spacer />
              <RegisterButton type="button" onClick={loginByEmailAndPassword}>
                ログイン
              </RegisterButton>
            </div>
            <LeadUsingChromeText />
          </Panel>
        </FlexLayout>
      </ContainerBox>
    </Container>
  );
}
