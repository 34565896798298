import { z } from "zod";
import { zRequiredString } from "../rules";

// GET /storage/entity のクエリパラメータ
export const getStorageEntitySchema = z.object({
  filePath: zRequiredString,
});

export type GetStorageEntitySchemaType = z.infer<typeof getStorageEntitySchema>;

// GET /storage/download のクエリパラメータ
export const getStorageDownloadSchema = z.object({
  filePath: zRequiredString,
  fileName: z.string(),
});

export type GetStorageDownloadSchemaType = z.infer<
  typeof getStorageDownloadSchema
>;
export const imageUploadDirectorySchema = z.string().optional();
// POST /storage/deleteImage のリクエストボディ
export const deleteStorageImageSchema = z.object({
  filePath: zRequiredString,
});

export type DeleteStorageImageSchemaType = z.infer<
  typeof deleteStorageImageSchema
>;
