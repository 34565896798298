import * as client from "./client";
import { InvitationValidateResponse } from "@shared/types/invitation";
import {
  getOrGenerateInvitationTokenSchema,
  GetOrGenerateInvitationTokenSchemaType,
  getOrGenerateInvitationResidentTokenSchema,
  GetOrGenerateInvitationResidentTokenSchemaType,
  inviteBEMemberSchema,
  InviteBEMemberSchemaType,
  verifyTokenSchema,
  VerifyTokenSchemaType,
  InviteResidentSchemaType,
  inviteResidentSchema,
  GetOrGenerateInvitationTokenForPTAParentFamilyType,
} from "@shared/validator/features/invitation.schema";

export async function validateInvitationToken(
  args: VerifyTokenSchemaType
): Promise<InvitationValidateResponse> {
  verifyTokenSchema.parse(args);
  const res = await client.post<
    VerifyTokenSchemaType,
    { valid: boolean; type?: "PTA" | "RESIDENT" }
  >("/invitation/validate", args);
  return res;
}

export async function getOrGenerateInvitationToken(
  args: GetOrGenerateInvitationTokenSchemaType
): Promise<string> {
  getOrGenerateInvitationTokenSchema.parse(args);
  const res = await client.post<
    GetOrGenerateInvitationTokenSchemaType,
    { token: string }
  >("/invitation/getOrGenerate/pta", args);
  return res.token;
}

export async function getOrGenerateInvitationTokenForPTAParentFamily(
  args: GetOrGenerateInvitationTokenForPTAParentFamilyType
): Promise<string> {
  getOrGenerateInvitationTokenSchema.parse(args);
  const res = await client.post<
    GetOrGenerateInvitationTokenForPTAParentFamilyType,
    { token: string }
  >("/invitation/getOrGenerate/ptaParentFamily", args);
  return res.token;
}

export async function getOrGenerateInvitationResidentToken(
  args: GetOrGenerateInvitationResidentTokenSchemaType
): Promise<string> {
  getOrGenerateInvitationResidentTokenSchema.parse(args);
  const res = await client.post<
    GetOrGenerateInvitationResidentTokenSchemaType,
    { token: string }
  >("/invitation/getOrGenerate/resident", args);
  return res.token;
}

export const inviteResidentMember = async (
  args: InviteResidentSchemaType
): Promise<void> => {
  inviteResidentSchema.parse(args);
  await client.post<InviteResidentSchemaType>("/invitation/resident", args);
};

export const inviteBEMember = async (
  args: InviteBEMemberSchemaType
): Promise<void> => {
  inviteBEMemberSchema.parse(args);
  await client.post<InviteBEMemberSchemaType>(
    "/invitation/boardEducation",
    args
  );
};

export const verifiedInvitationResidentMember = async (
  token: string
): Promise<{
  isNewUser: boolean;
}> => {
  const args = verifyTokenSchema.parse({ token });
  return await client.post<
    VerifyTokenSchemaType,
    {
      isNewUser: boolean;
    }
  >("/invitation/resident/verify", args);
};

export const verifiedInvitationBoardEducationMember = async (
  token: string
): Promise<{
  isNewUser: boolean;
}> => {
  const args = verifyTokenSchema.parse({ token });
  return await client.post<
    VerifyTokenSchemaType,
    {
      isNewUser: boolean;
    }
  >("/invitation/boardEducation/verify", args);
};
