import React, { useEffect, useState } from "react";
import { ModalHeader, ModalPortal } from "../../../components/Modal";
import { Button } from "../../../components/Button";
import {
  MINIMUM_NUMBER_OF_QUESTIONS,
  SURVEY_DEADLINE_TIME_INTERVAL,
} from "../../../apiClients/survey";
import styled from "styled-components";
import { Question } from "./Question";
import { Label } from "../../../components/form/Label";
import ReactDatePicker from "react-datepicker";
import { SurveyQuestions } from "@shared/validator/features/survey.schema";
import { useCreateSurvey } from "./useCreateSurvey";
import { Controller } from "react-hook-form";
import { User } from "src/apiClients/users";
import { ConfirmModal } from "../ConfirmModal";
import { useToast } from "src/components/Toast";
type Props = {
  isOnceSaved: boolean;
  setIsOnceSaved: (isOnceSaved: boolean) => void;
  user: User;
  onClose: () => void;
  surveyQuestions: SurveyQuestions | undefined;
  setSurveyQuestions: (questions: SurveyQuestions | undefined) => void;
};

export function CreateSurvey({
  isOnceSaved,
  setIsOnceSaved,
  user,
  onClose,
  surveyQuestions,
  setSurveyQuestions,
}: Props) {
  const {
    control,
    fields,
    handleAddQuestion,
    handleDeleteQuestion,
    formState: { isDirty },
    trigger,
    getValues,
  } = useCreateSurvey(user, surveyQuestions);

  useEffect(() => {
    if (isOnceSaved) {
      trigger();
    }
  }, []);

  const saveSurvey = () => {
    const data = getValues();
    data.state = "DRAFTED";
    setSurveyQuestions(data);
    onClose();
  };
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const toast = useToast();
  const handleClose = () => {
    if (isDirty) {
      setIsCancelModalOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      <ModalPortal onClose={handleClose}>
        <form>
          <ModalHeader>アンケート</ModalHeader>
          <QuestionWrapper>
            {fields.map((field, index) => (
              <Question
                key={field.id}
                index={index}
                control={control}
                onDelete={handleDeleteQuestion}
                isDeletable={fields.length > MINIMUM_NUMBER_OF_QUESTIONS}
              />
            ))}
            {fields.length < 10 && (
              <Button
                type="button"
                onClick={(e) => {
                  if (fields.length == 9) {
                    toast.info("質問数の上限に達しました。");
                  }
                  handleAddQuestion();
                }}
                color="ghost"
                size="large"
              >
                + 質問を追加
              </Button>
            )}
          </QuestionWrapper>

          <DeadlineField>
            <Label size="s" marginBottom={4}>
              回答期限
            </Label>
            <Controller
              name="deadLine"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <ReactDatePicker
                    selected={value ? new Date(value) : null}
                    onChange={(date) => onChange(date?.toISOString())}
                    dateFormat="yyyy/MM/dd HH:mm"
                    showTimeSelect
                    timeIntervals={SURVEY_DEADLINE_TIME_INTERVAL}
                    placeholderText="回答期限を選択してください"
                  />
                  {error && <ErrorText>{error.message}</ErrorText>}
                </>
              )}
            />
          </DeadlineField>

          <ButtonContainer>
            {surveyQuestions && (
              <Button
                fill
                size="large"
                color="danger"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsDeleteModalOpen(true);
                }}
              >
                削除
              </Button>
            )}
            <Button
              empty
              color="primary"
              size="large"
              type="button"
              onClick={() => {
                if (isDirty) {
                  setIsCancelModalOpen(true);
                } else {
                  onClose();
                }
              }}
            >
              キャンセル
            </Button>
            <Button
              fill
              color="primary"
              size="large"
              onClick={() => {
                saveSurvey();
                setIsOnceSaved(true);
              }}
            >
              保存
            </Button>
          </ButtonContainer>
        </form>
      </ModalPortal>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => {
          setSurveyQuestions(undefined);
          setIsOnceSaved(false);
          onClose();
        }}
        title="アンケートを削除"
        message="アンケートが削除されますがよろしいですか？"
      />
      <ConfirmModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onConfirm={onClose}
        title="編集内容を破棄"
        message="入力した内容が破棄されますがよろしいですか？"
      />
    </>
  );
}

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DeadlineField = styled.div`
  margin: 16px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const ErrorText = styled.span`
  color: #bd271e;
  font-size: 14px;
`;
