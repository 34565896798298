import {
  createOrUpdateGradeSizeSchema,
  CreateOrUpdateGradeSizeSchemaType,
  GradeSize,
} from "@shared/validator/features/gradeSize.schema";
import * as client from "./client";

export async function getGradeSizes(): Promise<GradeSize[]> {
  const res = await client.get<{}, GradeSize[]>(`/gradeSize/getGradeSize`, {});
  return res;
}

export async function createOrUpdateGradeSizes(
  args: CreateOrUpdateGradeSizeSchemaType
): Promise<void> {
  createOrUpdateGradeSizeSchema.parse(args);
  await client.post(`/gradeSize/createOrUpdateGradeSize`, args);
}
