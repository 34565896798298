import * as client from "./client";
import { BoardEducation } from "@shared/types/boardEducation";
import {
  getBoardEducationByOrganizationIdSchema,
  GetBoardEducationByOrganizationIdSchemaType,
  updateBoardEducationSchema,
  UpdateBoardEducationSchemaType,
} from "../@shared/validator/features/boardEducation.schema";

export async function getBoardEducationByOrganizationId(
  args: GetBoardEducationByOrganizationIdSchemaType
): Promise<BoardEducation> {
  getBoardEducationByOrganizationIdSchema.parse(args);
  return client.get<
    GetBoardEducationByOrganizationIdSchemaType,
    BoardEducation
  >("/internal/boardEducation/getByOrganizationId", args);
}

export async function updateBoardEducation(
  args: UpdateBoardEducationSchemaType
): Promise<BoardEducation> {
  updateBoardEducationSchema.parse(args);
  return client.post<UpdateBoardEducationSchemaType, BoardEducation>(
    "/internal/boardEducation/updateBoardEducation",
    args
  );
}
