import React, { useCallback, useState } from "react";
import styled from "styled-components";

import {
  ModalBody,
  ModalPortal,
  ModalPortalProps,
  ModalSubmitButtons,
  ModalThreeButtons,
} from "../../../components/Modal";

import { PostType } from "../../../hooks/api/post";

import { Heading2 } from "../../../components/Typo";
import { approvePost } from "../../../apiClients/post";
import { useToast } from "../../../components/Toast";
import { DangerText } from "../../../features/Post/CreatePostModal";
import { Margin } from "../../../components/Margin";
import { logger } from "src/utils/logger";

/**
 * 最終承認者の確認モーダル
 */
export const LastApprovalPostConfirmModal = ({
  disabled,
  onNowSubmit,
  onScheduledSubmit,
  onCancel,
}: {
  disabled: boolean;
  onNowSubmit: () => void;
  onScheduledSubmit: () => void;
  onCancel: () => void;
} & ModalPortalProps) => {
  return (
    <ModalPortal onClose={onCancel} modalBoxIsHeightFull={false}>
      <ModalRemandTop>
        <ModalHeader>連絡を承認する</ModalHeader>
        <ModalBody>
          <div>あなたは、最終承認者です。</div>
          <div>連絡を承認して投稿しますか？</div>
          <br />
          <div>すぐ投稿するでは、すぐ連絡を投稿できます。</div>
          <div>予約投稿するでは、連絡を投稿する日時を設定できます。</div>

          <Spacer />

          <ModalThreeButtons
            disabled={disabled}
            submitSecondText="予約投稿"
            submitPrimaryText="すぐ投稿"
            onSecondSubmit={onScheduledSubmit}
            onPrimarySubmit={onNowSubmit}
            onCancel={onCancel}
          />
        </ModalBody>
      </ModalRemandTop>
    </ModalPortal>
  );
};

/**
 * 最終承認時の予約時間設定モーダル
 */
export const LastApprovalPostScheduleModal = ({
  post,
  onClose,
  onPostApproved,
}: {
  post: PostType;
  onClose: () => void;
  onPostApproved: (post: PostType) => void;
} & ModalPortalProps) => {
  const [text, setText] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const toast = useToast();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);

  const onSubmit = async () => {
    if (errorMessage) {
      return;
    }
    setDoubleClickBlocked(true);
    try {
      await approvePost({
        postId: post.id,
        isLastApprover: true,
        scheduledSendAt: text ? new Date(text) : undefined,
      });
      onClose();
      onPostApproved(post);
      toast.error("予約投稿に成功しました");
    } catch (error) {
      logger.error(error);
      toast.error("予約投稿に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  const onChangeText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);
    if (date.getTime() < Date.now()) {
      setErrorMessage("過去の日時は設定できません");
    } else if (date.getTime() > Date.now() + 72 * 60 * 60 * 1000) {
      setErrorMessage("72時間後まで設定できます");
    } else {
      setText(e.target.value);
      setErrorMessage("");
    }
  }, []);

  return (
    <ModalPortal onClose={onClose} modalBoxIsHeightFull={true}>
      <ModalRemandTop>
        <ModalHeader>予約投稿を設定する</ModalHeader>
        <ModalBody>
          <div>投稿したい日時を設定してください。</div>
          <Margin marginBottom={10} />

          <DateTimePickerField
            type="datetime-local"
            id="send-time"
            name="send-time"
            onChange={onChangeText}
          />

          {errorMessage && <DangerText>{errorMessage}</DangerText>}
          <ModalSubmitButtons
            disabled={
              errorMessage.length > 0 || text.length === 0 || doubleClickBlocked
            }
            submitText="予約投稿"
            onSubmit={onSubmit}
            onCancel={onClose}
          />
        </ModalBody>
      </ModalRemandTop>
    </ModalPortal>
  );
};

// FIXME: do not use "export const" with styled-components
export const ModalTop = styled.div`
  height: 302px;
`;

const ModalRemandTop = styled.div`
  height: auto;
`;

const ModalHeader = styled(Heading2)`
  padding-bottom: 24px;
  display: flex;
`;

const Spacer = styled.div`
  height: 24px;
`;

// FIXME: do not use "export const" with styled-components
export const DateTimePickerField = styled.input`
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  padding-left: 40px;
  ::placeholder {
    color: #aab4c4;
  }
`;
