import React from "react";
import { colorsPallet } from "src/theme";
import styled from "styled-components";

type Props = {
  content: string;
  isMyMessage: boolean;
};

export const ParseMessage = ({ content, isMyMessage }: Props) => {
  const urlRegex = /(https?:\/\/[^\s/$.?#].[^\s]*)/g;
  return content.split(urlRegex).map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <Link
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          $isMyMessage={isMyMessage}
        >
          {part}
        </Link>
      );
    }
    return part;
  });
};

const Link = styled.a<{ $isMyMessage: boolean }>`
  color: ${({ $isMyMessage }) =>
    $isMyMessage ? colorsPallet.emptyShade : colorsPallet.primary};
  text-decoration: underline;
`;
