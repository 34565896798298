import { z } from "zod";
import { baseRequestSchema } from "./common.schema";
import { zRequiredString } from "../rules";

export const getRecruitmentTemplateByIdSchema = baseRequestSchema.extend({
  recruitmentTemplateId: zRequiredString,
});
export type GetRecruitmentTemplateByIdSchemaType = z.infer<
  typeof getRecruitmentTemplateByIdSchema
>;

export const deleteRecruitmentTemplateSchema = z.object({
  recruitmentTemplateId: zRequiredString,
});
export type DeleteRecruitmentTemplateSchemaType = z.infer<
  typeof deleteRecruitmentTemplateSchema
>;
