import { z } from "zod";
import { isHiragana, isValidPhoneNumber, isPastDate } from "../utils";

/**
 * 必須入力用の文字列スキーマ
 */
export const zRequiredString = z
  .string()
  .min(1, { message: "入力必須項目です" });

/**
 * ひらがな専用の文字列スキーマ
 */
export const zHiraganaString = zRequiredString.refine(isHiragana, {
  message: "ひらがなのみで入力してください",
});

/**
 * 有効な電話番号専用の文字列スキーマ
 */
export const zPhoneNumberString = zRequiredString.refine(isValidPhoneNumber, {
  message: "ハイフンは入力せず、半角文字のみで入力してください",
});

/**
 * 過去日付専用の文字列スキーマ
 */
export const zPastDateString = z.string().optional().refine(isPastDate, {
  message: "未来の日付は設定できません",
});

/**
 * 未来日付専用の文字列スキーマ
 * 1) 文字列 → new Date(...) でパース可能かチェック
 * 2) 過去でなければOK (== 未来 or 今)
 */
export const zFutureDateString = z.string().refine(
  (value) => {
    const date = new Date(value);

    if (Number.isNaN(date.getTime())) {
      return false;
    }
    return date.getTime() >= Date.now();
  },
  {
    message: "過去の日時は指定できません",
  }
);
