import { z } from "zod";

export enum enumTargetGradesType {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
  Sixth = 6,
  Seventh = 7,
  Eighth = 8,
  Ninth = 9,
  Tenth = 10,
  Eleventh = 11,
  Twelfth = 12,
}

export enum enumTargetClassesType {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
  Sixth = 6,
  Seventh = 7,
  Eighth = 8,
  Ninth = 9,
  Tenth = 10,
  Eleventh = 11,
  Twelfth = 12,
  Special = 100,
  Unknown = -1,
}

export const classNumberSchema = z.nativeEnum(enumTargetClassesType, {
  errorMap: () => {
    return { message: "クラスの値が無効な値です" };
  },
});
export type ClassNumberSchemaType = z.infer<typeof classNumberSchema>;

export const gradeNumberSchema = z.nativeEnum(enumTargetGradesType, {
  errorMap: () => {
    return { message: "学年の値が無効な値です" };
  },
});
export type GradeNumberSchemaType = z.infer<typeof gradeNumberSchema>;
