import { zRequiredString } from "../rules";
import {
  MultiChoiceQuestionSchema,
  OpenEndedQuestionSchema,
} from "./survey.schema";
import { z } from "zod";

export const fetchSurveyAnswersSchema = z.object({
  surveyQuestionsId: zRequiredString,
});

export type FetchSurveyAnswersSchemaType = z.infer<
  typeof fetchSurveyAnswersSchema
>;

export const MultiChoiceAnswerSchema = z.object({
  id: zRequiredString,
  question: MultiChoiceQuestionSchema,
  selectedChoices: z.array(zRequiredString),
});

export type MultiChoiceAnswerSchemaType = z.infer<
  typeof MultiChoiceAnswerSchema
>;

export const OpenEndedAnswerSchema = z.object({
  id: zRequiredString,
  question: OpenEndedQuestionSchema,
  text: zRequiredString,
});

export type OpenEndedAnswerSchemaType = z.infer<typeof OpenEndedAnswerSchema>;

export const AnswerSchema = z.union([
  MultiChoiceAnswerSchema,
  OpenEndedAnswerSchema,
]);
export type AnswerSchemaType = z.infer<typeof AnswerSchema>;

export const SurveyAnswersSchema = z.object({
  answerList: z.array(AnswerSchema),
  isAlreadyAnswered: z.boolean(),
});

export const sendSurveyAnswersSchema = z.object({
  surveyAnswers: SurveyAnswersSchema,
});

export type SendSurveyAnswersSchemaType = z.infer<
  typeof sendSurveyAnswersSchema
>;
