import React from "react";
import { useForm } from "react-hook-form";
import {
  EditModalFormLabel,
  ModalWrapper,
  TextField,
} from "../../../pages/pta/admin/ProfilePage";
import { Button } from "../../../components/Button";
import { Margin } from "../../../components/Margin";
import { ModalBody, ModalHeader, ModalPortal } from "../../../components/Modal";
import { useToast } from "../../../components/Toast";
import styled from "styled-components";
import { PTAParentFamily } from "@shared/types/ptaParentFamily";
import { udpatePTAParentFamily } from "src/apiClients/ptaParentFamily";
import { UpdatePTAParentFamilyRequestSchemaType } from "@shared/validator/features/ptaParentFamily.schema";

type Props = {
  onClose: () => void;
  ptaParentFamily: PTAParentFamily | null;
  fetch: () => void;
};
export const PTAParentFamilyInfoEditModal = ({
  onClose,
  ptaParentFamily,
  fetch,
}: Props) => {
  const toast = useToast();

  const { id, name } = ptaParentFamily || {};

  const { register, handleSubmit } =
    useForm<UpdatePTAParentFamilyRequestSchemaType>({
      defaultValues: {
        id,
        name,
      },
      mode: "onBlur",
    });

  const onSubmit = async (data: UpdatePTAParentFamilyRequestSchemaType) => {
    await udpatePTAParentFamily(data)
      .then(() => {
        toast.success("世帯情報を更新しました");
        fetch();
      })
      .catch(() => {
        toast.error("世帯情報の更新に失敗しました");
      });
    onClose();
  };
  return (
    <ModalPortal onClose={onClose}>
      <ModalWrapper>
        <ModalHeader>世帯情報</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <EditModalFormLabel>世帯名</EditModalFormLabel>
            <Flex>
              <FlexItem>
                <TextField placeholder="例）山田" {...register("name")} />
              </FlexItem>
            </Flex>
            <Margin marginTop={25} />
            <Flex>
              <Button
                type="button"
                color="primary"
                empty
                size="large"
                onClick={onClose}
              >
                キャンセル
              </Button>
              <Button type="submit" color="primary" fill size="large">
                保存
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalWrapper>
    </ModalPortal>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`;

const FlexItem = styled.div`
  flex: 1;
`;
