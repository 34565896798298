import React, { memo } from "react";
import { RegisterStepper } from "../RegisterStepper";

type StepMemberProps = {
  stepNum: number;
  maxNum: number;
};

export const StepMember = memo(({ stepNum, maxNum }: StepMemberProps) => {
  return (
    <RegisterStepper
      count={maxNum}
      current={stepNum}
      title="入力することでpolyfitをより快適に使っていただけます！"
    />
  );
});

export const MAX_STEP_NUMBER = {
  default: 4,
  subAccountForPTAParentFamily: 3,
};
