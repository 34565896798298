import React from "react";
import styled from "styled-components";
import { Margin } from "src/components/Margin";

type Props = {
  selectedBelongIds: string[];
  setSelectedBelongIds: (belongIds: string[]) => void;
  belongOptions: { id: string; name: string; order: number | null }[];
};
export const CustomBelong = ({
  selectedBelongIds,
  setSelectedBelongIds,
  belongOptions,
}: Props) => {
  return (
    <>
      {belongOptions.length === 0 ? (
        <div>所属が存在しません。</div>
      ) : (
        <>
          <SubText>※未選択であれば全てが対象になります。</SubText>
          <Margin marginTop={12} />

          <CustomSelectWrapper>
            {/* TODO: 検索バーの作成 */}
            {belongOptions.map((belong) => (
              <div key={belong.id}>
                <label>
                  <CheckBox
                    type="checkbox"
                    value={belong.id}
                    checked={selectedBelongIds?.includes(belong.id)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const currentBelongIds = selectedBelongIds;
                      if (checked) {
                        setSelectedBelongIds([...currentBelongIds, belong.id]);
                      } else {
                        setSelectedBelongIds(
                          currentBelongIds.filter((id) => id !== belong.id)
                        );
                      }
                    }}
                  />
                  {belong.name}
                </label>
              </div>
            ))}
          </CustomSelectWrapper>
        </>
      )}
    </>
  );
};

const CustomSelectWrapper = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CheckBox = styled.input`
  font-family: "Avenir-Roman";
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border: 1px solid #c9cbcd;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  transition: all 100ms;
  &:checked {
    background: #0077cc;
    border: 1px solid #0077cc;
  }
`;

const SubText = styled.p`
  font-size: 12px;
  color: #666;
`;
