import * as client from "./client";
import { GetPTAParentFamilyInvoiceListByProductIdType } from "@shared/validator/features/ptaParentFamilyInvoice.schema";
import { PTAParentFamilyInvoiceListType } from "@shared/types/ptaParentFamilyInvoice";

export async function getPTAParentFamilyInvoiceList(
  args: GetPTAParentFamilyInvoiceListByProductIdType
): Promise<PTAParentFamilyInvoiceListType[]> {
  return await client.get<
    GetPTAParentFamilyInvoiceListByProductIdType,
    PTAParentFamilyInvoiceListType[]
  >("/invoice/ptaParentFamilyInvoice/list", args);
}
