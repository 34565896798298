import React, { JSX } from "react";
import { MultiChoiceAnswerSchemaType as MultiChoiceAnswer } from "@shared/validator/features/surveyAnswers.schema";
import { EventHandlers } from "./hooks";
import { AnswerContainer } from "./AnswerField";
import { Label } from "../../../components/form/Label";
import { CheckBox } from "../../../components/form/CheckBox";
import { RadioButton } from "../../../components/form/RadioButton";
import { Choice } from "@shared/validator/features/survey.schema";

type Props = {
  answer: MultiChoiceAnswer;
  eventHandlers: EventHandlers;
};

export function MultiChoiceAnswerField({
  answer,
  eventHandlers,
}: Props): JSX.Element {
  // 唯一複数回答によって見た目とイベントを変える
  const allowMultipleAnswers: boolean = answer.question.allowMultipleAnswers;
  const onEdit: (answer: MultiChoiceAnswer, choice: Choice) => void =
    allowMultipleAnswers
      ? eventHandlers.onEditCheckBox
      : eventHandlers.onEditRadio;
  return (
    <AnswerContainer>
      <Label marginBottom={0}>{answer.question.text}</Label>
      <Label size="s" marginBottom={8}>
        <span style={{ fontWeight: 400 }}>{answer.question.description}</span>
      </Label>
      {answer.question.choiceList.map((choice: Choice) => {
        const isChecked: boolean = answer.selectedChoices.includes(choice.id);
        return allowMultipleAnswers ? (
          <CheckBox
            key={choice.id}
            checked={isChecked}
            onChange={() => {
              onEdit(answer, choice);
            }}
            label={choice.text}
          />
        ) : (
          <RadioButton
            key={choice.id}
            checked={isChecked}
            onChange={() => {
              onEdit(answer, choice);
            }}
            label={choice.text}
          />
        );
      })}
    </AnswerContainer>
  );
}
