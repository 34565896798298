import { useEffect, useMemo, useState } from "react";
import { PTAParentFamily } from "@shared/types/ptaParentFamily";
import { getPTAParentFamily } from "src/apiClients/ptaParentFamily";
import { getOrGenerateInvitationTokenForPTAParentFamily } from "src/apiClients/invitation";
import { useQuery } from "@tanstack/react-query";
import { useApiContext } from "src/apiClients/client";

export const usePTAParentFamily = (inPTA: boolean) => {
  const [ptaParentFamily, setPTAParentFamily] =
    useState<PTAParentFamily | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPTAParentFamily = async () => {
    if (!inPTA) return;
    setIsLoading(true);
    const ptaParentFamily = await getPTAParentFamily();
    setPTAParentFamily(ptaParentFamily);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPTAParentFamily();
  }, [inPTA]);

  return {
    ptaParentFamily,
    isLoading,
    fetchPTAParentFamily,
  };
};

export const usePTAParentFamilyInvitationToken = () => {
  const apiContext = useApiContext();

  const query = useQuery({
    queryKey: ["api", "invitation", "ptaParentFamily", apiContext],
    queryFn: async () => {
      return await await getOrGenerateInvitationTokenForPTAParentFamily({});
    },
  });

  return {
    token: useMemo(() => query.data || "", [query.data]),
    ...query,
  };
};
