import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { zodResolver } from "@hookform/resolvers/zod";
import { ModalBackGround } from "src/components/Common/Menu";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import { useToast } from "src/components/Toast";
import { Label } from "src/components/form/Label";
import { logger } from "src/utils/logger";
import {
  createPTAInvoiceProductSchema,
  CreatePTAInvoiceProductType,
} from "@shared/validator/features/ptaInvoiceProduct.schema";
import { useCreatePTAInvoiceProduct } from "src/hooks/query/invoice/ptaInvoiceProduct";
import { usePolyfitHistory } from "src/hooks/router";
import { toZonedTime } from "date-fns-tz";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const PTAInvoiceProductNewModal = ({ isOpen, onClose }: Props) => {
  if (!isOpen) return null;

  const toast = useToast();
  const history = usePolyfitHistory();
  const createMutation = useCreatePTAInvoiceProduct();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CreatePTAInvoiceProductType>({
    resolver: zodResolver(createPTAInvoiceProductSchema),
    mode: "onChange",
  });

  const onSubmit = async (data: CreatePTAInvoiceProductType) => {
    try {
      const product = await createMutation.mutateAsync(data);
      history.push({
        to: "PTA_INVOICE_PROCESS",
        query: { id: product.id, step: "1" },
      });
      onClose();
    } catch (err) {
      logger.error(err, { data });
      toast.error("エラーが発生しました");
    }
  };

  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>新しい請求項目を追加</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Label required>請求項目名</Label>
            <TextField placeholder="例) 2025年度会費" {...register("name")} />
            {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}

            <Label required>支払期日</Label>
            <DateTimePickerField
              type="datetime-local"
              {...register("dueDate", {
                setValueAs: (value) => {
                  if (!value) return undefined;
                  return toZonedTime(
                    new Date(value),
                    "Asia/Tokyo"
                  ).toISOString();
                },
              })}
            />
            {errors.dueDate && (
              <ErrorMessage>{errors.dueDate.message}</ErrorMessage>
            )}

            <ModalSubmitButtons
              onCancel={onClose}
              type="submit"
              disabled={!isValid || isSubmitting}
              submitText={isSubmitting ? "送信中..." : "保存する"}
            />
          </form>
        </ModalBody>
      </ModalPortal>
      <ModalBackGround isOpen={isOpen} />
    </>
  );
};

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
  margin-bottom: 8px;
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  color: #bd271e;
  margin-bottom: 8px;
`;

const DateTimePickerField = styled.input`
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  width: 100%;
  margin-bottom: 8px;
`;
