import { z } from "zod";
import { zRequiredString } from "../rules";
import { baseRequestSchema } from "./common.schema";

export const getCommunityIconSchema = baseRequestSchema.extend({
  communityId: zRequiredString,
  organizationId: zRequiredString,
});

export type GetCommunityIconSchemaType = z.infer<typeof getCommunityIconSchema>;
