import { z } from "zod";
import { baseRequestSchema } from "./common.schema";
import { zRequiredString } from "../rules/string";

import {
  CreatedSurveyQuestionsSchema,
  DraftedSurveyQuestionsSchema,
} from "./survey.schema";
import { classNumberSchema, gradeNumberSchema } from "../rules/child";

export const CreatePostStatus = {
  UNAPPROVED: "UNAPPROVED",
  DRAFT: "DRAFT",
} as const;
export type CreatePostStatus =
  (typeof CreatePostStatus)[keyof typeof CreatePostStatus];

export const PostStatus = {
  ...CreatePostStatus,
  REMANDED: "REMANDED",
  APPROVED: "APPROVED",
} as const;

export type PostStatus = (typeof PostStatus)[keyof typeof PostStatus];

// 作成用のPost / 更新用のPostの共通部分のSchema
export const commonPostSchema = z.object({
  organizationId: zRequiredString,
  userId: zRequiredString,
  title: zRequiredString,
  text: z.string(),
  fileName: z.string(),
  status: z.nativeEnum(PostStatus),
  surveyQuestions: z
    .union([DraftedSurveyQuestionsSchema, CreatedSurveyQuestionsSchema])
    .optional(), //アンケート機能実装前への対応
  targetAllTag: z.boolean().default(false),
  targetGradeTags: z.array(gradeNumberSchema),
  targetGradeAndClasses: z.array(
    z.object({
      grade: gradeNumberSchema,
      classes: z.array(classNumberSchema),
    })
  ),
  targetBelongIdTags: z.array(zRequiredString),
  targetPostUserGroupIdTags: z.array(zRequiredString),
});

export const listPostRequestSchema = z.object({
  limit: z.number(),
  skip: z.number(),
});

export type ListPostRequestSchemaType = z.infer<typeof listPostRequestSchema>;

export const getPostWithTargetsRequestSchema = baseRequestSchema.extend({
  postId: zRequiredString,
});

export type GetPostWithTargetsRequestSchemaType = z.infer<
  typeof getPostWithTargetsRequestSchema
>;

export const getSurveyPostTargetUsersRequestSchema = baseRequestSchema.extend({
  surveyQuestionsId: zRequiredString,
});

export type GetSurveyPostTargetUsersRequestSchemaType = z.infer<
  typeof getSurveyPostTargetUsersRequestSchema
>;

export const createPostRequestSchema = commonPostSchema.extend({
  status: z.nativeEnum(CreatePostStatus),
  file: z.string(),
  surveyQuestions: z.union([
    DraftedSurveyQuestionsSchema,
    CreatedSurveyQuestionsSchema,
  ]),
});

export type CreatePostRequestSchemaType = z.infer<
  typeof createPostRequestSchema
>;

export const reapplyPostRequestSchema = commonPostSchema.extend({
  id: zRequiredString,
  pdfFilePath: z.string(),
});

export type ReapplyPostRequestSchemaType = z.infer<
  typeof reapplyPostRequestSchema
>;

export const deletePostSchema = z.object({
  postId: zRequiredString,
});

export type DeletePostSchemaType = z.infer<typeof deletePostSchema>;

export const remandPostSchema = z.object({
  postId: zRequiredString,
  comment: z.string(),
});

export type RemandPostSchemaType = z.infer<typeof remandPostSchema>;

export const approvePostSchema = z.object({
  postId: zRequiredString,
  isLastApprover: z.boolean(),
  scheduledSendAt: z.date().optional(),
});

export type ApprovePostSchemaType = z.infer<typeof approvePostSchema>;

export const approvePostSchemaServer = approvePostSchema.extend({
  scheduledSendAt: z.string().datetime().optional(),
});

export type ApprovePostSchemaServerType = z.infer<
  typeof approvePostSchemaServer
>;

export const resendPostSchema = z.object({
  postId: zRequiredString,
  userIds: z.array(zRequiredString),
});

export type ResendPostSchemaType = z.infer<typeof resendPostSchema>;

export const resendSurveyPostSchema = z.object({
  postId: zRequiredString,
  userIds: z.array(zRequiredString),
});

export type ResendSurveyPostSchemaType = z.infer<typeof resendSurveyPostSchema>;

export const cancelScheduledPostSchema = z.object({
  postId: zRequiredString,
});

export type CancelScheduledPostSchemaType = z.infer<
  typeof cancelScheduledPostSchema
>;
