import { z } from "zod";
import { zHiraganaString, zRequiredString } from "../../validator/rules/string";
import { enumTargetClassesType } from "../../validator/rules/child";

// 更新
export const csChildUpdateQuerySchema = z.array(
  z.object({
    id: z.string().optional(),
    accountId: zRequiredString,
    childLastName: zRequiredString,
    childFirstName: zRequiredString,
    childLastNameKana: zHiraganaString,
    childFirstNameKana: zHiraganaString,
    communityId: z.string().nullable(),
    grade: z.union([z.number().min(1).max(12), z.null()]).optional(),
    class: z.nativeEnum(enumTargetClassesType).nullable().optional(),
  })
);

export type CsChildUpdateQueryType = z.infer<typeof csChildUpdateQuerySchema>;

export const getChildrenByAccountIdSchema = z.object({
  accountId: zRequiredString,
});

export type GetChildrenByAccountIdSchemaType = z.infer<
  typeof getChildrenByAccountIdSchema
>;

export const deleteChildrenSchema = z.object({
  accountId: zRequiredString,
});

export type DeleteChildrenSchemaType = z.infer<typeof deleteChildrenSchema>;
