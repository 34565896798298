import * as client from "./client";
import { ServiceType } from "../utils/types/serviceType";
import { PartSchemaType as Part } from "@shared/validator/models/part.schema";
import { Community } from "@shared/types/community";
import {
  CreateBeOrganizationResponse,
  InternalOrganization,
  OrganizationWithSignatureTemplates,
  SchoolDisplayType,
  SchoolType,
} from "@shared/types/organization";
import { BelongSchemaType as Belong } from "@shared/validator/models/belong.schema";
import {
  createOrganizationSchema,
  CreateOrganizationSchemaType,
  createBEOrganizationSchema,
  CreateBEOrganizationSchemaType,
  updateOrganizationSchema,
  UpdateOrganizationSchemaType,
  getOrganizationSchema,
  GetOrganizationSchemaType,
  listByCommunityIdSchema,
  ListByCommunityIdSchemaType,
} from "@shared/validator/features/organization.schema";

export type Organization = {
  id: string;
  name: string;
  serviceType: ServiceType;
  nameListPublished: boolean;
  requireAdminToUpdateCareer: boolean;
  postSchoolYearsFilter: string[];
  showAllPostToTeacher: boolean;
  schoolType: SchoolType;
  schoolDisplayType: SchoolDisplayType;
  lineOption?: boolean;
  belongs: Belong[];
  parts: Part[];
  communities: Community[];
  managingBoardEducation?: any;
  createdAt: string;
  updatedAt: string;
};

export async function createOrganization(
  args: CreateOrganizationSchemaType
): Promise<Organization> {
  createOrganizationSchema.parse(args);
  const res = await client.post<CreateOrganizationSchemaType, Organization>(
    "/organization/create",
    args
  );
  return res;
}

export async function addCommunity(
  args: UpdateOrganizationSchemaType
): Promise<Organization> {
  updateOrganizationSchema.parse(args);
  const res = await client.post<UpdateOrganizationSchemaType, Organization>(
    "/organization/addCommunity",
    args
  );
  return res;
}

export async function updateOrganization(
  args: UpdateOrganizationSchemaType
): Promise<Organization> {
  updateOrganizationSchema.parse(args);
  const res = await client.post<UpdateOrganizationSchemaType, Organization>(
    "/organization/update",
    args
  );
  return res;
}

export async function currentOrganization(): Promise<OrganizationWithSignatureTemplates> {
  const res = await client.get<{}, OrganizationWithSignatureTemplates>(
    "/organization/currentOrganization",
    {}
  );
  return res;
}

export async function getOrganizations(): Promise<InternalOrganization[]> {
  const res = await client.get<{}, InternalOrganization[]>(
    "/organization/list",
    {}
  );
  return res;
}

export async function getOrganizationById(
  organizationId: string
): Promise<Organization> {
  const args = { organizationId };
  getOrganizationSchema.parse(args);
  const res = await client.get<GetOrganizationSchemaType, Organization>(
    "/organization/get",
    args
  );
  return res;
}

export async function getOrganizationByCommunityId(
  communityId: string
): Promise<Organization[]> {
  const args = { communityId };
  listByCommunityIdSchema.parse(args);
  const res = await client.get<ListByCommunityIdSchemaType, Organization[]>(
    "/organization/listByCommunityId",
    args
  );
  return res;
}

export async function createBEOrganization(
  args: CreateBEOrganizationSchemaType
): Promise<CreateBeOrganizationResponse> {
  createBEOrganizationSchema.parse(args);
  const res = await client.post<
    CreateBEOrganizationSchemaType,
    CreateBeOrganizationResponse
  >("/organization/create/boardEducation", args);
  return res;
}
