import {
  LineCustomAggregationUnit,
  LineInsightWithAmountOfLineMessages,
} from "@shared/types/lineCustomAggregation";
import {
  getOrCreateCustomAggregationUnitSchema,
  GetOrCreateCustomAggregationUnitSchemaType,
  getLineInsightWithAmountOfLineMessagesSchema,
  GetLineInsightWithAmountOfLineMessagesSchemaType,
} from "../@shared/validator/features/lineCustomAggregation.schema";
import { get, post } from "./client";

export async function getOrCreateCustomAggregationUnit(
  args: GetOrCreateCustomAggregationUnitSchemaType
): Promise<LineCustomAggregationUnit | undefined> {
  getOrCreateCustomAggregationUnitSchema.parse(args);
  return await get<
    GetOrCreateCustomAggregationUnitSchemaType,
    LineCustomAggregationUnit | undefined
  >("/lineCustomAggregation/getOrCreateCustomAggregationUnit", args);
}

export async function getLineInsightWithAmountOfLineMessages(
  args: GetLineInsightWithAmountOfLineMessagesSchemaType
): Promise<LineInsightWithAmountOfLineMessages | undefined> {
  getLineInsightWithAmountOfLineMessagesSchema.parse(args);
  return await post<
    GetLineInsightWithAmountOfLineMessagesSchemaType,
    LineInsightWithAmountOfLineMessages | undefined
  >("/lineCustomAggregation/getLineInsightWithAmountOfLineMessages", args);
}
