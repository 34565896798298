import React from "react";
import styled from "styled-components";
import { Spinner } from "src/components/icons/Spinner";
import { usePolyfitHistory } from "src/hooks/router";
import { PTAParentFamilyInvoiceListType } from "@shared/types/ptaParentFamilyInvoice";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getClassName } from "src/utils/getChildClass";
import { Link } from "src/components/Link";

type Props = {
  isLoading: boolean;
  invoiceProductId: string;
  ptaParentFamilyInvoices: PTAParentFamilyInvoiceListType[];
};

export const InvoiceStatus = {
  PENDING: "未払い",
  PAID: "支払い済み",
  CANCELED: "キャンセル",
} as const;

export const PTAInvoiceUserListContent: React.FC<Props> = ({
  isLoading,
  invoiceProductId,
  ptaParentFamilyInvoices,
}) => {
  const history = usePolyfitHistory();
  const { organization } = useGetCurrentOrganization();

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  }

  if (ptaParentFamilyInvoices.length === 0) {
    history.push({
      to: "PTA_INVOICE_PROCESS",
      query: { id: invoiceProductId, step: "1" },
    });
    return;
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          <Th>子の名前</Th>
          <Th>学年</Th>
          <Th>クラス</Th>
          <Th>世帯の請求金額</Th>
          <Th>子の請求金額</Th>
          <Th>状況</Th>
          <Th>操作</Th>
        </tr>
      </thead>
      <tbody>
        {ptaParentFamilyInvoices.map((invoice) =>
          invoice.PTAChildInvoiceItems.map((childInvoiceItem) => (
            <Tr key={childInvoiceItem.id}>
              <Td>
                <LinkText
                  to={{
                    to: "PTA_LIST_MEMBER",
                    query: { userId: childInvoiceItem.child.userId ?? "" },
                  }}
                  $place="start"
                >{`${childInvoiceItem.child.childLastName} ${childInvoiceItem.child.childFirstName}`}</LinkText>
              </Td>
              <Td>
                {getGradeLabel(
                  childInvoiceItem.child.grade,
                  organization?.schoolDisplayType,
                  true,
                  false,
                  "short"
                )}
              </Td>
              <Td>{getClassName(childInvoiceItem.child.class)}</Td>
              <Td>{invoice.unitAmount}</Td>
              <Td>{childInvoiceItem.amount}</Td>
              <Td>{InvoiceStatus[invoice.status]}</Td>
              <Td>TODO</Td>
            </Tr>
          ))
        )}
      </tbody>
    </StyledTable>
  );
};

const LoadingWrapper = styled.div`
  padding: 24px;
  text-align: center;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #f7f8fa;
  }
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const Td = styled.td`
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const LinkText = styled(Link)<{ $place: "start" | "center" | "end" }>`
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: ${(props) => props.$place};
  color: #07c;
`;
