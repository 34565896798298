import React from "react";
import styled from "styled-components";
import { Margin } from "src/components/Margin";

type Props = {
  selectedUserGroupIds: string[];
  setSelectedUserGroupIds: (userGroupIds: string[]) => void;
  userGroupOptions: { id: string; name: string }[];
};
export const CustomUserGroup = ({
  selectedUserGroupIds,
  setSelectedUserGroupIds,
  userGroupOptions,
}: Props) => {
  return (
    <>
      {userGroupOptions.length === 0 ? (
        <div>連絡グループが存在しません。</div>
      ) : (
        <>
          <SubText>※未選択であれば全てが対象になります。</SubText>
          <Margin marginTop={12} />

          <CustomSelectWrapper>
            {userGroupOptions.map((userGroup) => (
              <div key={userGroup.id}>
                <label>
                  <CheckBox
                    type="checkbox"
                    value={userGroup.id}
                    checked={selectedUserGroupIds?.includes(userGroup.id)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const currentUserGroupIds = selectedUserGroupIds;
                      if (checked) {
                        setSelectedUserGroupIds([
                          ...currentUserGroupIds,
                          userGroup.id,
                        ]);
                      } else {
                        setSelectedUserGroupIds(
                          currentUserGroupIds.filter(
                            (id) => id !== userGroup.id
                          )
                        );
                      }
                    }}
                  />
                  {userGroup.name}
                </label>
              </div>
            ))}
          </CustomSelectWrapper>
        </>
      )}
    </>
  );
};

const CustomSelectWrapper = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CheckBox = styled.input`
  font-family: "Avenir-Roman";
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border: 1px solid #c9cbcd;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  transition: all 100ms;
  &:checked {
    background: #0077cc;
    border: 1px solid #0077cc;
  }
`;

const SubText = styled.p`
  font-size: 12px;
  color: #666;
`;
