import { z } from "zod";
import { zRequiredString } from "../rules";

export const createRecruitmentApplicationCommentSchema = z.object({
  recruitmentApplicationId: zRequiredString,
  comment: zRequiredString,
  isAlert: z.boolean(),
});
export type CreateRecruitmentApplicationCommentSchemaType = z.infer<
  typeof createRecruitmentApplicationCommentSchema
>;

export const deleteRecruitmentApplicationCommentSchema = z.object({
  recruitmentApplicationCommentId: zRequiredString,
});
export type DeleteRecruitmentApplicationCommentSchemaType = z.infer<
  typeof deleteRecruitmentApplicationCommentSchema
>;

export const updateRecruitmentApplicationCommentSchema = z.object({
  id: zRequiredString,
  comment: zRequiredString,
  isAlert: z.boolean(),
});
export type UpdateRecruitmentApplicationCommentSchemaType = z.infer<
  typeof updateRecruitmentApplicationCommentSchema
>;
