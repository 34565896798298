import * as client from "./client";
import {
  getCommunityIconSchema,
  GetCommunityIconSchemaType,
} from "@shared/validator/features/communityIcon.schema";
import { CommunityIcon } from "@shared/types/communityIcon";

export async function createOrUpdateCommunityIcon({
  icon,
}: {
  icon: File;
}): Promise<void> {
  await client.postWithFormData<{ icon: File }>(
    "/communityIcon/createOrUpdate",
    { icon }
  );
}

export async function getCommunityIcon(
  args: GetCommunityIconSchemaType
): Promise<CommunityIcon | undefined> {
  getCommunityIconSchema.parse(args);
  const res = await client.get<
    GetCommunityIconSchemaType,
    { communityIcon: CommunityIcon | undefined }
  >("/communityIcon/get", args);
  return res.communityIcon;
}
