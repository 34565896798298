import { z } from "zod";
import { zRequiredString } from "../rules";
import { baseRequestSchema } from "./common.schema";

export const getUnverifiedUserByEmailSchema = baseRequestSchema.extend({
  email: zRequiredString,
});

export type GetUnverifiedUserByEmailSchemaType = z.infer<
  typeof getUnverifiedUserByEmailSchema
>;

export const deleteUserFromBounceListSchema = z.object({
  userId: zRequiredString,
});

export type DeleteUserFromBounceListSchemaType = z.infer<
  typeof deleteUserFromBounceListSchema
>;

export const deleteUnverifiedUserSchema = z.object({
  userId: zRequiredString,
});

export type DeleteUnverifiedUserSchemaType = z.infer<
  typeof deleteUnverifiedUserSchema
>;
