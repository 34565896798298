import { z } from "zod";
import { zRequiredString } from "../rules/string";
import { Role } from "../../types/role";

export const accountCommunityTypeSchema = z.enum([
  "PARENT",
  "TEACHER",
  "RESIDENT",
]);

export const accountCommunityRolesRequestSchema = z.object({
  roleName: z.string().optional(),
  communityId: z.string(),
  isMain: z.boolean(),
  type: accountCommunityTypeSchema,
});

export type AccountCommunityRolesRequestSchemaType = z.infer<
  typeof accountCommunityRolesRequestSchema
>;

export const accountCommunityRoleSchema = z.object({
  accountId: zRequiredString,
  roleId: zRequiredString,
  role: z.object({
    id: zRequiredString,
    name: z.nativeEnum(Role),
  }),
  communityId: zRequiredString,
  isMain: z.boolean(),
  type: accountCommunityTypeSchema,
});

export type AccountCommunityRole = z.infer<typeof accountCommunityRoleSchema>;
