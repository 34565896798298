import { z } from "zod";
import { zRequiredString } from "../rules/string";

export const createPartSchema = z.object({
  organizationId: zRequiredString,
  name: zRequiredString,
  order: z.number().nullable(),
});

export type CreatePartSchemaType = z.infer<typeof createPartSchema>;

export const updatePartSchema = z.object({
  partId: zRequiredString,
  name: zRequiredString,
  order: z.number().nullable(),
});

export type UpdatePartSchemaType = z.infer<typeof updatePartSchema>;

export const deletePartSchema = z.object({
  partId: zRequiredString,
});

export type DeletePartSchemaType = z.infer<typeof deletePartSchema>;
