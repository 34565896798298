import { z } from "zod";
import { zRequiredString } from "../rules";

// GET /getByOrganizationId のクエリパラメータのスキーマ
export const getBoardEducationByOrganizationIdSchema = z.object({
  organizationId: zRequiredString,
});

export type GetBoardEducationByOrganizationIdSchemaType = z.infer<
  typeof getBoardEducationByOrganizationIdSchema
>;

// POST /updateBoardEducation のボディのスキーマ
export const updateBoardEducationSchema = z.object({
  id: zRequiredString,
  postalCode: zRequiredString,
  prefecture: zRequiredString,
  city: zRequiredString,
  address1: zRequiredString,
  address2: z.string().nullable(),
  latitude: z.number(),
  longitude: z.number(),
});

export type UpdateBoardEducationSchemaType = z.infer<
  typeof updateBoardEducationSchema
>;
