import { ResidentFilterType } from "@shared/validator/features/user.schema";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const residentFilterState = atom<ResidentFilterType>({
  key: "ResidentFilter",
  default: {
    communityUserType: [],
    preferredPaid: [],
    preferredDays: [],
    licenses: [],
    preferredVolunteerTypes: [],
    name: "",
    city: "",
    withinWalkMinute: undefined,
  },
  effects_UNSTABLE: [persistAtom],
});

export const useResidentFilter = () => {
  const [residentFilter, setResidentFilter] =
    useRecoilState(residentFilterState);

  return [residentFilter, setResidentFilter] as const;
};
