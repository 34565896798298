import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useApiContext } from "src/apiClients/client";
import { useMemo } from "react";
import { getApprovers, updateApprovers } from "src/apiClients/users";

export function useGetApprovers(queryOptions: QueryOptions = {}) {
  const { apiContext } = useApiContext();
  const query = useQuery({
    queryKey: ["api", "users", "getApprovers", apiContext],
    queryFn: async () => {
      return await getApprovers();
    },
    ...queryOptions,
  });
  return {
    approvers: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}

export const useUpdateApprovers = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateApprovers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["api", "users", "getApprovers"],
      });
    },
  });
};
