import { z } from "zod";
import { zRequiredString } from "../../rules/string";

export const ptaImportCsvInvoiceTargetSchema = z.array(
  z.object({
    familyId: zRequiredString,
    childId: zRequiredString,
    amount: z.number().int().min(0),
  })
);

export type PTAImportCsvInvoiceTargetType = z.infer<
  typeof ptaImportCsvInvoiceTargetSchema
>;

export const ptaImportCsvInvoiceTargetSchemaForServer = z.object({
  args: z.string(),
});

export type PTAImportCsvInvoiceTargetSchemaForServerType = z.infer<
  typeof ptaImportCsvInvoiceTargetSchemaForServer
>;
