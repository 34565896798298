import { useCallback } from "react";
import * as partApi from "../../apiClients/part";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";

export function usePartById(organizationId: string) {
  const toast = useToast();
  const createPart = useCallback(
    async (name: string, order: number | null) => {
      try {
        const part = await partApi.createPart({
          organizationId,
          name,
          order,
        });
        return part;
      } catch (err) {
        logger.error(err);
        toast.error("役職の作成に失敗しました");
      }
    },
    [organizationId]
  );

  const updatePart = useCallback(
    async (partId: string, name: string, order: number | null) => {
      try {
        const part = await partApi.updatePart({
          partId,
          name,
          order,
        });
        return part;
      } catch (err) {
        logger.error(err);
        toast.error("役職の更新に失敗しました");
      }
    },
    []
  );

  const deletePart = useCallback(async (partId: string) => {
    try {
      const part = await partApi.deletePart({ partId });
      return part;
    } catch (err) {
      logger.error(err);
      toast.error("役職の削除に失敗しました");
    }
  }, []);

  return { createPart, updatePart, deletePart };
}
