import React, { useCallback, useState } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { Margin } from "../../../components/Margin";
import styled from "styled-components";
import { Avatar } from "../../../components/Common/Avatar";
import { resendSurveyPost } from "../../../hooks/api/post";
import { useToast } from "../../../components/Toast";
import { colorsPallet } from "src/theme";
import { usePolyfitHistory } from "src/hooks/router";
import { SurveyPostTargetUserResponse } from "@shared/types/post/getSurveyPostTargetUsers";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { isPtaAdminRole } from "src/utils/types/role";
import { useCurrentUser } from "src/hooks/recoil/user";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  postId: string;
  unAnsweredUsers: SurveyPostTargetUserResponse[];
  isExpired: boolean;
};

export const ResendModal = ({
  isOpen,
  onClose,
  postId,
  unAnsweredUsers,
  isExpired,
}: Props) => {
  if (!isOpen) return <></>;
  const [selectedUsers, setSelectedUsers] = useState<
    SurveyPostTargetUserResponse[]
  >([]);
  const toast = useToast();

  const history = usePolyfitHistory();
  const onUserDetailClick = useCallback(
    (userId: string) => {
      history.push({
        to: "PTA_LIST_MEMBER",
        query: { userId },
      });
    },
    [history]
  );
  const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");
  const currentUser = useCurrentUser();
  const onSubmit = async () => {
    await resendSurveyPost(
      postId,
      selectedUsers.map((u) => u.id)
    );
    toast.success("再送しました");
    onClose();
  };
  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>未回答の方</ModalHeader>
      <ModalBody>
        <SubTitle>
          <p>弊社のシステムで検知したアンケートを未回答の方です。</p>
          {isExpired && (
            <p style={{ color: colorsPallet.danger }}>
              回答期限切れのため再送できません。
            </p>
          )}
        </SubTitle>
        <Ul>
          <LiHeader>
            {!isExpired && (
              <>
                <input
                  type="checkbox"
                  checked={selectedUsers.length === unAnsweredUsers.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedUsers(unAnsweredUsers);
                    } else {
                      setSelectedUsers([]);
                    }
                  }}
                />
                <Margin marginLeft={16} />
              </>
            )}
            氏名
          </LiHeader>
          {unAnsweredUsers.map((user) => (
            <Li key={user.id}>
              <Label>
                {!isExpired && (
                  <>
                    <input
                      type="checkbox"
                      checked={selectedUsers.some((u) => u.id === user.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedUsers([...selectedUsers, user]);
                        } else {
                          setSelectedUsers(
                            selectedUsers.filter((u) => u.id !== user.id)
                          );
                        }
                      }}
                    />
                    <Margin marginLeft={16} />
                  </>
                )}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={user.picture || defaultUserImg}
                    size={40}
                    alt={user.name}
                  />
                  <Margin marginLeft={8} />
                  <span style={{ marginLeft: "5px" }}>{user.name}</span>
                </div>
              </Label>
              {isPtaAdminRole(currentUser.role) && (
                <Link onClick={() => onUserDetailClick(user.id)}>
                  <LinkText>詳細</LinkText>
                </Link>
              )}
            </Li>
          ))}
        </Ul>
      </ModalBody>
      <ModalSubmitButtons
        submitText="再送"
        disabled={selectedUsers.length === 0 || isExpired}
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    </ModalPortal>
  );
};

const SubTitle = styled.div`
  margin-bottom: 16px;
`;

const Ul = styled.ul`
  list-style: none;
  padding-bottom: 8px;
`;

const LiHeader = styled.li`
  display: flex;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #e3e6eb;
  border-bottom: 1px solid #e3e6eb;
`;

const Li = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #e3e6eb;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Link = styled.a`
  font-size: 16px;
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: end;
  color: #07c;
`;

const LinkText = styled.span`
  color: #005ec4;
  cursor: pointer;
`;
