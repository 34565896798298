import * as client from "./client";
import {
  CreateRecruitmentApplicationCommentSchemaType,
  DeleteRecruitmentApplicationCommentSchemaType,
  UpdateRecruitmentApplicationCommentSchemaType,
} from "../../../server/src/@shared/validator/features/recruitmentApplicationComment.schema";

/**
 * 応募に対してのコメントAPIクライアント
 */

export async function createRecruitmentApplicationComment(
  arg: CreateRecruitmentApplicationCommentSchemaType
): Promise<void> {
  await client.post<CreateRecruitmentApplicationCommentSchemaType>(
    "/recruitment-comment/create",
    arg
  );
}

export async function deleteRecruitmentApplicationComment(
  recruitmentApplicationCommentId: string
): Promise<void> {
  await client.post<DeleteRecruitmentApplicationCommentSchemaType>(
    "/recruitment-comment/delete",
    { recruitmentApplicationCommentId }
  );
}

export type RecruitmentApplicationComment = {
  id: string;
  organizationId: string;
  recruitmentApplicationId: string;
  userId: string;
  comment: string;
  isAlert: boolean;
  user: {
    select: {
      id: string;
      name: string;
      picture: string;
    };
  }[];
};

export async function editRecruitmentApplicationComment(
  args: UpdateRecruitmentApplicationCommentSchemaType
): Promise<void> {
  await client.post<UpdateRecruitmentApplicationCommentSchemaType>(
    "/recruitment-comment/edit",
    args
  );
}
