import { z } from "zod";
import { zRequiredString } from "../rules/string";

export const partSchema = z.object({
  id: zRequiredString,
  organizationId: zRequiredString,
  name: zRequiredString,
  order: z.number().nullable(),
});

export type PartSchemaType = z.infer<typeof partSchema>;
