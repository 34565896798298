import React, { useCallback } from "react";
import { Button } from "../../../components/Button";
import { ModalPortal } from "../../../components/Modal";
import { useToast } from "../../../components/Toast";
import styled from "styled-components";
import { usePTAParentFamilyInvitationToken } from "src/hooks/query/ptaParentFamily";

type Props = {
  onClose: () => void;
};
export const PTAParentFamilyInvitationModal = ({ onClose }: Props) => {
  const toast = useToast();

  const { token, isLoading } = usePTAParentFamilyInvitationToken();

  const invitationLink = `${location.origin}/signup?token=${token}&openExternalBrowser=1`;

  const onClickToCopy = useCallback(() => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(invitationLink ?? "");
      toast.info("コピーしました");
    }
  }, [token, toast]);

  const displayInvitationLink: string = (() => {
    if (isLoading) {
      return "読込中";
    } else if (!token) {
      return "リンクを発行できませんでした";
    } else {
      return invitationLink;
    }
  })();

  return (
    <ModalPortal onClose={onClose}>
      <InviteContainerH2>家族を招待</InviteContainerH2>
      <InviteContainerP>
        招待リンクを共有して、家族メンバーをアプリに招待できます。
      </InviteContainerP>
      <InviteLinkBox>
        {isLoading ? (
          <>読み込み中...</>
        ) : (
          <InviteLinkBoxInput
            type="text"
            value={displayInvitationLink}
            id="invite-link"
          />
        )}
        <Button color="text" onClick={onClickToCopy}>
          コピー
        </Button>
      </InviteLinkBox>
    </ModalPortal>
  );
};

const InviteContainerH2 = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
`;

const InviteContainerP = styled.p`
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 15px;
`;

const InviteLinkBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 15px;
`;

const InviteLinkBoxInput = styled.input`
  border: none;
  padding: 10px;
  flex-grow: 1;
  font-size: 14px;
  color: #333;
`;
