import { z } from "zod";
import { zRequiredString } from "../rules";

// グループユーザー取得用スキーマ
export const getGroupUsersSchema = z.object({
  id: zRequiredString,
});

export type GetGroupUsersSchemaType = z.infer<typeof getGroupUsersSchema>;

// 所属グループ取得用スキーマ
export const getMyPostUserGroupsSchema = z.object({
  accountId: zRequiredString,
});

export type GetMyPostUserGroupsSchemaType = z.infer<
  typeof getMyPostUserGroupsSchema
>;

// グループ作成用スキーマ
export const createPostUserGroupSchema = z.object({
  name: zRequiredString,
  accountIds: z
    .array(zRequiredString)
    .min(1, "少なくとも1人のメンバーを選択してください"),
});

export type CreatePostUserGroupSchemaType = z.infer<
  typeof createPostUserGroupSchema
>;

// グループ更新用スキーマ
export const updatePostUserGroupSchema = z.object({
  id: zRequiredString,
  name: zRequiredString,
  accountIds: z
    .array(zRequiredString)
    .min(1, "少なくとも1人のメンバーを選択してください"),
});

export type UpdatePostUserGroupSchemaType = z.infer<
  typeof updatePostUserGroupSchema
>;

// グループ削除用スキーマ
export const deletePostUserGroupSchema = z.object({
  id: zRequiredString,
});

export type DeletePostUserGroupSchemaType = z.infer<
  typeof deletePostUserGroupSchema
>;
