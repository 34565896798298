import { z } from "zod";
import { gradeNumberSchema } from "../rules/child";

export const invoiceCSVCustomInfoSchema = z.object({
  assignFee: z.string().refine((val) => val === "" || !isNaN(Number(val)), {
    message: "数字を入力してください",
  }),
  grades: z.array(gradeNumberSchema),
  belongIds: z.array(z.string()),
  userGroupIds: z.array(z.string()),
});

export type InvoiceCSVCustomInfoSchemaType = z.infer<
  typeof invoiceCSVCustomInfoSchema
>;

export const invoiceCSVCustomInfoSchemaForServer = z.object({
  filter: z.string(),
});

export type InvoiceCSVCustomInfoSchemaForServerType = z.infer<
  typeof invoiceCSVCustomInfoSchemaForServer
>;
