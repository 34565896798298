import * as childApi from "../../apiClients/child";
import { ChildrenFilter } from "@shared/validator/models/child.schema";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";

export function useGetChildrenByCondition(
  condition: {
    q?: string;
    filter?: ChildrenFilter;
  },
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "child", "getChildrenByCondition", apiContext, condition],
    queryFn: async () => {
      return await childApi.getChildrenByCondition(
        condition.q,
        condition.filter
      );
    },
    ...queryOptions,
  });

  return {
    children: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}

export function useInvalidateGetChildrenByCondition() {
  const queryClient = useQueryClient();

  function invalidateGetChildrenByCondition() {
    queryClient.invalidateQueries({
      queryKey: ["api", "child", "getChildrenByCondition"],
    });
  }

  return {
    invalidateGetChildrenByCondition,
  };
}

export function useGetChildrenCountsByGrade() {
  const query = useQuery({
    queryKey: ["api", "child", "getChildrenCountsByGrade"],
    queryFn: async () => {
      return await childApi.getChildrenCountsByGrade();
    },
  });

  return {
    childrenCountsByGrade: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}
