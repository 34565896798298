import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { NonPostElement } from "../PTAPersonalPage";
import {
  ModalBody,
  ModalPortal,
  ModalPortalProps,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { PostHeader } from "../../../features/Post/PostHeader";

import { PostType, useUnDeliveredPostList } from "../../../hooks/api/post";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { Posts } from "./PostPage";
import { PostNavigation } from "../../../features/Post/Post";
import { Margin } from "../../../components/Margin";
import { Heading2 } from "../../../components/Typo";
import { PostCard } from "../../../features/Post/PostCard";
import { useToast } from "../../../components/Toast";
import { cancelScheduledPost } from "../../../apiClients/post";
import { Spinner } from "../../../components/icons/Spinner";
import { useOrganization } from "../../../hooks/recoil/organization";
import { usePostUserGroupList } from "../../../hooks/recoil/postUserGroup";
import { logger } from "src/utils/logger";
import { TargetGradesType } from "@shared/types/post/api";

/**
 * 連絡ページ(配信予約)
 */
export default function PostUndeliveredPage() {
  const currentUser = useCurrentUser();
  const { organization, writeOrganization } = useOrganization({});
  const { getPostUserGroupLabel } = usePostUserGroupList();
  const { posts, getPosts, isLoading } = useUnDeliveredPostList(currentUser);
  const [openPostId, setOpenPostId] = useState("");

  const getPostBelongLabel = useCallback(
    (target: TargetGradesType | string) => {
      return (
        organization?.belongs?.find((belong) => belong.id === target)?.name ??
        "削除済み"
      );
    },
    [organization]
  );

  // 承認する
  const handleApprovePost = async () => {
    getPosts();
  };

  return (
    <>
      {currentUser.isApprover ? (
        <>
          <PostHeader
            organization={organization}
            updateOrganization={writeOrganization}
            refetch={getPosts}
          />
          <PostNavigation />
          <Posts>
            {posts.length === 0 && isLoading && (
              <Card>
                <Spinner />
              </Card>
            )}
            {posts.length !== 0
              ? posts.map((post, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        if (openPostId !== post.id) {
                          setOpenPostId(post.id);
                        } else {
                          setOpenPostId("");
                        }
                      }}
                    >
                      <PostCard
                        post={post}
                        isOpen={openPostId == post.id}
                        type="undelivered"
                        onPostApproved={handleApprovePost}
                        getPostBelongLabel={getPostBelongLabel}
                        getPostUserGroupLabel={getPostUserGroupLabel}
                        refetch={getPosts}
                        borderBottom={i !== posts.length - 1}
                      />
                    </div>
                  );
                })
              : !isLoading && (
                  <NonPostElement
                    title="連絡がありません"
                    message="新規作成から作成ができます"
                  />
                )}
          </Posts>
        </>
      ) : (
        <>閲覧権限がありません</>
      )}
    </>
  );
}

/**
 * 予約投稿のキャンセルモーダル
 */
export const CancelScheduledPostModal = ({
  post,
  onClose,
  refetch,
}: {
  post: PostType;
  onClose: () => void;
  refetch: () => void;
} & ModalPortalProps) => {
  const toast = useToast();

  const onSubmit = async () => {
    try {
      // 予約投稿のキャンセルAPI
      await cancelScheduledPost({ postId: post.id });
      onClose();
      await refetch();
    } catch (error) {
      logger.error(error);
      toast.error("予約投稿の取り消しに失敗しました");
    }
  };

  return (
    <ModalPortal onClose={onClose} modalBoxIsHeightFull={true}>
      <ModalRemandTop>
        <ModalHeader>予約投稿の取り消し</ModalHeader>
        <ModalBody>
          <div>予約投稿を取り消しますか？</div>
          <Margin marginTop={15} />
          <ModalSubmitButtons
            disabled={false}
            submitText="取り消し"
            onSubmit={onSubmit}
            onCancel={onClose}
          />
        </ModalBody>
      </ModalRemandTop>
    </ModalPortal>
  );
};

const ModalRemandTop = styled.div`
  height: auto;
`;

const ModalHeader = styled(Heading2)`
  padding-bottom: 24px;
  display: flex;
`;

const Card = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
