import { Child } from "./child";
import { Account } from "./auth";

export type PTAParentFamily = {
  id: string;
  name: string;
  organizationId: string;
  mainAccount: Account;
  subAccount: Account | null;
  children: Child[];
  createdAt: Date;
  updatedAt: Date;
};

export const ACCOUNT_TYPE_OF_PTA_PARENT_FAMILY = {
  main: "main",
  sub: "sub",
} as const;
export type AccountTypeOfPTAParentFamily =
  keyof typeof ACCOUNT_TYPE_OF_PTA_PARENT_FAMILY;
