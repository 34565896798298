import { useState, useEffect, useCallback } from "react";
import { BaseInfo, ResidentBaseInfo } from "../recoil/user";
import * as authApi from "../../apiClients/auth";
import * as storageApi from "../../apiClients/storage";

/**
 * ログインユーザー用のBaseInfoカスタムフック
 */
export function useBaseInfo() {
  const [currentBaseInfo, setCurrentBaseInfo] = useState<BaseInfo>({
    userId: "",
    lastName: "",
    firstName: "",
    lastNameKana: "",
    firstNameKana: "",
    phoneNumber: "",
    schoolGroup: "",
    isPreschooler: false,
    selfFreeMemo: "",
    belongId: "",
    partId: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const getBaseInfo = async () => {
    setIsLoading(true);
    const baseInfo = await authApi.getBaseInfo();
    setCurrentBaseInfo(baseInfo);
    setIsLoading(false);
  };

  useEffect(() => {
    getBaseInfo();
  }, []);

  return { currentBaseInfo, isLoading, getBaseInfo };
}

export function useResidentBaseInfo() {
  const [currentBaseInfo, setCurrentBaseInfo] = useState<
    Partial<ResidentBaseInfo>
  >({
    userId: "",
    lastName: "",
    firstName: "",
    lastNameKana: "",
    firstNameKana: "",
    gender: undefined,
    birthday: undefined,
    ageGroup: undefined,
    phoneNumber: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const getBaseInfo = async () => {
    setIsLoading(true);
    const baseInfo = await authApi.getResidentBaseInfo();
    setCurrentBaseInfo(baseInfo);
    setIsLoading(false);
  };

  useEffect(() => {
    getBaseInfo();
  }, []);

  return { currentBaseInfo, isLoading, getBaseInfo };
}

/**
 * 全ユーザー用のBaseInfoカスタムフック
 */
export const useBaseInfoById = (userId: string) => {
  const [baseInfoById, setBaseInfoById] = useState<BaseInfo>();

  /**
   * getBaseInfoById
   */
  const getBaseInfoById = useCallback(async () => {
    if (userId) {
      const baseInfo = await authApi.getBaseInfoById(userId);
      setBaseInfoById(baseInfo);
    }
  }, [userId]);

  /**
   * updateBelongAndPart
   */
  const updateBelongAndPart = useCallback(
    async (belongId?: string | null, partId?: string | null) => {
      if (baseInfoById?.userId == undefined) {
        throw new Error("userId is undefined");
      }

      const baseInfo = await authApi.updateBelongAndPart({
        userId: baseInfoById?.userId,
        belongId,
        partId,
      });
      setBaseInfoById(baseInfo);
      return baseInfo;
    },
    [userId, baseInfoById]
  );

  useEffect(() => {
    getBaseInfoById();
  }, []);

  return { baseInfoById, updateBelongAndPart };
};

export const resizeImage = async (file: File): Promise<File> => {
  const IMG_WIDTH = 800;
  return new Promise((resolve) => {
    const imgReader = new Image();
    const reader = new FileReader();
    reader.onloadend = () => {
      imgReader.onload = () => {
        const imgHeight = imgReader.height * (IMG_WIDTH / imgReader.width);
        const canvas = document.createElement("canvas");
        canvas.width = IMG_WIDTH;
        canvas.height = imgHeight;
        const ctx = canvas.getContext("2d");
        if (!ctx) return resolve(file);
        ctx.drawImage(imgReader, 0, 0, IMG_WIDTH, imgHeight);
        canvas.toBlob((blob) => {
          if (!blob) return resolve(file);
          const imageFile = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });
          resolve(imageFile);
        });
      };
      if (reader.result) {
        imgReader.src = reader.result.toString();
      }
    };
    reader.readAsDataURL(file);
  });
};

export function useRegisterInfo() {
  const [isSaving, setIsSaving] = useState(false);

  const registerInfo = useCallback(async (data: BaseInfo) => {
    setIsSaving(true);
    await authApi.registerInfo({
      ...data,
    });
    setIsSaving(false);
  }, []);

  const registerFaceImgPath = useCallback(async (faceImg: File | undefined) => {
    let imgPath = "";
    if (faceImg) {
      imgPath = await storageApi.upload({
        file: await resizeImage(faceImg),
      });
    }
    await authApi.registerFaceImgPath({
      faceImgPath: imgPath,
    });
  }, []);

  return {
    registerInfo,
    registerFaceImgPath,
    isSaving,
  };
}
