import * as client from "./client";
import { Partner as PartnerType } from "@shared/validator/models/partner.schema";
import {
  upsertPartnerSchema,
  UpsertPartnerSchemaType,
  getPartnerForAdminSchema,
  GetPartnerForAdminSchemaType,
} from "@shared/validator/features/partner.schema";

export type Partner = PartnerType;

export const getPartner = async (): Promise<Partner | null> => {
  const res = await client.get("/partner/getPartner", {});
  return res.data;
};

export const getPartnerForAdmin = async (
  userId: string
): Promise<Partner | null> => {
  const args = getPartnerForAdminSchema.parse({ userId });
  const res = await client.get<
    GetPartnerForAdminSchemaType,
    { data: Partner | null }
  >("/partner/getPartnerForAdmin", args);
  return res.data;
};

export const upsertPartner = async (
  args: UpsertPartnerSchemaType
): Promise<void> => {
  upsertPartnerSchema.parse(args);
  await client.post<UpsertPartnerSchemaType, void>(
    "/partner/upsertPartner",
    args
  );
};
