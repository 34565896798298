import React from "react";
import styled from "styled-components";
import { Button } from "src/components/Button";
import { useGetPTAInvoiceProduct } from "src/hooks/query/invoice/ptaInvoiceProduct";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { Link } from "src/components/Link";
import { BreadCrumb } from "src/components/Common/BreadCrumb";
import { PageHeader } from "src/components/Page";
import { usePolyfitLocationQuery, usePolyfitUrl } from "src/hooks/router";
import { useGetPTAParentFamilyInvoiceList } from "src/hooks/query/invoice/ptaParentFamilyInvoices";
import { PTAInvoiceUserListContent } from "./PTAInvoiceUserListContent";
import { toDateFormat } from "src/utils/time";

export function PTAInvoiceProductPage() {
  const currentUser = useCurrentUser();
  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );
  const { id: invoiceProductId } = usePolyfitLocationQuery(
    "PTA_INVOICE_DETAIL",
    {
      id: "",
    }
  );
  const { ptaInvoiceProduct } = useGetPTAInvoiceProduct(invoiceProductId);
  const { ptaParentFamilyInvoices, isLoading } =
    useGetPTAParentFamilyInvoiceList(invoiceProductId);
  const backUrl = usePolyfitUrl({ to: "PTA_INVOICE_LIST" });

  if (!ptaOrganizationInvoiceAccount?.enabled) {
    return <div>閲覧できません</div>;
  }

  return (
    <div>
      <BreadCrumb>
        <Link
          to={{
            to: "PTA_INVOICE_LIST",
          }}
        >
          請求一覧
        </Link>
        <LinkWrap>請求詳細</LinkWrap>
      </BreadCrumb>
      <HeaderRow>
        <div>
          {/* モバイル時のみ戻るボタン表示 */}
          <MobileInvoiceDiv>
            <PageHeader backTo={backUrl}>
              <Title>{`請求詳細: ${ptaInvoiceProduct?.name}`}</Title>
            </PageHeader>
          </MobileInvoiceDiv>
          <PCInvoiceDiv>
            <Title>{`請求詳細: ${ptaInvoiceProduct?.name}`}</Title>
          </PCInvoiceDiv>
        </div>
        <ButtonRow>
          <Button fill size="large" onClick={() => {}}>
            請求を追加発行
          </Button>
          <Button fill size="large" onClick={() => {}}>
            請求リマインド通知
          </Button>
        </ButtonRow>
      </HeaderRow>

      {ptaInvoiceProduct?.dueDate && (
        <DueDateBlock>
          {`支払期日: ${toDateFormat(new Date(ptaInvoiceProduct.dueDate))}`}
        </DueDateBlock>
      )}

      <TableWrapper>
        <PTAInvoiceUserListContent
          isLoading={isLoading}
          invoiceProductId={invoiceProductId}
          ptaParentFamilyInvoices={ptaParentFamilyInvoices}
        />
      </TableWrapper>
    </div>
  );
}

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const DueDateBlock = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
`;

const TableWrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
`;

const LinkWrap = styled.span`
  font-weight: bold;
`;

const MobileInvoiceDiv = styled.div`
  @media (min-width: 1279px) {
    display: none;
  }
`;

const PCInvoiceDiv = styled.div`
  padding-bottom: 18px;
  padding-top: 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
`;
