import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { getResidentUsers } from "src/apiClients/users";
import { ResidentFilterType } from "@shared/validator/features/user.schema";

/**
 * 地域住民を取得する
 */
export const useGetResidentUsers = (
  filter: Partial<ResidentFilterType>,
  queryOptions: QueryOptions = {}
) => {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "users", "residentUsers", apiContext, filter],
    queryFn: async () => {
      return await getResidentUsers(filter);
    },
    ...queryOptions,
  });

  return {
    residents: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
};

export function useInvalidateGetResidentUsers() {
  const queryClient = useQueryClient();

  function invalidateGetResidentUsers() {
    queryClient.invalidateQueries({
      queryKey: ["api", "users", "residentUsers"],
    });
  }

  return {
    invalidateGetResidentUsers,
  };
}
