import { z } from "zod";
import { zRequiredString, zHiraganaString } from "../rules";
import { baseRequestSchema } from "./common.schema";

export const upsertPartnerSchema = z.object({
  email: zRequiredString,
  lastName: zRequiredString,
  firstName: zRequiredString,
  lastNameKana: zHiraganaString,
  firstNameKana: zHiraganaString,
  isShare: z.boolean(),
});

export type UpsertPartnerSchemaType = z.infer<typeof upsertPartnerSchema>;

export const getPartnerForAdminSchema = baseRequestSchema.extend({
  userId: zRequiredString,
});

export type GetPartnerForAdminSchemaType = z.infer<
  typeof getPartnerForAdminSchema
>;
