import styled from "styled-components";
import React, { useCallback, useState } from "react";
import * as Typo from "src/components/Typo";
import { AppliedRecruitmentCard } from "src/features/common/Recruitment/application/AppliedRecruitmentCard";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import { BREAKPOINTS } from "src/components/Responsive";
import { useToast } from "src/components/Toast";
import { deleteRecruitmentApplicationComment } from "src/apiClients/boardEducation/recruitmentApplicationComment";
import CreateOpenRecruitmentAppliedCommentModal from "../recruitment/CreateRecruitmentAppliedCommentModal";
import DeleteApplicationCommentModal from "./DeleteApplicationCommentModal";
import { createPolyfitUrl } from "src/hooks/router";
import { useGetManagedOrganization } from "src/hooks/query/boardEducation/organizations/organization";
import { colorTokens } from "src/theme";

type Props = {
  isLoading: boolean;
  recruitmentList: AppliedRecruitment[] | null;
  refetch: () => void;
};

const AppliedRecruitmentContent = (props: Props) => {
  const { organization } = useGetManagedOrganization();
  const [isRecruitmentApplicationId, setIsRecruitmentApplicationId] =
    useState<string>("");
  const [
    isCreateRecruitmentAppliedComment,
    setIsCreateRecruitmentAppliedComment,
  ] = useState(false);
  const [isDeleteModalOpenId, setIsDeleteModalOpenId] = useState<string>();
  const [comment, setComment] = useState<string>("");
  const toast = useToast();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmitComment = useCallback(async () => {
    setDoubleClickBlocked(true);
    if (!isDeleteModalOpenId) {
      toast.warn("削除に失敗しました");
      setDoubleClickBlocked(false);
      return;
    }

    try {
      await deleteRecruitmentApplicationComment(isDeleteModalOpenId ?? "");
      setIsDeleteModalOpenId(undefined);
      toast.success("削除に成功しました");
      props.refetch();
    } catch (e) {
      toast.warn("削除に失敗しました");
    }
    setDoubleClickBlocked(false);
  }, [isDeleteModalOpenId]);

  const onCloseCommentModal = useCallback(() => {
    setIsDeleteModalOpenId(undefined);
    setIsCreateRecruitmentAppliedComment(true);
  }, []);

  const onOpenConfirmDeleteCommentModal = useCallback(
    (recruitmentApplicationCommentId: string, comment: string) => {
      setIsCreateRecruitmentAppliedComment(false);
      setIsDeleteModalOpenId(recruitmentApplicationCommentId);
      setComment(comment);
    },
    []
  );

  return (
    <>
      <CareerTitle>応募一覧</CareerTitle>
      <CardWrapper>
        {props.isLoading ? (
          <></>
        ) : props.recruitmentList?.length === 0 ? (
          <NoRecruitmentMessage>応募はありません</NoRecruitmentMessage>
        ) : (
          <CardContainer>
            {props.recruitmentList?.map((r, i) => {
              return (
                <AppliedRecruitmentCard
                  key={i}
                  appliedRecruitment={r}
                  onOpen={() => {
                    setIsCreateRecruitmentAppliedComment(true);
                    setIsRecruitmentApplicationId(r.id);
                  }}
                  newTabUrl={createPolyfitUrl({
                    to: "BE_ADMIN_RECRUITMENT_DETAIL",
                    query: { id: r.recruitment.id },
                  })}
                  currentCommunityId={null}
                  boardEducationName={organization?.name}
                />
              );
            })}
          </CardContainer>
        )}
      </CardWrapper>
      {!!isCreateRecruitmentAppliedComment && (
        <CreateOpenRecruitmentAppliedCommentModal
          isRecruitmentApplicationId={isRecruitmentApplicationId}
          isDeletedRecruitment={
            props.recruitmentList?.find(
              (recruitment) => recruitment.id === isRecruitmentApplicationId
            )?.recruitment.status === "DELETED"
          }
          comments={
            props.recruitmentList?.find(
              (recruitment) => recruitment.id === isRecruitmentApplicationId
            )?.comment ?? []
          }
          onOpenConfirmDeleteCommentModal={onOpenConfirmDeleteCommentModal}
          onClose={() => {
            setIsCreateRecruitmentAppliedComment(false);
          }}
          refetch={props.refetch}
        />
      )}
      {!!isDeleteModalOpenId && (
        <DeleteApplicationCommentModal
          comment={comment}
          onSubmit={onSubmitComment}
          onClose={onCloseCommentModal}
          disabled={doubleClickBlocked}
        />
      )}
    </>
  );
};

const CardWrapper = styled.div`
  margin: 8px auto;
`;

const CardContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${BREAKPOINTS.PC}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CareerTitle = styled(Typo.Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #343741;
  margin-bottom: 20px;
`;

const NoRecruitmentMessage = styled.p`
  font-size: 14px;
  color: ${colorTokens.textSubdued};
  margin-bottom: 10px;
`;

export default AppliedRecruitmentContent;
