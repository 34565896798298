import { z } from "zod";
import { zRequiredString } from "../rules";

// GET /exportCsv のクエリパラメータのスキーマ
export const exportCsvSchema = z.object({
  type: z.enum(["PARENT", "TEACHER"]),
  q: z.string().optional(),
  filter: z.string().optional(), // JSON文字列化されたフィルター
});

export type ExportCsvSchemaType = z.infer<typeof exportCsvSchema>;

// GET /exportCsvByInternalAdmin のクエリパラメータのスキーマ
export const exportCsvByInternalAdminSchema = z.object({
  organizationId: zRequiredString,
});

export type ExportCsvByInternalAdminSchemaType = z.infer<
  typeof exportCsvByInternalAdminSchema
>;
