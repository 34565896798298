import { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryOptions } from "src/hooks/query/QueryOptions";
import { useApiContext } from "src/apiClients/client";
import { ResidentFilterType } from "@shared/validator/features/user.schema";
import { getBEResidentUsers } from "src/apiClients/boardEducation/users";

/**
 * 教育委員会の名簿用に地域住民を取得する
 */
export function useBEResidentUsers(
  filter?: Partial<ResidentFilterType>,
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "users", "getBEResidentUsers", apiContext, filter],
    queryFn: async () => await getBEResidentUsers(filter),
    ...queryOptions,
  });

  return {
    residents: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}

export function useInvalidateBEResidentUsers() {
  const queryClient = useQueryClient();

  function invalidateBEResidentUsers() {
    queryClient.invalidateQueries({
      queryKey: ["api", "users", "getBEResidentUsers"],
    });
  }

  return {
    invalidateBEResidentUsers,
  };
}
