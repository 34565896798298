import { z } from "zod";
import { zRequiredString } from "../rules";

// GET /community/getCommunity のスキーマ
export const getCommunitySchema = z.object({
  communityId: zRequiredString,
});

export type GetCommunitySchemaType = z.infer<typeof getCommunitySchema>;

// GET /community/list のスキーマ
export const getCommunityListSchema = z.object({
  searchText: z.string(),
  take: z
    .string()
    .optional()
    .transform((val) => (val ? Number(val) : undefined)),
});

export type GetCommunityListSchemaType = z.infer<typeof getCommunityListSchema>;

// POST /community/updateCommunity のスキーマ
export const updateCommunitySchema = z.object({
  id: zRequiredString,
  name: zRequiredString,
  schoolCode: zRequiredString,
  address: z.string().nullable(),
  postalCode: z.string().nullable(),
  nameListPublished: z.boolean().optional(),
  requireAdminToUpdateCareer: z.boolean().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  postSchoolYearsFilter: z.array(z.string()).optional(),
  prefecture: z.string().optional(),
  city: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().optional(),
});

export type UpdateCommunitySchemaType = z.infer<typeof updateCommunitySchema>;
