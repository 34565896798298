import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  upsertPTAOrganizationInvoiceAccount,
  getPTAOrganizationInvoiceAccountForInternal,
} from "src/apiClients/ptaOrganizationInvoiceAccount";
import { GetPTAOrganizationInvoiceAccountType } from "@shared/validator/features/ptaOrganizationInvoiceAccount.schema";

export function useGetPTAOrganizationInvoiceAccountForInternal(
  args: GetPTAOrganizationInvoiceAccountType
) {
  const query = useQuery({
    queryKey: ["api", "ptaOrganizationInvoiceAccount", args.organizationId],
    queryFn: async () => {
      if (!args.organizationId) return null;
      return await getPTAOrganizationInvoiceAccountForInternal(args);
    },
    enabled: Boolean(args.organizationId),
  });

  return {
    ptaOrganizationInvoiceAccount: query.data ?? undefined,
    ...query,
  };
}

export function useUpsertPTAOrganizationInvoiceAccount() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: upsertPTAOrganizationInvoiceAccount,
    onSuccess: (_result) => {
      queryClient.invalidateQueries({
        queryKey: ["api", "ptaOrganizationInvoiceAccount"],
      });
    },
  });
}
