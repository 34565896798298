import React from "react";
import {
  Answer,
  isMultiChoiceAnswer,
  isOpenEndedAnswer,
  fetchSurveyAnswers,
} from "../../../apiClients/surveyAnswers";
import {
  Choice,
  SurveyQuestions,
} from "@shared/validator/features/survey.schema";
import {
  MultiChoiceAnswerSchemaType as MultiChoiceAnswer,
  OpenEndedAnswerSchemaType as OpenEndedAnswer,
} from "@shared/validator/features/surveyAnswers.schema";
import { SurveyAnswers } from "@shared/types/surveyAnswers";

// stateを扱うハンドラ一覧
export interface EventHandlers {
  // 記述式の回答（テキストボックス）修正イベント
  onEditText: (answer: OpenEndedAnswer) => void;
  // 選択式の回答（radio、唯一回答の時）修正イベント
  onEditRadio: (answer: MultiChoiceAnswer, choice: Choice) => void;
  // 選択式の回答（checkbox、複数回答の時）修正イベント
  onEditCheckBox: (answer: MultiChoiceAnswer, choice: Choice) => void;
  // 未回答を既回答に切り替える
  onUpdateIsAlreadyAnswered: (surveyAnswers: SurveyAnswers) => void;
}

// stateが必要な質問、回答リストと付随するイベントハンドラを定義して返す関数
// モーダルを除いて、アンケート回答で用いられる全ての状態をラップして管理する
export const useSurveyAnswersHooks = (surveyQuestions: SurveyQuestions) => {
  // DBから取得完了したかどうかを判定するフラグ
  // ロード中は回答ボタンを表示させないために使用
  const [surveyAnswersLoaded, setSurveyAnswersLoaded] =
    React.useState<boolean>(false);
  // 状態管理すべき回答のリスト
  const [surveyAnswers, setSurveyAnswers] = React.useState<SurveyAnswers>({
    isAlreadyAnswered: false,
    answerList: Array<Answer>(),
  });
  // DBから自身の回答を取得
  React.useEffect(() => {
    (async () => {
      const surveyAnswers = await fetchSurveyAnswers(surveyQuestions.id);
      setSurveyAnswers(surveyAnswers);
      setSurveyAnswersLoaded(true);
    })();
  }, [surveyQuestions.id]);
  // イベント、テキスト修正（記述式のみ）
  const onEditText = (answer_: OpenEndedAnswer): void => {
    const newSurveyAnswers: SurveyAnswers = {
      ...surveyAnswers,
      answerList: surveyAnswers.answerList.map((answer: Answer) => {
        // 記述式のみ該当
        if (isMultiChoiceAnswer(answer)) {
          return answer;
        }
        // 指定IDでなければそのまま返す
        if (answer_.id !== answer.id) {
          return answer;
        }
        // 質問が指定IDなら新しい要素（テキスト更新済）を返す
        return answer_;
      }),
    };
    setSurveyAnswers(newSurveyAnswers);
  };
  // イベント、radio修正（選択式、唯一回答のみ）
  // answer_: 該当する回答
  // choice_: 該当する選択肢
  const onEditRadio = (answer_: MultiChoiceAnswer, choice_: Choice): void => {
    const newSurveyAnswers: SurveyAnswers = {
      ...surveyAnswers,
      answerList: surveyAnswers.answerList.map((answer: Answer) => {
        // 選択式のみ該当
        if (isOpenEndedAnswer(answer)) {
          return answer;
        }
        // 指定IDでなければそのまま返す
        if (answer_.id !== answer.id) {
          return answer;
        }
        // radioの振る舞いを表現（選択された要素のみチェック）
        answer_.selectedChoices = [choice_.id];
        return answer_;
      }),
    };
    setSurveyAnswers(newSurveyAnswers);
  };
  // イベント、checkbox修正（選択式、複数回答のみ）
  // answer_: 該当する回答
  // choice_: 該当する選択肢
  const onEditCheckBox = (
    answer_: MultiChoiceAnswer,
    choice_: Choice
  ): void => {
    const newSurveyAnswers: SurveyAnswers = {
      ...surveyAnswers,
      answerList: surveyAnswers.answerList.map((answer: Answer) => {
        // 選択式のみ該当
        if (isOpenEndedAnswer(answer)) {
          return answer;
        }
        // 指定IDでなければそのまま返す
        if (answer_.id !== answer.id) {
          return answer;
        }
        // 各選択肢を走査して、idが同じならチェック状態を変更
        for (const choice of answer_.question.choiceList) {
          if (choice_.id !== choice.id) {
            continue;
          }
          // 選択されているリストに含まれている＝選択中
          const isIncluded: boolean = answer_.selectedChoices.includes(
            choice.id
          );
          if (isIncluded) {
            // 外された、listから削除
            const index: number = answer_.selectedChoices.indexOf(choice.id);
            // 含まれていることはわかっているがfail-safeに
            if (index !== -1) {
              answer_.selectedChoices.splice(index, 1);
            }
          } else {
            // チェックされた、listに追加
            answer_.selectedChoices.push(choice.id);
          }
        }
        return answer_;
      }),
    };
    setSurveyAnswers(newSurveyAnswers);
  };
  const onUpdateIsAlreadyAnswered = (surveyAnswers: SurveyAnswers): void => {
    const newSurveyAnswers: SurveyAnswers = {
      ...surveyAnswers,
      isAlreadyAnswered: true,
    };
    setSurveyAnswers(newSurveyAnswers);
  };
  const eventHandlers: EventHandlers = {
    onEditText,
    onEditRadio,
    onEditCheckBox,
    onUpdateIsAlreadyAnswered,
  };
  return { surveyAnswers, surveyAnswersLoaded, eventHandlers };
};
