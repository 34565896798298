import React, { useMemo, useState } from "react";
import { SwitchBar } from "src/components/Common/SwitchBar";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
  ModalBody,
} from "src/components/Modal";
import styled from "styled-components";
import { InputTitle } from "../Post/CreatePostModal";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Margin } from "src/components/Margin";
import { colorsPallet } from "src/theme";
import { CustomGrade } from "./Custom/CustomGrade";
import { CustomBelong } from "./Custom/CustomBelong";
import { CustomUserGroup } from "./Custom/CustomUserGroup";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { usePostUserGroupList } from "src/hooks/recoil/postUserGroup";
import {
  invoiceCSVCustomInfoSchema,
  InvoiceCSVCustomInfoSchemaType,
} from "@shared/validator/features/invoiceCSVCustomInfo";
import { TargetGradesType } from "@shared/types/post/api";
type Props = {
  onClose: () => void;
  onSubmit: (csvCustom: InvoiceCSVCustomInfoSchemaType) => void;
  csvCustom: InvoiceCSVCustomInfoSchemaType;
};

export const CustomInfoModal = ({ onClose, onSubmit, csvCustom }: Props) => {
  const [selectedFilterLabelIndex, setSelectedFilterLabelIndex] = useState(0);
  const { organization } = useGetCurrentOrganization();
  const { postGroups } = usePostUserGroupList();
  const [assignFee, setAssignFee] = useState<string>(csvCustom.assignFee);
  const [selectedGrades, setSelectedGrades] = useState<TargetGradesType[]>(
    csvCustom.grades
  );
  const [selectedBelongIds, setSelectedBelongIds] = useState<string[]>(
    csvCustom.belongIds
  );
  const [selectedUserGroupIds, setSelectedUserGroupIds] = useState<string[]>(
    csvCustom.userGroupIds
  );
  const belongOptions = useMemo(() => {
    return (
      organization?.belongs?.map((belong) => {
        return {
          id: belong.id,
          name: belong.name,
          order: belong.order,
        };
      }) ?? []
    );
  }, [organization]);

  const userGroupOptions = useMemo(() => {
    return (
      postGroups?.map((postGroup) => {
        return {
          id: postGroup.id,
          name: postGroup.name,
        };
      }) ?? []
    );
  }, [postGroups]);

  const filterLabels = [
    { label: "学年", value: "grade" },
    { label: "所属", value: "belong" },
    { label: "連絡グループ", value: "userGroup" },
  ];

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<InvoiceCSVCustomInfoSchemaType>({
    resolver: zodResolver(invoiceCSVCustomInfoSchema),
    defaultValues: {
      assignFee: csvCustom.assignFee,
      grades: csvCustom.grades,
      belongIds: csvCustom.belongIds,
      userGroupIds: csvCustom.userGroupIds,
    },
  });

  // submit時にエラーになる
  // おそらくwatchの対象が存在せずエラーになる=>undefinedが返ってくる
  // そのため、watchの対象が存在しない場合は、csvCustomをそのまま返す
  // もしかしたらwatchではなくuseStateで渡さないといけない
  const onSubmitCustomInfo = () => {
    const newCsvCustom = {
      assignFee: assignFee,
      grades: selectedGrades,
      belongIds: selectedBelongIds,
      userGroupIds: selectedUserGroupIds,
    };
    onSubmit(newCsvCustom);
  };

  return (
    <ModalPortal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmitCustomInfo)}>
        <ModalHeader>ダウンロード情報の変更</ModalHeader>

        <ModalBody>
          <ModalLabel>割り当て料金</ModalLabel>
          <Margin marginTop={12} />

          <InputTitle
            type="number"
            onChange={(e) => setAssignFee(e.target.value)}
            placeholder="金額を入力してください"
            min={0}
            value={assignFee}
          />

          {errors.assignFee && (
            <ErrorMessage>{errors.assignFee.message}</ErrorMessage>
          )}

          <Margin marginTop={20} />

          <ScrollXWrapper>
            <SwitchBar
              labels={filterLabels.map((l) => l.label)}
              onSelect={setSelectedFilterLabelIndex}
              selectedIndex={selectedFilterLabelIndex}
            />
          </ScrollXWrapper>

          <Margin marginTop={20} />

          {/* 学年 */}
          {selectedFilterLabelIndex === 0 && (
            <CustomGrade
              selectedGrades={selectedGrades}
              setSelectedGrades={setSelectedGrades}
              schoolType={organization?.schoolType}
            />
          )}

          {/* 所属 */}
          {selectedFilterLabelIndex === 1 && (
            <CustomBelong
              selectedBelongIds={selectedBelongIds}
              setSelectedBelongIds={setSelectedBelongIds}
              belongOptions={belongOptions}
            />
          )}

          {/* 連絡グループ */}
          {selectedFilterLabelIndex === 2 && (
            <CustomUserGroup
              selectedUserGroupIds={selectedUserGroupIds}
              setSelectedUserGroupIds={setSelectedUserGroupIds}
              userGroupOptions={userGroupOptions}
            />
          )}

          <ModalSubmitButtons submitText="変更" type="submit" />
        </ModalBody>
      </form>
    </ModalPortal>
  );
};

const ModalLabel = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const ScrollXWrapper = styled.div`
  overflow-x: scroll;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: ${colorsPallet.danger};
`;
