import { EmptyObject } from "react-hook-form";
import * as client from "../client";
import { BEAccount } from "@shared/types/boardEducation/auth";
import { UpdateAccountCommunityRolesRequestSchemaType } from "@shared/validator/features/BE/auth.schema";
import {
  BaseInfo,
  baseInfoSchema,
} from "@shared/validator/models/baseInfo.schema";
import {
  RegisterInfoSchemaType,
  ResidentInfoBodyForBESchemaType,
} from "@shared/validator/features/auth.schema";
import { ResidentInfoSchemaType } from "@shared/validator/models/residentInfo.schema";

/**
 * 現在の教育委員会アカウントを取得する
 */
export const getAccount = async (): Promise<BEAccount> => {
  const res = await client.get<{}, BEAccount>(
    "/boardEducation/auth/getAccount",
    {}
  );
  return res;
};

/**
 * 現在のRoleを更新する
 */
export const updateRole = async (
  accountId: string,
  role: string,
  currentCommunityId: string
) => {
  const res = await client.post<{
    accountId: string;
    role: string;
    currentCommunityId: string;
  }>("/boardEducation/auth/updateRole", {
    accountId,
    role,
    currentCommunityId,
  });
  return res;
};

/**
 * 名簿用の退会処理
 */
export async function deleteAccountById(
  accountId: string,
  currentCommunityId: string
): Promise<EmptyObject> {
  const data = { accountId, currentCommunityId };
  const res = await client.post("/boardEducation/auth/deleteAccount", { data });
  return res;
}

/**
 * 紐づくcommunitiesを更新する
 */
export const updateCommunityRoles = async (
  data: UpdateAccountCommunityRolesRequestSchemaType
) => {
  const res = await client.post<UpdateAccountCommunityRolesRequestSchemaType>(
    "/boardEducation/auth/updateCommunityRoles",
    data
  );
  return res;
};

/**
 * 校区の選択を変更できるかどうかを確認する
 */
export async function canDeleteCommunityRole(
  communityId: string,
  userId: string
) {
  const res = await client.get<
    { communityId: string; userId: string },
    boolean
  >("/boardEducation/auth/canDeleteCommunityRole", {
    userId: userId,
    communityId: communityId,
  });
  return res;
}

export async function registerInfo(data: BaseInfo) {
  baseInfoSchema.parse(data);
  const baseInfo = await client.post<RegisterInfoSchemaType, BaseInfo>(
    "/boardEducation/auth/registerInfo",
    {
      data,
    }
  );
  return baseInfo;
}

export async function registerFaceImgPath(data: {
  accountId: string;
  faceImgPath: string;
}) {
  const baseInfo = await client.post<
    { accountId: string; faceImgPath: string },
    BaseInfo
  >("/boardEducation/auth/registerFaceImgPath", data);
  return baseInfo;
}

export async function updateResidentInfo(
  accountId: string,
  data: ResidentInfoSchemaType
): Promise<{}> {
  const res = await client.post<ResidentInfoBodyForBESchemaType>(
    "/boardEducation/auth/updateResidentInfo",
    { accountId, data }
  );
  return res;
}
