import { z } from "zod";
import { InternalUserRole } from "../../types/internalUserRole";
import { ServiceType } from "../../types/serviceType";
import { zRequiredString } from "../rules";
import { baseRequestSchema } from "./common.schema";

export const updateUserByInternalAdminSchema = z.object({
  userId: z.string().min(1),
  email: z.string().optional(),
  name: z.string().optional(),
  picture: z.string().optional(),
  internalRole: z
    .nativeEnum(InternalUserRole)
    .refine((role) => role !== InternalUserRole.INTERNAL_ADMIN, {
      message: "ユーザーをInternalAdminに変更することはできません",
    }),
});

export type UpdateUserByInternalAdminSchemaType = z.infer<
  typeof updateUserByInternalAdminSchema
>;

export const usersByCommunityIdSchema = z.object({
  communityId: zRequiredString,
  serviceType: z.nativeEnum(ServiceType),
});

export type UsersByCommunityIdSchemaType = z.infer<
  typeof usersByCommunityIdSchema
>;

export const usersByOrganizationIdSchema = baseRequestSchema.extend({
  organizationId: zRequiredString,
});

export type UsersByOrganizationIdSchemaType = z.infer<
  typeof usersByOrganizationIdSchema
>;

// 2024-12-14現在、frontではfilterは利用されていない
export const teacherFilterTypeSchema = z.object({
  includeAdmin: z.enum(["0", "1"]).optional(),
});

export type TeacherFilterType = z.infer<typeof teacherFilterTypeSchema>;

export const disabledTeacherUsersSchema = baseRequestSchema.extend({
  q: z.string().optional(),
  filterQuery: z.string().optional(),
});

export type DisabledTeacherUsersSchemaType = z.infer<
  typeof disabledTeacherUsersSchema
>;

export const teacherUsersSchema = baseRequestSchema.extend({
  q: z.string().optional(),
  filterQuery: z.string().optional(),
});

export type TeacherUsersSchemaType = z.infer<typeof teacherUsersSchema>;

export const residentFilterTypeSchema = z.object({
  communityUserType: z.array(z.enum(["PARENT", "TEACHER", "RESIDENT"])),
  preferredPaid: z.array(z.enum(["FREE", "PAID"])),
  preferredDays: z.array(
    z.enum([
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ])
  ),
  licenses: z.array(z.string()),
  preferredVolunteerTypes: z.array(z.string()),
  name: z.string().optional(),
  city: z.string().optional(),
  withinWalkMinute: z.number().optional(),
  roles: z.array(z.enum(["CSAdmin", "CSOperator", "CSMember"])).optional(),
});

export type ResidentFilterType = z.infer<typeof residentFilterTypeSchema>;

export const residentUserSchema = baseRequestSchema.extend({
  filterQuery: z.string().optional(),
});

export type ResidentUserSchemaType = z.infer<typeof residentUserSchema>;

export const updateApproversSchema = z.object({
  approverIds: z.array(zRequiredString),
});

export type UpdateApproversSchemaType = z.infer<typeof updateApproversSchema>;

export const getMemberUserSchema = baseRequestSchema.extend({
  userId: zRequiredString,
});

export type GetMemberUserSchemaType = z.infer<typeof getMemberUserSchema>;

export const getAdminUsersByOrganizationIdSchema = baseRequestSchema.extend({
  organizationId: zRequiredString,
});

export type GetAdminUsersByOrganizationIdSchemaType = z.infer<
  typeof getAdminUsersByOrganizationIdSchema
>;
