import React, { useEffect, useMemo } from "react";
import { PageContainer, PageHeader } from "../../../components/Page";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { profileTabState } from "../../../hooks/recoil/profileTab";
import { useCurrentUser } from "../../../hooks/recoil/user";
import * as theme from "../../../theme";
import { Heading3 } from "../../../components/Typo";
import { useGetChildren } from "../../../hooks/api/child";
import { BaseInfoContent } from "../../../features/Profile/BaseInfo/export";
import { ChildInfoContent } from "../../../features/Profile/ChildInfo/export";
import CareerInfoContent from "../../../features/Profile/Career/CareerInfoContent";
import { BreadCrumb } from "../../../components/Common/BreadCrumb";
import { PartnerInfoContent } from "../../../features/Profile/PartnerInfo/PartnerInfoContent";
import { TeacherInfoContent } from "../../../features/Profile/TeacherInfo/TeacherInfoContent";
import { ResidentInfoContent } from "../../../features/Profile/ResidentInfo/ResidentInfoContent";
import { inCommunitySchool, inPTA } from "../../../apiClients/users";
import { CurrentUser } from "../../../apiClients/auth";
import ResidentInfoBelongOrganizationContent from "../../../features/Profile/ResidentInfo/ResidentInfoBelongOrganizationContent";
import { ResidentChatQRCode } from "../../../features/Profile/ResidentChatQRCode/ResidentChatQRCode";
import { isCsAdminRole, isPtaRole } from "../../../utils/types/role";
import { useCurrentCommunityId } from "src/hooks/router";
import { BREAKPOINTS } from "src/components/Responsive";
import LoginSetting from "src/features/Profile/LoginSetting/LoginSetting";
import { NotificationSetting } from "src/features/Profile/NotificationSetting/NotificationSetting";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { CsChildInfoContent } from "src/features/Profile/ChildInfo/CsChildInfoContent";
import { hasHigherInternalRole } from "@shared/utils/internalUserRole";
import { PTAParentFamilyInfoContent } from "src/features/Profile/PTAParentFamilyInfo/PTAParentFamilyInfoContent";

export default function ProfilePage() {
  const user = useCurrentUser();
  const [openTab, setOpenTab] = useRecoilState(profileTabState);
  const { children } = useGetChildren(user.id, !isPtaRole(user.role));
  const isCSAdmin = isCsAdminRole(user.role);
  const { communityId } = useCurrentCommunityId();
  const { organization } = useGetCurrentOrganization();
  const isInternalAdmin = hasHigherInternalRole(
    user.internalRole,
    "INTERNAL_ADMIN"
  );

  useEffect(() => {
    return () => {
      setOpenTab("baseInfo");
    };
  }, []);

  const linkToHome = useMemo(() => {
    if (inPTA(user)) {
      return "/pta";
    }
    if (inCommunitySchool(user) && isCSAdmin) {
      return "/pta/list";
    }
    return "/resident/recruitment";
  }, [user]);

  return (
    <>
      <ProfileContainer>
        <BreadCrumb>
          <Link
            to={{ pathname: linkToHome, search: `communityId=${communityId}` }}
          >
            ホーム
          </Link>
          <span>プロフィール</span>
        </BreadCrumb>
        <PageHeader>プロフィール</PageHeader>
        <StyledFlexSpaceBetween>
          <PCTabsContainer>
            <PCTabMenu
              isActive={openTab === "baseInfo"}
              onClick={() => setOpenTab("baseInfo")}
            >
              基本情報
            </PCTabMenu>
            {user.type === "PARENT" && (
              <>
                <PCTabMenu
                  isActive={openTab === "childInfo"}
                  onClick={() => setOpenTab("childInfo")}
                >
                  お子さま情報
                </PCTabMenu>
                <PCTabMenu
                  isActive={openTab === "partnerInfo"}
                  onClick={() => setOpenTab("partnerInfo")}
                >
                  他の保護者情報
                </PCTabMenu>
                {isInternalAdmin && (
                  <PCTabMenu
                    isActive={openTab === "ptaParentInfo"}
                    onClick={() => setOpenTab("ptaParentInfo")}
                  >
                    世帯情報
                  </PCTabMenu>
                )}
              </>
            )}
            {user.type === "RESIDENT" && (
              <>
                <PCTabMenu
                  isActive={openTab === "csChildInfo"}
                  onClick={() => setOpenTab("csChildInfo")}
                >
                  お子さま情報
                </PCTabMenu>
              </>
            )}

            {user.type === "TEACHER" && (
              <>
                <PCTabMenu
                  isActive={openTab === "teacherInfo"}
                  onClick={() => setOpenTab("teacherInfo")}
                >
                  担任情報
                </PCTabMenu>
              </>
            )}
            {user.type === "RESIDENT" && (
              <>
                <PCTabMenu
                  isActive={openTab === "residentInfo"}
                  onClick={() => setOpenTab("residentInfo")}
                >
                  配信・応募条件
                </PCTabMenu>
              </>
            )}
            {(user.type === "PARENT" || user.type === "TEACHER") && (
              <PCTabMenu
                isActive={openTab === "resume"}
                onClick={() => setOpenTab("resume")}
              >
                経歴
              </PCTabMenu>
            )}
            <PCTabMenu
              isActive={openTab === "loginSetting"}
              onClick={() => setOpenTab("loginSetting")}
            >
              アカウント設定
            </PCTabMenu>

            <PCTabMenu
              isActive={openTab === "notificationSetting"}
              onClick={() => setOpenTab("notificationSetting")}
            >
              通知設定
            </PCTabMenu>

            {user.type === "RESIDENT" && (
              <PCTabMenu
                isActive={openTab === "residentInfoBelongOrganization"}
                onClick={() => setOpenTab("residentInfoBelongOrganization")}
              >
                所属団体
              </PCTabMenu>
            )}
            {isCSAdmin && (
              <PCTabMenu
                isActive={openTab === "residentChatQRCode"}
                onClick={() => setOpenTab("residentChatQRCode")}
              >
                連絡用QRコード
              </PCTabMenu>
            )}
          </PCTabsContainer>

          {openTab === "baseInfo" && <BaseInfoContent />}

          {openTab === "childInfo" && (
            <ChildInfoContent user={user} isAdminOrCurrentUser={true} />
          )}

          {openTab === "csChildInfo" && <CsChildInfoContent user={user} />}

          {openTab === "partnerInfo" && (
            <PartnerInfoContent inPTA={isPtaRole(user.role)} />
          )}

          {openTab === "ptaParentInfo" && (
            <PTAParentFamilyInfoContent
              inPTA={isPtaRole(user.role)}
              user={user}
            />
          )}

          {openTab === "teacherInfo" && <TeacherInfoContent />}

          {openTab === "residentInfo" && <ResidentInfoContent />}
          {openTab === "residentInfoBelongOrganization" && (
            <ResidentInfoBelongOrganizationContent />
          )}
          {openTab === "residentChatQRCode" && <ResidentChatQRCode />}

          {openTab === "resume" && (
            <CareerInfoContent
              userId={user.id}
              userChildren={children}
            ></CareerInfoContent>
          )}

          {openTab === "loginSetting" && <LoginSetting />}

          {openTab === "notificationSetting" &&
            (organization?.lineOption && user.type === "RESIDENT" ? (
              <NotificationSetting isLineSettingEnabled={true} />
            ) : (
              <NotificationSetting isLineSettingEnabled={false} />
            ))}
        </StyledFlexSpaceBetween>
      </ProfileContainer>
    </>
  );
}

const ProfileContainer = styled(PageContainer)`
  @media (min-width: 960px) {
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

// FIXME: do not use "export const" with styled-components
export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

// FIXME: do not use "export const" with styled-components
export const EditLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  flex-wrap: nowrap;

  @media (max-width: ${BREAKPOINTS.SP}) {
    margin-top: 10px;
  }
`;

// FIXME: do not use "export const" with styled-components
export const StyledFlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 30px;

  @media (max-width: 1025px) {
    display: block;
  }
`;

const PCTabsContainer = styled.ul`
  list-style: none;
  margin-top: 20px;
`;

const PCTabMenu = styled.li<{
  isActive?: boolean;
}>`
  border-left: ${(props) =>
    props.isActive && `2px solid ${theme.colorsPallet.primary}`};
  padding-left: ${(props) => (props.isActive ? "10px" : "12px")};
  font-weight: ${(props) => (props.isActive ? "700" : "400")};
  margin-bottom: 20px;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    cursor: pointer;
  }
`;

// FIXME: do not use "export const" with styled-components
export const PCBaseInfoWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e4e6f3;
  padding: 24px;
  flex: 1;

  @media (max-width: 1025px) {
    width: 100%;
  }
`;

// FIXME: do not use "export const" with styled-components
export const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 580px) {
    display: block;
  }
`;

// FIXME: do not use "export const" with styled-components
export const UserImg = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
`;

// FIXME: do not use "export const" with styled-components
export const FullNameContainer = styled.div`
  margin-left: 20px;
`;

// FIXME: do not use "export const" with styled-components
export const FullName = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: ${theme.colorsPallet.darkestShade};

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

// FIXME: do not use "export const" with styled-components
export const FullNameKana = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colorsPallet.darkestShade};

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

// FIXME: do not use "export const" with styled-components
export const StyledTable = styled.table`
  margin-top: 20px;
`;

// FIXME: do not use "export const" with styled-components
export const StyledTr = styled.tr``;

// FIXME: do not use "export const" with styled-components
export const StyledTh = styled.th`
  min-width: 300px;
  text-align: left;
  padding-right: 50px;
  padding-bottom: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${theme.colorsPallet.darkestShade};
  vertical-align: top;

  @media (max-width: ${BREAKPOINTS.SP}) {
    padding-right: 30px;
    white-space: nowrap;
    font-size: 12px;
  }
`;

// FIXME: do not use "export const" with styled-components
export const StyledTd = styled.td`
  word-break: break-all;
  text-align: left;
  font-weight: 400;
  padding-bottom: 5px;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${theme.colorsPallet.darkestShade};

  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 12px;
    white-space: wrap;
  }
`;

// FIXME: do not use "export const" with styled-components
export const EditBaseInfoIcon = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0077cc;
  white-space: nowrap;
  color: ${theme.colorsPallet.primary};
`;

// FIXME: do not use "export const" with styled-components
export const Close = styled.div`
  margin-left: auto;
  cursor: pointer;
  width: 10px;
  height: 10px;
`;

// FIXME: do not use "export const" with styled-components
export const CheckTabs = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  > * {
    width: 100%;
  }
`;

// FIXME: do not use "export const" with styled-components
export const ModalWrapper = styled.div`
  overflow-y: scroll;
  max-height: 564px;
  padding: 20px;

  @media (max-width: ${BREAKPOINTS.SP}) {
    max-height: none;
    height: 100%;
  }
`;

// FIXME: do not use "export const" with styled-components
export const ModalHeader = styled(Heading3)`
  padding-bottom: 24px;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  color: #1a1c21;
`;

// FIXME: do not use "export const" with styled-components
export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;

// FIXME: do not use "export const" with styled-components
export const EditModalFormLabel = styled.label`
  color: #343741;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-top: 14px;
  margin-bottom: 4px;
  display: inline-block;
`;

// FIXME: do not use "export const" with styled-components
export const ChildFormContainer = styled.div``;

// FIXME: do not use "export const" with styled-components
export const ChildFormLabel = styled(EditModalFormLabel)`
  font-size: 16px;
  color: #343741;
  margin-top: 0;
`;

// FIXME: do not use "export const" with styled-components
export const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  ::placeholder {
    color: #aab4c4;
  }
  &:disabled {
    opacity: 0.6;
  }
  -webkit-appearance: none;
`;

// FIXME: do not use "export const" with styled-components
export const TextAreaField = styled.textarea`
  width: 100%;
  height: 96px;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  ::placeholder {
    color: #aab4c4;
  }
  &:disabled {
    opacity: 0.6;
  }
`;

// FIXME: do not use "export const" with styled-components
export const BlueTextAreaField = styled.textarea`
  width: 100%;
  height: 96px;
  padding: 10px 12px;
  background: #e5f4ff;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  ::placeholder {
    color: #aab4c4;
  }
  &:disabled {
    opacity: 0.6;
  }
`;

// FIXME: do not use "export const" with styled-components
export const SelectField = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
`;

// FIXME: do not use "export const" with styled-components
export const DivCenterWrapper = styled.div`
  text-align: center;
`;

// FIXME: do not use "export const" with styled-components
export const Picture = styled.img`
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
`;

// FIXME: do not use "export const" with styled-components
export const UploadImagesContainer = styled.div`
  position: relative;
`;

// FIXME: do not use "export const" with styled-components
export const UploadImgButton = styled.button`
  background-color: gray;
  border-radius: 50%;
  border: none;
  position: absolute;
  bottom: 0;
  left: 53%;
  height: 24px;
  width: 24px;
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isCSAdmin(user: CurrentUser) {
  throw new Error("Function not implemented.");
}
