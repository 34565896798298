import React, { useEffect, useState, useMemo, useRef } from "react";
import styled from "styled-components";
import { createPost } from "../../apiClients/post";
import {
  PostType,
  reapplyPost,
  useUnApprovedPostList,
} from "../../hooks/api/post";
import * as postApi from "../../apiClients/post";
import { useCurrentUser, User } from "../../hooks/recoil/user";
import { ModalPortal, ModalPortalProps } from "../../components/Modal";
import { Heading2 } from "../../components/Typo";
import { Margin } from "../../components/Margin";
import { useToast } from "../../components/Toast";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../../components/Button";
import {
  QuillEditor,
  QuillEditorHandle,
} from "../../components/form/ContentEditor";
import { useModal } from "../../components/Modal";
import { CreateSurvey } from "./CreateSurvey/EntryPoint";
import { NoteIcon } from "../../components/icons/NoteIcon";
import { usePostUserGroupList } from "../../hooks/recoil/postUserGroup";
import { logger } from "src/utils/logger";
import {
  TargetClassesType,
  TargetGradeAndClass,
  TargetGradesType,
} from "@shared/types/post/api";
import {
  SurveyQuestions,
  SurveyQuestionsSchema,
} from "@shared/validator/features/survey.schema";
import DestinationForm, { FormValues } from "./DestinationForm/DestinationForm";
import { useGradeOptions } from "src/hooks/useGradeOptions";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { CloseIcon } from "src/components/icons/CloseIcon";
import { PdfIcon } from "src/components/icons/PdfIcon";
import { APIError } from "src/utils/types/ApiError";
import { ConfirmModal } from "./ConfirmModal";
import { CreatePostRequestSchemaType } from "@shared/validator/features/post.schema";
import { useGetApprovers } from "src/hooks/query/approvers";

const classOptions: { id: TargetClassesType; name: string }[] = [
  { id: 1, name: "1組" },
  { id: 2, name: "2組" },
  { id: 3, name: "3組" },
  { id: 4, name: "4組" },
  { id: 5, name: "5組" },
  { id: 6, name: "6組" },
  { id: 7, name: "7組" },
  { id: 8, name: "8組" },
  { id: 9, name: "9組" },
  { id: 10, name: "10組" },
  { id: 11, name: "11組" },
  { id: 12, name: "12組" },
  { id: 100, name: "その他" },
  { id: -1, name: "クラス未設定" },
];

type Props = {
  onClose: () => void;
  refetch: () => void;
};
// TBD
// TODO: ファイル送付
export const CreatePostModal = (props: Props & ModalPortalProps) => {
  const { organization } = useGetCurrentOrganization();
  const targetGradesOption = useGradeOptions(organization?.schoolType);
  const {
    setValue,
    formState: { errors },
    watch,
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      title: "",
      text: "",
      targetGrades: [],
      targetGradeAndClasses: [],
      targetBelongIds: [],
      targetPostUserGroupIds: [],
      status: "UNAPPROVED",
    },
    mode: "onChange",
  });

  const quillEditorRef = useRef<QuillEditorHandle | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const currentUser = useCurrentUser();
  const { getPosts } = useUnApprovedPostList(currentUser);
  const [errorNonExistApproverError, setErrorNonExistApproverError] =
    useState(false);
  const toast = useToast();
  const { approvers } = useGetApprovers({});
  const { postGroups } = usePostUserGroupList();

  const status = watch("status");
  const targetAll = watch("targetAll");
  const targetGrades = watch("targetGrades");
  const targetGradeAndClasses = watch("targetGradeAndClasses");
  const targetBelongIds = watch("targetBelongIds");
  const targetPostUserGroupIds = watch("targetPostUserGroupIds");
  const targetFile = watch("file");

  const belongOptions = useMemo(() => {
    return (
      organization?.belongs?.map((belong) => {
        return {
          id: belong.id,
          name: belong.name,
          order: belong.order,
        };
      }) ?? []
    );
  }, [organization]);

  const postUserGroupOptions = useMemo(() => {
    return (
      postGroups?.map((postGroup) => {
        return {
          id: postGroup.id,
          name: postGroup.name,
        };
      }) ?? []
    );
  }, [postGroups]);

  const disabled =
    submitting ||
    !(errors.constructor === Object && Object.keys(errors).length === 0);

  // onSubmitにキャプチャしてもらうためにアンケートデータをここで定義
  // NOTE: surveyQuestionsはeventHandlersを介してのみ操作すること
  const [surveyQuestions, setSurveyQuestions] = useState<
    SurveyQuestions | undefined
  >(undefined);
  const [isOnceSaved, setIsOnceSaved] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const confirmOnClose = () => {
    // 何かが入力されている場合、確認ダイアログを出す
    if (
      watch("title") !== "" ||
      watch("text") !== "" ||
      targetAll ||
      targetGrades.length > 0 ||
      targetBelongIds.length > 0 ||
      targetPostUserGroupIds.length > 0 ||
      targetFile
    ) {
      setIsConfirmModalOpen(true);
    } else {
      props.onClose();
    }
  };

  const maxFileSize = 2 * 1000 * 1000; // ファイルの上限サイズ: 2MB
  const onSubmit = async (data: FormValues) => {
    if (submitting) return;
    // 承認者がいないかつ、投稿申請の場合はエラー
    if (approvers.length < 1 && status === "UNAPPROVED") {
      return setErrorNonExistApproverError(true);
    }
    //gradeAndClassesのclassesのlengthがclassOptions.lengthと一致する場合、targetGradeに追加
    const targetGrades: TargetGradesType[] = data.targetGradeAndClasses
      .map((tgc) => {
        if (tgc.classes.length === classOptions.length) {
          return tgc.grade;
        }
      })
      .filter((item): item is TargetGradesType => item !== undefined);
    const solidGradeAndClasses = data.targetGradeAndClasses.filter(
      (item) =>
        item.classes.length > 0 && item.classes.length < classOptions.length
    ); //クラスの無い学年&全てのクラスが選択されている学年を除外する

    if (
      !data.targetAll &&
      targetGrades.length === 0 &&
      solidGradeAndClasses.length === 0 &&
      data.targetBelongIds.length === 0 &&
      data.targetPostUserGroupIds.length === 0
    ) {
      return setError("targetGrades", {});
    }

    try {
      setSubmitting(true);
      if (data.status === "UNAPPROVED" && surveyQuestions) {
        try {
          SurveyQuestionsSchema.parse(surveyQuestions);
          surveyQuestions.state = "CREATED";
        } catch (error) {
          toast.error(
            "アンケートの内容に不備があります。\n修正してから再度投稿申請してください"
          );
          setIsOnceSaved(true);
          setIsSurveyQuestionsModal(true);
          return;
        }
      }

      const filePath = targetFile
        ? await postApi.uploadPdf({
            pdfFile: targetFile!,
          })
        : "";

      const arg: CreatePostRequestSchemaType = {
        organizationId: currentUser.account?.organizationId ?? "",
        userId: currentUser.id,
        title: data.title,
        text: data.text,
        file: filePath,
        fileName: targetFile?.name ?? "",
        targetAllTag: data.targetAll,
        targetGradeTags: data.targetAll ? [] : targetGrades,
        targetGradeAndClasses: data.targetAll ? [] : solidGradeAndClasses,
        targetBelongIdTags: data.targetAll ? [] : data.targetBelongIds,
        targetPostUserGroupIdTags: data.targetAll
          ? []
          : data.targetPostUserGroupIds,
        status: data.status,
        surveyQuestions: surveyQuestions,
      };

      // 連絡投稿
      await createPost({ args: arg });
      // 最終的にコンテンツに残さなかった画像は削除
      // 削除は最悪失敗しても大きな影響はないので非同期で実行し、エラーは無視する
      quillEditorRef.current?.deleteImages();
      await getPosts(); // 不要かも
      await props.refetch();
      props.onClose();
      // 妥協でリロードにしている
      // TODO: 親で状態更新検知して差分更新できない？(SPAっぽくしたい)
      if (status === "DRAFT") {
        toast.success("下書き保存しました");
      } else {
        toast.success("投稿申請が完了しました");
      }
    } catch (err) {
      logger.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (targetGrades.length > 0) {
      clearErrors("targetGrades");
    }
  }, [targetGrades]);

  useEffect(() => {
    if (targetAll) {
      clearErrors("targetGrades");
    }
  }, [targetAll]);

  useEffect(() => {
    if (!targetFile) return;
    if (targetFile.size > maxFileSize) {
      setError("file", {
        type: "custom",
        message:
          "ファイルが2MBを超えています。容量を軽くしてアップロードしてください",
      });
    } else {
      clearErrors("file");
    }
  }, [targetFile]);
  const [isSurveyQuestionsModal, setIsSurveyQuestionsModal] = useState(false);
  return (
    <>
      <ModalPortal
        onClose={() => confirmOnClose()}
        onClickOverlay={confirmOnClose}
        modalBoxIsHeightFull={true}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalTop>
            <ModalHeader>新規作成</ModalHeader>
            {errorNonExistApproverError && (
              <DangerText>
                新規投稿をする前に、承認者を設定してください。承認者は承認者の設定から複数人、設定できます。
              </DangerText>
            )}

            <ModalLabel>宛先</ModalLabel>
            <Margin marginBottom={5} />
            <DestinationForm
              targetGrades={targetGradesOption}
              targetBelongs={belongOptions}
              targetPostUserGroups={postUserGroupOptions}
              selectedIsAll={targetAll}
              selectedGradeAndClasses={targetGradeAndClasses}
              selectedBelongIds={targetBelongIds}
              selectedPostUserGroupIds={targetPostUserGroupIds}
              setValue={setValue}
              classOptions={classOptions}
            />
            {errors.targetGrades && (
              <DangerText>宛先を選択してください</DangerText>
            )}
            <Margin marginTop={15} />

            <ModalLabel>件名</ModalLabel>
            <Margin marginBottom={5} />
            <InputTitle
              type="text"
              value={watch("title")}
              placeholder="入力してください（1000文字以内）"
              {...register("title", {
                required: "入力してください",
                maxLength: {
                  value: 1000,
                  message: "1000文字以内で入力してください",
                },
              })}
            />
            {errors.title && <DangerText>{errors.title.message}</DangerText>}
            <Margin marginTop={15} />

            <ModalLabel>投稿内容</ModalLabel>
            <Margin marginBottom={5} />
            <Controller
              name="text"
              control={control}
              render={({ field }) => (
                <QuillEditor
                  initialValue={field.value}
                  onChange={field.onChange}
                  ref={quillEditorRef}
                  imageUploadDirectory="post"
                />
              )}
            />
            {errors.text && <DangerText>{errors.text.message}</DangerText>}

            <Margin marginTop={10} />
            <PdfSurveyContainer>
              <Pdf>
                {!targetFile ? (
                  <TextButton type="button">
                    <FileIcon />
                    <label>
                      ファイルを添付
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(event) => {
                          event.target.files &&
                            setValue("file", event.target.files[0]);
                        }}
                      />
                    </label>
                  </TextButton>
                ) : (
                  <>
                    <PdfPreview>
                      <PdfIconWrapper>
                        <PdfIcon size={16} />
                      </PdfIconWrapper>
                      <PdfLabel target="blank">{targetFile.name}</PdfLabel>
                      <PdfRemoveButton
                        type="button"
                        onClick={() => {
                          setValue("file", undefined);
                          clearErrors("file");
                        }}
                      >
                        <CloseIcon color="#343741" />
                      </PdfRemoveButton>
                    </PdfPreview>
                  </>
                )}
                {errors.file && <DangerText>{errors.file.message}</DangerText>}
              </Pdf>
              <SurveyWrapper
                user={currentUser}
                surveyQuestions={surveyQuestions}
                setSurveyQuestions={setSurveyQuestions}
                isOnceSaved={isOnceSaved}
                setIsOnceSaved={setIsOnceSaved}
                isSurveyQuestionsModal={isSurveyQuestionsModal}
                setIsSurveyQuestionsModal={setIsSurveyQuestionsModal}
              />
            </PdfSurveyContainer>

            <Margin marginTop={10} />
            <ModalFooter>
              <Button
                empty
                color="primary"
                size="large"
                type="button"
                onClick={confirmOnClose}
              >
                キャンセル
              </Button>
              <Button
                fill
                color="dark"
                size="large"
                type="submit"
                disabled={disabled}
                onClick={() => setValue("status", "DRAFT")}
              >
                下書き保存
              </Button>
              <Button
                fill
                color="primary"
                size="large"
                type="submit"
                disabled={disabled}
                onClick={() => setValue("status", "UNAPPROVED")}
              >
                投稿申請
              </Button>
            </ModalFooter>
          </ModalTop>
        </form>
      </ModalPortal>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => {
          props.onClose();
        }}
        title="連絡を破棄"
        message="入力した内容が破棄されますがよろしいですか？"
        confirmText="破棄する"
        cancelText="キャンセル"
      />
    </>
  );
};

export const DangerText = styled.div`
  color: #bd271e;
  font-size: 14px;
`;

export const PdfWrapper = styled.div`
  color: #005ec4;
  font-size: 14px;
  label {
    margin-top: -20px;
    max-height: 20px !important;
  }
  label span {
    padding-bottom: -16px !important;
    max-height: 5px !important;
    visibility: hidden;
  }
`;
export const ModalHeader = styled(Heading2)`
  padding-bottom: 24px;
  display: flex;
`;

export const Close = styled.div`
  margin-left: auto;
  cursor: pointer;
  width: 10px;
  height: 10px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const RePostModal = ({
  post,
  onClose,
  title,
  refetch,
}: {
  post: PostType;
  onClose: () => void;
  title: "下書き編集" | "再編集";
  refetch: () => void;
} & ModalPortalProps) => {
  const tmpText = post.text.split("\\n").map((item) => {
    return item + "\n";
  });
  const quillEditorRef = useRef<QuillEditorHandle | null>(null);
  const currentUser = useCurrentUser();
  const { organization } = useGetCurrentOrganization();
  const targetGradesOption = useGradeOptions(organization?.schoolType);
  const { postGroups } = usePostUserGroupList();
  const { approvers } = useGetApprovers({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  // const [file, setFile] = useState<File>(); // 送付pdfファイル
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { getPosts } = useUnApprovedPostList(currentUser);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filePath, setFilePath] = useState<string>(post.fileName);
  const [errorNonExistApproverError, setErrorNonExistApproverError] =
    useState(false);
  const toast = useToast();

  const belongOptions = useMemo(() => {
    return (
      organization?.belongs?.map((belong) => {
        return {
          id: belong.id,
          name: belong.name,
          order: belong.order,
        };
      }) ?? []
    );
  }, [organization]);

  const postUserGroupOptions = useMemo(() => {
    return (
      postGroups?.map((postGroup) => {
        return {
          id: postGroup.id,
          name: postGroup.name,
        };
      }) ?? []
    );
  }, [postGroups]);

  const defaultTargetGradeAndClasses: TargetGradeAndClass[] = post.targetAllTag
    ? targetGradesOption.map((grade) => ({
        grade: grade.id,
        classes: classOptions.map((c) => c.id),
      }))
    : [
        ...post.targetGradeTags.map((grade) => ({
          grade: grade,
          classes: classOptions.map((c) => c.id),
        })),
        ...post.targetGradeAndClasses,
      ];

  const {
    setValue,
    formState: { errors },
    watch,
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      title: post.title,
      text: tmpText.join(""),
      targetAll: post.targetAllTag,
      targetGrades: post.targetAllTag ? [] : post.targetGradeTags,
      targetGradeAndClasses: defaultTargetGradeAndClasses,
      targetBelongIds: post.targetBelongIdTags,
      targetPostUserGroupIds: post.targetPostUserGroupIdTags,
      status: "UNAPPROVED",
    },
    mode: "onChange",
  });

  const targetAll = watch("targetAll");
  const targetGrades = watch("targetGrades");
  const targetGradeAndClasses = watch("targetGradeAndClasses");
  const targetBelongIds = watch("targetBelongIds");
  const targetPostUserGroupIds = watch("targetPostUserGroupIds");
  const targetFile = watch("file");

  // onSubmitにキャプチャしてもらうためにアンケートデータをここで定義
  // NOTE: surveyQuestionsはeventHandlersを介してのみ操作すること
  const [surveyQuestions, setSurveyQuestions] = useState<
    SurveyQuestions | undefined
  >(post.surveyQuestions);
  const [isOnceSaved, setIsOnceSaved] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const maxFileSize = 2 * 1000 * 1000; // ファイルの上限サイズ: 2MB
  const onSubmit = async (data: FormValues) => {
    if (submitting) return;
    // 承認者がいないかつ、投稿申請の場合はエラー
    if (approvers.length < 1 && watch("status") === "UNAPPROVED") {
      return setErrorNonExistApproverError(true);
    }
    //gradeAndClassesのclassesのlengthがclassOptions.lengthと一致する場合、targetGradeに追加
    const targetGrades: TargetGradesType[] = data.targetGradeAndClasses
      .map((tgc) => {
        if (tgc.classes.length === classOptions.length) {
          return tgc.grade;
        }
      })
      .filter((item): item is TargetGradesType => item !== undefined);
    const solidGradeAndClasses = data.targetGradeAndClasses.filter(
      (item) =>
        item.classes.length > 0 && item.classes.length < classOptions.length
    ); //クラスの無い学年が存在する可能性があるためここで除外する
    if (
      !data.targetAll &&
      targetGrades.length === 0 &&
      solidGradeAndClasses.length === 0 &&
      data.targetBelongIds.length === 0 &&
      data.targetPostUserGroupIds.length === 0
    ) {
      return setError("targetGrades", {});
    }

    if (!(data.text.length > 0)) {
      return;
    }
    try {
      setSubmitting(true);
      if (data.status === "UNAPPROVED" && surveyQuestions) {
        try {
          SurveyQuestionsSchema.parse(surveyQuestions);
          surveyQuestions.state = "CREATED";
        } catch (error) {
          toast.error(
            "アンケートの内容に不備があります。\n修正してから再度投稿申請してください"
          );
          setIsOnceSaved(true);
          setIsSurveyQuestionsModal(true);
          return;
        }
      }
      let fp = "";
      if (targetFile != null && targetFile?.name !== post.fileName) {
        fp = await postApi.uploadPdf({ pdfFile: targetFile });
        setFilePath(fp);
      }
      const arg = {
        id: post.id,
        organizationId: post.organizationId,
        userId: post.userId,
        title: data.title,
        text: data.text,
        status: data.status,
        pdfFilePath: deletePDF
          ? ""
          : targetFile != null && targetFile?.name !== post.fileName
          ? fp
          : post.pdfFilePath,
        fileName: deletePDF
          ? ""
          : targetFile != null && targetFile?.name !== post.fileName
          ? targetFile?.name
          : post.fileName,
        targetAllTag: data.targetAll,
        targetGradeTags: data.targetAll ? [] : targetGrades,
        targetGradeAndClasses: data.targetAll ? [] : solidGradeAndClasses,
        targetBelongIdTags: data.targetAll ? [] : data.targetBelongIds,
        targetPostUserGroupIdTags: data.targetAll
          ? []
          : data.targetPostUserGroupIds,
        surveyQuestions: surveyQuestions,
      };
      // 連絡投稿
      await reapplyPost(arg);
      // 最終的にコンテンツに残さなかった画像は削除
      // 削除は最悪失敗しても大きな影響はないので非同期で実行し、エラーは無視する
      quillEditorRef.current?.deleteImages();
      await getPosts();
      // setRole(updatedUser.role);
      onClose();
      // 妥協でリロードにしている
      await refetch();
      if (data.status === "DRAFT") {
        toast.success("下書き保存しました");
      } else {
        toast.success("投稿申請が完了しました");
      }
    } catch (err) {
      logger.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const disabled =
    submitting ||
    !(errors.constructor === Object && Object.keys(errors).length === 0);

  const confirmOnClose = () => {
    // 何かが入力されている場合、確認ダイアログを出す
    if (
      watch("title") !== "" ||
      watch("text") !== "" ||
      targetAll ||
      targetGrades.length > 0 ||
      targetBelongIds.length > 0 ||
      targetPostUserGroupIds.length > 0 ||
      targetFile
    ) {
      setIsConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (targetGrades.length > 0) {
      clearErrors("targetGrades");
    }
  }, [targetGrades]);

  useEffect(() => {
    if (targetAll) {
      clearErrors("targetGrades");
    }
  }, [targetAll]);

  useEffect(() => {
    if (!targetFile) return;
    if (targetFile.size > maxFileSize) {
      setError("file", {
        type: "custom",
        message:
          "ファイルが2MBを超えています。容量を軽くしてアップロードしてください",
      });
    } else {
      clearErrors("file");
    }
  }, [targetFile]);

  const [fileName, setFileName] = useState<string>(post.fileName);
  useEffect(() => {
    setFileName(targetFile?.name ?? post.fileName);
  }, [targetFile?.name, post.fileName]);
  const [deletePDF, setDeletePDF] = useState<boolean>(false);
  const [isSurveyQuestionsModal, setIsSurveyQuestionsModal] = useState(false);
  return (
    <>
      <ModalPortal
        onClose={() => confirmOnClose()}
        onClickOverlay={confirmOnClose}
        modalBoxIsHeightFull={true}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalTop>
            <ModalHeader>{title}</ModalHeader>
            {errorNonExistApproverError && (
              <DangerText>
                新規投稿をする前に、承認者を設定してください。承認者は承認者の設定から複数人、設定できます。
              </DangerText>
            )}

            <ModalLabel>宛先</ModalLabel>
            <Margin marginBottom={5} />
            <DestinationForm
              targetGrades={targetGradesOption}
              targetBelongs={belongOptions}
              targetPostUserGroups={postUserGroupOptions}
              selectedIsAll={targetAll}
              selectedGradeAndClasses={targetGradeAndClasses}
              selectedBelongIds={targetBelongIds}
              selectedPostUserGroupIds={targetPostUserGroupIds}
              setValue={setValue}
              classOptions={classOptions}
            />
            {errors.targetGrades && (
              <DangerText>宛先を選択してください</DangerText>
            )}
            <Margin marginTop={15} />

            <ModalLabel>件名</ModalLabel>
            <Margin marginBottom={5} />
            <InputTitle
              type="text"
              value={watch("title")}
              placeholder="入力してください（1000文字以内）"
              {...register("title", {
                required: "入力してください",
                maxLength: {
                  value: 1000,
                  message: "1000文字以内で入力してください",
                },
              })}
            />
            {errors.title && <DangerText>{errors.title.message}</DangerText>}
            <Margin marginTop={15} />

            <ModalLabel>投稿内容</ModalLabel>
            <Margin marginBottom={5} />
            {errors.text && <DangerText>{errors.text.message}</DangerText>}
            <Controller
              name="text"
              control={control}
              render={({ field }) => (
                <QuillEditor
                  initialValue={field.value}
                  onChange={field.onChange}
                  ref={quillEditorRef}
                  imageUploadDirectory="post"
                />
              )}
            />

            <Margin marginTop={10} />
            <PdfSurveyContainer>
              <Pdf>
                {deletePDF || fileName.length == 0 ? (
                  <TextButton type="button">
                    <FileIcon />
                    <label>
                      ファイルを添付
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(event) => {
                          setDeletePDF(false);
                          event.target.files &&
                            setValue("file", event.target.files[0]);
                        }}
                      />
                    </label>
                  </TextButton>
                ) : (
                  <>
                    <PdfPreview>
                      <PdfIconWrapper>
                        <PdfIcon size={16} />
                      </PdfIconWrapper>
                      <PdfLabel target="blank">{fileName}</PdfLabel>
                      <PdfRemoveButton
                        type="button"
                        onClick={() => {
                          setDeletePDF(true);
                          clearErrors("file");
                        }}
                      >
                        <CloseIcon color="#343741" />
                      </PdfRemoveButton>
                    </PdfPreview>
                  </>
                )}
                {errors.file && <DangerText>{errors.file.message}</DangerText>}
              </Pdf>
              <SurveyWrapper
                user={currentUser}
                surveyQuestions={surveyQuestions}
                setSurveyQuestions={setSurveyQuestions}
                isOnceSaved={isOnceSaved}
                setIsOnceSaved={setIsOnceSaved}
                isSurveyQuestionsModal={isSurveyQuestionsModal}
                setIsSurveyQuestionsModal={setIsSurveyQuestionsModal}
              />
            </PdfSurveyContainer>

            <ModalFooter>
              <Button
                empty
                color="primary"
                size="large"
                type="button"
                onClick={confirmOnClose}
              >
                キャンセル
              </Button>
              {title === "下書き編集" && (
                <Button
                  fill
                  color="dark"
                  size="large"
                  type="submit"
                  disabled={disabled}
                  onClick={() => setValue("status", "DRAFT")}
                >
                  下書き保存
                </Button>
              )}
              <Button
                fill
                color="primary"
                size="large"
                type="submit"
                disabled={disabled}
                onClick={() => setValue("status", "UNAPPROVED")}
              >
                投稿申請
              </Button>
            </ModalFooter>
          </ModalTop>
        </form>
      </ModalPortal>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => {
          onClose();
        }}
        title="編集内容を破棄"
        message="これまでの編集内容が破棄されますがよろしいですか？"
        confirmText="閉じる"
        cancelText="キャンセル"
      />
    </>
  );
};

// アンケート追加ボタンとモーダル処理を含むcomponent
function SurveyWrapper({
  user,
  surveyQuestions,
  setSurveyQuestions,
  isOnceSaved,
  setIsOnceSaved,
  isSurveyQuestionsModal,
  setIsSurveyQuestionsModal,
}: {
  user: User;
  surveyQuestions: SurveyQuestions | undefined;
  setSurveyQuestions: (questions: SurveyQuestions | undefined) => void;
  isOnceSaved: boolean;
  setIsOnceSaved: (isOnceSaved: boolean) => void;
  isSurveyQuestionsModal: boolean;
  setIsSurveyQuestionsModal: (isSurveyQuestionsModal: boolean) => void;
}): JSX.Element {
  // ref: https://vitejs.dev/guide/env-and-mode.html#env-variables
  if (!import.meta.env.VITE_FEATURE_SURVEY) {
    return <></>;
  }
  // モーダルの状態
  const [, { show, close }] = useModal();
  // 表示内容はアンケートの編集状態によって変わる
  const textContent: string = surveyQuestions
    ? "アンケートを編集"
    : "アンケートを追加";

  return (
    <TextButton
      type="button"
      onClick={() => {
        setIsSurveyQuestionsModal(!isSurveyQuestionsModal);
        show();
      }}
    >
      <NoteIcon />
      {textContent}
      {isSurveyQuestionsModal && (
        <CreateSurvey
          isOnceSaved={isOnceSaved}
          setIsOnceSaved={setIsOnceSaved}
          user={user}
          surveyQuestions={surveyQuestions}
          setSurveyQuestions={setSurveyQuestions}
          onClose={() => {
            setIsSurveyQuestionsModal(!isSurveyQuestionsModal);
            close();
          }}
        />
      )}
    </TextButton>
  );
}

const PdfSurveyContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const InputTitle = styled.input`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
  padding: 10px 8px;
  font-size: 14px;
`;

export const InputPostContent = styled.textarea`
  width: 100%;
  height: 264px;
  font-size: 14px;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px;
`;

export const ModalTop = styled.div`
  height: auto;
`;

export const ModalLabel = styled.p`
  font-size: 12px;
  font-weight: bold;
`;

const FileIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84063 1.01877L3.04471 7.57041C2.05796 8.52169 1.91237 10.0867 2.84618 11.0865C3.79718 12.1075 5.42672 12.1921 6.48584 11.2752C6.52019 11.2455 6.55365 11.2149 6.58631 11.1835L12.2409 5.73211C12.4422 5.53807 12.4422 5.22345 12.2409 5.02938C12.0397 4.83533 11.7133 4.83533 11.512 5.02938L5.91975 10.4206C5.34778 10.972 4.41509 11.0777 3.789 10.584C3.12606 10.0647 3.02528 9.12564 3.56393 8.48653C3.59743 8.4468 3.63303 8.40874 3.67059 8.37253L10.5696 1.7215C11.576 0.751242 13.2078 0.751242 14.2143 1.7215C15.2207 2.69176 15.2207 4.26488 14.2143 5.23517L7.29978 11.9011C5.96728 13.1857 3.80318 13.3937 2.35021 12.2368C0.809618 11.0179 0.585649 8.82561 1.85009 7.34036C1.92496 7.25242 2.00421 7.16807 2.08765 7.08763L7.86734 1.51568C8.06863 1.32163 8.06863 1.00702 7.86734 0.812941C7.66606 0.618866 7.33972 0.618896 7.13841 0.812941L1.35871 6.38487C-0.452883 8.13131 -0.452914 10.9629 1.35868 12.7094L1.35871 12.7094V12.7094C3.54553 14.8176 6.56047 14.0193 8.01831 12.6138L14.9432 5.93785C16.3523 4.57947 16.3523 2.37709 14.9432 1.01874C13.5342 -0.339584 11.2497 -0.339584 9.84063 1.01877Z"
        fill="#005EC4"
      />
    </svg>
  );
};

const TextButton = styled.button`
  /* button style */
  margin-left: 4px;
  color: #005ec4;
  font-size: 14px;
  font-weight: normal;
  border-width: 0;
  background-color: inherit;
  cursor: pointer;
  width: fit-content;
  /* flex style */
  display: flex;
  align-items: center;
  gap: 5px;
  /* icon style */
  svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
  /* input style */
  input[type="file"] {
    display: none;
  }
  /* label style */
  label {
    cursor: inherit;
  }
`;

const Pdf = styled.div``;

const PdfIconWrapper = styled.div`
  width: 10px;
  display: grid;
  place-items: center;
  margin-right: 6px;
`;
const PdfPreview = styled.div`
  max-width: 258px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #ececec;
  background-color: #ffffff;
  margin-top: 4px;
  &:last-child {
    margin-right: 0;
  }
`;

const PdfLabel = styled.a`
  text-decoration: none;
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
`;

const PdfRemoveButton = styled(Button)`
  width: 16px;
  height: 16px;
  background-color: transparent;
  padding: 0;
  margin-left: 8px;
`;
