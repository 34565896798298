import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { getApplyRecruitmentList } from "src/apiClients/recruitment";
import { QueryOptions } from "src/hooks/query/QueryOptions";
import { declineApplicationBySelf } from "src/apiClients/recruitmentApplicationEvent";

export const APPLY_RECRUITMENT_QUERY_KEY = [
  "api",
  "recruitment",
  "apply",
] as const;

export const useApplyRecruitmentList = (
  status?: string,
  queryOptions: QueryOptions = {}
) => {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: [...APPLY_RECRUITMENT_QUERY_KEY, apiContext, status],
    queryFn: async () =>
      await getApplyRecruitmentList(
        status && status.length > 0 ? status : undefined
      ),
    ...queryOptions,
  });

  return {
    recruitments: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
};

export const useDeclineApplicantEvents = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: declineApplicationBySelf,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: APPLY_RECRUITMENT_QUERY_KEY,
      });
    },
  });
};
