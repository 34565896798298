import { z } from "zod";
import { zRequiredString } from "../rules/string";

export const getPTAParentFamilyInvoiceListByProductIdSchema = z.object({
  ptaInvoiceProductId: zRequiredString,
});
export type GetPTAParentFamilyInvoiceListByProductIdType = z.infer<
  typeof getPTAParentFamilyInvoiceListByProductIdSchema
>;

export const childInvoiceItemSchema = z.object({
  PTAParentFamilyId: zRequiredString,
  childId: zRequiredString,
  amount: z.number().int().positive(),
});

export const createPTAParentFamilyInvoiceSchema = z.object({
  PTAInvoiceProductId: zRequiredString,
  children: z.array(childInvoiceItemSchema).min(1),
});

export type CreatePTAParentFamilyInvoiceType = z.infer<
  typeof createPTAParentFamilyInvoiceSchema
>;
