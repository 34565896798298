import { useQuery } from "@tanstack/react-query";
import { getPTAOrganizationInvoiceAccount } from "src/apiClients/ptaOrganizationInvoiceAccount";
import { GetPTAOrganizationInvoiceAccountType } from "@shared/validator/features/ptaOrganizationInvoiceAccount.schema";

export function useGetPTAOrganizationInvoiceAccount(
  args: GetPTAOrganizationInvoiceAccountType
) {
  const query = useQuery({
    queryKey: ["api", "ptaOrganizationInvoiceAccount", args.organizationId],
    queryFn: async () => {
      if (!args.organizationId) return null;
      return await getPTAOrganizationInvoiceAccount();
    },
    enabled: Boolean(args.organizationId),
  });

  return {
    ptaOrganizationInvoiceAccount: query.data ?? undefined,
    ...query,
  };
}
