import React, { FC, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { Button, ButtonColor, ButtonGroup } from "./Button";
import { Heading3 } from "./Typo";
import * as theme from "../theme";
import { zIndexes } from "../zIndex";
import { BREAKPOINTS } from "src/components/Responsive";

const MODAL_ROOT_ID = "modal-root";

/**
 * @description modal state hook
 * @example ```
 *  const [isModalOpen, { show, close, toggle }] = useModal();
 *  show();
 *  return <div>
 *    <button onClick={show}></button>
 *    { isModalOpen && <ModalPortal onClose={close}>hello</ModalPortal> }
 *  </div>
 * ```
 */
export function useModal() {
  const [isShown, setIsShown] = useState(false);
  const show = useCallback(() => {
    setIsShown(true);
  }, []);
  const close = useCallback(() => {
    setIsShown(false);
  }, []);
  const toggle = useCallback(() => {
    setIsShown(!isShown);
  }, [isShown]);
  return [isShown, { show, close, toggle }] as const;
}

/**
 * @description modal state with props hook
 * @example ```
 *  const [modalProps, { show, close }] = useModalWithProps<{ title: string }>();
 *
 *  return <div>
 *    <button onClick={() => show({ title: 'hello' })}></button>
 *    { modalProps && <ModalPortal onClose={close}>{ modalProps.title }</ModalPortal> }
 *  </div>
 * ```
 */
export function useModalWithProps<P>() {
  const [modalProps, setModalProps] = useState<P | null>(null);
  const show = useCallback((props: P) => {
    setModalProps(props);
  }, []);
  const close = useCallback(() => {
    setModalProps(null);
  }, []);
  return [modalProps, { show, close }] as const;
}

const ModalOverlay = styled.div`
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  padding: 16px 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: ${zIndexes.modal};
  opacity: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

// NOTE: height: 100%はつけたいケースと、つけたくないケースがあるので、オプショナル引数で渡せるようにする
const ModalBox = styled.div<{ $isHeightFull?: boolean }>`
  position: relative;
  overflow: scroll;
  box-sizing: border-box;
  background-color: white;
  text-align: left;
  padding: 24px;
  max-width: 550px;
  min-width: 280px;
  width: 100%;
  max-height: min(650px, 80vh);
  margin: 16px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 32px 64px -16px rgb(0 16 14 / 31%);
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #343741;

  /* NOTE: スマホサイズの時だけこのスタイルを当てたい */
  @media (max-width: ${BREAKPOINTS.SP}) {
    height: ${(props) => props.$isHeightFull && "80%"};
  }
`;

const DeleteModalOverlay = styled(ModalOverlay)`
  background: rgba(0, 0, 0, 0.4);
`;

const DeleteModalBox = styled(ModalBox)`
  max-width: 450px;
  width: 100%;
`;

const RemoveCancelButton = styled(Button)`
  color: ${theme.colorsPallet.primary};
`;

const PromoteModalOverlay = styled(ModalOverlay)`
  background: rgba(0, 0, 0, 0.5);
`;

const PromoteModalBox = styled(ModalBox)`
  padding: 0;
  max-width: 600px;
  width: 100%;
  max-height: 440px;
  border-radius: 16px;
`;

const PromoteModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const PromoteModalImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%343741" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>')
    no-repeat center center;
  width: 24px;
  height: 24px;
  margin-top: 10px;
  margin-right: 10px;
`;

export type ModalPortalProps = {
  onClose?: () => void;
  onClickOverlay?: () => void;
  modalBoxIsHeightFull?: boolean;
  children?: React.ReactNode;
};
/**
 * @example ```
 * <ModalPortal onClose={onClose}>
 *   <ModalHeader onClose={onClose}>Title</ModalHeader>
 *   <ModalBody>
 *     Body
 *   </ModalBody>
 * </ModalPortal>
 * ```
 */
export const ModalPortal: FC<ModalPortalProps> = ({
  onClose,
  onClickOverlay,
  children,
  modalBoxIsHeightFull = false,
}) => {
  const modalRoot = document.getElementById(MODAL_ROOT_ID);
  const onClickOverlayDefault = useCallback(
    (event: React.MouseEvent) => {
      onClose?.();
      event.stopPropagation();
      event.preventDefault();
    },
    [onClose]
  );

  const onClickModalBox = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  if (!modalRoot) return <></>;
  return createPortal(
    <ModalOverlay onClick={onClickOverlay ?? onClickOverlayDefault}>
      <ModalBox onClick={onClickModalBox} $isHeightFull={modalBoxIsHeightFull}>
        <CloseButton onClick={onClose} />
        {children}
      </ModalBox>
    </ModalOverlay>,
    modalRoot
  );
};

// 削除モーダル用
export const DeleteModalPortal: FC<ModalPortalProps> = ({
  onClose,
  children,
}) => {
  const modalRoot = document.getElementById(MODAL_ROOT_ID);
  const onClickOverlay = useCallback(
    (event: React.MouseEvent) => {
      onClose?.();
      event.stopPropagation();
      event.preventDefault();
    },
    [onClose]
  );

  const onClickModalBox = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  if (!modalRoot) return <></>;
  return createPortal(
    <DeleteModalOverlay onClick={onClickOverlay}>
      <DeleteModalBox onClick={onClickModalBox}>
        <CloseButton onClick={onClose} />
        {children}
      </DeleteModalBox>
    </DeleteModalOverlay>,
    modalRoot
  );
};

type PromoteModalPortalProps = ModalPortalProps & {
  onClickImg: () => void;
  imageUrl: string;
};
export const PromoteModalPortal: FC<PromoteModalPortalProps> = ({
  onClose,
  onClickImg,
  onClickOverlay,
  children,
  imageUrl,
  modalBoxIsHeightFull = false,
}) => {
  const modalRoot = document.getElementById(MODAL_ROOT_ID);
  const onClickOverlayDefault = useCallback(
    (event: React.MouseEvent) => {
      onClose?.();
      event.stopPropagation();
      event.preventDefault();
    },
    [onClose]
  );

  const onClickModalBox = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  if (!modalRoot) return <></>;
  return createPortal(
    <PromoteModalOverlay onClick={onClickOverlay ?? onClickOverlayDefault}>
      <PromoteModalBox
        onClick={onClickModalBox}
        $isHeightFull={modalBoxIsHeightFull}
      >
        <PromoteModalContainer>
          <PromoteModalImage src={imageUrl} onClick={onClickImg} />
        </PromoteModalContainer>
        <CloseButton onClick={onClose} />
        {children}
      </PromoteModalBox>
    </PromoteModalOverlay>,
    modalRoot
  );
};

export const ModalRoot = () => {
  return <div id={MODAL_ROOT_ID} />;
};

export const ModalHeader = styled(Heading3)`
  padding-bottom: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  color: #1a1c21;
`;
export const ModalHeaderClose = styled.div``;
export const ModalBody = styled.div``;

export const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
export type ModalSubmitButtonsProps = {
  submitColor?: ButtonColor;
  cancelColor?: ButtonColor;
  submitText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
};
export const ModalSubmitButtons = (props: ModalSubmitButtonsProps) => {
  return (
    <AlignRight>
      <ButtonGroup>
        {props.onCancel && (
          <Button
            color={props.cancelColor || "primary"}
            empty
            size="large"
            onClick={props.onCancel}
          >
            {props.cancelText || "キャンセル"}
          </Button>
        )}
        <Button
          color={props.submitColor || "primary"}
          fill
          size="large"
          onClick={props.onSubmit}
          style={{ marginLeft: "8px" }}
          disabled={props.disabled}
          type={props.type}
        >
          {props.submitText || "保存"}
        </Button>
      </ButtonGroup>
    </AlignRight>
  );
};

export const ModalRemoveButtons = (props: ModalSubmitButtonsProps) => {
  return (
    <AlignRight>
      <ButtonGroup>
        <RemoveCancelButton
          color="primary"
          empty
          size="large"
          onClick={props.onCancel}
        >
          {props.cancelText || "キャンセル"}
        </RemoveCancelButton>
        <Button
          color={props.submitColor || "primary"}
          fill
          size="large"
          type="button"
          onClick={props.onSubmit}
          style={{ marginLeft: "8px" }}
          disabled={props.disabled}
        >
          {props.submitText || "削除"}
        </Button>
      </ButtonGroup>
    </AlignRight>
  );
};

export type ModalThreeButtonsProps = {
  submitPrimaryColor?: ButtonColor;
  submitPrimaryText?: string;
  onPrimarySubmit?: () => void;
  submitSecondColor?: ButtonColor;
  submitSecondText?: string;
  onSecondSubmit?: () => void;
  cancelText?: string;
  onCancel?: () => void;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
};
export const ModalThreeButtons = (props: ModalThreeButtonsProps) => {
  return (
    <AlignRight>
      <ButtonGroup>
        <Button color="primary" empty size="large" onClick={props.onCancel}>
          {props.cancelText || "キャンセル"}
        </Button>
        <Button
          color={props.submitSecondColor || "dark"}
          fill
          size="large"
          onClick={props.onSecondSubmit}
          style={{ marginLeft: "8px" }}
          disabled={props.disabled}
          type={props.type}
        >
          {props.submitSecondText || "保存"}
        </Button>
        <Button
          color={props.submitPrimaryColor || "primary"}
          fill
          size="large"
          onClick={props.onPrimarySubmit}
          style={{ marginLeft: "8px" }}
          disabled={props.disabled}
          type={props.type}
        >
          {props.submitPrimaryText || "保存"}
        </Button>
      </ButtonGroup>
    </AlignRight>
  );
};

const StyledTextCenter = styled.div`
  text-align: center;
`;
type ErrorModalProps = {
  onClick: () => void;
  children: React.ReactNode;
};
export const ErrorModal: FC<ErrorModalProps> = ({ children, onClick }) => {
  return (
    <ModalPortal>
      <ModalHeader style={{ color: "#FF4F02", fontSize: "18px" }}>
        <StyledTextCenter>{children}</StyledTextCenter>
      </ModalHeader>
      <Button color="primary" empty size="large" onClick={onClick}>
        閉じる
      </Button>
    </ModalPortal>
  );
};

/**
 * 連絡承認時のメール送信中のsubmittingモーダル
 */
export const SubmittingModal = () => {
  return (
    <ModalOverlay>
      <ModalBox>
        <SubmittingIcon></SubmittingIcon>
        <SubmittingDescription>投稿を送信中です</SubmittingDescription>
        <Warning>
          ※ メール送信中はタブを閉じないでください。
          <br />
          送信中に閉じるとメール送信が中断されます。
        </Warning>
      </ModalBox>
    </ModalOverlay>
  );
};

const SubmittingIcon = styled.div`
  padding-top: 10%;
  text-align: center;
  svg {
    width: 10%;
  }
`;
const SubmittingDescription = styled.div`
  text-align: center;
  padding-bottom: 10%;
`;
const Warning = styled.p`
  text-align: center;
  text-align: center;
  font-size: 12px;
`;

/**
 * CSVダウンロード中のモーダル
 */
export const CSVLoadingModal = () => {
  return (
    <ModalOverlay>
      <ModalBox>CSVを生成しています...</ModalBox>
    </ModalOverlay>
  );
};
