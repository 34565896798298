import React from "react";
import { useForm, useWatch } from "react-hook-form";
import styled from "styled-components";
import {
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../components/Modal";
import { Margin } from "../../components/Margin";
import { Organization } from "../../apiClients/organization";
import { UpdateOrganization } from "../../hooks/recoil/organization";
import { CheckBox } from "src/components/form/CheckBox";

type Props = {
  organization: Organization;
  updateOrganization: (organization: UpdateOrganization) => Promise<void>;
  refetch: () => Promise<void>;
  onClose: () => void;
};

export const ShowAllPostToTeacherModal = ({
  organization,
  updateOrganization,
  refetch,
  onClose,
}: Props) => {
  type FormType = {
    showAllPostToTeacher: boolean;
  };

  const { handleSubmit, setValue, control } = useForm<FormType>({
    defaultValues: {
      showAllPostToTeacher: organization.showAllPostToTeacher,
    },
  });

  const showAllPostToTeacher = useWatch({
    control,
    name: "showAllPostToTeacher",
  });

  const onSubmit = async (data: FormType) => {
    await updateOrganization({
      showAllPostToTeacher: data.showAllPostToTeacher,
    });
    await refetch();
    onClose();
  };

  return (
    <ModalPortal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <p>教員向け通知設定</p>
        </ModalHeader>
        <ModalBody>
          <p>教員に対する連絡事項の表示と通知方法を設定できます。</p>
          <p>
            この設定を有効にすると、新しい連絡が投稿された際に教員にメールが自動送信されます。
          </p>
          <Margin marginBottom={10} />
          <CheckBox
            label="すべての連絡を教員に表示する"
            checked={showAllPostToTeacher}
            onChange={() => {
              setValue("showAllPostToTeacher", !showAllPostToTeacher);
            }}
          />
        </ModalBody>
        <ModalSubmitButtons submitText="設定" onCancel={onClose} />
      </form>
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  height: fit-content;
`;
