import React, { useCallback, useState } from "react";
import { NonPostElement } from "../PTAPersonalPage";
import { PostHeader } from "src/features/Post/PostHeader";
import { Posts } from "./PostPage";
import { PostNavigation } from "src/features/Post/Post";
import { PostCard } from "src/features/Post/PostCard";
import styled from "styled-components";
import { Spinner } from "src/components/icons/Spinner";
import { useOrganization } from "src/hooks/recoil/organization";
import { usePostUserGroupList } from "src/hooks/recoil/postUserGroup";
import { TargetGradesType } from "@shared/types/post/api";
import { useRemandedPostList } from "src/hooks/api/post";

/**
 * 連絡ページ(差し戻し)
 * 差し戻しされた投稿を一覧表示するページ
 */
export function PostRemandedPage() {
  const { organization, writeOrganization } = useOrganization({});
  const { getPostUserGroupLabel } = usePostUserGroupList();

  const { posts, isLoading, getPosts } = useRemandedPostList();

  const getPostBelongLabel = useCallback(
    (target: TargetGradesType | string) => {
      return (
        organization?.belongs?.find((belong) => belong.id === target)?.name ??
        "削除済み"
      );
    },
    [organization]
  );

  const [openPostId, setOpenPostId] = useState("");

  return (
    <>
      <PostHeader
        organization={organization}
        updateOrganization={writeOrganization}
        refetch={getPosts}
      />
      <PostNavigation />
      <Posts>
        {posts.length === 0 && isLoading && (
          <Card>
            <Spinner />
          </Card>
        )}
        {posts.length !== 0
          ? posts.map((post, i) => (
              <div
                key={post.id}
                onClick={() => {
                  setOpenPostId(openPostId !== post.id ? post.id : "");
                }}
              >
                <PostCard
                  post={post}
                  isOpen={openPostId === post.id}
                  type="remanded"
                  getPostBelongLabel={getPostBelongLabel}
                  getPostUserGroupLabel={getPostUserGroupLabel}
                  refetch={getPosts}
                  borderBottom={i !== posts.length - 1}
                />
              </div>
            ))
          : !isLoading && (
              <NonPostElement
                title="差し戻しされた連絡はありません"
                message=""
              />
            )}
      </Posts>
    </>
  );
}

const Card = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
