import { z } from "zod";
import { zRequiredString } from "../rules";

export const getMailEventsByRecordIdSchema = z.object({
  recordId: zRequiredString,
});

export type GetMailEventsByRecordIdSchemaType = z.infer<
  typeof getMailEventsByRecordIdSchema
>;
