import { createRoute } from "..";
import AppDefaultLayout from "../../layouts/AppDefaultLayout";
import EmptyLayout from "../../layouts/EmptyLayout";
import PTAPersonalPage from "../../pages/pta/PTAPersonalPage";
import PTAOrganizationSettingPage from "../../pages/pta/admin/PTAOrganizationSettingPage";
import LinkListPage from "../../pages/pta/link/LinkListPage";
import PostMePage from "../../pages/pta/post/PostMePage";
import PostPage from "../../pages/pta/post/PostPage";
import PostUnApprovedPage from "../../pages/pta/post/PostUnApprovedPage";
import PostUndeliveredPage from "../../pages/pta/post/PostUndeliveredPage";
import DisabledMembersPage from "../../pages/pta/list/DisabledMembersPage";
import LoginWithResetEmailCompleteMemberPage from "../../pages/registration/pta/LoginWithResetEmailCompleteMemberPage";
import SendResetPasswordMailCompletePage from "../../pages/registration/pta/SendResetPasswordMailCompletePage";
import SignUpCompleteMemberPage from "../../pages/registration/pta/SignUpCompleteMemberPage";
import GradeSizeSettingPage from "../../pages/pta/admin/GradeSizeSettingPage";
import { PostRemandedPage } from "src/pages/pta/post/PostRemandedPage";
import { PTAInvoiceProductListPage } from "src/pages/pta/invoice/list/PTAInvoiceProductList.page";
import { PTAInvoiceProcessPage } from "src/pages/pta/invoice/process";
import { PTAInvoiceProductPage } from "src/pages/pta/invoice/detail/PTAInvoiceProduct.page";
/**
 * 認証済みユーザーだけが通れるルート(PTA)
 */
export const PTA_ROUTE_DEFS = {
  SIGNUP_COMPLETE_MEMBER: createRoute({
    path: "/signup_complete_member",
    title: "",
    component: SignUpCompleteMemberPage,
    layout: EmptyLayout,
    route: "PTA",
  }),
  LOGIN_WITH_RESET_EMAIL_COMPLETE_MEMBER: createRoute({
    path: "/login_with_reset_email_complete_member",
    title: "",
    component: LoginWithResetEmailCompleteMemberPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  SEND_RESET_PASSWORD_MAIL_COMPLETE: createRoute({
    path: "/send_reset_password_mail_complete",
    title: "",
    component: SendResetPasswordMailCompletePage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  PTA_INDEX: createRoute({
    path: "/pta",
    title: "ホーム",
    component: PTAPersonalPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  POST_PAGE: createRoute({
    path: "/pta/post",
    title: "連絡",
    component: PostPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  POST_ME_PAGE: createRoute({
    path: "/pta/post/me",
    title: "連絡",
    component: PostMePage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  POST_UNAPPROVED_PAGE: createRoute({
    path: "/pta/post/unapproved",
    title: "連絡",
    component: PostUnApprovedPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  POST_UNDELIVERED_PAGE: createRoute({
    path: "/pta/post/undelivered",
    title: "連絡",
    component: PostUndeliveredPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  POST_REMANDED_PAGE: createRoute({
    path: "/pta/post/remanded",
    title: "連絡",
    component: PostRemandedPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  PTA_DISABLED_MEMBERS: createRoute({
    path: "/pta/disabled_members",
    title: "",
    component: DisabledMembersPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  PTA_LINK_LIST: createRoute({
    path: "/pta/link/list",
    title: "リンク",
    component: LinkListPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  PTA_COMMUNITY_SETTING: createRoute({
    path: "/pta/community_setting",
    title: "",
    component: PTAOrganizationSettingPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  PTA_GRADE_SIZE_SETTING: createRoute({
    path: "/pta/grade_size_setting",
    title: "",
    component: GradeSizeSettingPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  PTA_INVOICE_LIST: createRoute({
    path: "/pta/invoice/list",
    title: "",
    component: PTAInvoiceProductListPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  PTA_INVOICE_DETAIL: createRoute<{ id: string }>({
    path: "/pta/invoice/detail",
    title: "",
    component: PTAInvoiceProductPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
  PTA_INVOICE_PROCESS: createRoute<{ id: string; step?: string }>({
    path: "/pta/invoice/process",
    title: "",
    component: PTAInvoiceProcessPage,
    layout: AppDefaultLayout,
    route: "PTA",
  }),
} as const;

export const ptaRouters = Object.values(PTA_ROUTE_DEFS);
