import React from "react";
import { Header } from "src/components/Header";
import { usePolyfitLocationQuery } from "src/hooks/router";
import styled from "styled-components";
import { PTAInvoiceProcessImportPage } from "./PTAInvoiceProcessImportPage";
import { PTAInvoiceProcessDownloadPage } from "./PTAInvoiceProcessDownloadPage";

export const PTAInvoiceProcessPage = () => {
  const { step } = usePolyfitLocationQuery("PTA_INVOICE_PROCESS", {
    id: "",
    step: "1",
  });
  return (
    <div>
      <Header title="請求プロセス" />
      <Body>
        {step === "1" && <PTAInvoiceProcessDownloadPage />}
        {step === "2" && <PTAInvoiceProcessImportPage />}
      </Body>
    </div>
  );
};

const Body = styled.div`
  background-color: #fff;
`;
