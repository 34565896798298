import React, { useMemo } from "react";
import { PdfViewer } from "../../../components/PdfViewer";
import { entityUrl } from "../../../apiClients/storage";
import { usePolyfitHistory } from "../../../hooks/router";
import styled from "styled-components";
import { Margin } from "../../../components/Margin";
import {
  MAX_STEP_NUMBER,
  StepMember,
} from "../../../components/icons/StepMember";
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { RegistrationFooter } from "../../../components/RegistrationFooter";
import { useCustomReactHookForm } from "../../../components/form/ReactHookForm";
import { useSignatureTemplate } from "src/hooks/query/signatureTemplate";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useAccountTypeOfPTAParentFamily } from "src/hooks/query/ptaParentFamily";
import { ACCOUNT_TYPE_OF_PTA_PARENT_FAMILY } from "@shared/types/ptaParentFamily";

export default function SignatureConfirmPage() {
  const history = usePolyfitHistory();
  const currentUser = useCurrentUser();

  const { accountTypeOfPTAParentFamily } = useAccountTypeOfPTAParentFamily(
    currentUser.account?.id
  );
  const isSubAccountOfPTAParentFamily =
    accountTypeOfPTAParentFamily === ACCOUNT_TYPE_OF_PTA_PARENT_FAMILY.sub;

  const { Form } = useCustomReactHookForm<{}>();
  const { query } = useSignatureTemplate({
    type: "PTA",
  });
  const signatureTemplate = useMemo(() => query.data, [query.data]);

  const onSubmit = async () => {
    history.push({ to: "SIGNUP_PREVIEW_MEMBER" });
  };

  const StepComponent: JSX.Element = (() => {
    if (isSubAccountOfPTAParentFamily) {
      return (
        <StepMember
          stepNum={3}
          maxNum={MAX_STEP_NUMBER.subAccountForPTAParentFamily}
        />
      );
    } else {
      return <StepMember stepNum={4} maxNum={MAX_STEP_NUMBER.default} />;
    }
  })();

  return (
    <div>
      <Margin marginTop={20} />
      {StepComponent}
      <Form onSubmit={onSubmit}>
        <StyledContainer>
          <Margin marginTop={20} />
          <Typo.Heading3>入会規約の確認</Typo.Heading3>
          <Responsive.Row>
            <Responsive.Col>
              <Margin marginTop={20} />
              <Text>PTAの目的や運営ルールなどが規約に記載されています</Text>
              <Margin marginTop={20} />
              {signatureTemplate && !query.isLoading && (
                <PdfViewer
                  width="100%"
                  pageWidth="calc(100%)"
                  height="600px"
                  file={entityUrl(signatureTemplate.pdfFilePath)}
                />
              )}
            </Responsive.Col>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter></RegistrationFooter>
      </Form>
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding-bottom: 88px;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;
const Text = styled(Typo.Paragraph)`
  color: gray;
`;
