import { CSChild } from "@shared/types/csChild";
import * as client from "./client";
import {
  CsChildUpdateQueryType,
  csChildUpdateQuerySchema,
  getChildrenByAccountIdSchema,
  GetChildrenByAccountIdSchemaType,
  deleteChildrenSchema,
  DeleteChildrenSchemaType,
} from "@shared/validator/features/csChild.schema";

export async function getChildrenByAccountId(accountId: string) {
  const args = getChildrenByAccountIdSchema.parse({ accountId });
  return await client.get<GetChildrenByAccountIdSchemaType, CSChild[]>(
    "/csChild/getChildrenByAccountId",
    args
  );
}

export async function updateChildren(data: CsChildUpdateQueryType) {
  csChildUpdateQuerySchema.parse(data);
  return await client.post<CsChildUpdateQueryType, CSChild[]>(
    "/csChild/updateChildren",
    data
  );
}

export async function deleteChildren(accountId: string) {
  const args = deleteChildrenSchema.parse({ accountId });
  return await client.post<DeleteChildrenSchemaType, {}>(
    "/csChild/deleteChildren",
    args
  );
}

export async function getChildrenByOrganizationId() {
  return await client.get<{}, CSChild[]>(
    "/csChild/getChildrenByOrganizationId",
    {}
  );
}
