import React, { JSX } from "react";
import { OpenEndedAnswerSchemaType as OpenEndedAnswer } from "@shared/validator/features/surveyAnswers.schema";
import { EventHandlers } from "./hooks";
import { AnswerContainer } from "./AnswerField";
import { Label } from "../../../components/form/Label";
import styled from "styled-components";
import ReactTextareaAutosize from "react-textarea-autosize";

type Props = {
  answer: OpenEndedAnswer;
  eventHandlers: EventHandlers;
};

// TODO: 半角想定?
const maxLength: number = 3000;

export function OpenEndedAnswerField({
  answer,
  eventHandlers,
}: Props): JSX.Element {
  return (
    <AnswerContainer>
      <Label marginBottom={0}>{answer.question.text}</Label>
      <Label size="s" marginBottom={8}>
        <span style={{ fontWeight: 400 }}>{answer.question.description}</span>
      </Label>
      <Textarea
        placeholder={"入力してください（" + maxLength + "文字以内）"}
        value={answer.text}
        maxLength={maxLength}
        onChange={(e) => {
          answer.text = e.target.value;
          eventHandlers.onEditText(answer);
        }}
      />
    </AnswerContainer>
  );
}

const Textarea = styled(ReactTextareaAutosize)`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  ::placeholder {
    font-size: 14px;
    color: #aab4c4;
  }
  &:disabled {
    opacity: 0.6;
  }
`;
