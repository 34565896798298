import React from "react";
import styled from "styled-components";
import { CommunityWithSignatureTemplate } from "src/@shared/types/organization";
import { UnionIcon } from "src/components/icons/Union";
import { useSignatureTemplatePdf } from "src/hooks/query/boardEducation/signatureTemplate/signatureTemplate";
import { getFirstValidSignatureTemplate } from "src/features/Member/Community/utils/utils";

type Props = {
  community: CommunityWithSignatureTemplate;
  disabled?: boolean;
};

export const SignatureTemplateButton = (props: Props) => {
  const signatureTemplate = getFirstValidSignatureTemplate(props.community);

  const { pdfUrl, isLoading } = useSignatureTemplatePdf(
    {
      filePath: signatureTemplate?.pdfFilePath || "",
      fileName: `${props.community.name}_参加規約.pdf`,
    },
    {
      enabled: !!signatureTemplate,
    }
  );

  if (signatureTemplate && isLoading) {
    return (
      <SignatureButton>
        <UnionIcon />
        <span>参加規約</span>
      </SignatureButton>
    );
  }

  if (!pdfUrl || props.disabled) {
    return (
      <SignatureButton $disabled={props.disabled}>
        <UnionIcon />
        <span>参加規約</span>
      </SignatureButton>
    );
  }

  return (
    <SignatureButton
      as="a"
      href={pdfUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <UnionIcon />
      <span>参加規約</span>
    </SignatureButton>
  );
};

const SignatureButton = styled.a<{ $disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  background-color: white;
  color: ${(props) => (props.$disabled ? "#aab4c4" : "#007bff")};
  border: 1px solid ${(props) => (props.$disabled ? "#ddd" : "#007bff")};
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s, border-color 0.3s;
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};

  &:hover:not(:disabled) {
    background-color: #e6f2ff;
  }

  span {
    margin-left: 4px;
  }
`;
