import { z } from "zod";
import { zRequiredString } from "../rules";

export const updateParticipantChatRoomSchema = z.object({
  chatRoomId: zRequiredString,
});

export type UpdateParticipantChatRoomSchemaType = z.infer<
  typeof updateParticipantChatRoomSchema
>;

export const approveParticipantChatRoomSchema = z.object({
  chatRoomId: zRequiredString,
});

export type ApproveParticipantChatRoomSchemaType = z.infer<
  typeof approveParticipantChatRoomSchema
>;

export const rejectParticipantChatRoomSchema = z.object({
  chatRoomId: zRequiredString,
});

export type RejectParticipantChatRoomSchemaType = z.infer<
  typeof rejectParticipantChatRoomSchema
>;

export const deleteParticipantChatRoomSchema = z.object({
  chatRoomId: zRequiredString,
});

export type DeleteParticipantChatRoomSchemaType = z.infer<
  typeof deleteParticipantChatRoomSchema
>;
