import React, { useEffect } from "react";
import { PageContainer, PageHeader, PageBody } from "../../components/Page";
import * as Typo from "../../components/Typo";
import * as Responsive from "../../components/Responsive";
import { AirPlaneIcon } from "../../components/icons/SvgIcons";
import styled from "styled-components";
import { Navigation } from "../../components/Navigation3";
import * as authApi from "../../apiClients/auth";
import { useToast } from "../../components/Toast";
import { usePolyfitHistory, usePolyfitLocationQuery } from "../../hooks/router";
import { useFirebaseUserContext } from "../../hooks/context/firebaseUser";
import { logger } from "src/utils/logger";

const EmailVerificationPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 702px;
  margin: 0 auto;
  padding-top: 124px;
  padding-bottom: 16px;
  .message {
    text-align: center;
  }
  .resend {
    text-align: center;
    margin-top: 40px;
  }
  .resend a {
    color: #0077cc;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
    padding-top: 120px;
  }
`;

export default function EmailVerification() {
  const toast = useToast();
  const history = usePolyfitHistory();
  const { firebaseUser } = useFirebaseUserContext();
  const { token } = usePolyfitLocationQuery("SIGNUP_EMAIL_VERIFICATION", {
    token: undefined,
  });

  const resend = async (event: React.MouseEvent) => {
    event.preventDefault();
    try {
      if (!token) {
        toast.error("トークンが取得できませんでした");
        logger.error("Not found token for email verification");
        return;
      }
      await authApi.resendEmailVerificationMail({
        token,
      });
      toast.success("メールを再送信しました");
    } catch (err) {
      toast.error("メールの再送信に失敗しました");
      logger.error(err);
    }
  };

  // verify済みの場合はリダイレクト
  useEffect(() => {
    if (firebaseUser?.emailVerified) {
      history.replace({
        to: "LOADING",
        query: {
          ...(token && { token }),
        },
      });
    }
  }, [firebaseUser?.emailVerified]);

  return (
    <>
      <Navigation />
      <EmailVerificationPageContainer>
        <AirPlaneIcon />
        <PageHeader>メールを送信しました</PageHeader>
        <PageBody>
          <Responsive.Row>
            <Responsive.Col>
              <Typo.Paragraph className="message">
                入力いただいたメールアドレスに新規登録のメールを送信しました。
                <br />
                記載のURLにアクセスいただき、登録を完了してください。
              </Typo.Paragraph>
            </Responsive.Col>
          </Responsive.Row>
          <Responsive.Row>
            <Responsive.Col>
              <Typo.Paragraph className="resend">
                メールが届かない場合
                <br />
                <a
                  href="https://www.polyfit.jp/support/pta/posts/Bf4PJzD1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
                をご確認の上、
                <a href="#" onClick={resend}>
                  再送信
                </a>
                をして下さい。
              </Typo.Paragraph>
            </Responsive.Col>
          </Responsive.Row>
        </PageBody>
      </EmailVerificationPageContainer>
    </>
  );
}
