import { UpdatePushNotificationSettingSchemaType } from "@shared/validator/features/pushNotificationSetting.schema";
import * as client from "./client";
import type { UpdatePushTokenSchemaType } from "@shared/validator/models/pushNotificationToken.schema";
/**
 * プッシュ通知トークンの登録・更新
 */
export async function upsertPushNotificationToken(
  token: UpdatePushTokenSchemaType
) {
  const res = await client.post<UpdatePushTokenSchemaType>(
    "/pushNotificationToken",
    token
  );
  return res;
}

/**
 * プッシュ通知設定の変更
 */

export async function updatePushNotificationSetting(
  isPushNotificationEnabled: UpdatePushNotificationSettingSchemaType
) {
  //TODO putにする
  return await client.post<UpdatePushNotificationSettingSchemaType>(
    "/pushNotificationSetting",
    isPushNotificationEnabled
  );
}

/**
 * プッシュ通知設定の取得
 */

export async function fetchPushNotification() {
  return await client.get("/pushNotificationSetting", {});
}
