import * as client from "./client";
import { InvitationBEUserList } from "../../../server/src/@shared/types/invitationBEUserList";
import {
  deleteInvitationBEUserSchema,
  DeleteInvitationBEUserSchemaType,
  getInvitationBEUsersSchema,
  GetInvitationBEUsersSchemaType,
} from "../../../server/src/@shared/validator/features/invitationBEUser.schema";

/**
 * 招待中の教育委員会ユーザーを取得する（Internal用）
 */
export async function getInvitationBEUsersByOrganizationId(
  args: GetInvitationBEUsersSchemaType
): Promise<InvitationBEUserList[]> {
  getInvitationBEUsersSchema.parse(args);
  const res = await client.get<
    GetInvitationBEUsersSchemaType,
    { invitationBEUsers: InvitationBEUserList[] }
  >("/invitationBeUser/list", args);
  return res.invitationBEUsers;
}

/**
 * 招待中の教育委員会ユーザーを削除する（Internal用）
 */
export async function deleteInvitationBEUser(
  args: DeleteInvitationBEUserSchemaType
): Promise<void> {
  deleteInvitationBEUserSchema.parse(args);
  await client.post<DeleteInvitationBEUserSchemaType>(
    "/invitationBeUser/delete",
    args
  );
}
