import * as client from "./client";
import {
  getMailEventsByRecordIdSchema,
  GetMailEventsByRecordIdSchemaType,
} from "../@shared/validator/features/mailEvent.schema";

export const getMailEventsByRecordId = async (recordId: string) => {
  const args = getMailEventsByRecordIdSchema.parse({ recordId });
  const mailEvents = await client.get<GetMailEventsByRecordIdSchemaType>(
    "/mail-event/getMailEventsByRecordId",
    args
  );
  return mailEvents;
};
