import React from "react";
import styled from "styled-components";
import { FileIcon } from "src/components/icons/FileIcon";

export const NotFoundInvoiceProductList = () => {
  return (
    <NotFoundWrapper>
      <NotFoundContents>
        <FileIcon size={240} />
        <NotFoundTextWrapper>
          <NotFoundTitle>作成された請求はありません</NotFoundTitle>
          <NotFoundDescription>
            請求を作成するとこちらの画面に表示されます
          </NotFoundDescription>
        </NotFoundTextWrapper>
      </NotFoundContents>
    </NotFoundWrapper>
  );
};

const NotFoundWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  max-height: 584px;
`;

const NotFoundContents = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 142px 0;

  @media (max-width: 700px) {
    padding: 38px 0;
  }
`;

const NotFoundTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 52px;
`;

const NotFoundTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #343741;
`;

const NotFoundDescription = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #343741;
`;
