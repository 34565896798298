import { post, get } from "./client";
import {
  LineAccountDeleteResponse,
  LineAccountResponse,
} from "@shared/types/lineAccount";
import {
  createOrUpdateLineAccountSchema,
  CreateOrUpdateLineAccountSchemaType,
} from "@shared/validator/features/lineAccount.schema";

export async function createOrUpdateLineAccount(
  data: CreateOrUpdateLineAccountSchemaType
): Promise<LineAccountResponse> {
  createOrUpdateLineAccountSchema.parse(data);
  return await post<CreateOrUpdateLineAccountSchemaType, LineAccountResponse>(
    "/lineAccount/upsert",
    data
  );
}

export async function getLineAccount(): Promise<LineAccountResponse | null> {
  return await get<{}, LineAccountResponse | null>("/lineAccount/get", {});
}

export async function deleteLineAccount(): Promise<LineAccountDeleteResponse> {
  return await post<{}, LineAccountDeleteResponse>("/lineAccount/delete", {});
}
