import { Child, ChildrenFilter } from "@shared/validator/models/child.schema";
import * as client from "./client";
import {
  getChildrenByConditionSchema,
  GetChildrenByConditionSchemaType,
  getChildrenSchema,
  GetChildrenSchemaType,
  getDisabledUsersChildrenByConditionSchema,
  GetDisabledUsersChildrenByConditionSchemaType,
  upsertAndDeleteChildrenSchema,
  UpsertAndDeleteChildrenSchemaType,
} from "../@shared/validator/features/child.schema";

export async function getChildrenByCondition(
  q?: string,
  filter?: ChildrenFilter
) {
  const filterQuery = JSON.stringify(filter);
  const args = getChildrenByConditionSchema.parse({
    q,
    filter: filterQuery,
  });
  const children = await client.get<GetChildrenByConditionSchemaType, Child[]>(
    "/child/getChildrenByCondition",
    args
  );
  return children;
}

export async function getChildren(userId: string) {
  const args = getChildrenSchema.parse({ userId });
  const children = await client.get<GetChildrenSchemaType, Child[]>(
    "/child/getChildren",
    args
  );
  return children;
}

export async function getDisabledUsersChildrenByOrganizationId(
  q?: string,
  filter?: ChildrenFilter
) {
  const filterQuery = JSON.stringify(filter);
  const args = getDisabledUsersChildrenByConditionSchema.parse({
    q,
    filter: filterQuery,
  });
  const children = await client.get<
    GetDisabledUsersChildrenByConditionSchemaType,
    Child[]
  >("/child/getDisabledUsersChildrenByCondition", args);
  return children;
}

export async function upsertAndDeleteChildren(userId: string, data: Child[]) {
  const args = upsertAndDeleteChildrenSchema.parse({ userId, data });
  return await client.post<UpsertAndDeleteChildrenSchemaType, Child[]>(
    "/child/upsertAndDeleteChildren",
    args
  );
}

export async function getChildrenCountsByGrade() {
  return await client.get<{}, { grade: number; _count: number }[]>(
    "/child/getChildrenCountsByGrade",
    {}
  );
}
