import React, { useCallback, useMemo, useState } from "react";
import { entityUrl } from "src/apiClients/storage";
import { ImgField } from "src/components/Form";
import { FilePlusIcon } from "src/components/icons/FilePlusIcon";
import { Margin } from "src/components/Margin";
import { PageBody, PageContainer } from "src/components/Page";
import { PdfViewer } from "src/components/PdfViewer";
import { TabNavigation } from "src/components/TabNavigation";
import { SubTitle, Title } from "src/components/Title";
import { useSignatureTemplate } from "src/hooks/query/signatureTemplate";
import { usePolyfitHistory } from "src/hooks/router";
import { DangerText } from "../Post/CreatePostModal";
import { Button, ButtonGroup } from "src/components/Button";
import { ErrorModal } from "src/components/Modal";

export const SignatureTemplatesCSTab = ({
  showTab,
  setTab,
}: {
  showTab: boolean;
  setTab: (tab: "pta" | "cs") => void;
}) => {
  const history = usePolyfitHistory();
  const { query, mutation } = useSignatureTemplate({ type: "RESIDENT" });
  const signatureTemplate = useMemo(() => query.data, [query.data]);

  const [file, setFile] = useState<File>();
  const [errorFile, setErrorFile] = useState(false);

  const pdfFilePathForPreview = useMemo(() => {
    if (file) return URL.createObjectURL(file);
    else if (signatureTemplate && signatureTemplate.pdfFilePath)
      return entityUrl(signatureTemplate.pdfFilePath);
    else return null;
  }, [file, signatureTemplate]);

  const isValid = useMemo(() => {
    if (!signatureTemplate) {
      return file;
    } else if (file) {
      return file;
    }
    return true;
  }, [file, signatureTemplate]);

  const onSubmit = useCallback(async () => {
    // ファイルのサイズが30mb超えてる場合
    if (file?.size != null && file?.size > 30 * 1024 * 1024) {
      console.error(
        "ファイルが30MBを超えています。容量を軽くしてアップロードしてください",
        file.size
      );
      setErrorFile(true);
      return;
    }
    if (file) {
      mutation.mutate(file, { onSuccess: () => setFile(undefined) });
    }
    setErrorFile(false);
  }, [signatureTemplate, file, history]);

  return (
    <PageContainer>
      <Title>参加規約設定</Title>
      <SubTitle>コミュニティスクールの参加規約の設定が行えます</SubTitle>
      <Margin marginBottom={8} />
      {showTab && (
        <TabNavigation
          elements={[
            {
              text: "PTA",
              onclick: () => setTab("pta"),
              isActive: false,
            },
            {
              text: "コミュニティスクール",
              onclick: () => setTab("cs"),
              isActive: true,
            },
          ]}
        />
      )}
      <PageBody>
        <ImgField
          label="参加規約（pdf）"
          accept="application/pdf"
          onChange={setFile}
        >
          {pdfFilePathForPreview ? (
            <PdfViewer width="100%" file={pdfFilePathForPreview} />
          ) : (
            <FilePlusIcon />
          )}
        </ImgField>
        {errorFile && (
          <DangerText>
            ファイルが30MBを超えています。容量を軽くしてアップロードしてください
          </DangerText>
        )}
        <ButtonGroup>
          {!mutation.isPending && (
            <>
              <Button
                size="large"
                disabled={!isValid}
                fill
                style={{ marginLeft: "8px", marginBottom: "24px" }}
                onClick={onSubmit}
              >
                保存する
              </Button>
            </>
          )}
          {mutation.isPending && (
            <>
              <Button
                size="large"
                disabled
                aria-busy
                fill
                style={{ marginLeft: "8px", marginBottom: "24px" }}
              >
                保存中
              </Button>
            </>
          )}
        </ButtonGroup>
      </PageBody>
      {mutation.isError && (
        <ErrorModal onClick={() => mutation.reset()}>
          保存に失敗しました。
          <br />
          もう一度お試しください。
        </ErrorModal>
      )}
    </PageContainer>
  );
};
