import { z } from "zod";
import { zPhoneNumberString, zRequiredString } from "../rules";
import { accountCommunityTypeSchema } from "../models/accountCommunityRole.schema";

export const FlowSchema = z.enum([
  "DEFAULT",
  "ORGANIZATION_CHAT",
  "PTA_PARENT_FAMILY",
]);

export type FlowType = z.infer<typeof FlowSchema>;

// PTA招待トークン生成用スキーマ
export const getOrGenerateInvitationTokenSchema = z.object({
  organizationId: z.string().optional(),
});

export type GetOrGenerateInvitationTokenSchemaType = z.infer<
  typeof getOrGenerateInvitationTokenSchema
>;

// PTA 家族招待トークン生成用スキーマ
export const getOrGenerateInvitationTokenForPTAParentFamilySchema = z.object(
  {}
);

export type GetOrGenerateInvitationTokenForPTAParentFamilyType = z.infer<
  typeof getOrGenerateInvitationTokenForPTAParentFamilySchema
>;

// 住民招待トークン生成用スキーマ
export const getOrGenerateInvitationResidentTokenSchema = z.object({
  organizationId: z.string().optional(),
  flow: FlowSchema.default("DEFAULT").optional(),
});

export type GetOrGenerateInvitationResidentTokenSchemaType = z.infer<
  typeof getOrGenerateInvitationResidentTokenSchema
>;

export const updateInviteCommunityRolesRequestSchema = z.array(
  z.object({
    roleName: zRequiredString,
    communityId: zRequiredString,
    isMain: z.boolean(),
    mainElementarySchoolType: accountCommunityTypeSchema.optional(),
    mainJuniorHighSchoolType: accountCommunityTypeSchema.optional(),
  })
);

export type UpdateInviteCommunityRolesRequestSchemaType = z.infer<
  typeof updateInviteCommunityRolesRequestSchema
>;

// 住民招待用のスキーマ
export const inviteResidentSchema = z.object({
  email: zRequiredString.email(),
  lastName: zRequiredString,
  firstName: zRequiredString,
  lastNameKana: zRequiredString,
  firstNameKana: zRequiredString,
  phoneNumber: zRequiredString,
  gender: z.enum(["MALE", "FEMALE", "OTHER"]),
  ageGroup: z.number(),
  postalCode: zRequiredString,
  prefecture: zRequiredString,
  city: zRequiredString,
  address1: zRequiredString,
  address2: z.string().nullable(),
  communityRoles: updateInviteCommunityRolesRequestSchema,
});

export type InviteResidentSchemaType = z.infer<typeof inviteResidentSchema>;

// 教育委員会メンバー招待用スキーマ
export const inviteBEMemberSchema = z.object({
  organizationId: zRequiredString,
  email: zRequiredString,
  lastName: zRequiredString,
  firstName: zRequiredString,
  lastNameKana: zRequiredString,
  firstNameKana: zRequiredString,
  phoneNumber: zPhoneNumberString,
});

export type InviteBEMemberSchemaType = z.infer<typeof inviteBEMemberSchema>;

// トークン検証用スキーマ
export const verifyTokenSchema = z.object({
  token: zRequiredString,
});

export type VerifyTokenSchemaType = z.infer<typeof verifyTokenSchema>;
