import React from "react";
import { ModalBackGround } from "src/components/Common/Menu";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import { useForm } from "react-hook-form";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { Organization } from "src/apiClients/organization";
import { Label } from "src/components/form/Label";
import styled from "styled-components";
import { zodResolver } from "@hookform/resolvers/zod";
import { Switch } from "src/components/form/Switch";
import {
  upsertPTAOrganizationInvoiceAccountSchema,
  UpsertPTAOrganizationInvoiceAccountType,
} from "@shared/validator/features/ptaOrganizationInvoiceAccount.schema";
import { PTAOrganizationInvoiceAccountType } from "@shared/validator/models/invoice/ptaOrganizationInvoiceAccount.schema";
import { useUpsertPTAOrganizationInvoiceAccount } from "src/hooks/query/internal/invoice/ptaOrganizationInvoiceAccount";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
  ptaOrganizationInvoiceAccount?: PTAOrganizationInvoiceAccountType;
};

export const PTAOrganizationInvoiceAccountSettingModal = ({
  isOpen,
  onClose,
  organization,
  ptaOrganizationInvoiceAccount,
}: Props) => {
  if (!isOpen) return null;

  const toast = useToast();
  const upsertMutation = useUpsertPTAOrganizationInvoiceAccount();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm<UpsertPTAOrganizationInvoiceAccountType>({
    defaultValues: {
      ...ptaOrganizationInvoiceAccount,
      organizationId: organization.id,
    },
    mode: "onChange",
    resolver: zodResolver(upsertPTAOrganizationInvoiceAccountSchema),
  });

  const onSubmit = async (data: UpsertPTAOrganizationInvoiceAccountType) => {
    try {
      await upsertMutation.mutateAsync(data);

      toast.success("集金設定を登録しました");
      onClose();
    } catch (err) {
      if (err instanceof APIError) {
        toast.error(err.message);
      } else {
        toast.error("エラーが発生しました");
      }
    }
  };

  const enabled = watch("enabled");

  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>{organization.name}の集金機能設定</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Label>集金機能</Label>
            <Switch register={register("enabled")} />
            <Description>
              {enabled ? "集金機能は有効です" : "現在は無効になっています"}
            </Description>

            <Label required>Stripe Connect ID</Label>
            <TextField
              placeholder="例) acct_1234abcd5678"
              {...register("stripeAccountId")}
            />
            {errors.stripeAccountId && (
              <ErrorMessage>{errors.stripeAccountId.message}</ErrorMessage>
            )}

            <ModalSubmitButtons
              onCancel={onClose}
              type="submit"
              disabled={!isValid || isSubmitting}
              submitText={isSubmitting ? "送信中..." : "保存する"}
            />
          </form>
        </ModalBody>
      </ModalPortal>
      <ModalBackGround isOpen={isOpen} />
    </>
  );
};

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;

const Description = styled.div`
  font-size: 14px;
  margin: 4px 0 12px;
  color: #666;
`;
