import * as client from "./client";
import { PTAInvoiceProductType } from "@shared/types/ptaInvoiceProduct";
import {
  CreatePTAInvoiceProductType,
  UpdatePTAInvoiceProductType,
  GetPTAInvoiceProductType,
} from "@shared/validator/features/ptaInvoiceProduct.schema";

export async function getPTAInvoiceProductList(): Promise<
  PTAInvoiceProductType[]
> {
  return await client.get<{}, PTAInvoiceProductType[]>(
    "/invoice/ptaInvoiceProduct/list",
    {}
  );
}

export async function getPTAInvoiceProduct(
  args: GetPTAInvoiceProductType
): Promise<PTAInvoiceProductType | null> {
  return await client.get<
    GetPTAInvoiceProductType,
    PTAInvoiceProductType | null
  >("/invoice/ptaInvoiceProduct/get", { id: args.id });
}

export async function createPTAInvoiceProduct(
  data: CreatePTAInvoiceProductType
): Promise<PTAInvoiceProductType> {
  return await client.post<CreatePTAInvoiceProductType, PTAInvoiceProductType>(
    "/invoice/ptaInvoiceProduct/create",
    data
  );
}

export async function updatePTAInvoiceProduct(
  data: UpdatePTAInvoiceProductType
): Promise<PTAInvoiceProductType> {
  return await client.post<UpdatePTAInvoiceProductType, PTAInvoiceProductType>(
    "/invoice/ptaInvoiceProduct/update",
    data
  );
}
