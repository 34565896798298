import React from "react";
import { Button } from "src/components/Button";
import { ModalBody, ModalHeader, ModalPortal } from "src/components/Modal";
import styled from "styled-components";

function UserDeleteModal({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}) {
  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>ユーザーの削除</ModalHeader>
      <ModalBody>
        <p>
          ユーザーを削除しますか？ユーザー情報がFirebase及びデータベースから削除されます。
        </p>
        <ModalButtonWrapper>
          <Button color="ghost" fill onClick={onClose} size="large">
            キャンセル
          </Button>
          <Button color="danger" fill size="large" onClick={onConfirm}>
            ユーザーを削除する
          </Button>
        </ModalButtonWrapper>
      </ModalBody>
    </ModalPortal>
  );
}

export default UserDeleteModal;

const ModalButtonWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
