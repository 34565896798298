import { z } from "zod";
import { zRequiredString } from "../rules/string";

export const upsertPTAOrganizationInvoiceAccountSchema = z.object({
  organizationId: zRequiredString,
  stripeAccountId: zRequiredString,
  enabled: z.boolean(),
});
export type UpsertPTAOrganizationInvoiceAccountType = z.infer<
  typeof upsertPTAOrganizationInvoiceAccountSchema
>;

export const getPTAOrganizationInvoiceAccountSchema = z.object({
  organizationId: zRequiredString,
});
export type GetPTAOrganizationInvoiceAccountType = z.infer<
  typeof getPTAOrganizationInvoiceAccountSchema
>;
