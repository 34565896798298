import { PTAImportCsvInvoiceTargetType } from "@shared/validator/models/invoice/ptaImportCsvInvoiceTarget.schema";
import { useQuery } from "@tanstack/react-query";
import { getImportCsvTargetByPTAInvoiceProcess } from "src/apiClients/invoice";

export function useGetImportCsvTargetByPTAInvoiceProcess(
  data: PTAImportCsvInvoiceTargetType | undefined
) {
  const query = useQuery({
    queryKey: ["api", "invoice", "ptaImportCsvInvoiceTarget", data],
    queryFn: async () => {
      if (!data) return [];
      return await getImportCsvTargetByPTAInvoiceProcess(data);
    },
    enabled: !!data,
  });

  return {
    targetList: query.data,
    ...query,
  };
}
