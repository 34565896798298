import React from "react";
import styled from "styled-components";
import { Spinner } from "src/components/icons/Spinner";
import { PTAInvoiceProductType } from "@shared/validator/models/invoice/ptaInvoiceProduct.schema";
import { NotFoundInvoiceProductList } from "./NotFoundInvoiceProductList";
import { usePolyfitHistory } from "src/hooks/router";
import { toDateFormat } from "src/utils/time";

interface Props {
  isLoading: boolean;
  ptaInvoiceProducts: PTAInvoiceProductType[];
}

export const PTAInvoiceProductListContent: React.FC<Props> = ({
  isLoading,
  ptaInvoiceProducts,
}) => {
  const history = usePolyfitHistory();

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  }

  if (ptaInvoiceProducts.length === 0) {
    return <NotFoundInvoiceProductList />;
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          <Th>請求項目</Th>
          <Th>支払期日</Th>
          <Th>対象人数</Th>
          <Th>支払い済み人数</Th>
          <Th>支払い率</Th>
        </tr>
      </thead>
      <tbody>
        {ptaInvoiceProducts.map((product) => (
          <Tr
            key={product.id}
            onClick={() => {
              history.push({
                to: "PTA_INVOICE_DETAIL",
                query: { id: product.id },
              });
            }}
          >
            <Td>{product.name}</Td>
            <Td>{toDateFormat(new Date(product.dueDate))}</Td>
            <Td>-(TODO)</Td>
            <Td>-(TODO)</Td>
            <Td>-(TODO)</Td>
          </Tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const LoadingWrapper = styled.div`
  padding: 24px;
  text-align: center;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  cursor: pointer;

  &:hover {
    background-color: #f7f8fa;
  }
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const Td = styled.td`
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;
