import React, { useState } from "react";
import styled from "styled-components";
import { zIndexes } from "../../zIndex";
import { UpdateCareerModal } from "./UpdateCareerModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const MenuModal = ({ isOpen, onClose }: Props) => {
  const [isOpenUpdateCareerModal, setIsOpenUpdateCareerModal] = useState(false);

  return (
    <>
      {isOpen && (
        <>
          <ModalWrapper>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setIsOpenUpdateCareerModal(true);
                }}
              >
                経歴入力設定
              </MenuItem>
            </MenuList>
          </ModalWrapper>
          <ModalBackground onClick={() => onClose()} />
          <UpdateCareerModal
            isOpen={isOpenUpdateCareerModal}
            onClose={() => setIsOpenUpdateCareerModal(false)}
          />
        </>
      )}
    </>
  );
};

const ModalWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: ${zIndexes.modal};
  background: #ffffff;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const MenuList = styled.ul`
  list-style: none;
`;

const MenuItem = styled.li`
  font-size: 14px;
  padding: 16px;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  z-index: ${zIndexes.modalBackground};
`;
