import { ResidentUserDetail } from "@shared/types/boardEducation/residentUser";
import * as client from "../client";
import { BEResidentUser } from "@shared/types/boardEducation/residentUsers";
import { ResidentFilterType } from "@shared/validator/features/user.schema";

/**
 * 地域住民詳細
 */
export async function getBEResidentUserDetail({
  accountId,
  communityId,
}: {
  accountId: string;
  communityId: string;
}): Promise<ResidentUserDetail> {
  const res = await client.get<
    { accountId: string; communityId: string },
    { account: ResidentUserDetail }
  >("/boardEducation/users/residentUser/detail", {
    accountId: accountId,
    communityId: communityId,
  });
  return res.account;
}

export async function getBEResidentUsers(
  filter?: Partial<ResidentFilterType>
): Promise<BEResidentUser[]> {
  const filterQuery = JSON.stringify(filter);
  const res = await client.get<{}, { users: BEResidentUser[] }>(
    "/boardEducation/users/residentUsers",
    { filter: filterQuery }
  );
  return res.users;
}
