import { useMemo } from "react";
import * as usersApi from "src/apiClients/users";
import { useQuery, useQueryClient } from "@tanstack/react-query";

type MemberUser = usersApi.User;

export function useMemberUserById(userId: string) {
  const query = useQuery<MemberUser, Error>({
    queryKey: ["api", "users", "residentUser", "detail", userId],
    queryFn: () => usersApi.getMemberUser({ userId }),
  });

  return {
    memberUser: useMemo(() => query.data, [query.data]),
    getUser: query.refetch,
    ...query,
  };
}

export function useInvalidateMemberUserById() {
  const queryClient = useQueryClient();

  function invalidateMemberUserById(userId?: string) {
    if (userId) {
      queryClient.invalidateQueries({
        queryKey: ["api", "users", "residentUser", "detail", userId],
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: ["api", "users", "residentUser", "detail"],
      });
    }
  }

  return {
    invalidateMemberUserById,
  };
}
