import { Community } from "./community";
import { RecruitmentStatusSchemaType } from "../validator/features/recruitment.schema";
import {
  ApplicationAttendanceStatus,
  ApplicationSelectionStatus,
} from "./recruitmentApplicationEvent";

export type AppliedRecruitment = {
  id: string;
  rejectedAt: Date;
  recruitment: {
    id: string;
    title: string;
    status: RecruitmentStatusSchemaType;
    communityId: string | null;
  };
  comment: {
    id: string;
    comment: string;
    isAlert: boolean;
    createdAt: Date;
    user: {
      id: string;
      name: string;
      picture: string;
    };
  }[];
  community: Community;
  eventStatuses: {
    id: string;
    selectionStatus: ApplicationSelectionStatus;
    rejectedAt: Date | null;
    attendanceStatus?: ApplicationAttendanceStatus;
    recruitmentSchedule: {
      id: string;
      start: Date;
      end: Date;
      date: Date;
    };
  }[];
};

export const statusColorMap: { [key: string]: string } = {
  APPLIED: "#FF7E62",
  APPROVED: "#79AAD9",
  REJECTED: "#EE789D",
  DECLINED: "#6DCCB1",
};

export const statusTextMap: { [key: string]: string } = {
  APPLIED: "応募",
  APPROVED: "参加決定",
  REJECTED: "見送り",
  DECLINED: "不参加",
};
