import { RecruitmentTemplate } from "@shared/types/recruitmentTemplate";
import * as client from "./client";
import {
  getRecruitmentTemplateByIdSchema,
  GetRecruitmentTemplateByIdSchemaType,
  deleteRecruitmentTemplateSchema,
  DeleteRecruitmentTemplateSchemaType,
} from "../@shared/validator/features/recruitmentTemplate.schema";

export async function getRecruitmentTemplateList(): Promise<
  RecruitmentTemplate[]
> {
  return await client.get<{}, RecruitmentTemplate[]>(
    "/recruitment-template/list",
    {}
  );
}

export async function getRecruitmentTemplateById(
  recruitmentTemplateId: string
): Promise<RecruitmentTemplate> {
  const args = getRecruitmentTemplateByIdSchema.parse({
    recruitmentTemplateId,
  });
  return await client.get<
    GetRecruitmentTemplateByIdSchemaType,
    RecruitmentTemplate
  >("/recruitment-template/getByRecruitmentTemplateId", args);
}

export async function deleteRecruitmentTemplateById(
  recruitmentTemplateId: string
): Promise<void> {
  const args = deleteRecruitmentTemplateSchema.parse({ recruitmentTemplateId });
  await client.post<DeleteRecruitmentTemplateSchemaType>(
    "/recruitment-template/delete",
    args
  );
}
