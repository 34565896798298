// FIXME: Long lines (>1000 lines) CODE SMELLS
import React, { useEffect, useState } from "react";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { CurrentUser } from "../../../apiClients/auth";
import styled from "styled-components";
import { unreachable } from "../../../utils/unreachable";
import {
  isCsAdminRole,
  isCsMemberRole,
  isCsRole,
  isPtaAdminRole,
  isPtaRole,
} from "../../../utils/types/role";
import { useOrganization } from "../../../hooks/recoil/organization";
import { PTAListResidentTab } from "./PTAListResidentTab";
import { PTAListParentTab } from "./PTAListParentTab";
import { PTAListTeacherTab } from "./PTAListTeacherTab";
import { usePolyfitHistory } from "../../../hooks/router";

export default function PTAListPage() {
  const [tab, setTab] = useState<"parent" | "teacher" | "resident">();
  const currentUser: CurrentUser = useCurrentUser();
  const { organization } = useOrganization({});
  const history = usePolyfitHistory();
  const isPtaAdmin = isPtaAdminRole(currentUser.role);
  const isCSAdmin = isCsAdminRole(currentUser.role);

  useEffect(() => {
    if (tab === undefined) {
      if (isPtaRole(currentUser.role)) {
        setTab("parent");
      }
      if (isCsRole(currentUser.role)) {
        setTab("resident");
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (isCsMemberRole(currentUser.role)) {
      history.push({ to: "RESIDENT_RECRUITMENT_LIST" });
      return;
    }
  }, [currentUser.role]);

  // organization === undefinedの状態では何も表示しない
  // TODO: 不要であればこの処理を削除したい
  if (typeof organization === "undefined" || tab === undefined) {
    return (
      <NameListPage>
        <NameListHeadingRow>
          <NameListHeading>名簿</NameListHeading>
        </NameListHeadingRow>
      </NameListPage>
    );
  }

  switch (tab) {
    case "parent":
      if (!isPtaAdmin) {
        return <>この画面の閲覧権限がありません</>;
      }
      return <PTAListParentTab setTab={setTab} />;
    case "teacher":
      if (!isPtaAdmin) {
        return <>この画面の閲覧権限がありません</>;
      }
      return <PTAListTeacherTab setTab={setTab} />;
    case "resident":
      if (!isCSAdmin) {
        return <>この画面の閲覧権限がありません</>;
      }
      return <PTAListResidentTab setTab={setTab} />;
    default:
      return unreachable(tab);
  }
}

const NameListPage = styled.div`
  margin: 0 auto;
`;

const NameListHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 16px;
`;

const NameListHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: #1a1c21;
`;
