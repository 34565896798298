import React, { useCallback, useState } from "react";
import { User } from "../../apiClients/users";
import { Margin } from "../Margin";
import styled from "styled-components";

type Props = {
  users: User[];
  onClick: (user: User) => void;
};
export const SearchUserField = ({ users, onClick }: Props) => {
  const [text, setText] = useState("");
  const filteredUsers = useCallback(() => {
    return users.filter((user) => {
      const name =
        (user.baseInfo?.lastName ?? "") + (user.baseInfo?.firstName ?? "");
      const nameKana =
        (user.baseInfo?.lastNameKana ?? "") +
        (user.baseInfo?.firstNameKana ?? "");
      return name.includes(text) || nameKana.includes(text);
    });
  }, [text, users]);
  return (
    <>
      <SearchTextFieldLabel>
        <SearchTextField
          placeholder="例)  山田花子"
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </SearchTextFieldLabel>
      <Margin marginBottom={10} />
      <SearchCandidate height={120}>
        {filteredUsers().map((user) => (
          <li
            key={user.id}
            onClick={() => {
              onClick(user);
            }}
          >
            {user.baseInfo?.lastName ?? ""} {user.baseInfo?.firstName ?? ""}
          </li>
        ))}
      </SearchCandidate>
    </>
  );
};

export const SearchTextFieldLabel = styled.label`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%23343741' d='m11.271 11.978 3.872 3.873a.502.502 0 0 0 .708 0 .502.502 0 0 0 0-.708l-3.565-3.564c2.38-2.747 2.267-6.923-.342-9.532-2.73-2.73-7.17-2.73-9.898 0-2.728 2.729-2.728 7.17 0 9.9a6.955 6.955 0 0 0 4.949 2.05.5.5 0 0 0 0-1 5.96 5.96 0 0 1-4.242-1.757 6.01 6.01 0 0 1 0-8.486 6.004 6.004 0 0 1 8.484 0 6.01 6.01 0 0 1 0 8.486.5.5 0 0 0 .034.738Z'/%3e%3c/svg%3e")
      center / contain no-repeat;
  }
`;

export const SearchTextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  padding-left: 40px;
  ::placeholder {
    color: #aab4c4;
  }
`;

export const SearchCandidate = styled.ul<{ height: number }>`
  height: ${(props) => `${props.height}px`};
  overflow: scroll;
  width: 100%;

  li {
    width: 100%;
    padding: 12px 16px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 109, 228, 0.1);
    }
  }
`;
