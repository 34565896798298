import { z } from "zod";
import { zRequiredString } from "../rules";
import { baseRequestSchema } from "./common.schema";

export const fetchChatMessagesByRoomIdSchema = baseRequestSchema.extend({
  chatRoomId: zRequiredString,
});

export type FetchChatMessagesByRoomIdSchemaType = z.infer<
  typeof fetchChatMessagesByRoomIdSchema
>;

export const createChatMessageSchema = baseRequestSchema.extend({
  chatRoomId: zRequiredString,
  content: z.string(),
});

export type CreateChatMessageSchemaType = z.infer<
  typeof createChatMessageSchema
>;
