import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { TextField } from "../../../components/Form";
import { usePolyfitHistory } from "../../../hooks/router";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { useSearchParams } from "react-router-dom";
import { useOrganizationById } from "../../../hooks/api/internal/organizations";
import { logger } from "src/utils/logger";
import { SchoolDisplayType, SchoolType } from "@shared/types/organization";
import { useInvalidateGetOrganizations } from "src/hooks/query/internal/organizations/useGetOrganizations";
import LineAggregationArea from "./LineAggregationArea";
import {
  hasHigherInternalRole,
  isInternalRoleAdmin,
} from "@shared/utils/internalUserRole";
import { useModal } from "src/components/Modal";
import { useGetPTAOrganizationInvoiceAccountForInternal } from "src/hooks/query/internal/invoice/ptaOrganizationInvoiceAccount";
import { PTAOrganizationInvoiceAccountSettingModal } from "./PTAOrganizationInvoiceAccountSettingModal";

export default function OrganizationEditPage() {
  const [searchParams] = useSearchParams();
  const editOrganizationId = searchParams.get("organizationId");
  const history = usePolyfitHistory();

  const currentUser = useCurrentUser();
  const { organization, fetchOrganization, writeOrganization } =
    useOrganizationById(editOrganizationId ?? "");
  const { invalidateGetOrganizations } = useInvalidateGetOrganizations();

  const { ptaOrganizationInvoiceAccount, isLoading } =
    useGetPTAOrganizationInvoiceAccountForInternal({
      organizationId: editOrganizationId ?? "",
    });
  const [
    isOpenPTAOrganizationInvoiceAccountSettingModal,
    {
      show: showPTAOrganizationInvoiceAccountSettingModal,
      close: closePTAOrganizationInvoiceAccountSettingModal,
    },
  ] = useModal();

  /**
   * 変更系
   */
  const [organizationName, setOrganizationName] = useState<string>("");
  const [organizationNameListPublished, setOrganizationNameListPublished] =
    useState<boolean>(false);
  const [
    organizationRequireAdminToUpdateCareer,
    setOrganizationRequireAdminToUpdateCareer,
  ] = useState<boolean>(false);
  const [lineOption, setLineOption] = useState<boolean>(false);
  const [schoolType, setSchoolType] = useState<SchoolType>("B");
  const [schoolDisplayType, setSchoolDisplayType] =
    useState<SchoolDisplayType>("CONTINUOUS");

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationName(e.target.value);
  }, []);

  const onNameListPublishedChange = useCallback((e: any) => {
    setOrganizationNameListPublished(e.target.checked);
  }, []);

  const onRequireAdminToUpdateCareerChange = useCallback((e: any) => {
    setOrganizationRequireAdminToUpdateCareer(e.target.checked);
  }, []);

  const onLineOptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLineOption(e.target.checked);
    },
    []
  );
  const onSchoolTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSchoolType(e.target.value as SchoolType);
    },
    []
  );

  const onSchoolDisplayTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSchoolDisplayType(e.target.value as SchoolDisplayType);
    },
    []
  );

  const onSubmit = async () => {
    try {
      await writeOrganization({
        name: organizationName,
        nameListPublished: organizationNameListPublished,
        requireAdminToUpdateCareer: organizationRequireAdminToUpdateCareer,
        schoolType: schoolType,
        schoolDisplayType: schoolDisplayType,
        lineOption: lineOption,
      });

      alert("更新しました");
      invalidateGetOrganizations();
      fetchOrganization();
    } catch (e) {
      logger.error(e);
      alert("更新に失敗しました");
    }
  };

  /**
   * 画面初期化時
   */
  useEffect(() => {
    setOrganizationName(organization?.name ?? "");
    setOrganizationNameListPublished(organization?.nameListPublished ?? false);
    setOrganizationRequireAdminToUpdateCareer(
      organization?.requireAdminToUpdateCareer ?? false
    );
    setLineOption(organization?.lineOption ?? false);
    setSchoolType(organization?.schoolType ?? "B");
    setSchoolDisplayType(organization?.schoolDisplayType ?? "CONTINUOUS");
  }, [organization]);

  /**
   * 表示に関して
   */
  if (!editOrganizationId || !isInternalRoleAdmin(currentUser.internalRole)) {
    return <></>;
  }

  if (!organization) {
    return (
      <>
        <div>読み込み中…</div>
        <div>画面が切り替わらない場合はお問い合わせください</div>
      </>
    );
  }

  return (
    <>
      <TransitionLink
        onClick={() => {
          history.push({ to: "INTERNAL_ORGANIZATION_LIST" });
        }}
      >
        組織一覧に戻る
      </TransitionLink>{" "}
      {hasHigherInternalRole(currentUser.internalRole, "INTERNAL_ADMIN") && (
        <TransitionLink
          onClick={() => {
            history.push({
              to: "INTERNAL_ORGANIZATION_USER_LIST",
              query: {
                organizationId: currentUser.account?.organizationId ?? "",
              },
            });
          }}
        >
          ユーザー一覧を確認する
        </TransitionLink>
      )}
      <br />
      <h3>組織ID({editOrganizationId})の情報の編集</h3>
      {/* 組織情報の編集 */}
      <FormArea>
        <div>
          <TextField
            label="組織名"
            value={organizationName}
            onChange={onNameChange}
          />
          NameListPublished
          <input
            type="checkbox"
            checked={organizationNameListPublished}
            onChange={onNameListPublishedChange}
          />
          <br />
          RequireAdminToUpdateCareer
          <input
            type="checkbox"
            checked={organizationRequireAdminToUpdateCareer}
            onChange={onRequireAdminToUpdateCareerChange}
          />
          <br />
          学校種別:
          <select value={schoolType} onChange={onSchoolTypeChange}>
            <option value="B">小学校 (1-6年生)</option>
            <option value="C">中学校 (7-9年生)</option>
            <option value="D">高等学校 (10-12年生)</option>
            <option value="BC">小中一貫校 (1-9年生)</option>
            <option value="CD">中高一貫校 (7-12年生)</option>
            <option value="BCD">小中高一貫校 (1-12年生)</option>
          </select>
          <br />
          学年表示形式:
          <select
            value={schoolDisplayType}
            onChange={onSchoolDisplayTypeChange}
          >
            <option value="CONTINUOUS">連番表記</option>
            <option value="SEGMENTED">学校種別表記</option>
          </select>
          <br />
          LINEオプション
          <input
            type="checkbox"
            checked={lineOption}
            onChange={onLineOptionChange}
          />
          <br />
          作成日時: {organization?.createdAt}
          <br />
          更新日時: {organization?.updatedAt}
        </div>
        <Button
          size="large"
          color="secondary"
          style={{ marginLeft: "8px" }}
          onClick={onSubmit}
        >
          更新する
        </Button>
      </FormArea>
      {organization.serviceType === "PTA" && (
        <>
          <h3>集金機能</h3>
          {isLoading ? (
            <div>ロード中...</div>
          ) : ptaOrganizationInvoiceAccount ? (
            <>
              <div>
                Enabled:{" "}
                {ptaOrganizationInvoiceAccount.enabled ? "有効" : "無効"}
              </div>
              <div>
                StripeAccountId: {ptaOrganizationInvoiceAccount.stripeAccountId}
              </div>
            </>
          ) : (
            <div>未登録</div>
          )}
          <div style={{ margin: "8px 0" }}>
            <Button
              color="subPrimary"
              onClick={showPTAOrganizationInvoiceAccountSettingModal}
            >
              編集
            </Button>
          </div>
        </>
      )}
      {organization.lineOption && (
        <LineAggregationArea editOrganizationId={editOrganizationId} />
      )}
      {!isLoading &&
        organization &&
        isOpenPTAOrganizationInvoiceAccountSettingModal && (
          <PTAOrganizationInvoiceAccountSettingModal
            isOpen={isOpenPTAOrganizationInvoiceAccountSettingModal}
            onClose={closePTAOrganizationInvoiceAccountSettingModal}
            organization={organization}
            ptaOrganizationInvoiceAccount={ptaOrganizationInvoiceAccount}
          />
        )}
    </>
  );
}

const FormArea = styled.div`
  margin-top: 8px;
  padding-bottom: 16px;
  margin-bottom: 16px;

  h4 {
    margin-bottom: 8px;
  }
`;

const TransitionLink = styled.a`
  text-decoration-line: underline;

  &:hover {
    cursor: pointer;
  }
`;
