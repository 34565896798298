import * as client from "./client";
import {
  createBelongSchema,
  CreateBelongSchemaType,
  updateBelongSchema,
  UpdateBelongSchemaType,
  deleteBelongSchema,
  DeleteBelongSchemaType,
} from "../@shared/validator/features/belong.schema";
import { BelongSchemaType as Belong } from "@shared/validator/models/belong.schema";

export async function createBelong(arg: CreateBelongSchemaType) {
  createBelongSchema.parse(arg);
  const res = await client.post<CreateBelongSchemaType, Belong>(
    "/belong/create",
    arg
  );
  return res;
}

export async function updateBelong(arg: UpdateBelongSchemaType) {
  updateBelongSchema.parse(arg);
  const res = await client.post<UpdateBelongSchemaType, Belong>(
    "/belong/update",
    arg
  );
  return res;
}

export async function deleteBelong(arg: DeleteBelongSchemaType) {
  deleteBelongSchema.parse(arg);
  const res = await client.post<DeleteBelongSchemaType, Belong>(
    "/belong/delete",
    arg
  );
  return res;
}
