import * as client from "./client";
import {
  GetPTAOrganizationInvoiceAccountType,
  UpsertPTAOrganizationInvoiceAccountType,
} from "../../../server/src/@shared/validator/features/ptaOrganizationInvoiceAccount.schema";
import { PTAOrganizationInvoiceAccountType } from "@shared/types/ptaOrganizationInvoiceAccount";

export async function upsertPTAOrganizationInvoiceAccount(
  data: UpsertPTAOrganizationInvoiceAccountType
): Promise<void> {
  return await client.post<UpsertPTAOrganizationInvoiceAccountType, void>(
    "/invoice/ptaOrganizationInvoiceAccount/upsert",
    data
  );
}

export async function getPTAOrganizationInvoiceAccountForInternal(
  args: GetPTAOrganizationInvoiceAccountType
): Promise<PTAOrganizationInvoiceAccountType | null> {
  return await client.get<
    GetPTAOrganizationInvoiceAccountType,
    PTAOrganizationInvoiceAccountType | null
  >(`/invoice/ptaOrganizationInvoiceAccount/internal/get`, {
    organizationId: args.organizationId,
  });
}

export async function getPTAOrganizationInvoiceAccount(): Promise<PTAOrganizationInvoiceAccountType | null> {
  return await client.get<{}, PTAOrganizationInvoiceAccountType | null>(
    `/invoice/ptaOrganizationInvoiceAccount/get`,
    {}
  );
}
