import { useCallback, useEffect, useState } from "react";
import * as organizationApi from "../../../apiClients/organization";
import { Organization } from "../../../apiClients/organization";
import { SchoolDisplayType, SchoolType } from "@shared/types/organization";
import { CreateOrganizationSchemaType } from "@shared/validator/features/organization.schema";

export function useOrganizations() {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const fetchOrganizations = useCallback(async () => {
    const organizations = await organizationApi.getOrganizations();
    setOrganizations(organizations);
  }, []);

  const createOrganization = useCallback(
    async ({
      communityIds,
      name,
      serviceType,
    }: CreateOrganizationSchemaType) => {
      const organization = await organizationApi.createOrganization({
        name,
        communityIds,
        serviceType,
      });
      await fetchOrganizations();
      return organization;
    },
    [fetchOrganizations]
  );

  useEffect(() => {
    fetchOrganizations();
  }, []);

  return {
    organizations,
    createOrganization,
    fetchOrganizations,
  };
}

export function useOrganizationById(organizationId: string) {
  const [organization, setOrganization] = useState<Organization>();

  const fetchOrganization = useCallback(async () => {
    const organization = await organizationApi.getOrganizationById(
      organizationId
    );
    setOrganization(organization);
  }, [organizationId]);

  useEffect(() => {
    fetchOrganization();
  }, [organizationId]);

  const addCommunity = useCallback(
    async (communityIds: string[]) => {
      await organizationApi.addCommunity({
        id: organizationId,
        communityIds,
      });
      fetchOrganization();
    },
    [organizationId]
  );

  const writeOrganization = useCallback(
    async (args: {
      communityIds?: string[];
      name?: string;
      nameListPublished?: boolean;
      requireAdminToUpdateCareer?: boolean;
      postSchoolYearsFilter?: string[];
      schoolType?: SchoolType;
      schoolDisplayType?: SchoolDisplayType;
      lineOption?: boolean;
    }) => {
      await organizationApi.updateOrganization({
        id: organizationId,
        ...args,
      });
      fetchOrganization();
    },
    [organizationId]
  );

  return {
    organization,
    fetchOrganization,
    writeOrganization,
    addCommunity,
  };
}

export function useOrganizationsByCommunityId(communityId: string) {
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const fetchOrganizations = useCallback(async () => {
    const organizations = await organizationApi.getOrganizationByCommunityId(
      communityId
    );
    setOrganizations(organizations);
  }, [communityId]);

  useEffect(() => {
    fetchOrganizations();
  }, [communityId]);

  return {
    organizations,
    fetchOrganizations,
  };
}
