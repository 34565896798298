import { RecruitmentFormValue } from "../useRecruitmentForm";

export const formatSchedule = ({
  schedule,
}: {
  schedule: RecruitmentFormValue["schedule"];
}) => {
  return schedule.map((s) => {
    const date = new Date(s.date);
    const start = new Date(s.date + "T" + s.startTime);
    const end = new Date(s.date + "T" + s.endTime);

    return {
      date: isNaN(date.getTime()) ? null : date,
      start: isNaN(start.getTime()) ? null : start,
      end: isNaN(end.getTime()) ? null : end,
    };
  });
};
