import React, { useCallback } from "react";
import styled from "styled-components";

import {
  ModalBody,
  ModalPortal,
  ModalPortalProps,
  ModalSubmitButtons,
} from "../../components/Modal";

import { Heading2 } from "../../components/Typo";
import { User } from "../../apiClients/users";
import { usePolyfitHistory } from "../../hooks/router";
import { CheckBox } from "../../components/form/CheckBox";
import useUserSelection from "./useUserSelection";
import { getStaticImageUrl } from "../../utils/getStaticImageUrl";
import { Avatar } from "../../components/Common/Avatar";
import { useCurrentUser } from "src/hooks/recoil/user";
import { isPtaAdminRole } from "src/utils/types/role";

/**
 * 連絡再送モーダル
 */
export const PostTargetsModal = ({
  onCancel,
  postTargetUsers,
  headerText,
  submitText,
  descriptionText,
  onSubmit,
}: {
  onCancel: () => void;
  postTargetUsers: User[];
  headerText: string;
  submitText: string;
  descriptionText: string;
  onSubmit: (users: User[]) => Promise<void>;
} & ModalPortalProps) => {
  const history = usePolyfitHistory();
  const currentUser = useCurrentUser();

  const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

  const {
    selectedUserIds,
    selectedUsers,
    isUserSelected,
    toggleUserSelection,
    handleSelectAllClick,
  } = useUserSelection(postTargetUsers);

  const onUserDetailClick = useCallback(
    (userId: string) => {
      history.push({
        to: "PTA_LIST_MEMBER",
        query: { userId },
      });
    },
    [history]
  );

  return (
    <ModalPortal onClose={onCancel} modalBoxIsHeightFull={false}>
      <ModalRemandTop>
        <ModalHeader>{headerText}</ModalHeader>
        <ModalBody>
          <div>{descriptionText}</div>
          <Spacer />
          <Table>
            <thead>
              <TheadTr>
                <TheadTh>
                  <CheckBoxWrapper>
                    <CheckBox
                      onChange={handleSelectAllClick}
                      checked={
                        selectedUserIds.length === postTargetUsers.length
                      }
                    />
                  </CheckBoxWrapper>
                </TheadTh>
                <TheadTh>氏名</TheadTh>
              </TheadTr>
            </thead>

            {postTargetUsers?.map((user, i) => (
              <Tbody key={i}>
                <TbodyTr>
                  <TbodyTd>
                    <CheckBoxWrapper>
                      <CheckBox
                        checked={isUserSelected(user.id)}
                        onChange={() => toggleUserSelection(user.id)}
                      />
                    </CheckBoxWrapper>
                  </TbodyTd>
                  <TbodyTd>
                    <UserPicCell>
                      <Avatar
                        src={user.picture || defaultUserImg}
                        alt="ユーザーのアイコン"
                        size={40}
                      />
                      <Name>{user.name}</Name>
                    </UserPicCell>
                  </TbodyTd>
                  <TbodyTd>
                    {isPtaAdminRole(currentUser.role) && (
                      <Link onClick={() => onUserDetailClick(user.id)}>
                        詳細
                      </Link>
                    )}
                  </TbodyTd>
                </TbodyTr>
              </Tbody>
            ))}
          </Table>
          <Spacer />
          <ModalSubmitButtons
            disabled={selectedUsers.length === 0}
            submitText={submitText}
            onSubmit={async () => await onSubmit(selectedUsers)}
            onCancel={onCancel}
          ></ModalSubmitButtons>
        </ModalBody>
      </ModalRemandTop>
    </ModalPortal>
  );
};

const ModalRemandTop = styled.div`
  height: auto;
`;

const ModalHeader = styled(Heading2)`
  padding-bottom: 12px;
  display: flex;
`;

const Spacer = styled.div`
  height: 12px;
`;

const CheckBoxWrapper = styled.div`
  margin-left: 20px;
  margin-right: -28px;
`;

const Table = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-weight: normal;
`;

const TheadTr = styled.tr`
  background-color: #fff;
  border-top: 1px solid #e3e6eb;
  border-bottom: 1px solid #e3e6eb;
`;

const TheadTh = styled.th`
  color: #343741;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding: 8px;
`;

const Tbody = styled.tbody`
  padding: 0 16px;
`;

const TbodyTr = styled.tr`
  background-color: #fff;
  border-bottom: 1px solid #e3e6eb;
`;

const TbodyTd = styled.td`
  color: #343741;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  padding: 8px;
`;

const UserPicCell = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
`;

const Name = styled.div`
  color: #343741;
  font-size: 16px;
  font-weight: normal;
`;

const Link = styled.a`
  font-size: 16px;
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: end;
  color: #07c;
`;
