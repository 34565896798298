import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchPushNotification,
  updatePushNotificationSetting,
  upsertPushNotificationToken,
} from "src/apiClients/pushNotification";
import { registerServiceWorker, requestForToken } from "src/utils/firebase";

export const usePushNotificationSetting = () => {
  // プッシュ通知の有効状態を取得するクエリ
  const { data: isPushNotificationEnabled, refetch } = useQuery({
    queryKey: ["pushNotification"],
    queryFn: fetchPushNotification,
    select: (res) => res.isPushNotificationEnabled,
  });

  // プッシュ通知設定を更新するミューテーション
  const { mutate: updatePushNotificationEnabled } = useMutation({
    mutationFn: (value: boolean) => {
      if (value) {
        registerServiceWorker();
        return requestForToken().then((token) => {
          if (!token) return;
          upsertPushNotificationToken({ token });
          return updatePushNotificationSetting({
            isPushNotificationEnabled: value,
          });
        });
      } else {
        return updatePushNotificationSetting({
          isPushNotificationEnabled: value,
        });
      }
    },
    onSuccess: () => {
      refetch();
    },
  });

  return { isPushNotificationEnabled, updatePushNotificationEnabled };
};
