import * as client from "./client";
import {
  ApproveParticipantChatRoomSchemaType,
  approveParticipantChatRoomSchema,
  updateParticipantChatRoomSchema,
  UpdateParticipantChatRoomSchemaType,
  RejectParticipantChatRoomSchemaType,
  rejectParticipantChatRoomSchema,
  deleteParticipantChatRoomSchema,
  DeleteParticipantChatRoomSchemaType,
} from "@shared/validator/features/participantChatRoom.schema";

export async function updateParticipantChatRoom(
  arg: UpdateParticipantChatRoomSchemaType
): Promise<void> {
  updateParticipantChatRoomSchema.parse(arg);
  await client.post<UpdateParticipantChatRoomSchemaType>(
    "/participant-chat-room/update",
    arg
  );
}

export async function approveParticipantChatRoom(
  arg: ApproveParticipantChatRoomSchemaType
) {
  approveParticipantChatRoomSchema.parse(arg);
  await client.post<ApproveParticipantChatRoomSchemaType>(
    "/participant-chat-room/approve",
    arg
  );
}

export async function rejectParticipantChatRoom(
  arg: RejectParticipantChatRoomSchemaType
) {
  rejectParticipantChatRoomSchema.parse(arg);
  await client.post<RejectParticipantChatRoomSchemaType>(
    "/participant-chat-room/reject",
    arg
  );
}

export async function deleteParticipantChatRoom(
  arg: DeleteParticipantChatRoomSchemaType
) {
  deleteParticipantChatRoomSchema.parse(arg);
  await client.post<DeleteParticipantChatRoomSchemaType>(
    "/participant-chat-room/delete",
    arg
  );
}
