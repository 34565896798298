import * as client from "./client";
import {
  PostUserGroupType,
  PostUserGroupUserType,
} from "../hooks/api/postUserGroup";
import type {
  CreateGroupResponse,
  ExistGroupResponse,
} from "@shared/types/postUserGroup";
import {
  createPostUserGroupSchema,
  CreatePostUserGroupSchemaType,
  deletePostUserGroupSchema,
  DeletePostUserGroupSchemaType,
  getGroupUsersSchema,
  GetGroupUsersSchemaType,
  getMyPostUserGroupsSchema,
  GetMyPostUserGroupsSchemaType,
  updatePostUserGroupSchema,
  UpdatePostUserGroupSchemaType,
} from "../@shared/validator/features/postUserGroup.schema";

const endpoint = "/postUserGroup";

/**
 * 連絡ユーザーグループ一覧取得
 */
export async function getPostUserGroups() {
  const res = await client.get<{}, PostUserGroupType[]>(endpoint, {});
  return res;
}

/**
 * 連絡ユーザーグループのユーザー一覧取得
 */
export async function getGroupUsers(id: string) {
  const args = getGroupUsersSchema.parse({ id });
  const res = await client.get<
    GetGroupUsersSchemaType,
    PostUserGroupUserType[]
  >(`${endpoint}/users`, args);
  return res;
}

/**
 * アカウントが所属する連絡ユーザーグループ一覧取得
 * @param args GetMyPostUserGroupsArgs
 */
export async function fetchMyPostUserGroups(
  args: GetMyPostUserGroupsSchemaType
) {
  getMyPostUserGroupsSchema.parse(args);
  const res = await client.get<
    GetMyPostUserGroupsSchemaType,
    PostUserGroupType[]
  >(`${endpoint}/myPostUserGroups`, args);
  return res;
}

/**
 * 連絡ユーザーグループ作成
 */
export async function createGroup({
  args,
}: {
  args: CreatePostUserGroupSchemaType;
}) {
  createPostUserGroupSchema.parse(args);
  const res = await client.post<
    CreatePostUserGroupSchemaType,
    CreateGroupResponse
  >(`${endpoint}/create`, args);
  return res;
}

/**
 * 連絡ユーザーグループ更新
 */
export async function updateGroup({
  args,
}: {
  args: UpdatePostUserGroupSchemaType;
}) {
  updatePostUserGroupSchema.parse(args);
  const res = await client.post<
    UpdatePostUserGroupSchemaType,
    PostUserGroupType
  >(`${endpoint}/update`, args);
  return res;
}

/**
 * 連絡ユーザーグループ削除
 */
export async function deleteGroup(id: string) {
  const args = deletePostUserGroupSchema.parse({ id });
  const res = await client.post<
    DeletePostUserGroupSchemaType,
    PostUserGroupType
  >(`${endpoint}/delete`, args);
  return res;
}

/**
 * すでにグループが存在するかどうかの判定
 */
export async function existGroup() {
  const res = await client.get<{}, ExistGroupResponse>(`${endpoint}/exist`, {});
  return res;
}
