import React from "react";
import { Margin } from "../../../../components/Margin";
import styled from "styled-components";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "../../../../components/Modal";
import { deleteParticipantChatRoom } from "src/apiClients/participantChatRoom";
import { deleteChatRoom } from "src/apiClients/chatRoom";
import { ChatRoom } from "@shared/types/chatRoom";

interface Props {
  isAdmin: boolean;
  onSubmit: () => void;
  onClose: () => void;
  chatRoom: ChatRoom;
}

export const DeleteChatRoomModal = (props: Props) => {
  const label = props.isAdmin
    ? {
        title: "グループ削除",
        body: `${props.chatRoom.name} を削除してもよろしいですか？\n削除した後は全てのメッセージが見れなくなります。`,
        submitText: "削除",
      }
    : {
        title: "グループ退会",
        body: `${props.chatRoom.name} から退会してもよろしいですか？\n退会した後は全てのメッセージが見れなくなります。`,
        submitText: "退会",
      };

  const onSubmit = async () => {
    if (props.isAdmin) {
      await deleteChatRoom({ chatRoomId: props.chatRoom.id });
    } else {
      await deleteParticipantChatRoom({ chatRoomId: props.chatRoom.id });
    }
    props.onSubmit();
  };

  return (
    <ModalPortal onClose={props.onClose}>
      <ModalHeader>
        <p>{label.title}</p>
      </ModalHeader>
      <ModalBody>
        <div>{label.body}</div>
        <Margin marginBottom={10} />
      </ModalBody>
      <ModalSubmitButtons
        submitText={label.submitText}
        submitColor="danger"
        onSubmit={onSubmit}
        onCancel={props.onClose}
        cancelColor="subPrimary"
      />
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
  white-space: pre-wrap;
`;
