import React, { useState } from "react";
import { Button } from "src/components/Button";
import { RegisterStepper } from "src/components/RegisterStepper";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import styled from "styled-components";
import * as Typo from "src/components/Typo";
import { Margin } from "src/components/Margin";
import { colorsPallet } from "src/theme";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { useCurrentUser } from "src/hooks/recoil/user";
import { DownloadIcon } from "src/components/icons/Lucide/DownloadIcon";
import { useGetImportCsvTargetByPTAInvoiceProcess } from "src/hooks/query/invoice/ptaImportCsvInvoiceTarget";
import { PTAImportCsvInvoiceTargetType } from "@shared/validator/models/invoice/ptaImportCsvInvoiceTarget.schema";
import { executePTAParentFamilyInvoice } from "src/apiClients/invoice";

const CURERNT_STEP = 2;

export const PTAInvoiceProcessImportPage = () => {
  const currentUser = useCurrentUser();
  const { id: invoiceProductId } = usePolyfitLocationQuery(
    "PTA_INVOICE_PROCESS",
    {
      id: "",
    }
  );
  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );

  const [csvTarget, setCsvTarget] = useState<
    PTAImportCsvInvoiceTargetType | undefined
  >(undefined);
  const { targetList, error } =
    useGetImportCsvTargetByPTAInvoiceProcess(csvTarget);

  const history = usePolyfitHistory();
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("選択されていません");

  if (!ptaOrganizationInvoiceAccount?.enabled) {
    return <div>閲覧できません</div>;
  }

  const prevStep = () => {
    history.push({
      to: "PTA_INVOICE_PROCESS",
      query: { id: invoiceProductId, step: `${CURERNT_STEP - 1}` },
    });
  };

  const nextStep = async () => {
    await executePTAParentFamilyInvoice({
      PTAInvoiceProductId: invoiceProductId,
      children:
        csvTarget?.map((target) => ({
          PTAParentFamilyId: target.familyId,
          childId: target.childId,
          amount: target.amount,
        })) ?? [],
    });

    history.push({
      to: "PTA_INVOICE_PROCESS",
      query: { id: invoiceProductId, step: `${CURERNT_STEP + 1}` },
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
    } else {
      setFileName("選択されていません");
    }
  };

  const handleImport = async () => {
    // fileからcsvを読み込む
    const csvData = await file?.text();
    const csvDataArray = csvData?.split("\n");
    // 家族IDと子どもIDを取得
    const targetList = csvDataArray?.slice(1).map((row) => {
      const columns = row.split(",");
      const familyId = columns[0]; // 家族ID
      const childId = columns[3]; // 子どもID
      const amount = Number(columns[7]); // 割り当て料金
      return { familyId, childId, amount };
    });
    setCsvTarget(targetList);
  };

  return (
    <Container>
      <RegisterStepper count={2} current={CURERNT_STEP} title="" />
      <Margin marginBottom={16} />
      <PaddingWrapper>
        <SubTitleWrapper>
          <Typo.Heading3>請求データのインポート</Typo.Heading3>
        </SubTitleWrapper>
        <Margin marginBottom={16} />
        <p>名簿データのCSVファイルをインポートしてください。</p>
        <Margin marginBottom={16} />
        <FlexWrapper>
          <StyledInputFileWrapper>
            <StyledInputFileLabel htmlFor="file-upload">
              ファイルを選択
            </StyledInputFileLabel>
            <InputFile
              id="file-upload"
              type="file"
              accept=".csv"
              onChange={handleFileChange}
            />
            <FileNameDisplay>{fileName}</FileNameDisplay>
          </StyledInputFileWrapper>
          <FlexButton color="primary" fill size="large" onClick={handleImport}>
            <DownloadIcon size={16} color={"white"} />
            インポート
          </FlexButton>
          {error && (
            <ErrorMessage>
              CSVファイルの家族IDあるいは子どもID、金額に不備があります。
            </ErrorMessage>
          )}
        </FlexWrapper>
        <Margin marginBottom={16} />
        <Typo.Heading3>インポート結果</Typo.Heading3>
        {targetList?.map((target) => (
          <div key={target.id}>
            <Typo.Heading3>{target.childLastName}</Typo.Heading3>
            <Typo.Heading3>{target.childFirstName}</Typo.Heading3>
          </div>
        ))}
        <Margin marginBottom={16} />
        <ButtonWrapper>
          <PrevButton color="text" fill onClick={prevStep}>
            戻る
          </PrevButton>
          <NextButton
            color="primary"
            fill
            onClick={nextStep}
            disabled={!targetList}
          >
            次へ
          </NextButton>
        </ButtonWrapper>
      </PaddingWrapper>
    </Container>
  );
};

const Container = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const PaddingWrapper = styled.div`
  padding: 0 16px;
`;

const StyledInputFileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colorsPallet.primary};
  padding: 8px;
  border-radius: 4px;
`;

const StyledInputFileLabel = styled.label`
  background-color: ${colorsPallet.primary};
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
`;

const InputFile = styled.input`
  display: none;
`;

const FileNameDisplay = styled.div`
  color: ${colorsPallet.primary};
  padding-left: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 32px;
`;

const PrevButton = styled(Button)`
  width: 30%;
  padding: 9.5px 0;
`;

const NextButton = styled(Button)`
  width: 30%;
  padding: 9.5px 0;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
`;

const FlexButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: ${colorsPallet.danger};
`;
