import { initializeApp, type FirebaseOptions } from "firebase/app";
import {
  getAuth,
  setPersistence as _setPersistence,
  GoogleAuthProvider,
  EmailAuthProvider,
  reauthenticateWithCredential,
  type User,
  updatePassword as _updatePassword,
  signInWithPopup,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  type ActionCodeSettings,
  checkActionCode as _checkActionCode,
  applyActionCode as _applyActionCode,
  verifyPasswordResetCode as _verifyPasswordResetCode,
  confirmPasswordReset as _confirmPasswordReset,
  reauthenticateWithPopup,
  verifyBeforeUpdateEmail as _verifyBeforeUpdateEmail,
  unlink,
  linkWithPopup,
} from "firebase/auth";
import { getToken, MessagePayload, onMessage } from "firebase/messaging";
import { getMessaging, onBackgroundMessage } from "firebase/messaging/sw";
export type Provider = "password" | "google.com";

const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
  measurementId: import.meta.env.VITE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging();
const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      // sw.js をサービスワーカーとして登録
      console.log("start");
      const registrationFirebase = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js",
        {
          scope: "/",
          type: "classic",
        }
      );
      console.log(
        "registrationFirebaseサービスワーカーが登録されました:",
        registrationFirebase.scope
      );
    } catch (error) {
      console.error("サービスワーカーの登録に失敗しました:", error);
    }
  } else {
    console.error("このブラウザではサービスワーカーがサポートされていません。");
  }
};

function reauthenticateWithPassword(user: User, password: string) {
  if (!user.email) {
    throw new Error("Email is not set");
  }

  const credential = EmailAuthProvider.credential(user.email, password);
  return reauthenticateWithCredential(user, credential);
}

export async function reauthenticateWithGoogle(user: User) {
  const provider = new GoogleAuthProvider();
  const googleProvider = user.providerData.find(
    (p) => p.providerId === "google.com"
  );
  if (googleProvider?.email) {
    provider.setCustomParameters({
      login_hint: googleProvider.email,
    });
  }

  return reauthenticateWithPopup(user, provider);
}

function updatePassword(user: User, newPassword: string) {
  return _updatePassword(user, newPassword);
}

function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
}

function signInWithEmailAndPassword(email: string, password: string) {
  return _signInWithEmailAndPassword(auth, email, password);
}

function createUserWithEmailAndPassword(email: string, password: string) {
  return _createUserWithEmailAndPassword(auth, email, password);
}

function sendPasswordResetEmail(
  email: string,
  actionCodeSettings: ActionCodeSettings
) {
  return _sendPasswordResetEmail(auth, email, actionCodeSettings);
}

export function verifyBeforeUpdateEmail(user: User, email: string) {
  return _verifyBeforeUpdateEmail(user, email);
}

function checkActionCode(actionCode: string) {
  return _checkActionCode(auth, actionCode);
}

function applyActionCode(actionCode: string) {
  return _applyActionCode(auth, actionCode);
}

function verifyPasswordResetCode(actionCode: string) {
  return _verifyPasswordResetCode(auth, actionCode);
}

function confirmPasswordReset(actionCode: string, newPassword: string) {
  return _confirmPasswordReset(auth, actionCode, newPassword);
}

export function linkWithPassword(user: User, password: string) {
  // linkWithCredentialを使うとなぜかemailの再認証が必要になるので、update password APIを使ってパスワードログインできるようにする
  return _updatePassword(user, password);
}

export function linkWithGoogle(user: User) {
  const provider = new GoogleAuthProvider();
  if (user.email) {
    provider.setCustomParameters({
      login_hint: user.email,
    });
  }

  return linkWithPopup(user, provider);
}

export function unlinkProvider(user: User, providerId: Provider) {
  return unlink(user, providerId);
}

const requestForToken = async () => {
  if ("serviceWorker" in navigator) {
    try {
      // sw.js をサービスワーカーとして登録
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js",
        {
          scope: "/",
          type: "classic",
        }
      );
      const token = await getToken(messaging, {
        vapidKey: import.meta.env.VITE_VAPID_KEY,
        serviceWorkerRegistration: registration,
      });
      return token;
    } catch (error) {
      console.error("サービスワーカーの登録に失敗しました:", error);
    }
  }
};

const onMessageListener = () => {
  return new Promise((resolve: (payload: MessagePayload) => void) => {
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      resolve(payload);
    });
  });
};

const onBackgroundMessageListener = () => {
  return new Promise((resolve: (payload: MessagePayload) => void) => {
    onBackgroundMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      resolve(payload);
    });
  });
};

export {
  auth,
  messaging,
  reauthenticateWithPassword,
  updatePassword,
  signInWithGoogle,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  checkActionCode,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
  registerServiceWorker,
  onMessageListener,
  onBackgroundMessageListener,
  requestForToken,
};
