import { useCallback } from "react";
import * as belongApi from "../../apiClients/belong";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
export function useBelongById(organizationId: string) {
  const toast = useToast();
  const createBelong = useCallback(
    async (name: string, order: number | null) => {
      try {
        const belong = await belongApi.createBelong({
          organizationId,
          name,
          order,
        });
        return belong;
      } catch (err) {
        logger.error(err);
        toast.error("所属の作成に失敗しました");
      }
    },
    [organizationId]
  );

  const updateBelong = useCallback(
    async (belongId: string, name: string, order: number | null) => {
      try {
        const belong = await belongApi.updateBelong({
          belongId,
          name,
          order,
        });
        return belong;
      } catch (err) {
        logger.error(err);
        toast.error("所属の更新に失敗しました");
      }
    },
    []
  );

  const deleteBelong = useCallback(async (belongId: string) => {
    try {
      const belong = await belongApi.deleteBelong({ belongId });
      return belong;
    } catch (err) {
      logger.error(err);
      toast.error("所属の削除に失敗しました");
    }
  }, []);

  return { createBelong, updateBelong, deleteBelong };
}
