import { CurrentUser } from "./auth";
import * as client from "./client";
import { Organization } from "./organization";
import {
  CreateCareerSchemaType,
  DeleteCareerSchemaType,
  GetCareerListSchemaType,
  GetCareerSchemaType,
  UpdateCareerSchemaType,
} from "@shared/validator/features/career.schema";
import { PartSchemaType as Part } from "@shared/validator/models/part.schema";
import { BelongSchemaType as Belong } from "@shared/validator/models/belong.schema";

export type Career = {
  id: string;
  userId: string;
  childId: string | null;
  isGradutatedChild: boolean; // todo: isGraduatedChildにDBカラム名を変更後にこのプロパティは消す
  isHighestPart: boolean;
  organizationId: string;
  belongId: string;
  partId: string;
  startDate: string;
  endDate: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  user: CurrentUser;
  belong: Belong;
  part: Part;
  organization: Organization;
};

export async function getCareer({
  careerId,
}: {
  careerId: string;
}): Promise<Career> {
  const res = await client.get<GetCareerSchemaType, { career: Career }>(
    "/career/getCareer",
    {
      careerId,
    }
  );
  return res.career;
}

export async function getCareerListByUser({
  userId,
}: {
  userId: string;
}): Promise<Career[]> {
  const res = await client.get<GetCareerListSchemaType, Career[]>(
    "/career/getCareerListByUser",
    {
      userId,
    }
  );
  return res;
}

export async function createCareer(
  args: CreateCareerSchemaType
): Promise<Career> {
  const res = await client.post<CreateCareerSchemaType, { career: Career }>(
    "/career/createCareer",
    args
  );
  return res.career;
}

export async function updateCareer(
  args: UpdateCareerSchemaType
): Promise<Career> {
  const res = await client.post<UpdateCareerSchemaType, { career: Career }>(
    "/career/updateCareer",
    args
  );
  return res.career;
}

export async function deleteCareer(careerId: string) {
  const res = await client.post<DeleteCareerSchemaType>(
    "/career/deleteCareer",
    { careerId: careerId }
  );
  return res;
}
