import {
  recruitmentScheduleCloseBodySchema,
  RecruitmentScheduleCloseBodyType,
} from "@shared/validator/features/recruitment-schedule";
import * as client from "./client";

export async function closeRecruitmentSchedule(
  recruitmentId: string,
  recruitmentScheduleIds: string[]
): Promise<void> {
  const args = recruitmentScheduleCloseBodySchema.parse({
    recruitmentId,
    recruitmentScheduleIds,
  });

  await client.post<RecruitmentScheduleCloseBodyType, { result: string }>(
    "/recruitment-schedule/close",
    args
  );
}
