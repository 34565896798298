import { z } from "zod";
import { zRequiredString } from "../rules";

// GET /getOrCreateCustomAggregationUnit のクエリパラメータのスキーマ
export const getOrCreateCustomAggregationUnitSchema = z.object({
  organizationId: zRequiredString,
});

export type GetOrCreateCustomAggregationUnitSchemaType = z.infer<
  typeof getOrCreateCustomAggregationUnitSchema
>;
export const lineCustomAggregationUnitSchema = zRequiredString;

export type LineCustomAggregationUnitSchemaType = z.infer<
  typeof lineCustomAggregationUnitSchema
>;

// POST /getLineInsightWithAmountOfLineMessages のボディパラメータのスキーマ
export const getLineInsightWithAmountOfLineMessagesSchema = z.object({
  customAggregationUnit: lineCustomAggregationUnitSchema,
  from: z.string().datetime(),
  to: z.string().datetime(),
});

export type GetLineInsightWithAmountOfLineMessagesSchemaType = z.infer<
  typeof getLineInsightWithAmountOfLineMessagesSchema
>;
