import * as client from "./client";
import { PostType } from "../hooks/api/post";
import { SurveyPostTargetUserResponse } from "@shared/types/post/getSurveyPostTargetUsers";
import {
  listPostRequestSchema,
  ListPostRequestSchemaType,
  getPostWithTargetsRequestSchema,
  GetPostWithTargetsRequestSchemaType,
  getSurveyPostTargetUsersRequestSchema,
  GetSurveyPostTargetUsersRequestSchemaType,
  createPostRequestSchema,
  CreatePostRequestSchemaType,
  ReapplyPostRequestSchemaType,
  reapplyPostRequestSchema,
  deletePostSchema,
  DeletePostSchemaType,
  remandPostSchema,
  RemandPostSchemaType,
  approvePostSchema,
  ApprovePostSchemaType,
  ResendPostSchemaType,
  resendPostSchema,
  ResendSurveyPostSchemaType,
  resendSurveyPostSchema,
  CancelScheduledPostSchemaType,
  cancelScheduledPostSchema,
} from "@shared/validator/features/post.schema";

export async function getLatestPostByOrganizationId() {
  const post = await client.get("/post/getLatestPost", {});
  return post;
}

/**
 * 連絡一覧を取得する
 */
export async function getPosts(data: ListPostRequestSchemaType) {
  listPostRequestSchema.parse(data);
  const res = await client.post<{}, PostType[]>("/post/list", data);
  return res;
}

// TODO: 以下APIのURIも修正する
/**
 * 自分の連絡を取得する
 */
export async function getCurrentUserPosts() {
  const posts = await client.get<{}, PostType[]>(
    "/post/getCurrentUserPosts",
    {}
  );
  return posts;
}

/**
 * 未承認の連絡を取得する
 */
export async function getUnApprovedPost() {
  const posts = await client.get<{}, PostType[]>("/post/getUnApprovedPost", {});
  return posts;
}

/**
 * 投稿予約中の連絡を取得する
 */
export async function getUnDeliveredPost() {
  const posts = await client.get<{}, PostType[]>(
    "/post/getUnDeliveredPost",
    {}
  );
  return posts;
}

/**
 * 差し戻しされた連絡を取得する
 */
export async function getRemandedPost() {
  const posts = await client.get<{}, PostType[]>("/post/getRemandedPost", {});
  return posts;
}

export async function createPost({
  args,
}: {
  args: CreatePostRequestSchemaType;
}): Promise<PostType> {
  // 改行対応
  const convertedText = args.text.split(/(\n)/).map((item) => {
    return item.match(/\n/) ? "\\n" : item;
  });
  args.text = convertedText.join("");
  createPostRequestSchema.parse(args);
  const res = await client.post<CreatePostRequestSchemaType, PostType>(
    "/post/createPost",
    args
  );
  return res;
}

export async function reapplyPost({
  args,
}: {
  args: ReapplyPostRequestSchemaType;
}): Promise<PostType> {
  // 改行対応
  const convertedText: string[] | undefined = args.text
    .split(/(\n)/)
    .map((item) => {
      return item.match(/\n/) ? "\\n" : item;
    });
  args.text = convertedText.join("");
  reapplyPostRequestSchema.parse(args);
  const res = await client.post<PostType, PostType>(
    "/post/reapplyPost",
    // @ts-ignore
    args
  );
  return res;
}

/**
 * 投稿の削除
 */
export async function deletePost(args: DeletePostSchemaType) {
  deletePostSchema.parse(args);
  const res = await client.post<DeletePostSchemaType, PostType>(
    "/post/deletePost",
    args
  );

  return res;
}

/**
 * 投稿の差し戻し
 */
export async function remandPost(args: RemandPostSchemaType) {
  remandPostSchema.parse(args);
  await client.post<RemandPostSchemaType, PostType>("/post/remandPost", args);
}

/**
 * 投稿の承認
 */
// TODO: 通常承認と最終承認でエンドポイントを分ける
export async function approvePost(args: ApprovePostSchemaType) {
  approvePostSchema.parse(args);
  await client.post<ApprovePostSchemaType, PostType>("/post/approve", args);
}

export async function resendPost(args: ResendPostSchemaType) {
  resendPostSchema.parse(args);
  await client.post<ResendPostSchemaType, PostType>("/post/resendPost", args);
}

export async function resendSurveyPost(args: ResendSurveyPostSchemaType) {
  resendSurveyPostSchema.parse(args);
  await client.post<ResendSurveyPostSchemaType, PostType>(
    "/post/resendSurveyPost",
    args
  );
}

/**
 * 投稿の予約投稿のキャンセル
 */
export async function cancelScheduledPost(args: CancelScheduledPostSchemaType) {
  cancelScheduledPostSchema.parse(args);
  await client.post<CancelScheduledPostSchemaType, PostType>(
    "/post/cancelScheduledPost",
    args
  );
}

export async function getPostWithTargets(postId: string): Promise<PostType> {
  getPostWithTargetsRequestSchema.parse({ postId });
  const { post } = await client.get<
    GetPostWithTargetsRequestSchemaType,
    { post: PostType }
  >("/post/getPostWithTargets", {
    postId,
  });

  return post;
}

export async function getSurveyPostTargetUsers(
  surveyQuestionsId: GetSurveyPostTargetUsersRequestSchemaType["surveyQuestionsId"]
) {
  getSurveyPostTargetUsersRequestSchema.parse({ surveyQuestionsId });
  const res = await client.get<
    GetSurveyPostTargetUsersRequestSchemaType,
    { users: SurveyPostTargetUserResponse[] }
  >("/post/getSurveyPostTargetUsers", { surveyQuestionsId });
  return res.users;
}

export async function uploadPdf({
  pdfFile,
}: {
  pdfFile: File;
}): Promise<string> {
  const res = await client.postWithFormData<
    { pdfFile: File },
    { pdfFilePath: string }
  >("/post/uploadPdf", { pdfFile });
  return res.pdfFilePath;
}
