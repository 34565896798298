import { z } from "zod";
import { zRequiredString } from "../rules";

// LINE アカウントの作成/更新用スキーマ
export const createOrUpdateLineAccountSchema = z.object({
  lineUserId: zRequiredString,
  displayName: z.string().optional(),
  pictureUrl: z.string().optional(),
});

export type CreateOrUpdateLineAccountSchemaType = z.infer<
  typeof createOrUpdateLineAccountSchema
>;
