import React from "react";
import styled from "styled-components";
import { PostType } from "../../hooks/api/post";
import { useCurrentUser } from "../../hooks/recoil/user";
import { ApproveButton } from "./ApproveButton";
import { RemandButton } from "./RemandButton";
import { RePostButton } from "./RePostButton";
import { TrashButton } from "./TrashButton";
import { CancelScheduledButton } from "./CancelScheduledButton";
import { isPtaAdminRole } from "../../utils/types/role";

type props = {
  post: PostType;
  type: "default" | "me" | "unapproved" | "undelivered" | "remanded";
  onPostApproved?: (post: PostType) => void;
  refetch: () => void;
};

export const PostHistoryButtons = ({
  post,
  type,
  onPostApproved,
  refetch,
}: props) => {
  const currentUser = useCurrentUser();

  const isScheduled =
    post.scheduledSendAt && new Date() < new Date(post.scheduledSendAt)
      ? true
      : false;

  /**
   * 削除ボタンの表示フラグ
   * - 自分の投稿または閲覧者が管理者
   * - 投稿が投稿予約状態ではない
   * @return boolean
   */
  const isShowDeleteButton = (() => {
    if (
      (currentUser.id === post.userId || isPtaAdminRole(currentUser.role)) &&
      !isScheduled
    ) {
      return true;
    }
    return false;
  })();

  // 再編集ボタンを表示するかどうかを判定するロジック
  const shouldShowRePostButton =
    (type === "me" && post.status === "REMANDED") ||
    (type === "remanded" &&
      post.status === "REMANDED" &&
      currentUser.id === post.userId);

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      {shouldShowRePostButton && (
        <RePostButton post={post} title="再編集" refetch={refetch} />
      )}
      {type === "me" && post.status === "DRAFT" && (
        <RePostButton post={post} title="下書き編集" refetch={refetch} />
      )}
      {type === "unapproved" && onPostApproved !== undefined && (
        <>
          <ApproveButton post={post} onPostApproved={onPostApproved} />
          <RemandButton post={post} refetch={refetch} />
        </>
      )}
      {type === "undelivered" && (
        <>
          <CancelScheduledButton post={post} refetch={refetch} />
        </>
      )}
      {isShowDeleteButton && <TrashButton post={post} refetch={refetch} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
`;
