import React from "react";
import styled from "styled-components";
import { Margin } from "src/components/Margin";
import { SchoolType } from "@shared/types/organization";
import { TargetGradesType } from "@shared/types/post/api";
import { useGradeOptions } from "src/hooks/useGradeOptions";

type Props = {
  selectedGrades: TargetGradesType[];
  setSelectedGrades: (grades: TargetGradesType[]) => void;
  schoolType: SchoolType | undefined;
};

export const CustomGrade = ({
  selectedGrades,
  setSelectedGrades,
  schoolType,
}: Props) => {
  const targetGradesOption = useGradeOptions(schoolType);

  const handleCheckAllGrades = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedGrades([1, 2, 3, 4, 5, 6]);
    } else {
      setSelectedGrades([]);
    }
  };
  return (
    <>
      <SubText>※未選択であれば全てが対象になります。</SubText>
      <Margin marginTop={12} />
      <CustomSelectWrapper>
        <div>
          <label>
            <CheckBox
              type="checkbox"
              onChange={handleCheckAllGrades}
              checked={selectedGrades.length === 6}
            />
            全て
          </label>
        </div>
        {targetGradesOption.map((grade) => (
          <div key={grade.id}>
            <label>
              <CheckBox
                type="checkbox"
                value={grade.id}
                checked={selectedGrades.includes(grade.id)}
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked) {
                    setSelectedGrades([...selectedGrades, grade.id]);
                  } else {
                    setSelectedGrades(
                      selectedGrades.filter((g) => g !== grade.id)
                    );
                  }
                }}
              />
              {grade.name}
            </label>
          </div>
        ))}
      </CustomSelectWrapper>
    </>
  );
};

const CustomSelectWrapper = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CheckBox = styled.input`
  font-family: "Avenir-Roman";
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border: 1px solid #c9cbcd;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  transition: all 100ms;
  &:checked {
    background: #0077cc;
    border: 1px solid #0077cc;
  }
`;

const SubText = styled.p`
  font-size: 12px;
  color: #666;
`;
