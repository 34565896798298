import * as client from "./client";
import { Child } from "@shared/types/child";
import {
  createPTAParentFamilyInvoiceSchema,
  CreatePTAParentFamilyInvoiceType,
} from "@shared/validator/features/ptaParentFamilyInvoice.schema";
import {
  ptaImportCsvInvoiceTargetSchema,
  PTAImportCsvInvoiceTargetType,
} from "@shared/validator/models/invoice/ptaImportCsvInvoiceTarget.schema";

// 請求用PTA名簿CSVインポート
export async function getImportCsvTargetByPTAInvoiceProcess(
  data: PTAImportCsvInvoiceTargetType
): Promise<Child[]> {
  const args = ptaImportCsvInvoiceTargetSchema.parse(data);
  const res = await client.get<{}, Child[]>(
    "/invoice/ptaImportCsvInvoiceTarget",
    { args: JSON.stringify(args) }
  );
  return res;
}

/**
 * 請求用PTA名簿CSVインポート実行
 * - データベースに保存する
 */
export async function executePTAParentFamilyInvoice(
  data: CreatePTAParentFamilyInvoiceType
): Promise<void> {
  const args = createPTAParentFamilyInvoiceSchema.parse(data);
  const res = await client.post<{}, void>(
    "/invoice/ptaParentFamilyInvoice/execute",
    args
  );
  return res;
}
