import { z } from "zod";
import { zRequiredString, zHiraganaString } from "../rules";
import { classNumberSchema, gradeNumberSchema } from "../rules/child";
import { userSchema } from "./user.schema";

export const childSchema = z.object({
  id: z.string().optional(),
  userId: z.string().optional(),
  organizationId: z.string().optional(),
  childLastName: zRequiredString,
  childFirstName: zRequiredString,
  childLastNameKana: zHiraganaString,
  childFirstNameKana: zHiraganaString,
  grade: gradeNumberSchema.optional(),
  class: classNumberSchema.optional(),
  user: userSchema.optional(),
  accountId: z.string().optional(),
});

export type Child = z.infer<typeof childSchema>;

export const childrenFilterSchema = z.object({
  grades: z.array(gradeNumberSchema).optional(),
  classes: z.array(classNumberSchema).optional(),
  parentsAreUnique: z.union([z.literal("0"), z.literal("1")]).optional(),
  includeAdmin: z.union([z.literal("0"), z.literal("1")]).optional(),
});

export type ChildrenFilter = z.infer<typeof childrenFilterSchema>;
