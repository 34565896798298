import { useEffect, useState } from "react";
import { CurrentUser } from "../../apiClients/auth";
import * as postApi from "../../apiClients/post";
import type { User } from "../../apiClients/users";
import { SurveyPostTargetUserResponse } from "@shared/types/post/getSurveyPostTargetUsers";
import { logger } from "src/utils/logger";
import {
  PostBaseType,
  TargetGradeAndClass,
  TargetGradesType,
} from "@shared/types/post/api";
import { ReapplyPostRequestSchemaType } from "@shared/validator/features/post.schema";

type PostApproval = {
  id: string;
  postId: string;
  userId: string;
  user: User;
  approve: boolean;
  comment?: string;
  createdAt: Date;
  updatedAt: Date;
};

export type PostType = PostBaseType & {
  targetUsers: { user: User }[];
  targetAllTag: boolean;
  targetGradeTags: TargetGradesType[];
  targetGradeAndClasses: TargetGradeAndClass[];
  targetBelongIdTags: string[];
  targetPostUserGroupIdTags: string[];
  postApprovals: PostApproval[];
};

export function useLatestPostByOrganizationId() {
  const [post, setPost] = useState<PostType>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getPost();
  }, []);

  const getPost = async () => {
    setIsLoading(true);
    const post = await postApi.getLatestPostByOrganizationId();
    // @ts-ignore
    setPost(post);
    setIsLoading(false);
  };

  return { post, isLoading };
}

export function useMinePostList() {
  const [posts, setPosts] = useState<PostType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    const posts = await postApi.getCurrentUserPosts();
    setPosts(posts);
    setIsLoading(false);
  };

  return { posts, isLoading, getPosts };
}

export function useUnApprovedPostList(user: CurrentUser) {
  const [posts, setPosts] = useState<PostType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    let posts: PostType[] = [];
    if (user.isApprover) {
      posts = await postApi.getUnApprovedPost();
    }
    setPosts(posts);
    setIsLoading(false);
  };

  return { posts, getPosts, isLoading };
}

export function useUnDeliveredPostList(user: CurrentUser) {
  const [posts, setPosts] = useState<PostType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    let posts: PostType[] = [];
    if (user.isApprover) {
      posts = await postApi.getUnDeliveredPost();
    }
    setPosts(posts);
    setIsLoading(false);
  };

  return { posts, getPosts, isLoading };
}

export function useRemandedPostList() {
  const [posts, setPosts] = useState<PostType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    setIsLoading(true);
    try {
      const remandedPosts = await postApi.getRemandedPost();
      setPosts(remandedPosts);
    } catch (error) {
      console.error("差し戻し投稿の取得に失敗しました", error);
      setPosts([]);
    } finally {
      setIsLoading(false);
    }
  };

  return { posts, isLoading, getPosts };
}

export function usePostWithTargets(postId: string) {
  const [post, setPost] = useState<PostType>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getPost();
  }, []);

  const getPost = async () => {
    const post = await postApi.getPostWithTargets(postId);
    setPost(post);
    setIsLoading(false);
  };

  return { post, isLoading };
}

/**
 * アンケートの投稿対象者を取得する
 */
export function useSurveyPostTargetUsers(surveyQuestionsId: string) {
  const [targetUsers, setTargetUsers] = useState<
    SurveyPostTargetUserResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getTargetUsers();
  }, []);

  const getTargetUsers = async () => {
    const targetUsers = await postApi.getSurveyPostTargetUsers(
      surveyQuestionsId
    );
    setTargetUsers(targetUsers);
    setIsLoading(false);
  };

  return { targetUsers, isLoading };
}

/**
 * 投稿の更新
 */
export async function reapplyPost(args: ReapplyPostRequestSchemaType) {
  try {
    const post = await postApi.reapplyPost({ args });
    return post;
  } catch (error) {
    logger.error(error);
  }
}

/**
 * 投稿の削除
 */
export async function deletePost(postId: string) {
  try {
    const post = await postApi.deletePost({ postId });
    return post;
  } catch (error) {
    logger.error(error);
  }
}

/**
 * 投稿の差し戻し
 */
export async function remandPost(postId: string, comment: string) {
  try {
    await postApi.remandPost({ postId, comment });
  } catch (error) {
    logger.error(error);
  }
}

export async function resendPost(postId: string, userIds: string[]) {
  try {
    await postApi.resendPost({ postId, userIds });
  } catch (error) {
    logger.error(error);
  }
}

export async function resendSurveyPost(postId: string, userIds: string[]) {
  try {
    await postApi.resendSurveyPost({ postId, userIds });
  } catch (error) {
    logger.error(error);
  }
}
