import React from "react";
import { Button } from "src/components/Button";
import { usePushNotificationSetting } from "src/hooks/query/pushNotification";
import styled from "styled-components";

export const PushNotificationSettingSection = () => {
  const { isPushNotificationEnabled, updatePushNotificationEnabled } =
    usePushNotificationSetting();

  return (
    <>
      <SettingMainItem>
        <SettingItemLabel>
          <SettingItemName>プッシュ通知設定</SettingItemName>
        </SettingItemLabel>
        <SettingAction>
          <Button
            size="small"
            color={isPushNotificationEnabled ? "subPrimary" : "primary"}
            onClick={() =>
              updatePushNotificationEnabled(!isPushNotificationEnabled)
            }
          >
            {isPushNotificationEnabled ? "無効にする" : "有効にする"}
          </Button>
        </SettingAction>
      </SettingMainItem>
      <SettingItemDetailDescription>
        連絡やチャットの通知を受け取ることができるようになります。アプリをご利用の場合は、デバイスの通知設定を行い、ホーム画面にアプリを追加してください。
        <br />
        PWA（ホーム画面に追加するアプリ）についての詳細は
        <a href="https://www.youtube.com/watch?v=YJp2lhKjKSI">こちら</a>
        をご覧ください。
      </SettingItemDetailDescription>
    </>
  );
};

const SettingItemLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
`;

const SettingItemName = styled.span`
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
`;
const SettingMainItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SettingItemDetailDescription = styled.p`
  font-weight: 400;
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  color: #808080;
  margin-top: 4px;
`;
const SettingAction = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 10px;
`;
