import { SchoolDisplayType } from "../types/organization";

const getLabel = (prefix: string, grade: number, showPrefix: boolean) =>
  showPrefix ? `${prefix}${grade}` : `${grade}`;

const getPrefix = (
  shortPrefix: string,
  prefixType: "full" | "short" = "short"
): string => {
  if (prefixType === "short") return shortPrefix;
  switch (shortPrefix) {
    case "小":
      return "小学";
    case "中":
      return "中学";
    case "高":
      return "高校";
    default:
      return shortPrefix;
  }
};

/**
 * 学年、表示タイプに基づいて学年ラベルを生成する関数
 */
export function getGradeLabel(
  grade: number | undefined | null,
  schoolDisplayType: SchoolDisplayType | undefined,
  showPrefix: boolean,
  showSuffix: boolean,
  prefixType: "full" | "short" = "short",
  suffixLabel: string = "年"
): string {
  if (grade === undefined || grade === null) {
    return "-";
  }
  if (!schoolDisplayType || grade < 1 || grade > 12) return String(grade);
  let label = "";

  if (schoolDisplayType === "SEGMENTED") {
    // 学校種別表記: 学校段階ごとに区切って表示 (例: 小1, 小2, ..., 中1, 中2, 中3)
    if (grade <= 6) {
      label = getLabel(
        showPrefix ? getPrefix("小", prefixType) : "",
        grade,
        showPrefix
      );
    } else if (grade <= 9) {
      label = getLabel(
        showPrefix ? getPrefix("中", prefixType) : "",
        grade - 6,
        showPrefix
      );
    } else {
      label = getLabel(
        showPrefix ? getPrefix("高", prefixType) : "",
        grade - 9,
        showPrefix
      );
    }
  } else {
    // 連番表記: 学年を1から連続した数字で表示 (例: 1, 2, 3, ..., 9)
    label = String(grade);
  }

  return showSuffix ? `${label}${suffixLabel}` : label;
}
